<app-buttons (button_selected)="option_selector($event)"></app-buttons>
<app-grid [rowData]="rowData" [columnDefs]="columnDefs" [show_filter]="true" [rowSelection]="'single'"></app-grid>


<!-- Ventana modal para creación y actualización de usuarios -->
<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" *ngIf="!user.user_id">Nuevo Usuario</h4>
        <h4 class="modal-title" *ngIf="user.user_id">Actualizar Usuario</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body p-0">
        <div class="card m-b-0">
            <ul class="nav nav-tabs profile-tab" role="tablist">
                <li class="nav-item"> <a class="nav-link active" data-toggle="tab" href="#account" role="tab">Cuenta de usuario</a></li>
                <li class="nav-item" *ngIf="user.user_id"> <a class="nav-link"  data-toggle="tab" href="#general" role="tab">Generales</a></li>
                <li class="nav-item" *ngIf="user.user_id && user.role_id == 9"> <a class="nav-link" data-toggle="tab"  href="#medico" role="tab">Médico</a></li>
                <li class="nav-item" *ngIf="user.user_id"> <a class="nav-link" data-toggle="tab" href="#address"  role="tab">Domicilio</a></li>
            </ul>
            <div class="tab-content">
                <div class="tab-pane active" id="account" role="tabpanel">
                    <app-account [user]="user" [show_cancel_button]=true (close_modal)="modal.close('Cancelar')"  (submit_form)="create_user($event)"></app-account>
                </div>
                <div class="tab-pane" id="general" role="tabpanel" *ngIf="user.user_id">
                    <app-person [person]="user.person" [show_cancel_button]=true (close_modal)="modal.close('Cancelar')"  (submit_form)="update_profile($event)"></app-person>
                </div>
                <div class="tab-pane" id="medico" role="tabpanel" *ngIf="user.user_id && user.role_id == 9">
                    <app-medico [medico]="user.medico" [show_cancel_button]=true (close_modal)="modal.close('Cancelar')"  (submit_form)="update_medico($event)"></app-medico>
                </div>
                <div class="tab-pane" id="address" role="tabpanel" *ngIf="user.user_id">
                    <app-address [address]="user.person.address" [show_cancel_button]=true    (close_modal)="modal.close('Cancelar')" (submit_form)="update_address($event)"></app-address>
                </div>
            </div>
        </div>
    </div>
</ng-template>


<!-- Ventana modal para creación y actualización de roles -->
<ng-template #modal2 let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Asignación de módulos y permisos</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"><span
                aria-hidden="true">&times;</span></button>
    </div>
    <div class="modal-body p-0">
        <div class="card m-b-0">
            <ul class="nav nav-tabs profile-tab" role="tablist">
                <li class="nav-item"> <a class="nav-link active" data-toggle="tab" href="#account" role="tab">Usuario
                        [{{
                        user.username }}]</a></li>
            </ul>
            <div class="tab-content">
                <div class="tab-pane active" id="account" role="tabpanel">
                    <app-authorization-dialog [user_id]="user.user_id"></app-authorization-dialog>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!-- Ventana modal para administracion de horarios -->
<ng-template #modal3 let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Administración de horarios y excepciones</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"><span
                aria-hidden="true">&times;</span></button>
    </div>
    <div class="modal-body p-0">
        <div class="card m-b-0">
                <app-horario-dialog [medico]="user.medico"  (close_modal)="modal.close('Cancelar')"  (submit_form)="update_horario($event)"></app-horario-dialog>
        </div>
    </div>
</ng-template>
<!-- Ventana modal para compartir agenda -->
<ng-template #modal4 let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Compartir agenda</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"><span
                aria-hidden="true">&times;</span></button>
    </div>
    <div class="modal-body p-0">
        <br/>
        <h5 class="card-title  col-md-12">Compartir agenda médica del usuario <strong>{{user.person.fullname}}</strong><br/>
            Al compartir la agenda médica los usuarios compartidos podran visualizar las citas agendadas asi como la posibilidad de realizar la consulta médica en caso de que se trate de un perfil médico.
             </h5>
        <div class="card m-b-0">
                <app-share-schedule-dialog [user_id]="user.user_id"></app-share-schedule-dialog>
        </div>
    </div>
</ng-template>