<div class="card-body p-b-0">
  <form class="form-material" [formGroup]="modal_devoluciones_form" (ngSubmit)="on_submit()" #form="ngForm"  novalidate>
      <div class="row">
          <div class="col-md-4" >
            <div class="form-group">
              <label for="fecha_prestamo">Fecha de Devolución</label>
              <input type="text" formControlName="fecha_devolucion_real" placeholder="Fecha de devolución" class="form-control" id="fecha_devolucion_real"  ngbDatepicker #d1="ngbDatepicker" (focus)="d1.toggle()" />
              <i class="mdi mdi-calendar"></i>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
             <label for="ddl_persona_recibe">Personal devuelve</label>
             <ng-select  id="ddl_persona_recibe" class="form-control"
                  [items]="personal$ | async"
                   bindLabel="person.fullname"
                   bindValue="person.person_id"
                   [loading]="personalLoading"
                   [typeahead]="personalInput$"
                   formControlName="personal_devuelve_id"
                   placeholder="Personal entrega"
                   typeToSearchText ="Buscar personal"
                   notFoundText = "No se encontró personal"
                   loadingText = "Buscando">
            </ng-select>
            </div>
    </div>
      </div>
      <div class="row">
     
      
    </div>
      <div class="row m-t-30">
          <div class="col-md-12 modal-footer">
              <button *ngIf="show_cancel_button" type="button" class="btn btn-danger btn-sm" (click) = "on_close()" ><i class="mdi mdi-close"></i> Cancelar</button>
              <button type="submit" class="btn btn-primary btn-sm "><i class="mdi mdi-content-save"></i> Guardar</button>
          </div>
      </div>
  </form>
</div> 

