import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { IFormGroup, IFormBuilder } from '@rxweb/types';
import { Diagnostico, IDiagnostico } from 'src/app/models/diagnostico.model';
import { Utils } from 'src/app/shared/helpers/utils';
import { FormsService } from 'src/app/services/service.index';

@Component({
  selector: "app-diagnosticos-dialog",
  templateUrl: "./diagnosticos-dialog.component.html",
  styles: [],
})

export class DiagnosticosDialogComponent implements OnInit {
  @ViewChild("form", { static: true }) form;
  @Output() submit_form = new EventEmitter<IFormGroup<Diagnostico>>();
  @Output() close_modal = new EventEmitter();
  @Input() show_cancel_button: boolean = false;
  @Input() show_save_button: boolean = false;
  @Input() show_close_button: boolean = false;
  //@Input() catalog: Diagnostico;
  @Input() catalog: IDiagnostico;

  options = [
    { label: "Si", value: true },
    { label: "No", value: false },
  ];
  optionsGenero = [
    { label: "Masculino", value: 1 },
    { label: "Femenino", value: 2 },
  ];
  optionsEdad = [
    { label: "Días", value: 1 },
    { label: "Meses", value: 2 },
    { label: "Años", value: 3 },
  ];
  componentForm: IFormGroup<IDiagnostico>;
  formBuilder: IFormBuilder;


  constructor(formbuilder: FormBuilder, public _frm: FormsService) {
    this.formBuilder = formbuilder;
  }

  ngOnInit() {
    let utils = new Utils();
    var disableCie10 = this.catalog.cie10 ? true : false;
    this.componentForm = this.formBuilder.group<IDiagnostico>({
      diagnostico_id: [this.catalog.diagnostico_id],
      clase: [{value: this.catalog.clase, disabled: disableCie10}],
      descripcion: [{value: this.catalog.descripcion, disabled: disableCie10}],
      padre_id: [this.catalog.padre_id],
      is_leaf: [this.catalog.is_leaf],
      genero_id: [{value: this.catalog.genero_id, disabled: disableCie10}],
      genero_text: [{value: this.catalog.genero_text, disabled: disableCie10}],
      clave: [{value: this.catalog.clave, disabled: disableCie10}, Validators.required],
      edad_minima: [{value: utils.millisecondsToData(this.catalog.edad_minima).value, disabled: disableCie10}],
      edad_maxima: [{value: utils.millisecondsToData(this.catalog.edad_maxima).value, disabled: disableCie10}],
      edad_minima_tipo: [{value: utils.millisecondsToData(this.catalog.edad_minima).formatType, disabled: disableCie10}],
      edad_maxima_tipo: [{value: utils.millisecondsToData(this.catalog.edad_maxima).formatType, disabled: disableCie10}],
      edad_minima_text: [this.catalog.edad_minima_text],
      edad_maxima_text: [this.catalog.edad_maxima_text],
      clase_descripcion: [this.catalog.clase_descripcion, Validators.required],
      accion: [this.catalog.accion],
      cie10: [this.catalog.cie10],
    });
  }
  on_submit() {
    this.submit_form.emit(this.componentForm);
  }
  on_close() {
    this.close_modal.emit();
  }  
}
