<div class="card-body">
    <form class="form-material" [formGroup]="address_form" (ngSubmit)="onSubmit()">
        <div class="row">
            <div class="col-md-4">
                <div class="form-group">
                    <label for="input1">Código Postal</label>
                    <input type="text" placeholder="Código Postal" class="form-control" id="input1" (blur)="change_postalcode($event.target.value)"
                        formControlName="postal_code" maxlength="5">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="form-group">
                    <label for="gender">Estado</label>
                    <ng-select [searchable]="true" placeholder="Estado" formControlName="state_id" (change)="change_state($event)"
                        class="form-control" [items]="states"  bindLabel="name" bindValue="state_id">
                    </ng-select>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label for="gender">Delegación/Municipio</label>
                    <ng-select [searchable]="true" placeholder="Delegación/Municipio" formControlName="municipality_id" class="form-control"  (change)="change_municipality($event)"
                         [items]="municipalities"  bindLabel="name" bindValue="municipality_id">
                    </ng-select>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label for="gender">Colonia</label>
                    <ng-select [searchable]="true" placeholder="Colonia" formControlName="settlement" class="form-control" (change)="change_settlement($event)"
                        [items]="settlements"  bindLabel="name" bindValue="name">
                    </ng-select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="form-group">
                    <label for="input4">Calle</label>
                    <input type="email" placeholder="Calle" class="form-control" id="input4" formControlName="street" >
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label for="input5">Número Exterior</label>
                    <input type="text" placeholder="Número Exterior" class="form-control" id="input5" formControlName="exterior_number">
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label for="input5">Número Interior</label>
                    <input type="text" placeholder="Número Interior" class="form-control" id="input5" formControlName="interior_number">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label for="input5">Referencia</label>
                    <input type="text" placeholder="Referencia" class="form-control" id="input5" formControlName="reference">
                </div>
            </div>
        </div>

        <agm-map [latitude]="19.371125" [longitude]="-99.15474" [zoom]="15" [disableDefaultUI]="true" [zoomControl]="true">
            <agm-marker [latitude]="19.371125" [longitude]="-99.15474" [markerDraggable]="true"></agm-marker>
        </agm-map>


        <div class="row m-t-30">
            <div class="col-md-12  modal-footer">
                <button *ngIf="show_cancel_button" type="button" class="btn btn-danger btn-sm" (click) = "on_close()" ><i class="mdi mdi-close"></i> Cancelar</button>
                <button type="submit" class="btn btn-primary btn-sm"><i class="mdi mdi-content-save"></i>Guardar Domicilio</button>
            </div>
        </div>
    </form>
</div>