export class catalog {

    id?: number = null;
    nombre: string = "";
    codigo: string = "";
    descripcion: string = "";
    tabla:string = "";
    claveid:string = "";

    constructor(data: catalog | {} = {}) {
        Object.assign(this, data);
    }
}