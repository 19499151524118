export class medico {
    medico_id?: number = null;
    consultas_maximas?: number = null;
    cedula: string = "";
    consulta_externa?: boolean = null;
    nombre_completo: string = "";
    person_id?: number = null;
    name: string = "";
    last_name: string = "";
    middle_name: string = "";
    birthdate?: Date = null;
    email: string = "";
    home_phone: string = "";
    office_phone: string = "";
    mobile_phone: string = "";
    rfc: string = "";
    curp: string = "";
    gender_id?: number = null;
    photo: string = "";
    especialidades: string = "";
    saved_by?: number = null;

    constructor(data: medico | {} = {}) {
        Object.assign(this, data);
    }

}

export class medico_search {
    medico_id?: number = null;
    medico: string = "";
    curp: string = "";
    cedula: string = "";
    especialidad_id?: number = null;
    especialidad: string = "";

    constructor(data: medico_search | {} = {}) {
        Object.assign(this, data);
    }

}

export class horario {
    horario_id?: number = null;
    medico_id?: number = null;
    dia_id?: number = null;
    dia: string = "";
    horario_cadena: string = "";
    especialidad_id?: number = null;
    hora_inicio: string = "";
    hora_fin: string = "";

    constructor(data: horario | {} = {}) {
        Object.assign(this, data);
    }
}

export class calendario {
    fecha?: Date = null;
    laborable?: boolean = null;
    observacion: string = "";
    disponibilidad: string = "";

    consultas?: number = null;

    consultas_maximas?: number = null;

    constructor(data: calendario | {} = {}) {
        Object.assign(this, data);
    }


}

export class especialidad {
    id?: number = null;
    nombre:string = "";
    codigo: string = "";
    descripcion: string = "";
    activo?: boolean = null;

    constructor(data: especialidad | {} = {}) {
        Object.assign(this, data);
    }

}


export class article {
    articulo_id?: number = null;
    titulo:string = "";
    descripcion: string = "";
    ruta_archivo: string = "";
    ruta_image: string = "";
    autor: string = "";
    url: string = "";
    fecha_publicacion:  Date = null;
    b64_archivo: string = "";
    b64_image: string = "";
    activo?: boolean = null;

    constructor(data: article | {} = {}) {
        Object.assign(this, data);
    }

}
