<div class="card-body p-b-0">

    <div class="row">
        <div class="col-md-12">


            <form class="form-material"  [formGroup]="certificado_form"  #form="ngForm" (ngSubmit)="on_submit()" novalidate>

                <div  class="row">
                    <div class="col-md-12">
                        <label for="txt_antecedentes">Exploración física:</label>
                    </div>
                    <div class="col-md-4"  *ngFor="let exp of exploracion" >
                        <span style="font-size: 12px; color: #263238;">{{ exp.label }} : {{ exp.value }} {{ exp.placeholder}}</span>
                        
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <label for="txt_resultado_exploracion">Resultado de la exploración física:</label>
                    </div>
                    <div class="col-md-12">
                        <textarea style="height: 100px !important;" formControlName="resultado_exploracion" placeholder="Resultado de la exploración física" class="form-control" id="txt_resultado_exploracion"></textarea>
                    </div>
                </div>
                <div  class="row">
                    <div class="col-md-12 m-t-30">
                        <div class="form-group" >
                            <label for="txt_antecedentes">Antecedentes:</label>
                            <textarea formControlName="antecedentes" placeholder="Antecedentes" class="form-control" id="txt_antecedentes"></textarea>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="txt_observaciones">Observaciones:</label>
                            <textarea formControlName="observaciones" placeholder="Observaciones" class="form-control" id="txt_observaciones" style="height: 50px;"></textarea>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group"  [ngClass]="{ 'has-danger': is_invalid('estado')}">
                            <label for="txt_estado">Estado de salud:</label>
                            <textarea formControlName="estado" placeholder="Estado de salud" class="form-control" id="txt_estado"></textarea>
                            <div class="invalid-feedback">
                                <div  *ngIf="certificado_form.controls['estado'].errors?.required">Ingresa el estado de salud del paciente</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row m-t-30">
                    <div class="col-md-12 modal-footer" style="border: none;">
                        <button type="submit" class="btn btn-primary btn-sm"  *ngIf="!isNew()" ><i class="mdi mdi-content-save"></i> Guardar</button>

                        <button type="button" class="btn btn-primary btn-sm" *ngIf="isNew()" (click)="on_print()"  ><i class="mdi mdi-printer"></i> Imprimir</button>

                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="row m-t-30">
        <div class="col-md-12 modal-footer"></div>
    </div>


</div>