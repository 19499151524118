<div class="card-body p-b-0"> 
  <ul class="nav nav-tabs profile-tab" role="tablist">
    <li class="nav-item"> <a class="nav-link active" data-toggle="tab" href="#horarios" role="tab">Horarios</a></li>
    <li class="nav-item"> <a class="nav-link" data-toggle="tab" href="#exepciones" role="tab">Excepciones</a></li>
  </ul>
  <div class="tab-content">
    <div class="tab-pane active" id="horarios" role="tabpanel">

      <div class="row p-t-10">
        <div class="col-md-12">
          <form class="form-material" [formGroup]="especialidad_form" (ngSubmit)="on_submit()" #form="ngForm"
            novalidate>
            <div class="form-group">
              <label for="ddl_especialidad">Especialidad</label>
              <ng-select placeholder="Seleccione la especialidad" id="ddl_especialidad" class="form-control"
                formControlName="especialidad_id" [items]="especialidades2" bindLabel="nombre" bindValue="id"
                [searchable]="true" (change)="change_especialidad($event)"></ng-select>
            </div>
          </form>
        </div>
      </div>
      <form class="form-material" (ngSubmit)="on_submit()" novalidate>
        <div class="row p-t-10">
          <div class="col-sm-12">

            <ul class="list-group">
              <li class="list-group-item">
                <div class="row">
                  <div class="col-sm-2">
                    <div class="checkbox checkbox-primary">
                      <input type="checkbox" id="lunes" class="filled-in chk-col-light-blue" #inputlunes 
                        [disabled]="!especialidad_form.controls['especialidad_id'].value" [checked]="horario.lun.checked"  (change)="change_day(horario.lun, $event)">
                      <label for="lunes"> Lunes </label>
                    </div>
                  </div>
                  <div class="col-sm-10" *ngIf="inputlunes.checked" >
                    <div *ngFor="let item of horarioEspecialidad.days.lunesArray; let i = index" class="row">
                      <div class="col-sm-10">
                        <ion-range-slider #slider_lunes skin="modern" type="double" min="0" max="23.5"
                      [(from)]="item.from" [(to)]="item.to" grid="true" grid_num="24" step="0.5"
                      [prettify]="toHourString" (onChange)="change_slider(horarioEspecialidad.days.lunesArray, i, $event)"></ion-range-slider>  
                      </div>
                      <div class="col-sm-2">
                        <button type="button" class="btn btn-secondary" ngbTooltip="Eliminar" (click)="remove_horario(horarioEspecialidad.days.lunesArray, i)"><i class="mdi mdi-delete" style="color:red;"></i></button>
                      </div>                                                                
                    </div>
                      <div class="row">
                        <div class="col-sm-10"></div>
                        <div class="col-sm-2">
                          <button type="button" class="btn btn-secondary" ngbTooltip="Agregar" (click)="add_horario(horarioEspecialidad.days.lunesArray)"><i class="mdi mdi-plus-box"></i></button>
                        </div>
                    </div>
                  </div>                  
                </div>
              </li>
              <li class="list-group-item">
                <div class="row">
                  <div class="col-sm-2">
                    <div class="checkbox checkbox-primary">
                      <input type="checkbox" id="martes" class="filled-in chk-col-light-blue" #inputmartes
                        [disabled]="!especialidad_form.controls['especialidad_id'].value" [checked]="horario.mar.checked"  (change)="change_day(horario.mar, $event)">
                      <label for="martes"> Martes </label>
                    </div>
                  </div>
                  <div class="col-sm-10" *ngIf="inputmartes.checked">
                      <div *ngFor="let item of horarioEspecialidad.days.martesArray; let i = index" class="row">
                        <div class="col-sm-10">
                          <ion-range-slider #slider_martes skin="modern" type="double" min="0" max="23.5"
                        [(from)]="item.from" [(to)]="item.to" grid="true" grid_num="12" step="0.5"
                        [prettify]="toHourString" (onChange)="change_slider(horarioEspecialidad.days.martesArray, i, $event)"></ion-range-slider>  
                        </div>
                        <div class="col-sm-2">
                          <button type="button" class="btn btn-secondary" ngbTooltip="Eliminar" (click)="remove_horario(horarioEspecialidad.days.martesArray, i)"><i class="mdi mdi-delete" style="color:red;"></i></button>
                        </div>                                                                
                      </div>
                        <div class="row">
                          <div class="col-sm-10"></div>
                          <div class="col-sm-2">
                            <button type="button" class="btn btn-secondary" ngbTooltip="Agregar" (click)="add_horario(horarioEspecialidad.days.martesArray)"><i class="mdi mdi-plus-box"></i></button>
                          </div>
                      </div>
                  </div>
                </div>
              </li>
              <li class="list-group-item">
                <div class="row">
                  <div class="col-sm-2">
                    <div class="checkbox checkbox-primary">
                      <input type="checkbox" id="miercoles" class="filled-in chk-col-light-blue" #inputmiercoles
                        [disabled]="!especialidad_form.controls['especialidad_id'].value" [checked]="horario.mie.checked"  (change)="change_day(horario.mie, $event)">
                      <label for="miercoles"> Miercoles </label>
                    </div>
                  </div>
                  <div class="col-sm-10" *ngIf="inputmiercoles.checked">
                    <div *ngFor="let item of horarioEspecialidad.days.miercolesArray; let i = index" class="row">
                      <div class="col-sm-10">
                        <ion-range-slider #slider_miercoles skin="modern" type="double" min="0" max="23.5"
                      [(from)]="item.from" [(to)]="item.to" grid="true" grid_num="12" step="0.5"
                      [prettify]="toHourString" (onChange)="change_slider(horarioEspecialidad.days.miercolesArray, i, $event)"></ion-range-slider>  
                      </div>
                      <div class="col-sm-2">
                        <button type="button" class="btn btn-secondary" ngbTooltip="Eliminar" (click)="remove_horario(horarioEspecialidad.days.miercolesArray, i)"><i class="mdi mdi-delete" style="color:red;"></i></button>
                      </div>                                                                
                    </div>
                      <div class="row">
                        <div class="col-sm-10"></div>
                        <div class="col-sm-2">
                          <button type="button" class="btn btn-secondary" ngbTooltip="Agregar" (click)="add_horario(horarioEspecialidad.days.miercolesArray)"><i class="mdi mdi-plus-box"></i></button>
                        </div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="list-group-item">
                <div class="row">
                  <div class="col-sm-2">
                    <div class="checkbox checkbox-primary">
                      <input type="checkbox" id="jueves" class="filled-in chk-col-light-blue" #inputjueves
                        [disabled]="!especialidad_form.controls['especialidad_id'].value" [checked]="horario.jue.checked"  (change)="change_day(horario.jue, $event)">
                      <label for="jueves"> Jueves </label>
                    </div>
                  </div>
                  <div class="col-sm-10" *ngIf="inputjueves.checked">
                    <div *ngFor="let item of horarioEspecialidad.days.juevesArray; let i = index" class="row">
                      <div class="col-sm-10">
                        <ion-range-slider #slider_jueves skin="modern" type="double" min="0" max="23.5"
                      [(from)]="item.from" [(to)]="item.to" grid="true" grid_num="12" step="0.5"
                      [prettify]="toHourString" (onChange)="change_slider(horarioEspecialidad.days.juevesArray, i, $event)"></ion-range-slider>  
                      </div>
                      <div class="col-sm-2">
                        <button type="button" class="btn btn-secondary" ngbTooltip="Eliminar" (click)="remove_horario(horarioEspecialidad.days.juevesArray, i)"><i class="mdi mdi-delete" style="color:red;"></i></button>
                      </div>                                                                
                    </div>
                      <div class="row">
                        <div class="col-sm-10"></div>
                        <div class="col-sm-2">
                          <button type="button" class="btn btn-secondary" ngbTooltip="Agregar" (click)="add_horario(horarioEspecialidad.days.juevesArray)"><i class="mdi mdi-plus-box"></i></button>
                        </div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="list-group-item">
                <div class="row">
                  <div class="col-sm-2">
                    <div class="checkbox checkbox-primary">
                      <input type="checkbox" id="viernes" class="filled-in chk-col-light-blue" #inputviernes
                        [disabled]="!especialidad_form.controls['especialidad_id'].value" [checked]="horario.vie.checked"  (change)="change_day(horario.vie, $event)">
                      <label for="viernes"> Viernes </label>
                    </div>
                  </div>
                  <div class="col-sm-10" *ngIf="inputviernes.checked">
                    <div *ngFor="let item of horarioEspecialidad.days.viernesArray; let i = index" class="row">
                      <div class="col-sm-10">
                        <ion-range-slider #slider_viernes skin="modern" type="double" min="0" max="23.5"
                      [(from)]="item.from" [(to)]="item.to" grid="true" grid_num="12" step="0.5"
                      [prettify]="toHourString" (onChange)="change_slider(horarioEspecialidad.days.viernesArray, i, $event)"></ion-range-slider>  
                      </div>
                      <div class="col-sm-2">
                        <button type="button" class="btn btn-secondary" ngbTooltip="Eliminar" (click)="remove_horario(horarioEspecialidad.days.viernesArray, i)"><i class="mdi mdi-delete" style="color:red;"></i></button>
                      </div>                                                                
                    </div>
                      <div class="row">
                        <div class="col-sm-10"></div>
                        <div class="col-sm-2">
                          <button type="button" class="btn btn-secondary" ngbTooltip="Agregar" (click)="add_horario(horarioEspecialidad.days.viernesArray)"><i class="mdi mdi-plus-box"></i></button>
                        </div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="list-group-item">
                <div class="row">
                  <div class="col-sm-2">
                    <div class="checkbox checkbox-primary"> 
                      <input type="checkbox" id="sabado" class="filled-in chk-col-light-blue" #inputsabado
                        [disabled]="!especialidad_form.controls['especialidad_id'].value" [checked]="horario.sab.checked" (change)="change_day(horario.sab, $event)">
                      <label for="sabado"> Sabado </label>
                    </div>
                  </div>
                  <div class="col-sm-10" *ngIf="inputsabado.checked">
                    <div *ngFor="let item of horarioEspecialidad.days.sabadoArray; let i = index" class="row">
                      <div class="col-sm-10">
                        <ion-range-slider #slider_sabado skin="modern" type="double" min="0" max="23.5"
                      [(from)]="item.from" [(to)]="item.to" grid="true" grid_num="12" step="0.5"
                      [prettify]="toHourString" (onChange)="change_slider(horarioEspecialidad.days.sabadoArray, i, $event)"></ion-range-slider>  
                      </div>
                      <div class="col-sm-2">
                        <button type="button" class="btn btn-secondary" ngbTooltip="Eliminar" (click)="remove_horario(horarioEspecialidad.days.sabadoArray, i)"><i class="mdi mdi-delete" style="color:red;"></i></button>
                      </div>                                                                
                    </div>
                      <div class="row">
                        <div class="col-sm-10"></div>
                        <div class="col-sm-2">
                          <button type="button" class="btn btn-secondary" ngbTooltip="Agregar" (click)="add_horario(horarioEspecialidad.days.sabadoArray)"><i class="mdi mdi-plus-box"></i></button>
                        </div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="list-group-item">
                <div class="row">
                  <div class="col-sm-2">
                    <div class="checkbox checkbox-primary">
                      <input type="checkbox" id="domingo" class="filled-in chk-col-light-blue" #inputdomingo
                        [disabled]="!especialidad_form.controls['especialidad_id'].value" [checked]="horario.dom.checked"  (change)="change_day(horario.dom, $event)">
                      <label for="domingo"> Domingo </label>
                    </div>
                  </div>
                  <div class="col-sm-10" *ngIf="inputdomingo.checked">
                    <div *ngFor="let item of horarioEspecialidad.days.domingoArray; let i = index" class="row">
                      <div class="col-sm-10">
                        <ion-range-slider #slider_domingo skin="modern" type="double" min="0" max="23.5"
                      [(from)]="item.from" [(to)]="item.to" grid="true" grid_num="12" step="0.5"
                      [prettify]="toHourString" (onChange)="change_slider(horarioEspecialidad.days.domingoArray, i, $event)"></ion-range-slider>  
                      </div>
                      <div class="col-sm-2">
                        <button type="button" class="btn btn-secondary" ngbTooltip="Eliminar" (click)="remove_horario(horarioEspecialidad.days.domingoArray, i)"><i class="mdi mdi-delete" style="color:red;"></i></button>
                      </div>                                                                
                    </div>
                      <div class="row">
                        <div class="col-sm-10"></div>
                        <div class="col-sm-2">
                          <button type="button" class="btn btn-secondary" ngbTooltip="Agregar" (click)="add_horario(horarioEspecialidad.days.domingoArray)"><i class="mdi mdi-plus-box"></i></button>
                        </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>

          </div>
        </div>

        <div class="row m-t-30">
          <div class="col-md-12 modal-footer">
            <button type="button" class="btn btn-danger btn-sm" (click)="on_close()"><i class="mdi mdi-close"></i>
              Cancelar</button>
            <button type="submit" class="btn btn-primary btn-sm "><i class="mdi mdi-content-save"></i> Guardar</button>
          </div>
        </div>
      </form>
    </div>



    <div class="tab-pane" id="exepciones" role="tabpanel">
      <div class="col-md-12">
      <form class="form-material" [formGroup]="excepciones_form" (ngSubmit)="guardar()" #form="ngForm"
          novalidate>
        <div class="row">     
          <div class="col-md-4">
            <div class="form-group"  [ngClass]="{ 'has-danger': _frm.is_invalid('fecha_inicio', excepciones_form, form.submitted)}">
              <label for="txtnacimiento" [ngClass]="{ 'star_symbol': _frm.is_required('fecha_inicio', excepciones_form) }">Fecha de inicio</label>
              <input class="form-control" placeholder="Fecha de inicio" id="txtnacimiento"
                formControlName="fecha_inicio" ngbDatepicker #d="ngbDatepicker" (focus)="d.toggle()"
                readonly="readonly" [minDate]="fechaHoy"  (dateSelect)="onChangeDate('fecha_inicio')">
              <i class="mdi mdi-calendar"></i>
              <div class="invalid-feedback">
                <div *ngIf="excepciones_form.controls['fecha_inicio'].errors?.required &&  !bandValid">Ingrese la fecha de inicio</div>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group"  [ngClass]="{ 'has-danger': _frm.is_invalid('fecha_fin', excepciones_form, form.submitted)}">
              <label for="txtnacimiento" [ngClass]="{ 'star_symbol': _frm.is_required('fecha_fin', excepciones_form) }">Fecha de fin</label>
              <input class="form-control" placeholder="Fecha de inicio" id="txtnacimiento"
                formControlName="fecha_fin" ngbDatepicker #d2="ngbDatepicker" (focus)="d2.toggle()"
                readonly="readonly" [minDate]="fechaChange" >
              <i class="mdi mdi-calendar"></i>
              <div class="invalid-feedback">
                <div *ngIf="excepciones_form.controls['fecha_fin'].errors?.required &&  !bandValid">Ingrese la fecha de fin</div>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group"  [ngClass]="{ 'has-danger': _frm.is_invalid('laborable', excepciones_form, form.submitted)}">
              <label for="txtnacimiento" [ngClass]="{ 'star_symbol': _frm.is_required('laborable', excepciones_form) }">Laborable</label>
              <ng-select placeholder="Seleccione si el día es laborable" id="ddl_laborable" class="form-control"
              formControlName="laborable" [items]="sino" bindLabel="nombre" bindValue="id"
              [searchable]="true" ></ng-select>
              <div class="invalid-feedback">
                <div *ngIf="excepciones_form.controls['laborable'].errors?.required &&  bandValid">Ingrese si el día es laborable</div>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="form-group"  [ngClass]="{ 'has-danger': _frm.is_invalid('observacion', excepciones_form, form.submitted)}">
              <label for="observaciones" [ngClass]="{ 'star_symbol': _frm.is_required('observacion', excepciones_form) }">Observaciones</label>
              <textarea placeholder="Ingrese las obervaciones " id="observaciones" class="form-control"
              formControlName="observacion" ></textarea>
              <div class="invalid-feedback">
                <div *ngIf="excepciones_form.controls['observacion'].errors?.required &&  bandValid">Ingrese las observaciones</div>
              </div>
            </div>
          </div>

          
        </div>
        <div class="col-md-12">
          <button (click)="selectDelete()" class="btn btn-secondary float-right" ngbtooltip="Eliminar excepción" type="button" ng-reflect-ngb-tooltip="Eliminar excepción"><i class="mdi mdi-delete"></i></button>
          <app-grid [autosize]="true" [rowData]="rowData" [columnDefs]="columnDefs"    gridHeight="400px" [rowSelection]="'single'"  #grid1></app-grid>
      </div>

        <div class="row m-t-30">
            <div class="col-md-12 modal-footer">
              <button type="button" class="btn btn-danger btn-sm" (click)="on_close()"><i class="mdi mdi-close"></i>
                Cancelar</button>
              <button type="submit" class="btn btn-primary btn-sm "><i class="mdi mdi-content-save"></i> Guardar</button>
            </div>
          </div>
      </form>
      </div>
    
    </div>


  </div>
</div>