import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot} from "@angular/router";
import { AuthorizationService } from "./authorization.service";

@Injectable({
  providedIn: "root"
})
export class AutenticationGuard implements CanActivate {
  constructor(public _auth: AuthorizationService, public _router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this._auth.is_valid_login()) { 
      return true;
    } else {
      console.error("Acceso no autorizado " + state.url);
      this._router.navigate(["/login"], {
        queryParams: {
          return: state.url
        }
      });

      return false;
    }
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(route, state);
  }
}
