<div id="main-wrapper">
    <app-header></app-header>
    <app-sidebar></app-sidebar>

    <div class="page-wrapper">
        <div class="container-fluid">
            <app-breadcrumbs></app-breadcrumbs>
            <router-outlet></router-outlet>
        </div>
    </div>
</div>