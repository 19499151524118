<ul class="nav nav-tabs profile-tab" role="tablist">


    <li class="nav-item">
        <a class="nav-link active" data-toggle="tab" href="#general" role="tab">Datos generales</a>
    </li>
    <!-- <ng-container *ngIf="this.paciente.flat==1">

        <li class="nav-item"> <a class="nav-link" data-toggle="tab" href="#responsable" role="tab">Responsable</a></li>
        <li class="nav-item"> <a class="nav-link" data-toggle="tab" href="#documentos" role="tab">Documentos</a>
        </li>
        <li *appPermissions="'fullfile'" class="nav-item"> <a class="nav-link" data-toggle="tab" *ngIf="paciente.paciente_id" href="#referencias" role="tab">Referencias</a></li>

    </ng-container> -->
    <ng-container>

        <li class="nav-item"> <a class="nav-link" data-toggle="tab" *ngIf="paciente.paciente_id" href="#responsable" role="tab">Responsable</a></li>
        <li class="nav-item"> <a class="nav-link" data-toggle="tab" *ngIf="paciente.paciente_id" href="#documentos" role="tab">Documentos</a></li>
        <li *appPermissions="'fullfile'" class="nav-item"> <a class="nav-link" data-toggle="tab" *ngIf="paciente.paciente_id" href="#referencias" role="tab">Referencias</a></li>
        <li *appPermissions="'fullfile'" class="nav-item"> <a class="nav-link" data-toggle="tab" *ngIf="paciente.paciente_id" href="#antecedentes" role="tab">Historia Clínica</a></li>
        <li *appPermissions="'fullfile'" class="nav-item"> <a class="nav-link" data-toggle="tab" *ngIf="paciente.paciente_id" href="#consultas" role="tab">Consultas</a></li>
        <li *appPermissions="'fullfile'" class="nav-item"> <a class="nav-link" data-toggle="tab" *ngIf="paciente.paciente_id" href="#recetas" role="tab">Recetas</a></li>
        <li *appPermissions="'fullfile'" class="nav-item"> <a class="nav-link" data-toggle="tab" *ngIf="paciente.paciente_id" href="#laboratorio" role="tab">Aux. de laboratorio</a></li>
        <li *appPermissions="'fullfile'" class="nav-item"> <a class="nav-link" data-toggle="tab" *ngIf="paciente.paciente_id" href="#diagnosticos" role="tab">Diagnósticos</a></li>
        <!--<li *appPermissions="'fullfile'" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#ordenes" role="tab">Ordenes médicas</a></li>-->
        <li *appPermissions="'fullfile'" class="nav-item"> <a class="nav-link" data-toggle="tab" *ngIf="paciente.paciente_id" href="#procedimientos" role="tab">Procedimientos</a></li>
        <li *appPermissions="'fullfile'" class="nav-item"> <a class="nav-link" data-toggle="tab" *ngIf="paciente.paciente_id" href="#mediciones" role="tab">Mediciones</a></li>

    </ng-container>

</ul>
<div class="tab-content">
    <div class="tab-pane active" id="general" role="tabpanel">
        <app-area-general [paciente]="paciente" (submit_form)="update_paciente($event)"></app-area-general>
    </div>
    <ng-container *ngIf="paciente.paciente_id">

        <div class="tab-pane" id="responsable" role="tabpanel">
            <app-area-responsable [paciente]="paciente" (submit_form)="update_paciente($event)"> </app-area-responsable>

        </div>
        <div class="tab-pane" id="documentos" role="tabpanel">
            <app-area-documentos [paciente]="paciente" (submit_form)="update_paciente($event)"></app-area-documentos>
        </div>

        <div *appPermissions="'fullfile'" class="tab-pane" id="referencias" role="tabpanel">
            <app-area-referencias [paciente]="paciente" (submit_form)="update_paciente($event)"></app-area-referencias>
        </div>


    </ng-container>
    <ng-container *ngIf="paciente.paciente_id">

        <div class="tab-pane" id="responsable" role="tabpanel">
            <app-area-responsable [paciente]="paciente"></app-area-responsable>

        </div>
        <div *appPermissions="'fullfile'" class="tab-pane" id="documentos" role="tabpanel">
            <app-area-documentos [paciente]="paciente"></app-area-documentos>
        </div>
        <div *appPermissions="'fullfile'" class="tab-pane" id="antecedentes" role="tabpanel">
            <app-area-antecedentes [form_id]="3" [paciente]="paciente"></app-area-antecedentes>
        </div>
        <div *appPermissions="'fullfile'" class="tab-pane" id="consultas" role="tabpanel">
            <app-area-consultas [paciente]="paciente"></app-area-consultas>
        </div>
        <div *appPermissions="'fullfile'" class="tab-pane" id="recetas" role="tabpanel">
            <app-area-recetas [paciente]="paciente"></app-area-recetas>
        </div>
        <div *appPermissions="'fullfile'" class="tab-pane" id="laboratorio" role="tabpanel">
            <app-area-estudios [paciente]="paciente"></app-area-estudios>
        </div>
        <div *appPermissions="'fullfile'" class="tab-pane" id="diagnosticos" role="tabpanel">
            <app-area-diagnosticos [paciente]="paciente"></app-area-diagnosticos>
        </div>
        <div *appPermissions="'fullfile'" class="tab-pane" id="ordenes" role="tabpanel">
            <app-area-ordenes></app-area-ordenes>
        </div>
        <div *appPermissions="'fullfile'" class="tab-pane" id="referencias" role="tabpanel">
            <app-area-referencias [paciente]="paciente"></app-area-referencias>
        </div>
        <div *appPermissions="'fullfile'" class="tab-pane" id="procedimientos" role="tabpanel">
            <app-area-procedimientos [paciente]="paciente"></app-area-procedimientos>
        </div>
        <div *appPermissions="'fullfile'" class="tab-pane" id="mediciones" role="tabpanel">
           <app-area-mediciones [paciente]="paciente"></app-area-mediciones>
        </div>
    </ng-container>
</div>