import { Component, OnInit, ViewChild, Output, Input, EventEmitter } from '@angular/core';
import { paciente, almacenamiento } from '../../models/paciente.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormsService, PacienteService, CatalogService, AddressService } from 'src/app/services/service.index';
import { state, municipality, settlement, search_address } from 'src/app/models/address.model';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
@Component({
  selector: 'app-area-responsable',
  templateUrl: './area-responsable.component.html',
  styles: []
})
export class AreaResponsableComponent implements OnInit {
  model: NgbDateStruct;
  @ViewChild("form", { static: true }) form;
  @Input() paciente: paciente = new paciente({});
  paciente_form: FormGroup;
  genders: any;
  seguro: any;
  etnico: any;
  religion: any;
  sangre: any;
  socioeconomico = [{ nombre: '1', id: '1' }, { nombre: '2', id: '2' }, { nombre: '3', id: '3' }, { nombre: '4', id: '4' }, { nombre: '5', id: '5' }, { nombre: '6', id: '6' }, { nombre: 'Excento', id: 'Excento' }];;
  anaquel: any;
  fila: any;
  columna: any;
  country_id: number = 146;
  parentesco:[];
  states: state[];
  municipalities: municipality[];
  settlements: settlement[];
  search_address: search_address;
  myDate:any;
  fecha:any;
  fechaHoy:any;
  fg:any ;

  constructor(private _fb: FormBuilder, public _frm: FormsService, public _paciente: PacienteService, public _cat: CatalogService, public _adr: AddressService) { }

  ngOnInit() {
    
    this.myDate=new Date()
    this.fechaHoy ={year: this.myDate.getFullYear(), month: this.myDate.getMonth(), day: this.myDate.getDate()}
    this.paciente_form = this._fb.group({
      responsable_id:[this.paciente.responsable.responsable_id],
      paciente_id: [],
      nombre: ['', [Validators.required]],
      paterno: ['', [Validators.required]],
      materno: ['', [Validators.required]],
      fecha_nacimiento: [''],
      email: [''],
      telefono_fijo: [''],
      telefono_movil: [''],
      parentesco_id:['',[Validators.required]],
      correo:[''],
      
      // address
       domicilio: this._fb.group({ 
        postal_code: ['',[Validators.required]],
        state_id: [''],
        municipality_id: [''],
        settlement: [''],
        street: [''],
        exterior_number: [''],
        interior_number: [''],
        reference: ['']
      })
     
    });
    this.fg=this.paciente_form.controls['domicilio']
   
    this._adr.get_catalog("parentesco",true).subscribe((data: any) => { 
      this.parentesco = data 
    });
    if(this.paciente.paciente_id != null){
      this._paciente.get_responsable(this.paciente.paciente_id).subscribe((data: any) => { 
        if(data!=null){
          let fecha: Date = new Date(data.fecha_nacimiento);
          let fecha_2:NgbDateStruct = {year: fecha.getFullYear(), month: fecha.getMonth() + 1, day: fecha.getDate() }       
           
           this.paciente_form = this._fb.group({
            responsable_id:data.responsable_id,
            paciente_id: this.paciente.paciente_id,
            nombre: data.nombre,
            paterno: data.paterno,
            materno: data.materno,
            fecha_nacimiento: fecha_2,
            telefono_fijo: data.telefono_fijo,
            telefono_movil: data.telefono_movil,
            parentesco_id:data.parentesco_id,
            correo:data.correo,
            
            // address
             domicilio: this._fb.group({ 
              postal_code: data.domicilio.postal_code,
              state_id: data.domicilio.state_id,
              municipality_id: data.domicilio.municipality_id,
              settlement: data.domicilio.settlement,
              street: data.domicilio.street,
              exterior_number: data.domicilio.exterior_number,
              interior_number: data.domicilio.interior_number,
              reference:data.domicilio.reference
            })
          }); 
        }
      });
    }else{

    } 
    this.bind_catalogs(this.country_id, this.paciente.domicilio.state_id, this.paciente.domicilio.municipality_id, this.paciente.domicilio.settlement);
  }

  guardar(){
    this.paciente_form.value.paciente_id=this.paciente.paciente_id;
    this.paciente_form.value.responsable_id=this.paciente.responsable.responsable_id;
    if( this.paciente_form.value.telefono_fijo != '' || this.paciente_form.value.telefono_movil != '' ){
      this._frm.save_module(this.paciente_form, "paciente_id", () => {
        this._paciente.insert_responsable(this.paciente_form.value).subscribe(
          (res: any) =>
          { 
            this._frm.toast_alert("Responsable creado exitosamente", "Nuevo responsable", "success");
         
        },
          error => this._frm.toast_alert("El responsable no pudo ser creado correctamente. Error: " + error.error.message, "Nuevo Responsable", "error")
        );
        
      }, () => {
        this._paciente.update_responsable(this.paciente_form.value).subscribe(
          (res: any) =>
          { 
            this.paciente_form.value.paciente_id=this.paciente.paciente_id;
            this._frm.toast_alert("Responsable actualizado exitosamente", "Actualizar responsable", "success");
        
        },
          error => this._frm.toast_alert("El responsable no pudo ser actualizado correctamente. Error: " + error.error.message, "Actualizar Responsable", "error")
        );
      });
    }else{
      this._frm.toast_alert("Llene por lo menos un campo de telefono", "Responsable", "error")
    }
  }

  bind_catalogs(country_id: number, state_id: number, municipality_id: number, settlement: string) {
    if (country_id) {
      this._adr.get_states(country_id).subscribe((data: any) => { this.states = data });
      if (state_id) {
        this._adr.get_municipality(country_id, state_id).subscribe((data: any) => { this.municipalities = data });
        if (municipality_id) this._adr.get_settlements(country_id, state_id, municipality_id).subscribe((data: any) => { this.settlements = data; });
      }
    } 
  }

  change_postalcode(value: string) {
    
    this._adr.get_address_from_postalcode(value).subscribe((data: any) => {

      this.search_address = data;
      let state_id = this.search_address.state ? this.search_address.state.state_id : null;
      let municipality_id = this.search_address.municipality ? this.search_address.municipality.municipality_id : null;
      let settlement = this.search_address.settlement ? this.search_address.settlement : null;

      if (state_id) {
        this.paciente_form.patchValue({domicilio:{ "state_id": state_id }});

        this._adr.get_municipality(this.country_id, state_id).subscribe((data: any) => {
          this.municipalities = data;
          this.paciente_form.patchValue({domicilio:{ "municipality_id": municipality_id }});

          this.settlements = settlement;
          if (settlement.length > 1) {
            this.paciente_form.patchValue({domicilio:{ "settlement": null }});
          }
          else {
            this.paciente_form.patchValue({domicilio:{ "settlement": settlement[0].name }});
          }
        });
      }
      else {
        this.municipalities = [];
        this.settlements = [];
        this.paciente_form.patchValue({domicilio:{ "state_id": null, "municipality_id": null, "settlement": null }});
      }

    }, error => console.log(error));
  }
  change_state($event) {
    this.municipalities = [];
    this.settlements = [];
    this.paciente_form.patchValue({domicilio:{ "postal_code": null, "municipality_id": null, "settlement": null }});
    this._adr.get_municipality(this.country_id, $event.state_id).subscribe((data: any) => { this.municipalities = data });
  }
  change_municipality($event) {
    this.settlements = [];
    this.paciente_form.patchValue({domicilio:{ "postal_code": null, "settlement": null }});
    this._adr.get_settlements(this.country_id, $event.state_id, $event.municipality_id).subscribe((data: any) => { this.settlements = data; });
  }
  change_settlement($event) {
    this.paciente_form.patchValue({domicilio:{ "postal_code": $event.code }});
  }

  update_paciente($event){
    this.paciente = $event;
  }

}
