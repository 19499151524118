export interface IProcedimientos{
    padre_id: number;
    procedimiento_id: number;
    procedimiento_cod: number;
    nombre: string;
    nombre_compuesto: string;
    cie9: boolean;
}

export class Procedimientos implements IProcedimientos{
    padre_id: number;
    procedimiento_id: number;
    procedimiento_cod: number;
    nombre: string;
    nombre_compuesto: string;
    cie9: boolean;

    constructor(data: Procedimientos | {} = {}) {
        Object.assign(this, data);
    }
}