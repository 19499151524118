import { Injectable } from '@angular/core';
import { AuthorizationService } from './authorization.service';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  constructor(public http: HttpClient, public _auth: AuthorizationService) { }

  getUsersShareWithMe(user_id: number) {
    let url = environment.apiEndpoint + "users/get_user_agenda_usuario" + '?user_to_id=' + user_id;
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
    };
    return this.http.get(url, httpOptions);
  }

  getUsersForAssociate(user_id: number) {
    let url = environment.apiEndpoint + "users/get_user_agenda" + '?user_id=' + user_id;
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
    };
    return this.http.get(url, httpOptions);
  }

  getUsersAssociates(user_id: number) {
    let url = environment.apiEndpoint + "users/get_user_agenda_usuario" + '?user_id=' + user_id;
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
    };
    return this.http.get(url, httpOptions);
  }

  addUserToShareSchedule(user_id: number,userId: number) {
    let url = environment.apiEndpoint + "users/insert_user_agenda?user_to_id=" + userId + '&user_id=' + user_id;
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
    };
    return this.http.post(url,null,httpOptions);
  }

  deleteUserToShareSchedule(user_id: number,userId: number) {
    let url = environment.apiEndpoint + "users/delete_user_agenda?user_to_id=" + userId + '&user_id=' + user_id;
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
    };
    return this.http.delete(url,httpOptions);
  }

  getDataDynamicForm(consultaId: number) {
    //let url = environment.apiEndpoint + "dynamic/get_antecedente?paciente_id=" + pacienteId;
    let url = environment.apiEndpoint + "dynamic/get_antecedente_consulta?consulta_id=" + consultaId;
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
    };
    return this.http.get(url, httpOptions);
  }

  getDynamicForm(formId: number) {
    let url = environment.apiEndpoint + "dynamic/getform" + '?form_id=' + formId;
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
    };
    return this.http.get(url, httpOptions);
  }

  postDynamicForm(jsonData:string) {
    let url = environment.apiEndpoint + "dynamic/save_form";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
    };
    return this.http.post(url,jsonData,httpOptions);
  }

}
