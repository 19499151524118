export class MedicamentosResponseModel {
    medicamento_id:   number;
    clave:            string;
    nombre_generico:  string;
    descripcion:      string;
    cantidad:         string;
    presentacion:     string;
    grupo_id:         number;
    grupo_name:       string;
    accion:           null;
    controlado:       boolean;
    controlado_text:  string;
    nombre_compuesto: string;
    basico: string;
    vias:             Via[];
}

export class Via {
    medicamento_id:        number;
    via_administracion_id: number;
    nombre:                string;
}
