import { address } from './address.model';

export class paciente {

    paciente_id?: number = null;
    nombre: string = "";
    paterno: string = "";
    materno: string = "";
    nombre_completo: string = "";
    nombre_completo2: string = "";
    fecha_nacimiento?: Date = null;
    email: string = "";
    telefono_casa: string = "";
    telefono_oficina: string = "";
    telefono_movil: string = "";
    rfc: string = "";
    curp: string = "";
    sexo_id?: number = null;
    sexo: string = "";
    estado_civil_id?: number = null;
    estado_civil: string = "";
    fotografia: string = "";
    file: File;
    tipo_sangre_id?: number = null;
    tipo_sangre: string = "";
    grupo_etnico_id?: number = null;
    grupo_etnico: string = "";
    religion_id?: number = null;
    religion: string = "";
    tipo_seguro_id?: number = null;
    tipo_seguro: string = "";
    nivel_socioeconomico: string = "";
    numero_registro: string = "";
    numero_poliza: string = "";

    activo?: boolean = null;
    saved_by?: number = null;
    title: string = "";

    flat:number=null;
    user_id:number = null;

    domicilio: address = new address({});
    responsable: responsable = new responsable({});
    almacenamiento: almacenamiento = new almacenamiento({});

    referencias: referencia[] = [new referencia({})]
    documentos: paciente_documento[] = [new paciente_documento({})]
    discapacidades: paciente_discapacidad[] = [new paciente_discapacidad({})]
    antecedentes: antecedente_paciente[] = [new antecedente_paciente({})]
    ordenes: orden[] = [new orden({})]
    prestamos: prestamo[] = [new prestamo({})]


    constructor(data: paciente | {} = {}) {
        Object.assign(this, data);
    }
}


export class responsable {
    responsable_id?: number = null;
    nombre: string = "";
    paterno: string = "";
    materno: string = "";
    domicilio_id?: number = null;
    parentesco_id?: number = null;
    //address domicilio { get; set; }

    constructor(data: responsable | {} = {}) {
        Object.assign(this, data);
    }
}




export class almacenamiento {
    almacenamiento_id?: number = null;
    clave: string = "";
    capacidad?: number = null;
    orden?: number = null;
    anaquel?: number = null;
    fila?: number = null;
    columna?: number = null;

    constructor(data: almacenamiento | {} = {}) {
        Object.assign(this, data);
    }
}


export class paciente_documento {
    paciente_id?: number = null;
    tipo_documento_id?: number = null;
    ubicacion: string = "";
    hojas?: number = null;
    marca_temporal?: Date = null;
    fecha_creacion?: Date = null;

    constructor(data: paciente_documento | {} = {}) {
        Object.assign(this, data);
    }
}


export class paciente_discapacidad {
    paciente_id?: number = null;
    discapacidad_id?: number = null;

    constructor(data: paciente_discapacidad | {} = {}) {
        Object.assign(this, data);
    }
}

export class antecedente_paciente {
    paciente_id?: number = null;
    medico_id?: number = null;
    fecha?: Date = null;
    xml_template: string = "";

    constructor(data: antecedente_paciente | {} = {}) {
        Object.assign(this, data);
    }
}
export class orden {
    orden_id?: number = null;
    fecha?: Date = null;
    tipo_orden_id?: number = null;
    instrucciones_suministro: string = "";
    indicaciones_adicionales: string = "";
    medico_id?: number = null;
    paciente_id?: number = null;
    destinatario_id?: number = null;
    medicamento_id?: number = null;
    consentimiento?: boolean = null;
    impresion_diagnostica: string = "";
    fecha_inicio?: Date = null;
    fecha_fin?: Date = null;
    fecha_entrega?: Date = null;
    diagnostico_id?: number = null;
    procedimiento_id?: number = null;
    estado_orden_id?: number = null;
    prioridad_id?: number = null;
    saved_by?: number = null;

    constructor(data: orden | {} = {}) {
        Object.assign(this, data);
    }
}


export class prestamo {
    prestamo_id?:number=null;
    paciente_id?:number=null;
    fecha_prestamo?:Date=null;
    fecha_devolucion_real?:Date=null;
    fecha_devolucion_programada?:Date=null;
    observaciones:string="";
    personal_entrega_id?:number=null;
    personal_recibe_id?:number=null;
    personal_devuelve_id?:number=null;
    area_id?:number=null;
    cama_id?:number=null; 
    paciente:string=""; 
    medico:string="";
    personal_recibe:string="";
    personal_devuelve:string="";
    area:string="";
    cama:string="";
    constructor(data: prestamo | {} = {}) {
        Object.assign(this, data);
    }

}
export class referencia {
    referencia_id?: number = null;
    fecha?: Date = null;
    unidad_refiere: string = "";
    unidad_referida: string = "";
    especialidad_id?: number = null;
    motivo: string = "";
    paciente_id?: number = null;

    constructor(data: referencia | {} = {}) {
        Object.assign(this, data);
    }

}

export class documento{
    file: File;
    tipo_docuemento_id;
    

}


