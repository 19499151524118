<div class="card-body p-b-0">
    <form class="form-material" [formGroup]="role_form" (ngSubmit)="on_submit()" #form="ngForm"  novalidate>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group" [ngClass]="{ 'has-danger': is_invalid('name')}">
                    <label for="txt_name" [ngClass]="{ 'star_symbol': is_required('name') }">Nombre</label>
                    <input type="text" placeholder="Código" class="form-control" id="txt_name" formControlName="name">
                    <div class="invalid-feedback">
                        <div *ngIf="role_form.controls['name'].errors?.required">El código es obligatorio</div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group" [ngClass]="{ 'has-danger': is_invalid('display_text')}">
                    <label for="txt_display_text" [ngClass]="{ 'star_symbol': is_required('display_text') }">Etiqueta</label>
                    <input type="text" placeholder="Etiqueta" class="form-control" id="txt_display_text" formControlName="display_text">
                    <div class="invalid-feedback">
                        <div *ngIf="role_form.controls['display_text'].errors?.required">La etiqueta es obligatoria</div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="form-group" [ngClass]="{ 'has-danger': is_invalid('description')}">
                    <label for="txt_description" [ngClass]="{ 'star_symbol': is_required('description') }">Descripción</label>
                    <textarea placeholder="Descripción" class="form-control" id="txt_description" formControlName="description"></textarea>
                    <div class="invalid-feedback">
                        <div *ngIf="role_form.controls['description'].errors?.required">La descripción es obligatoria</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row m-t-30">
            <div class="col-md-12 modal-footer">
                <button *ngIf="show_cancel_button" type="button" class="btn btn-danger btn-sm" (click) = "on_close()" ><i class="mdi mdi-close"></i> Cancelar</button>
                <button type="submit" class="btn btn-primary btn-sm "><i class="mdi mdi-content-save"></i> Guardar Perfil</button>
            </div>
        </div>
    </form>
</div>