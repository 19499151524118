import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { agenda, consulta } from 'src/app/models/consulta.model';
import { ToastrService } from 'ngx-toastr';
import { ScheduleService } from 'src/app/services/schedule.service';
import { DynamicFormsBack, PostDynamicForm } from "src/app/models/dynamic_forms_back.model";
import {FormlyFieldConfig, FormlyTemplateOptions} from '@ngx-formly/core';
import { DynamicForms } from 'src/app/models/dynamic_forms.model';
import { FormsService } from 'src/app/services/forms.service';
import { PacienteService } from 'src/app/services/paciente.service';

@Component({
  selector: 'app-exploracion-dialog',
  templateUrl: './exploracion-dialog.component.html',
  styleUrls: ['./exploracion-dialog.component.css']
})
export class ExploracionDialogComponent implements OnInit {
  @Input() medicoId: number;
  @Input() pacienteId: number;
  @Input() consultaId: number;
  @Input() readOnly: boolean = false;
  @Output() submit_form = new EventEmitter();
  @Output() close_modal = new EventEmitter();

  dataBack:DynamicFormsBack;

  form = new FormGroup({});
  model = {};
  formFields: Array<FormlyFieldConfig> = [{
    type: 'flex-layout',
    templateOptions: {
      fxLayout: 'row',
    },
  }
  ];
  public currentPdf: string = null;


  constructor(public _scheduleService: ScheduleService, public _frm: FormsService,private _toastr: ToastrService, public _paciente: PacienteService) { }

  ngOnInit(): void {
    this.getData();
  }

   async getData(){
    //LÓGICA DEL BACK:
    //--1.LLAMAR PRIMERO A UN SERVICIO PARA OBTERNER LOS DATOS DEL FORMULARIOS 'DINÁMICO'
    //--2.SI TIENE DATOS MOSTRARLOS, SI NO, LLAMAR A OTRO SERVICIO PARA QUE ME DE LA ESTRUCTURA DEL FORMUALRIO

    //1. BUSCAR SI TIENE DATOS.
    console.log(`this.consultaId:${this.consultaId}`);
     var hasData = await this.formHasData(this.consultaId);

     //2.FORMULARIO DINÁMICO DE EXPLORACIÓN FÍSICA --> 9
     if(!hasData){
      this._scheduleService.getDynamicForm(9).subscribe(
        (data: any) => {
          console.log(data);          
          this.dataBack = data as DynamicFormsBack;
          //PARSEAR EL JSON QUE ENVÍA EL BACK, AL JSON QUE SE NECESITA PARA CREAR EL FORMULARIO.
          var dataForm = new DynamicForms();     
          var fieldsx: FormlyFieldConfig[] = [];
          this.dataBack.fields.forEach(backField =>{
            //COONVERTIR BACK TYPE A FORM TYPE
            var tmpTypeForm = '';
            switch(backField.type){
              case 'number':
                tmpTypeForm = 'input';
                  break;
              case 'text':
                tmpTypeForm = 'input';
                  break;
              case 'checkbox':
                tmpTypeForm = 'checkbox';
                  break;
            }
            //COONVERTIR BACK TYPE A FORM FIELD TYPE
            var tmpTypeFormField = '';
            switch(backField.type){
              case 'number':
                tmpTypeFormField = 'number';
                  break;
              case 'text':
                tmpTypeFormField = 'text';
                  break;
            }
  
            var field = <FormlyFieldConfig>{};
            field.key = backField.name;
            field.type = tmpTypeForm;
            field.templateOptions  = <FormlyTemplateOptions>{};
            field.templateOptions.label = `${backField.label}  (${backField.placeholder})`;
            field.templateOptions.type = tmpTypeFormField;
            field.templateOptions.placeholder = backField.placeholder;
            field.templateOptions.required = true;
            field.templateOptions.disabled = this.readOnly;            
  
            fieldsx.push(field);
  
          });
          var formFieldsx: Array<FormlyFieldConfig> = [{
            type: 'flex-layout',
            templateOptions: {
              fxLayout: 'row',
            },
            fieldGroup: fieldsx,
          }
          ];
          this.formFields = formFieldsx;
          
        },
        err => console.log(err)
      );
     }
  }

  async formHasData(consultaId:number): Promise<boolean> {
    var result = false;
    try {
      let response = await this._scheduleService.getDataDynamicForm(consultaId).toPromise();
      if(response == null){
        result = false;
        this.readOnly = false;
      }else{
        result = true;
        this.readOnly = true;
        this.dataBack = response as DynamicFormsBack;
        //PARSEAR EL JSON QUE ENVÍA EL BACK, AL JSON QUE SE NECESITA PARA CREAR EL FORMULARIO.
        var dataForm = new DynamicForms();     
        var fieldsx: FormlyFieldConfig[] = [];
        this.dataBack.fields.forEach(backField =>{
          //COONVERTIR BACK TYPE A FORM TYPE
          var tmpTypeForm = '';
          switch(backField.type){
            case 'number':
              tmpTypeForm = 'input';
                break;
            case 'text':
              tmpTypeForm = 'input';
                break;
            case 'checkbox':
              tmpTypeForm = 'checkbox';
                break;
          }
          //COONVERTIR BACK TYPE A FORM FIELD TYPE
          var tmpTypeFormField = '';
          switch(backField.type){
            case 'number':
              tmpTypeFormField = 'number';
                break;
            case 'text':
              tmpTypeFormField = 'text';
                break;
          }

          var field = <FormlyFieldConfig>{};
          field.key = backField.name;
          field.type = tmpTypeForm;
          field.templateOptions  = <FormlyTemplateOptions>{};
          //field.templateOptions.label = backField.label;
          field.templateOptions.label = `${backField.label}  (${backField.placeholder})`;
          field.templateOptions.type = tmpTypeFormField;
          field.templateOptions.placeholder = backField.placeholder;
          field.templateOptions.required = true;
          field.templateOptions.value = 5;
          //field.templateOptions.placeholder = '';
          this.model[backField.name] = backField.value;
          field.templateOptions.disabled = true;

          fieldsx.push(field);

        });
        //this.fields = fieldsx;
        var formFieldsx: Array<FormlyFieldConfig> = [{
          type: 'flex-layout',
          templateOptions: {
            fxLayout: 'row',
          },
          fieldGroup: fieldsx,
        }
        ];
        this.formFields = formFieldsx;
      }
    } catch(err) {
      console.log(err);
    } finally{
      return result;
    }

    /*this._scheduleService.getDataDynamicForm(this.pacienteId).subscribe(
      async (data: any) => {
        console.log('hasData-suscribe-ok');
        this.dataBack = data as DynamicFormsBack;
        //PARSEAR EL JSON QUE ENVÍA EL BACK, AL JSON QUE SE NECESITA PARA CREAR EL FORMULARIO.
        var dataForm = new DynamicForms();     
        var fieldsx: FormlyFieldConfig[] = [];
        this.dataBack.fields.forEach(backField =>{
          //COONVERTIR BACK TYPE A FORM TYPE
          var tmpTypeForm = '';
          switch(backField.type){
            case 'number':
              tmpTypeForm = 'input';
                break;
            case 'input':
              tmpTypeForm = 'input';
                break;
            case 'checkbox':
              tmpTypeForm = 'checkbox';
                break;
          }
          //COONVERTIR BACK TYPE A FORM FIELD TYPE
          var tmpTypeFormField = '';
          switch(backField.type){
            case 'number':
              tmpTypeFormField = 'number';
                break;
            case 'input':
              tmpTypeFormField = 'text';
                break;
          }

          var field = <FormlyFieldConfig>{};
          field.key = backField.name;
          field.type = tmpTypeForm;
          field.templateOptions  = <FormlyTemplateOptions>{};
          field.templateOptions.label = backField.label;
          field.templateOptions.type = tmpTypeFormField;
          field.templateOptions.placeholder = backField.placeholder;
          field.templateOptions.required = true;
          field.templateOptions.value = 5;
          field.templateOptions.placeholder = 'kg';
          this.model[backField.name] = backField.value;

          fieldsx.push(field);

        });
        //this.fields = fieldsx;
        var formFieldsx: Array<FormlyFieldConfig> = [{
          type: 'flex-layout',
          templateOptions: {
            fxLayout: 'row',
          },
          fieldGroup: fieldsx,
        }
        ];
        this.formFields = formFieldsx;
        
      },
      err => console.log(err)
    ); */
  }

  onSubmit() {
    if (this.form.valid) {
      //LLENAR EL JSON QUE SE ENVÍA AL BACK DEL FORMULARIO DINÁMICO, EN EL CASO DE EXPLOCAIÓN FÍSCIA (9)-->
      var postDF:PostDynamicForm = new PostDynamicForm();
      postDF.consulta_id = this.consultaId;
      postDF.medico_id = this.medicoId;
      postDF.json_template_obj = this.dataBack;

      postDF.json_template_obj.fields.forEach(field => {
        field.value = this.model[field.name];
      });

      postDF.json_template = JSON.stringify(postDF.json_template_obj);
      postDF.json_template_obj = null;
      //LLENAR EL JSON QUE SE ENVÍA AL BACK DEL FORMULARIO DINÁMICO, EN EL CASO DE EXPLOCAIÓN FÍSCIA (9)<--
      this.submit_form.emit(postDF);
    } else {
      this._frm.toast_alert("Valide los campos sean correctos", "Aviso", "error");
    }    
  }
  on_close() {
    this.close_modal.emit();
  }



  on_print() {
    let preloader = document.getElementById('preloader');
    preloader.style.display = 'block';

    this._paciente.print_exploracion(this.consultaId).subscribe(
      data => {
        let blob = new Blob([data], { type: 'application/pdf' });
        this.currentPdf = URL.createObjectURL(blob);
        window.open(this.currentPdf, '_blank');
        preloader.style.display = 'none';
      },
      err => console.log(err)
    );
  }





}
