import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LANDING_ROUTES } from './landing.routes';
import { LandingComponent } from './landing.component';
import { AppointmentComponent } from './appointment/appointment.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { InfoComponent } from './info/info.component';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatGridListModule} from '@angular/material/grid-list';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { InputTrimModule } from 'ng2-trim-directive';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PrivacyComponent } from './privacy/privacy.component';



@NgModule({
  declarations: [
    LandingComponent,
    AppointmentComponent,
    FooterComponent,
    HeaderComponent,
    InfoComponent,
    PrivacyComponent
  ],
  exports: [
    LandingComponent,
    AppointmentComponent
  ],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'es-ES' }],
  imports: [
    CommonModule,
    LANDING_ROUTES,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    MatDatepickerModule,
    MatGridListModule,
    MatNativeDateModule,
    InputTrimModule,
    NgxSpinnerModule,
    FontAwesomeModule
  ]
})
export class LandingModule { }
