import { Component, OnInit, ViewChild } from '@angular/core';
import { MedicoService, PacienteService, FormsService, ConsultaService } from 'src/app/services/service.index';
import { NgbDateStruct, NgbCalendar, NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GridComponent } from 'src/app/shared/components/grid.component';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { paciente } from 'src/app/models/paciente.model';
import { agenda, consulta } from 'src/app/models/consulta.model';
import { calendario, medico } from './../../models/medico.model';
import { FormGroup } from '@angular/forms';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { ScheduleService } from 'src/app/services/schedule.service';
import { ConsultaPrint } from 'src/app/models/consulta_print.model';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { IAfterGuiAttachedParams } from 'ag-grid-community';
import { BtnCellRenderer } from './btn-cell-renderer';

declare var sw: any;


class ResAgenda {
  consulta_id: number;
  fecha_inicio_programada: Date;
  fecha_fin_programada: Date;
  fecha: Date;
  fecha_inicio_real?: any;
  fecha_fin_real?: any;
  medico_id: number;
  paciente_id: number;
  nombre_paciente?: any;
  numero_registro: string;
  motivo?: any;
  nota_medica?: any;
  externa?: any;
  especialidad_id: number;
  especialidad: string;
  estatus_id: number;
  estatus: string;
  ubicacion?: any;
  fecha_fin_exploracion?: any;
  causes_id?: any;
  cause?: any;
  tipo_id: number;
  tipo: string;
  saved_by?: any;
  medico: string;
  duracion: number;
  paciente: string;
  modalidad_id: number;
  modalidad_name: string;
  link_medico: string;
  link_paciente: string;
  procedimientos?: any;
  medicamentos?: any;
  medicamentos2?: any;
  diagnosticos?: any;
  estudios?: any;
  exploracion?: any;
}

@Component({
  selector: 'app-calendario',
  templateUrl: './calendario.component.html',
  styles: []
})

export class CalendarioComponent implements OnInit {
  @ViewChild(GridComponent, { static: true }) mygrid: GridComponent;
  @ViewChild("content", { static: true }) content;
  @ViewChild("pacientes", { static: true }) paciente_dialog;
  @ViewChild("agenda", { static: true }) agenda_dialog;
  @ViewChild("consulta", { static: true }) consuta_dialog;
  @ViewChild("exploracion", { static: true }) exploracion_dialog;
  @ViewChild('dp', { static: true }) dp;

  model: NgbDateStruct;
  calendario: calendario[] = [new calendario({})];
  //calendario: IResCalendar[];
  current_date: string;
  paciente: paciente = new paciente({});
  cita: agenda = new agenda({});
  medicoId: number;
  pacienteId: number;
  consultaId: number;
  rowClassRules = { "verde": "data.consulta_id == 0" };
  next_hour: any = null;
  rowData: any;
  columnDefs = [
    { headerName: "Hora", field: "fecha", width: 100, cellRenderer: (data) => { return moment(data.value).format('hh:mm a') } },
    { headerName: "Duracion (min)", field: "duracion", width: 60 },
    { headerName: "Paciente", field: "paciente", width: 250 },
    { headerName: "Expediente", field: "numero_registro", width: 100 },
    { headerName: "Estado", field: "estatus", width: 100 },
    { headerName: "Tipo de consulta", field: "tipo", width: 120 },
  ];


  public currentPdf: string = null;
  public linkMedico: string = '';
  public explorationReadOnly: boolean = false;


  constructor(
    public _medico: MedicoService, public _paciente: PacienteService,
    public _agenda: ConsultaService, public _scheduleService: ScheduleService,
    public calendar: NgbCalendar, public _router: Router, private _toastr: ToastrService,
    private modalService: NgbModal, public _frm: FormsService) { }




  ngOnInit() {   
    console.log(this._medico.get_current_medico());
    
    let current_month = new Date();
    this.get_calendar(current_month.getMonth() + 1, current_month.getFullYear(), () => { this.select() })
  }

  receta(data:any){
    console.log(data);
    
  }


  get_calendar(month: number, year: number, callback: any) {
    if (this._agenda.selected_consultorio_id > 0) {
      this._agenda.consultorio_calendario_get(this._agenda.selected_consultorio_id, month, year).subscribe(
        (data: any) => {
          this.calendario = data;
          this.model = this.calendar.getToday();
          callback();
        },
        err => console.log(err)
      );
    }
    else this.back();
  }


  option_selector(button: string) {
    //console.log(`button:${button}`);
    switch (button) {
      case "back": {
        this.back();
        break;
      }
      case "next-date": {
        this.next_date();
        break;
      }
      case "new-date": {
        if (this.model == null) {
          this._toastr.warning('Seleccione una fecha válida del calendario', "Aviso")
        } else {
          let current = new Date();
          let selected = new Date(this.model.year, this.model.month - 1, this.model.day + 1);

          if (selected < current) {
            this._toastr.error("No es posible agendar citas para fechas anteriores al dia de hoy.", "Agenda médica")
          }
          else {
            this.busca_espacio();
            if (this.model) {
              this.cita = new agenda({});
              // this.cita.medico_id = this._medico.selected_medico.medico_id;
              // this.cita.medico = this._medico.selected_medico.medico;
              // this.cita.especialidad_id = this._medico.selected_medico.especialidad_id;
              // this.cita.especialidad = this._medico.selected_medico.especialidad;
              this.cita.consultorio_id = this._agenda.selected_consultorio_id
              this.cita.consultorio = `${this._agenda.selected_consultorio} (${this._agenda.selected_sucursal})` 
              this.cita.fecha = new Date(this.model.year, this.model.month - 1, this.model.day, this.next_hour.hour, this.next_hour.minute);
              this.modalService.open(this.agenda_dialog, { backdrop: 'static' });
            }
            else {
              this._toastr.error("La fecha seleccionada no esta disponible para el medico " + this._medico.selected_medico.medico, "Agenda médica")
            }

          }
        }


        break;
      }
      case "update-date": {
        let current = new Date();
        let selected = new Date(this.model.year, this.model.month - 1, this.model.day + 1);

        if (selected < current) {
          this._toastr.error("No es posible editar citas de fechas anteriores al dia de hoy.", "Agenda médica")
        }
        else {
          const selecteds = this.mygrid.getSelectedRows();
          if (selecteds.length == 0) this._toastr.warning("Seleccione un cita del listado", "Agenda médica")
          else {
            this.cita = selecteds[0];
            this.modalService.open(this.agenda_dialog, { backdrop: 'static' });
          }
        }

        break;
      }
      case "search-date": {
        this.modalService.open(this.paciente_dialog, { backdrop: 'static', size: 'lg' });
        break;
      }
      case "print": {

        let select_date = new Date(this.model.year, this.model.month - 1, this.model.day);
        let date_param = moment(select_date).format('YYYY-MM-DD');
        let preloader = document.getElementById('preloader');
        preloader.style.display = 'block';
        console.log(this._medico.selected_medico.medico_id, this._medico.selected_medico.especialidad_id, date_param)
        this._agenda.test_download(this._medico.selected_medico.medico_id, this._medico.selected_medico.especialidad_id, date_param).subscribe(
          (data: any) => {
            let blob = new Blob([data], { type: 'application/pdf' });
            //saveAs(blob, 'test.pdf');

            this.currentPdf = URL.createObjectURL(blob);
            window.open(this.currentPdf, '_blank');
            preloader.style.display = 'none';
          },
          err => console.log(err)
        );

        break;
      }
      case "expediente": {

        const selecteds = this.mygrid.getSelectedRows();
        if (selecteds.length == 0) this._toastr.warning("Seleccione un paciente del listado", "Expediente de paciente")
        else {
          let selected = selecteds[0];
          this._paciente.get_paciente(selected.paciente_id).subscribe(
            (data: any) => {
              this.paciente = data;
              console.log(this.paciente);

              // Validar si es médico
              var user = JSON.parse(localStorage.getItem("user"));
              console.log('médico:' + user["role_id"]);

              if ((user["medico_id"] == null) && (user["role_id"] == 1)) {
                this.paciente.flat = 2;
              } else if (user["medico_id"] != null) {
                this.paciente.flat = 2;
              } else {
                this.paciente.flat = 1;
              }

              this.modalService.open(this.content, { backdrop: 'static', windowClass: "xlg" });
              sw.common.init_plugins();
              /*
              this.paciente.flat = 2;           
              this.modalService.open(this.content, { backdrop: 'static', windowClass: "xlg" });
              sw.common.init_plugins();
              */
            },
            err => console.log(err)
          );

        }
        break;
      }
      case "consulta": {

        const selecteds = this.mygrid.getSelectedRows();
        var dateDay = new Date();
        var year = selecteds[0].fecha.substring(0, 4);
        var month = selecteds[0].fecha.substring(5, 7);
        var day = selecteds[0].fecha.substring(8, 10);
        let fechaRef = new Date(year, month - 1, day);
        let fechahoy = new Date(dateDay.getFullYear(), dateDay.getMonth(), dateDay.getDate())
        var tipo_id = selecteds[0].tipo_id;
        //console.log(fechaRef)
        //console.log(fechahoy)
        
        //console.log(tipo_id);
        
        if (tipo_id == 9) {// COnsulta somatometria 
          this._toastr.warning("La consulta es de tipo Somatometría, solo esta disponible la exploración física", "Agenda médica")
        }
        else {
          if (fechahoy < fechaRef) {
            this._toastr.warning("La fecha de la consulta es mayor a la fecha actual, si desea ajustar la consulta, actualize la fecha", "Agenda médica")
          } else {

            if (selecteds.length == 0) this._toastr.warning("Seleccione un cita del listado", "Agenda médica")
            else {
              this.cita = selecteds[0];
              //console.log(selecteds[0])
              this.pacienteId = this.cita.paciente_id;
              this.consultaId = selecteds[0].consulta_id;
              this.medicoId =  this._medico.get_current_medico();// this.cita.medico_id
              this.explorationReadOnly = true;
              this.modalService.open(this.consuta_dialog, { backdrop: 'static', size: 'lg' });
            }
          }
        }

        break;
      }
      case "exploration": {

        const selecteds = this.mygrid.getSelectedRows();

        if (selecteds.length == 0) this._toastr.warning("Seleccione un cita del listado", "Agenda médica")
        else {
          this.cita = selecteds[0];
          console.log(selecteds[0])
          this.pacienteId = this.cita.paciente_id;
          this.consultaId = selecteds[0].consulta_id;
          this.medicoId = this._medico.get_current_medico();//this.cita.medico_id
          this.explorationReadOnly = false;
          this.modalService.open(this.exploracion_dialog, { backdrop: 'static', size: 'lg' });
        }

        break;
      }
    }
  }


  is_disabled = (date: NgbDate, current: { month: number }) => {
    if (date.month !== current.month) return true;
    else if (this.calendario) {
      const result = this.calendario.filter(function (data) {
        let fecha = new Date(data.fecha);
        return (fecha.getFullYear() == date.year && fecha.getMonth() + 1 == date.month && fecha.getDate() == date.day);
      });

      if (result.length > 0) {
        return !result[0].laborable;
      }
      else { return false }
    }
    else {
      return false;
    }
  }
  set_day_class = (date: NgbDate, month: number) => {
    let current = new Date();
    let selected = new Date(date.year, date.month - 1, date.day + 1);
    let past = "";

    if (selected < current) { past = " past " }

    if (date.month !== month) { return "hidden" }

    else if (this.calendario) {
      const result = this.calendario.filter(function (data) {
        let fecha = new Date(data.fecha);
        return (fecha.getFullYear() == date.year && fecha.getMonth() + 1 == date.month && fecha.getDate() == date.day);
      });
      if (result.length > 0) {
        if (result[0].laborable) return "custom-day " + past + result[0].disponibilidad;
        else return "custom-day" + past;
      }
    }
  }

  select() {
    moment.locale('es');
    let select_date = new Date(this.model.year, this.model.month - 1, this.model.day);
    this.current_date = moment(select_date).format('dddd, DD [de] MMMM [de] YYYY');
    let date_param = moment(select_date).format('YYYY-MM-DD');

    //console.log(this._agenda.selected_consultorio_id);
    
    this._agenda.consultorio_agenda_get(this._agenda.selected_consultorio_id, date_param).subscribe(
      (data: any) => {
        var dataPipe: ResAgenda[] = [];
        data.forEach(element => {
          element.modalidad_name = element.modalidad_id == 1 ? 'Presencial' : element.modalidad_id == 2 ? 'En Linea' : '';
          dataPipe.push(element);
        });

        this.rowData = dataPipe;
        console.log( this.rowData);
        
      },
      err => console.log(err)
    );
  }
  navigate(event) {
    //this.get_calendar(event.next.month, event.next.year,  () => {})
  }

  isRowSelectable(rowNode) {
    return rowNode.data ? rowNode.data.consulta_id > 0 : false;
  };
  getRowHeight(params) {
    let duracion: number = params.data.duracion;
    if (duracion >= 100) return (duracion / 3) * 2;
    else return 28;
  };



  back() {
    this._router.navigate(["/agendar"]);
  }

  next_date() {
    let select_date = new Date(this.model.year, this.model.month - 1, this.model.day);
    let date_param = moment(select_date).format('YYYY-MM-DD');

    this._agenda.get_next_date(this._medico.selected_medico.medico_id, this._medico.selected_medico.especialidad_id, date_param).subscribe(
      (data: any) => {
        let next: Date = new Date(data);
        let fecha_2: NgbDateStruct = { year: next.getFullYear(), month: next.getMonth() + 1, day: next.getDate() }
        this.dp.navigateTo(fecha_2);
        this.model = fecha_2;
        this.select();

      },
      err => console.log(err)
    );
  }

  busca_espacio() {
    let empty: agenda[] = this.rowData.filter(item => item.consulta_id === 0);
    if (empty.length > 0) this.next_hour = { hour: new Date(empty[0].fecha).getHours(), minute: new Date(empty[0].fecha).getMinutes() }
    else this.next_hour = { hour: 0, minute: 0 }
  }

  guardar_agenda(form: FormGroup) {

    console.log(form.value);
    
    this._frm.save_module(form, "consulta_id", () => {
      this._agenda.insert_agenda(form.value).subscribe(
        (res: any) => {
          this._frm.toast_alert("Cita agendada exitosamente", "Agenda médica", "success");
          this.select();
          this.modalService.dismissAll(null);
        },
        error => { this._frm.toast_alert("La cita no pudo ser agendada correctamente. Error: " + error.error.message, "Agenda médica", "error"); console.log(error) }
      );
    }, () => {
      this._agenda.update_agenda(form.value).subscribe(
        (res: any) => {
          this._frm.toast_alert("Cita actualizado exitosamente", "Agenda médica", "success");
          this.select();
          this.modalService.dismissAll(null);
        },
        error => {
          this._frm.toast_alert("La cita no pudo ser actualizada correctamente. Error: " + error.error.message, "Agenda médica", "error");
        }
      );
    });
  }





  //guardar_consulta(consulta:consulta){
  guardar_consulta(consultaPrint: ConsultaPrint) {
    console.log(`consultaPrint:${JSON.stringify(consultaPrint)}`);

    this._agenda.insert_consulta(consultaPrint.consulta).subscribe(
      (res: any) => {
        this._frm.toast_alert("Consulta guardada exitosamente", "Consulta médica", "success");
        this.select();
        this.modalService.dismissAll(null);
        console.log('finish insert');
        if (consultaPrint.print) {
          this.printPrescription(consultaPrint.consulta.consulta_id);
        }
      },
      error => { this._frm.toast_alert("La consulta no pudo ser guardada correctamente. Error: " + error.error.message, "Consulta médica", "error"); console.log(error) }
    );
  }

  printPrescription(consulta_id: any) {
    console.log(`printPrescription from calendario`);
    let preloader = document.getElementById('preloader');
    preloader.style.display = 'block';

    this._paciente.print_prescription(consulta_id).subscribe(
      data => {
        let blob = new Blob([data], { type: 'application/pdf' });
        this.currentPdf = URL.createObjectURL(blob);
        window.open(this.currentPdf, '_blank');
        preloader.style.display = 'none';
      },
      err => console.log(err)
    );
  }





  guardarExploracion(formValues: any) {
    //console.log(`formValues:${JSON.stringify(formValues)}`);
    this._scheduleService.postDynamicForm(JSON.stringify(formValues)).subscribe(
      (res: any) => {
        this._frm.toast_alert("Exploración guardada exitosamente", "Aviso", "success");
        this.select();
        this.modalService.dismissAll(null);
      },
      error => { this._frm.toast_alert("La exploración física no pudo ser guardada correctamente. Error: " + error.error.message, "Aviso", "error"); console.log(error) }
    );
  }
}
