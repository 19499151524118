<div class="card-body p-b-0">
    <form class="form-material" [formGroup]="componentForm" (ngSubmit)="on_submit()" #form="ngForm"  novalidate>
        <div class="row">
            <div class="col-md-4">
                <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('clase', componentForm, form.submitted)}">
                    <label for="txt_clase" [ngClass]="{ 'star_symbol': _frm.is_required('clase', componentForm) }">Clase</label>
                    <input type="text" placeholder="clase" class="form-control" id="txt_clase" formControlName="clase">      
                    <div class="invalid-feedback">
                        <div *ngIf="componentForm.controls['clase'].errors?.required">El campo es obligatorio</div>
                    </div>    
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('clave', componentForm, form.submitted)}">
                    <label for="txt_clave" [ngClass]="{ 'star_symbol': _frm.is_required('clave', componentForm) }">Clave</label>
                    <input type="text" placeholder="clave" class="form-control" id="txt_clave" formControlName="clave">
                    <div class="invalid-feedback">
                        <div *ngIf="componentForm.controls['clave'].errors?.required">El campo es obligatorio</div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
              <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('descripcion', componentForm, form.submitted)}">
                  <label for="txt_descripcion" [ngClass]="{ 'star_symbol': _frm.is_required('descripcion', componentForm) }">Descripción Oficial</label>
                  <textarea type="text" placeholder="descripcion" class="form-control" id="txt_descripcion" formControlName="descripcion"></textarea>
                  <div class="invalid-feedback">
                    <div *ngIf="componentForm.controls['descripcion'].errors?.required">El campo es obligatorio</div>
                </div>
              </div>
          </div>
          <div class="col-md-4">
            <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('clase_descripcion', componentForm, form.submitted)}">
                <label for="txt_descripcion_personalizada" [ngClass]="{ 'star_symbol': _frm.is_required('clase_descripcion', componentForm) }">Descripción Personalizada</label>
                <textarea type="text" placeholder="Descripción Personalizada" class="form-control" id="txt_descripcion_personalizada" formControlName="clase_descripcion"></textarea>
                <div class="invalid-feedback">
                  <div *ngIf="componentForm.controls['clase_descripcion'].errors?.required">El campo es obligatorio</div>
              </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('genero_id', componentForm, form.submitted)}">
                <label for="ddl_genero_id" [ngClass]="{ 'star_symbol': _frm.is_required('genero_id', componentForm) }">Género</label>
                <ng-select id="ddl_genero_id" [searchable]="true" placeholder="Género" formControlName="genero_id" class="form-control"
                           [items]="optionsGenero"  bindLabel="label" bindValue="value">
                </ng-select>                
                  <div class="invalid-feedback">
                    <div *ngIf="componentForm.controls['genero_id'].errors?.required">El campo es obligatorio</div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('edad_minima_tipo', componentForm, form.submitted)}">
                <label for="ddl_edad_minima_tipo" [ngClass]="{ 'star_symbol': _frm.is_required('edad_minima_tipo', componentForm) }">Edad Mínima</label>
                <div class="row">
                    <div class="col-md-6">
                        <ng-select id="ddl_edad_minima_tipo" [searchable]="true" placeholder="Edad mínima" formControlName="edad_minima_tipo" class="form-control"
                           [items]="optionsEdad"  bindLabel="label" bindValue="value">
                </ng-select> 
                    </div>
                    <div class="col-md-6">
                        <input type="text" placeholder="0" class="form-control" id="txt_edad_minima" formControlName="edad_minima">              
                  <div class="invalid-feedback">
                    <div *ngIf="componentForm.controls['edad_minima'].errors?.required">El campo es obligatorio</div>
                </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('edad_maxima_tipo', componentForm, form.submitted)}">
                <label for="ddl_edad_maxima_tipo" [ngClass]="{ 'star_symbol': _frm.is_required('edad_maxima_tipo', componentForm) }">Edad Máxima</label>
                <div class="row">
                    <div class="col-md-6">
                        <ng-select id="ddl_edad_maxima_tipo" [searchable]="true" placeholder="Edad mínima" formControlName="edad_maxima_tipo" class="form-control"
                           [items]="optionsEdad"  bindLabel="label" bindValue="value">
                </ng-select> 
                    </div>
                    <div class="col-md-6">
                        <input type="text" placeholder="0" class="form-control" id="txt_edad_maxima" formControlName="edad_maxima">              
                  <div class="invalid-feedback">
                    <div *ngIf="componentForm.controls['edad_maxima'].errors?.required">El campo es obligatorio</div>
                </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <div class="row m-t-30">
            <div class="col-md-12 modal-footer">
                <button *ngIf="show_cancel_button" type="button" class="btn btn-danger btn-sm" (click) = "on_close()" ><i class="mdi mdi-close"></i> Cancelar</button>
                <button *ngIf="show_close_button" type="button" class="btn btn-danger btn-sm" (click) = "on_close()" ><i class="mdi mdi-close"></i> Cerrar</button>
                <button *ngIf="show_save_button" type="submit" class="btn btn-primary btn-sm "><i class="mdi mdi-content-save"></i> Guardar</button>
            </div>
        </div>
    </form>
  </div>
  