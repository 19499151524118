export class DynamicForms {
    form_id?:     number;
    name?:        string;
    description?: string;
    css_class?:   null;
    fields?:      Field[];
}

export class Field {
    key?:             string;
    type?:            string;
    templateOptions?: TemplateOptions;
}

export class TemplateOptions {
    label?:       string;
    type?:        string;
    placeholder?: string;
    required?:    boolean;
    options?:     Option[];
}

export class Option {
    label?: string;
    value?: string;
}
