import { Component, OnInit, Input } from '@angular/core';
import { paciente } from 'src/app/models/paciente.model';

@Component({
  selector: 'app-expediente',
  templateUrl: './expediente.component.html',
  styles: []
})
export class ExpedienteComponent implements OnInit {

  @Input() paciente: paciente = new paciente({});
  
  constructor() { }

  ngOnInit() {
  }
  update_paciente($event){
    this.paciente = $event;
  }

}
