import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { AuthorizationService } from './authorization.service';
import { environment } from '../../environments/environment';
import { person } from '../models/authorization.model';
import { paciente, paciente_discapacidad, referencia, responsable, documento } from '../models/paciente.model';
import { prestamo } from 'src/app/models/paciente.model';
import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { log } from 'console';

@Injectable({
  providedIn: 'root'
})
export class PacienteService {
    _listPrestamos:any = [];


  constructor(public datepipe:DatePipe,public http: HttpClient, public _auth: AuthorizationService) { }

  search_paciente(keyword: string) {
    let url = environment.apiEndpoint + "paciente/searchpaciente";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
      params: new HttpParams().set('keyword', keyword),
    };
    return this.http.get(url, httpOptions);
  }

  get_paciente(paciente_id: number) {
    // console.log(paciente_id)
    let url = environment.apiEndpoint + "paciente/getpaciente";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
      params: new HttpParams().set('paciente_id', paciente_id.toString()),
    };
    return this.http.get(url, httpOptions);
  }

  get_paciente_catalog(keyword: string) {
    let url = environment.apiEndpoint + "paciente/paciente_catalog_get";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
      params: new HttpParams().set('keyword', keyword),
    };
    return this.http.get(url, httpOptions);
  }

  insert_paciente(new_paciente: paciente) {
     let fecha_nacimiento: any = new_paciente.fecha_nacimiento;
     new_paciente.fecha_nacimiento = new Date(fecha_nacimiento.year, fecha_nacimiento.month - 1, fecha_nacimiento.day);

    // console.log( 'insert_paciente_fecha_nacimiento -->', new_paciente.fecha_nacimiento  );


    new_paciente.paciente_id = 0;
    let url = environment.apiEndpoint + "paciente/pacienteInsert";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
    };
    return this.http.post(url, new_paciente, httpOptions);
  }
  insert_paciente_attatch(new_paciente: paciente) {
    
     
    // let fecha_nacimiento: any = new_paciente.fecha_nacimiento;
    // new_paciente.fecha_nacimiento = new Date(fecha_nacimiento.year, fecha_nacimiento.month - 1, fecha_nacimiento.day);

    new_paciente.paciente_id = 0;
    
    let url = environment.apiEndpoint + "paciente/pacienteInsertAttatch";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
    };
    return this.http.post(url, new_paciente, httpOptions);
  }

  update_paciente(updated_paciente: paciente) {
     let fecha_nacimiento: any = updated_paciente.fecha_nacimiento;
     updated_paciente.fecha_nacimiento = new Date(fecha_nacimiento.year, fecha_nacimiento.month - 1, fecha_nacimiento.day);
     
    //  console.log( 'updated_paciente.fecha_nacimiento -->', updated_paciente.fecha_nacimiento );
     
    let url = environment.apiEndpoint + "paciente/pacienteUpdate";
    const httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this._auth.token })
    };
    const formData = new FormData();
    formData.append('data', JSON.stringify(updated_paciente));
    if (updated_paciente.file) formData.append('file', updated_paciente.file, updated_paciente.file.name);
    return this.http.put(url, formData, httpOptions)
  }
  delete_paciente(paciente_id:number) {
    let url = environment.apiEndpoint + "paciente/pacienteDelete";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
      params: new HttpParams().set('paciente_id', paciente_id.toString())
    };
    return this.http.delete(url, httpOptions);
  }
  prestamo_estatus_get(keyword: string,fecha_consulta:string,especialidad_id:string,medico_id:string) {
    let url = environment.apiEndpoint + "paciente/prestamo_estatus_get";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
      params: new HttpParams().set('keyword', keyword).set('fecha_consulta',fecha_consulta).set('especialidad_id',especialidad_id.toString()).set('medico_id',medico_id.toString())
    };
    return this.http.get(url, httpOptions);
  }
  prestamo_search(keyword: string,fecha_incio:Date,fecha_fin:Date,area_id:string,prestado:string) {
    // console.log(fecha_incio,fecha_fin)
    if(fecha_incio!=null){
      var fecha1:any =fecha_incio
      var f1  = new Date(Date.UTC(fecha1.year, fecha1.month -1, fecha1.day + 1));
    }else{
      f1  = new Date(Date.UTC(1900, 1, 1));
    }
    if(fecha_fin!=null){
      var fecha2:any =fecha_fin
      var f2  = new Date(Date.UTC(fecha2.year, fecha2.month -1, fecha2.day + 1));
    }else{
      f2  = new Date(Date.UTC(2076, 6, 1));
    }
  
   
   
   
    let url = environment.apiEndpoint + "paciente/prestamo_search";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
      params: new HttpParams().set('keyword', keyword).set('fecha_inicio',this.datepipe.transform(f1, "yyyy-MM-dd")).set('fecha_fin',this.datepipe.transform(f2, "yyyy-MM-dd")).set('area_id',area_id).set('prestado',"")
    };
    return this.http.get(url, httpOptions);
  }
  prestamo_estatus_consulta(fecha_consulta:Date,especialidad_id:string,medico_id:string) {
    if(fecha_consulta!=null){
      let fecha:any = fecha_consulta
      var f1:Date;
      f1  = new Date(Date.UTC(fecha.year, fecha.month -1, fecha.day +1));
      this.datepipe.transform(f1, "yyyy-MM-dd")
    }

    let url = environment.apiEndpoint + "paciente/prestamo_search_consulta";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
      params: new HttpParams().set('fecha_consulta',this.datepipe.transform(f1, "yyyy-MM-dd")).set('especialidad_id',especialidad_id).set('medico_id',medico_id)
    };
    return this.http.get(url, httpOptions);
  }


  insert_prestamo(listPrestamo: Array<prestamo>) {     
    listPrestamo.forEach(element => {
    // console.log(element)
    let fecha:any = element.fecha_devolucion_programada 
    element.fecha_devolucion_programada  = new Date(Date.UTC(fecha.year, fecha.month - 1, fecha.day));
    });

    // console.log(listPrestamo);
    
    let url = environment.apiEndpoint + "paciente/prestamosInsert";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
    };
    return this.http.post(url, listPrestamo, httpOptions);
  }

  update_prestamo(listPrestamo: Array<prestamo>) {     
    listPrestamo.forEach(element => {
    let fecha:any = element.fecha_devolucion_real 
    element.fecha_devolucion_real  = new Date(Date.UTC(fecha.year, fecha.month - 1, fecha.day));
    }); 
    let url = environment.apiEndpoint + "paciente/prestamosUpdate";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
    };
    return this.http.put(url, listPrestamo, httpOptions);
  }

  print_pacientes(pacientes:any) {

    let url = environment.apiEndpoint + "paciente/printPacientes";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
    };
    return this.http.post(url,pacientes, {responseType: 'blob' });
  }
  print_pacientes2(pacientes:any) {

    let url = environment.apiEndpoint + "paciente/printPacientes2";
    return this.http.post( url,
      pacientes,
      {
        headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${this._auth.token }`}),
        responseType: 'blob'
      });
  }
  print_prescription(consulta_id:any) {
    let url = environment.apiEndpoint + "paciente/printconsultas?consulta_id="+consulta_id;
    return this.http.post( url,
                    null,
                    {
                      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${this._auth.token }`}),
                      responseType: 'blob'
                    });
  } 

  print_estudios(consulta_id:any) {
    let url = environment.apiEndpoint + "paciente/printEstudios?consulta_id="+consulta_id;
    return this.http.post( url,
                    null,
                    {
                      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${this._auth.token }`}),
                      responseType: 'blob'
                    });
  } 

  print_certificado(consulta_id:any) {
    let url = environment.apiEndpoint + "paciente/printCertificado?consulta_id="+consulta_id;
    return this.http.post( url,
                    null,
                    {
                      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${this._auth.token }`}),
                      responseType: 'blob'
                    });
  } 

  print_exploracion(consulta_id:any) {
    let url = environment.apiEndpoint + "paciente/print_exploracion?consulta_id="+consulta_id;
    return this.http.post( url,
                    null,
                    {
                      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${this._auth.token }`}),
                      responseType: 'blob'
                    });
  } 



  get_referencias(paciente_id:number) {
    let url = environment.apiEndpoint + "paciente/referencias_get";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
      params: new HttpParams().set('paciente_id', paciente_id.toString())
    };
    return this.http.get(url, httpOptions);
  } 
  insert_referencias(referencia:referencia) {   
     
    let fecha:any = referencia.fecha 
    referencia.fecha   = new Date(Date.UTC(fecha.year, fecha.month - 1, fecha.day));
    let url = environment.apiEndpoint + "paciente/referencias_insert";
    // console.log(referencia)   
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
    };
    return this.http.post(url, referencia, httpOptions);
  }
  update_referencias(referencia:referencia) {  
    // console.log(referencia)   
    let fecha:any = referencia.fecha 
    referencia.fecha   = new Date(Date.UTC(fecha.year, fecha.month - 1, fecha.day));
    let url = environment.apiEndpoint + "paciente/referencias_update";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
    };
    return this.http.put(url, referencia, httpOptions);
  }
  delete_referencias(referencia_id:number) {  
    let url = environment.apiEndpoint + "paciente/referencias_delete";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
      params: new HttpParams().set('referencia_id', referencia_id.toString())
    };
    return this.http.get(url, httpOptions);
  }
  delete_documento(paciente_id:number,tipo_documento_id:number, ubicacion:string) {  
    let url = environment.apiEndpoint + "paciente/documentos_delete";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
      params: new HttpParams().set('paciente_id', paciente_id.toString()).set('tipo_documento_id', tipo_documento_id.toString()).set('ubicacion', ubicacion)
    };
    return this.http.get(url, httpOptions);
  }

  get_diagnosticos(paciente_id:number) {
    let url = environment.apiEndpoint + "paciente/diagnosticos_get";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
      params: new HttpParams().set('paciente_id', paciente_id.toString())
    };
    return this.http.get(url, httpOptions);
  } 
  get_estudios(paciente_id:number) {
    let url = environment.apiEndpoint + "paciente/estudios_get";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
      params: new HttpParams().set('paciente_id', paciente_id.toString())
    };
    return this.http.get(url, httpOptions);
  } 
  get_procedimientos(paciente_id:number) {
    let url = environment.apiEndpoint + "paciente/procedimientos_get";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
      params: new HttpParams().set('paciente_id', paciente_id.toString())
    };
    return this.http.get(url, httpOptions);
  } 
  get_recetas(paciente_id:number) {
    let url = environment.apiEndpoint + "paciente/recetas_get";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
      params: new HttpParams().set('paciente_id', paciente_id.toString())
    };
    return this.http.get(url, httpOptions);
  } 
  get_consultas(paciente_id:number) {
    let url = environment.apiEndpoint + "paciente/consultas_get";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
      params: new HttpParams().set('paciente_id', paciente_id.toString())
    };
    return this.http.get(url, httpOptions);
  } 

  insert_responsable(responsable:any){
    let fecha:any = responsable.fecha_nacimiento 
    responsable.fecha_nacimiento   = new Date(Date.UTC(fecha.year, fecha.month - 1, fecha.day));
    // console.log(responsable)
    let url = environment.apiEndpoint + "paciente/responsable_insert";  
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
    };
    return this.http.post(url, responsable, httpOptions);

  }
  update_responsable(responsable:any){
    let fecha:any = responsable.fecha_nacimiento 
    responsable.fecha_nacimiento   = new Date(Date.UTC(fecha.year, fecha.month - 1, fecha.day));
    // console.log(responsable)
    let url = environment.apiEndpoint + "paciente/responsable_update";  
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
    };
    return this.http.put(url, responsable, httpOptions);

  }

  get_responsable(paciente_id:number) {
    let url = environment.apiEndpoint + "paciente/responsable_get";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
      params: new HttpParams().set('paciente_id', paciente_id.toString())
    };
    return this.http.get(url, httpOptions);
  }

  insert_documento(doc:any){
    let url = environment.apiEndpoint + "paciente/documentos_insert";
    const httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this._auth.token })
    };
    const formData = new FormData();
    formData.append('data', JSON.stringify(doc));
    if (doc.file) formData.append('file', doc.file, doc.file.name);
    return this.http.post(url, formData, httpOptions)
  }

  insert_documento_qr(doc:any){
    let url = environment.apiEndpoint + "paciente/documento_qr_create";
    const httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this._auth.token })
    };
    return this.http.post(url, doc, httpOptions)
  }


  get_documentos(paciente_id:number) {
    let url = environment.apiEndpoint + "paciente/documentos_get";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
      params: new HttpParams().set('paciente_id', paciente_id.toString())
    };
    return this.http.get(url, httpOptions);
  } 

  // Reports pacientes
  search_paciente_reporte(keyword: string,fecha_incio:Date,fecha_fin:Date) {
    
    if(fecha_incio!=null){
      var fecha1:any =fecha_incio
      var f1  = new Date(Date.UTC(fecha1.year, fecha1.month -1, fecha1.day + 1));
    }else{
      f1  = new Date(Date.UTC(1900, 1, 1));
    }
    if(fecha_fin!=null){
      var fecha2:any =fecha_fin
      var f2  = new Date(Date.UTC(fecha2.year, fecha2.month -1, fecha2.day + 1));
    }else{
      f2  = new Date(Date.UTC(2076, 6, 1));
    }
  
    let url = environment.apiEndpoint + "paciente/search_paciente_reporte";
    
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token, responseType: 'blob'} ),
      params: new HttpParams().set('keyword', keyword).set('start',this.datepipe.transform(f1, "yyyy-MM-dd")).set('end',this.datepipe.transform(f2, "yyyy-MM-dd")),
    };    
    return this.http.get(url, httpOptions );
  }
  






  //Mediciones
  get_presion_arterial(user_id:number, fecha_incio:Date,fecha_fin:Date) {
    
    let url = environment.apiEndpoint + "paciente/get_presion";
    
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token, responseType: 'blob'} ),
      params: new HttpParams().set('user_id',user_id.toString()).set('fecha_inicio',this.datepipe.transform(fecha_incio, "yyyy/MM/dd HH:mm:ss")).set('fecha_fin',this.datepipe.transform(fecha_fin, "yyyy/MM/dd HH:mm:ss")),
    };    
    return this.http.get(url, httpOptions );
  }

  get_glucosa(user_id:number, fecha_incio:Date,fecha_fin:Date) {
    
    let url = environment.apiEndpoint + "paciente/get_glucosa";
    
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token, responseType: 'blob'} ),
      params: new HttpParams().set('user_id',user_id.toString()).set('fecha_inicio',this.datepipe.transform(fecha_incio, "yyyy/MM/dd HH:mm:ss")).set('fecha_fin',this.datepipe.transform(fecha_fin, "yyyy/MM/dd HH:mm:ss")),
    };    
    return this.http.get(url, httpOptions );
  }


}
