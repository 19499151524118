export class HorarioEspecialidad {
    especialidadId: number;
    days:           Days = new Days();
}

class Days {
    lunesArray:     ArrayElement[] = [];
    martesArray:    ArrayElement[] = [];
    miercolesArray: ArrayElement[] = [];
    juevesArray:    ArrayElement[] = [];
    viernesArray:   ArrayElement[] = [];
    sabadoArray:    ArrayElement[] = [];
    domingoArray:   ArrayElement[] = [];
}

class ArrayElement {
    from: number = 0;
    to:   number = 0;
}
/*
class HorarioEspecialidad {
  especialidad: Array<{ id: number; days: DaysEspecialidad }> = [];
}

class DaysEspecialidad {
  lunesArray: Array<{ from: number; to: number }> = [];
  martesArray: Array<{ from: number; to: number }> = [];
  miercolesArray: Array<{ from: number; to: number }> = [];
  juevesArray: Array<{ from: number; to: number }> = [];
  viernesArray: Array<{ from: number; to: number }> = [];
  sabadoArray: Array<{ from: number; to: number }> = [];
  domingoArray: Array<{ from: number; to: number }> = [];
}
*/
