
import { Component, OnInit, ViewChild } from '@angular/core';
import { CatalogService, FormsService } from 'src/app/services/service.index';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GridComponent } from 'src/app/shared/components/grid.component';
import { tipo_cosulta } from 'src/app/models/consulta.model';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-tipo-consulta',
  templateUrl: './tipo-consulta.component.html',
  styles: []
})
export class TipoConsultaComponent implements OnInit {
  @ViewChild("modal", { static: true }) modal;
  @ViewChild(GridComponent, { static: true }) mygrid: GridComponent;
  rowData: any;
  columnDefs = [
    { headerName: "Nombre", field: "nombre"},
    { headerName: "Duración", field: "duracion" },
  ];
  _tipo_consulta:tipo_cosulta = new tipo_cosulta({});

  constructor( public _cat: CatalogService, private modalService: NgbModal, public _frm: FormsService) { }

  ngOnInit() {

    this._cat.get_tipo_consulta().subscribe(data => this.rowData = data, error => console.log(error));

  }

 
  option_selector(button: string) {
    console.log(button);
    
    switch (button) {
      case "create": {
        this._tipo_consulta = new tipo_cosulta({});
        this.modalService.open(this.modal, { size: 'lg' });
        break;
      }
      case "update": {
          this._frm.get_rows_selected(this.mygrid, false, (selecteds) => {
          this._tipo_consulta = selecteds[0];
          this.modalService.open(this.modal, { size: 'lg' })
          console.log(this._tipo_consulta );      
        });

        break;
      }
      case "delete": {
        this._frm.get_rows_selected(this.mygrid, false, (selecteds) => {
          this._tipo_consulta = selecteds[0];
          console.log(this._tipo_consulta.tipo_id)
          this._frm.show_warning('Borrar Tipo consulta', 'Estas seguro de eliminar el Tipo consulta [' + this._tipo_consulta.nombre + ']. Una vez eliminado no podra recuperarse.', () => this.delete_tipo_consulta(this._tipo_consulta.tipo_id));
        });
        break;
      }
    }
  }


  save_tipo_consulta(form: FormGroup) {

    console.log(form);
    

    this._frm.save_module(form, "tipo_id", () => {
      // Crear nuevo rol
      this._cat.insert_tipo_consulta(form.value).subscribe(
        (res: any) =>
        { this._frm.toast_alert("Tipo consulta creada exitosamente", "Nuevo Tipo consulta", "success");
          this.modalService.dismissAll(null);
          this._cat.get_tipo_consulta().subscribe(data => this.rowData = data, error => console.log(error));
        },
        error => this._frm.toast_alert("El tipo consulta no pudo ser creado correctamente. Error: " + error.error.message, "Nuevo Tipo consulta", "error")
      );
      
    }, () => {
       this._cat.update_tipo_consulta(form.value).subscribe(
        // Actualizar rol seleccionado
        (res: any) =>
         {this._frm.toast_alert("Tipo consulta actualizada exitosamente", "Actualizar Tipo consulta", "success");
         this.modalService.dismissAll(null); 
         this._cat.get_tipo_consulta().subscribe(data => this.rowData = data, error => console.log(error));     
          },
         error => {
           this._frm.toast_alert("El Tipo consulta no pudo ser actualizado correctamente. Error: " + error.error.message, "Actualizar Tipo consulta", "error");
         }
       );
    });
    
  
  
  }

  delete_tipo_consulta(id:number) {
    this._cat.delete_tipo_consulta(id).subscribe(
      (res: any) => {this._frm.toast_alert("Tipo consulta eliminado exitosamente", "Borrar Tipo consulta ", "success");
      this.modalService.dismissAll(null);
      this._cat.get_tipo_consulta().subscribe(data => this.rowData = data, error => console.log(error));
    },
      error => { console.log(error); this._frm.toast_alert("El Tipo consulta no pudo ser actualizado correctamente. Error: " + error.error.message, "Borrar Tipo consulta", "error") }
    );
   
  }

  

}
