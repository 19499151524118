import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthorizationService } from './authorization.service';
import * as signalR from '@aspnet/signalr';

@Injectable({
  providedIn: 'root'
})
export class NotifyService {

  constructor(public _auth: AuthorizationService) {
    // let url = environment.urlbase + "notifyhub";
    // let connection = new signalR.HubConnectionBuilder().withUrl(url, { 
    //   skipNegotiation: true, 
    //   transport: signalR.HttpTransportType.WebSockets, 
    //   accessTokenFactory: () => this._auth.token }).build();

    // connection.on("Notify", (message) => {
    //   console.log(message);
    // });

    // connection.start().then(() => connection.invoke("SendMessage", "Hello World"));

  }



}
