<div class="row">
  <div class="col-md-6">
    <div class="card">
      <div class="card-body">
        <h6 class="card-title">Módulos</h6>
        <h6 class="card-subtitle text-muted">Selecciona el módulo que deseas asignar</h6>
        <app-grid #gridModules [rowData]="rowData" [columnDefs]="columnDefs" [autoGroupColumnDef]="autoGroupColumnDef"
          [treeData]="true" [rowMultiSelectWithClick]="'false'" [suppressRowClickSelection]="'true'"
          (on_first_data_rendered)="pre_select_nodes($event)" (on_row_selected)="change_selection($event)"
          (on_row_clicked)="module_clicked($event)"></app-grid>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="card">
      <div class="card-body">
        <h6 class="card-title">Permisos en [{{selected_module.title}}]</h6>
        <h6 class="card-subtitle text-muted">Selecciona los permisos para el módulo</h6>
        <app-grid #gridPermissions [rowData]="rowData2" [columnDefs]="columnDefs2" (on_row_selected)="permission_selected($event)"></app-grid>  
      </div>
    </div>
  </div>
</div>