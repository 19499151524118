import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-consultorios-dialog',
  templateUrl: './consultorios-dialog.component.html',
  styles: [
  ]
})
export class ConsultoriosDialogComponent implements OnInit {
  @ViewChild("form", { static: true }) form;
  @Output() submit_form = new EventEmitter<FormGroup>();
  @Output() close_modal = new EventEmitter();
  @Input()  consultorio: any;

  consultorio_form: FormGroup;
  sucursales: any = [{id: 1, text: "San Angel Inn"}]
  constructor(private _fb: FormBuilder,) { }

  ngOnInit(): void {

    this.consultorio_form = this._fb.group({
      consultorio_id: [this.consultorio.consultorio_id],
      consultorio: [this.consultorio.consultorio, [Validators.required]],
      sucursal_id: [this.consultorio.sucursal_id, [Validators.required]]
    });

  }




  is_required(control: string): boolean {
    var _validator: any = this.consultorio_form.controls[control].validator && this.consultorio_form.controls[control].validator(this.consultorio_form.controls[control]);
    return _validator && _validator.required;
  }
  is_invalid(control: string): boolean {
    return ( this.form.submitted && this.consultorio_form.controls[control].invalid) || (this.consultorio_form.controls[control].invalid && (this.consultorio_form.controls[control].dirty || this.consultorio_form.controls[control].touched))
  }

  on_submit() {
    this.submit_form.emit(this.consultorio_form);
  }
  on_close() {
    this.close_modal.emit();
  }



}
