import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { DynamicService, FormsService } from 'src/app/services/service.index';
import { FormGroup, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styles: []
})
export class FormComponent implements OnInit {
  @ViewChild("form") form;

  @Input() form_id: number;
  @Input() show_cancel_button: boolean = false;
  @Input() data: any;
  @Output() submit_form = new EventEmitter<FormGroup>();
  @Output() close_modal = new EventEmitter();

  meta_data: any;
  form_group: FormGroup;
  constructor(public _dyn: DynamicService, public _frm: FormsService) { }

  ngOnInit() {
    this._dyn.get_form(this.form_id).subscribe(
      data => {
        this.meta_data = data;
        let group: any = {};
        // console.log(this.meta_data);

        this.meta_data.fields.forEach(field => {
          let validations: any = [];

          if (field.validations) {
            field.validations.forEach(val => {
              if (val.name == "required") validations.push(Validators.required);
            });
          }
          group[field.name] = new FormControl({value: null, disabled:!field.enabled}, validations);
        });
        this.form_group = new FormGroup(group);
        this.patch_values();
      },
      err => console.log(err)
    );
  }


  patch_values() {
    this.meta_data.fields.forEach(field => {
      this.form_group.controls[field.name].patchValue(this.match_data(field.name));
    });
  }

  match_data(key: string) {
    if (this.data) {
      if (this.data[key] == null) return null;
      else return this.data[key];
    }
    else return null;
  }

  on_submit() {
    this.submit_form.emit(this.form_group);
  }
  on_close() {
    this.close_modal.emit();
  }


}
