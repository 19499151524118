<div class="card-body p-b-0">
    <form class="form-material" [formGroup]="profile_form" (ngSubmit)="on_submit()" #form="ngForm" novalidate>

        <div class="row">
            <div class="col-md-4">
                <div class="form-group">
                    <label>Fotografía</label>
                    <input type="file" class="dropify" accept=".png, .jpg, .jpeg" data-allowed-file-extensions="jpg png jpeg"
                        data-max-file-size="300K" [attr.data-default-file]="noPhoto" (change)="change_file('file', $event.target.files)">
                </div>

            </div>

            <div class="col-md-8">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group" [ngClass]="{ 'has-danger': is_invalid('name')}">
                            <label for="txtNombre" [ngClass]="{ 'star_symbol': is_required('name') }">Nombre</label>
                            <input type="text" placeholder="Nombre" class="form-control" id="txtNombre" formControlName="name">

                            <div class="invalid-feedback">
                                <div *ngIf="profile_form.controls['name'].errors?.required">El nombre es obligatorio</div>
                                <div *ngIf="profile_form.controls['name'].errors?.minlength">4 caracteres minimo</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="input2">Apellido Paterno</label>
                            <input type="text" placeholder="Apellido Paterno" class="form-control" id="input2"
                                formControlName="last_name">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="input3">Apellido Materno</label>
                            <input type="text" placeholder="Apellido Materno" class="form-control" id="input3"
                                formControlName="middle_name">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="input7">Fecha de nacimiento</label>
                            <input type="text" placeholder="Fecha de nacimiento" class="form-control" id="input7"
                                formControlName="birthdate" ngbDatepicker #d="ngbDatepicker" (focus)="d.toggle()" readonly="readonly">
                            <i class="mdi mdi-calendar"></i>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="gender">Sexo</label>
                            <ng-select [searchable]="true" placeholder="Sexo" formControlName="gender_id" class="form-control"
                                [items]="genders" bindLabel="name" bindValue="gender_id">
                            </ng-select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="input4">Correo electrónico</label>
                            <input type="email" placeholder="Correo electrónico" class="form-control" id="input4"
                                formControlName="email">
                            <i class="mdi mdi-at"></i>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="input5">Teléfono fijo</label>
                            <input type="text" placeholder="Teléfono fijo" class="form-control" id="input5" formControlName="home_phone">
                            <i class="mdi mdi-phone"></i>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="input5">Teléfono móvil</label>
                            <input type="text" placeholder="Teléfono movil" class="form-control" id="input5" formControlName="mobile_phone">
                            <i class="mdi mdi-cellphone"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row m-t-30">
            <div class="col-md-12 modal-footer">
                <button *ngIf="show_cancel_button" type="button" class="btn btn-danger btn-sm" (click) = "on_close()" ><i class="mdi mdi-close"></i> Cancelar</button>
                <button type="submit" class="btn btn-primary btn-sm "><i class="mdi mdi-content-save"></i> Guardar Perfil</button>
            </div>
        </div>
    </form>
</div>