import { Component, OnInit, ViewChild } from '@angular/core';
import { CatalogService, ConsultaService, FormsService } from 'src/app/services/service.index';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GridComponent } from 'src/app/shared/components/grid.component';
import { catalog } from 'src/app/models/catalog.model';
import { FormGroup } from '@angular/forms';
import { Medicamento } from 'src/app/models/medicamento.model';
import { MedicamentosResponseModel } from 'src/app/models/medicamentos_response.model';
import { element } from 'protractor';
import { GruposResponseModel } from 'src/app/models/grupos_response.model';

@Component({
  selector: 'app-medicamentos',
  templateUrl: './medicamentos.component.html',
  styles: [
  ]
})
export class MedicamentosComponent implements OnInit {
  @ViewChild("modal", { static: true }) modal;
  @ViewChild(GridComponent, { static: true }) mygrid: GridComponent;

  rowData: any;
  columnDefs = [
    { headerName: "Clave", field: "clave"},
    { headerName: "Nombre genérico", field: "nombre_generico"},
    { headerName: "Descripción", field: "descripcion"},
    { headerName: "Presentación", field: "presentacion"},
    { headerName: "Grupo", field: "grupo_name"},
    { headerName: "Controlado", field: "controlado_text"}
  ];
  //_medicamento:Medicamento = new Medicamento({});
  _medicamento:MedicamentosResponseModel = new MedicamentosResponseModel();

  show_cancel_button = false;
  show_close_button = true;
  show_save_button = false;

  constructor(public _cat: CatalogService, 
    public _consulta: ConsultaService,
              private modalService: NgbModal, 
              public _frm: FormsService) { }

  ngOnInit() {
    this.rowData = [];
  }

  onFilterTextBoxChanged(search_word) {
    if (search_word.trim().length > 2) {
      this._consulta.search_medicamento(search_word || '').subscribe(
        async data => { 
          console.log(data);          
          let newData: MedicamentosResponseModel[] = data as MedicamentosResponseModel[];  
          let dataGrupos = await this._cat.get_catalog('grupos_medicamento',true).toPromise();
          let newDataGrupos: GruposResponseModel[] = dataGrupos as GruposResponseModel[];

          newData.forEach( (elementMedicamento) =>{          
            elementMedicamento.controlado_text = elementMedicamento.controlado ? 'Si' : 'No';
            newDataGrupos.forEach((elementGrupo)=>{
              if(elementGrupo.id == elementMedicamento.grupo_id){
                console.log(`elementGrupo.nombre: ${elementGrupo.nombre}`);
                elementMedicamento.grupo_name = elementGrupo.nombre;
              }
            });
          });
          console.log(`newData:${JSON.stringify(newData)}`);
          this.rowData = newData; 
        },
        err => console.log(err)
      );
    }
  }

  option_selector(button: string) {
    console.log(button);
    
    switch (button) {
      case "create": {
        this.show_cancel_button = false;
        this.show_close_button = true;
        this.show_save_button = true;
        this._medicamento = new MedicamentosResponseModel();
        this.modalService.open(this.modal, { size: 'lg' });
        break;
      }
      case "update": {
        this.show_cancel_button = false;
        this.show_close_button = true;
        this.show_save_button = true;
          this._frm.get_rows_selected(this.mygrid, false, (selecteds) => {
          this._medicamento = selecteds[0];
          if(this._medicamento.basico) this.show_save_button = false;
          this.modalService.open(this.modal, { size: 'lg' })
        });
        break;
      }
      case "view": {
        this.show_cancel_button = false;
        this.show_close_button = true;
        this.show_save_button = false;
        this._frm.get_rows_selected(this.mygrid, false, (selecteds) => {
        this._medicamento = selecteds[0];
        this.modalService.open(this.modal, { size: 'lg' })
      });
      break;
    }
      case "delete": {
        this._frm.get_rows_selected(this.mygrid, true, (selecteds) => {
          if(selecteds.length > 0){
            this._frm.show_warning('Borrar medicamento', '¿Estas seguro de eliminar el/los medicamento(s)? Una vez eliminado(s) no podra(n) recuperarse.', () => {
              selecteds.forEach(element => {
                this._medicamento = element;
                this.delete_medicamento(this._medicamento.medicamento_id);
              });
            });
          }                  
        });
        break;
      }
    }
  }

  save_medicamento(form: FormGroup) {
    this._frm.save_module(form, "id", () => {
      
     console.log(form.value);
      this._cat.insert_update_medicamento(form.value).subscribe(
        (res: any) =>
        { this._frm.toast_alert("Creado exitosamente", "Mensaje", "success");
          this.modalService.dismissAll(null);
          this._consulta.search_medicamento("").subscribe(data => this.rowData = data, error => console.log(error));
        },
        error => this._frm.toast_alert("Error: " + error.error.message, "Ocurrio un error", "error")
      );
      
    }, () => {
      this._cat.insert_update_medicamento(form.value).subscribe(
        (res: any) =>
        { this._frm.toast_alert("Actualizado exitosamente", "Mensaje", "success");
          this.modalService.dismissAll(null);
          this._consulta.search_medicamento("").subscribe(data => this.rowData = data, error => console.log(error));
        },
        error => this._frm.toast_alert("Error: " + error.error.message, "Ocurrio un error", "error")
      );
    });

  }

  delete_medicamento(id:number) {
    this._cat.delete_medicamento(id).subscribe(
      (res: any) => {this._frm.toast_alert("Eliminado exitosamente", "Mensaje", "success");
      this.modalService.dismissAll(null);
      this._consulta.search_medicamento("").subscribe(data => this.rowData = data, error => console.log(error));
    },
    error => this._frm.toast_alert("Error: " + error.error.message, "Ocurrio un error", "error")
    );
  }

}
