<div class="landing">

    <header>
        <app-header></app-header>
    </header>

    <div class="container">
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:center;'><strong><span style='font-size:14px;font-family:"Times New Roman",serif;'>AVISO DE PRIVACIDAD</span></strong></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:center;'><strong><span style='font-size:14px;font-family:"Times New Roman",serif;'>&nbsp;</span></strong></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;'><strong><span style='font-size:14px;font-family:"Times New Roman",serif;'>RESPONSABLE DEL TRATAMIENTO DE DATOS PERSONALES</span></strong></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;'><strong><span style='font-size:14px;font-family:"Times New Roman",serif;'>&nbsp;</span></strong></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>Lara Campos y Asociados M&eacute;dicos, S.A. de C.V. (&ldquo;INTERAMED&rdquo;), con domicilio en Mayorazgo N&uacute;mero Exterior 130, Interior 926, Colonia Xoco, Alcald&iacute;a Benito Ju&aacute;rez, C.P. 03330, Ciudad de M&eacute;xico, es responsable del tratamiento de sus datos personales conforme a este Aviso de Privacidad.</span></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;'><strong><span style='font-size:14px;font-family:"Times New Roman",serif;'>DATOS QUE SE RECABAN</span></strong></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>Los datos personales de contacto y/o de identificaci&oacute;n que tratar&aacute; INTERAMED son de manera enunciativa, m&aacute;s no limitativa, los siguientes: Nombre, edad, sexo, ocupaci&oacute;n, domicilio, correo electr&oacute;nico, tel&eacute;fono (m&oacute;vil y/o fijo) y/o registro federal de contribuyentes.</span></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;'><strong><span style='font-size:14px;font-family:"Times New Roman",serif;'>DATOS SENSIBLES</span></strong></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>INTERAMED recabar&aacute; y tratar&aacute; datos sensibles, relacionados con el estado de salud, antecedentes e historial cl&iacute;nico e informaci&oacute;n sobre el modo de vida, necesarios o convenientes para las finalidades posteriormente se&ntilde;aladas. Los datos personales sensibles ser&aacute;n mantenidos y tratados con estricta seguridad y confidencialidad para fines relacionados con la prestaci&oacute;n de servicios de salud y conforme a este Aviso de Privacidad.</span></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;'><strong><span style='font-size:14px;font-family:"Times New Roman",serif;'>FINALIDAD DEL TRATAMIENTO DE LOS DATOS&nbsp;</span></strong></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>Los Datos Personales y/o Sensibles en posesi&oacute;n de INTERAMED ser&aacute;n utilizados para las siguientes finalidades:</span></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
        <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;'>
            <ol start="1" style="margin-bottom:0cm;list-style-type: lower-alpha;">
                <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;font-size:14px;'>Finalidades necesarias con el titular de los datos:</span></li>
            </ol>
        </div>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
        <ul style="list-style-type: square;">
            <li><span style='font-family:"Times New Roman",serif;font-size:14px;'>Prestaci&oacute;n de servicios m&eacute;dicos de primer contacto, atenci&oacute;n de enfermer&iacute;a, servicios farmac&eacute;uticos, an&aacute;lisis de laboratorio radiolog&iacute;a e imagen, estudios y an&aacute;lisis patol&oacute;gicos, terapia, rehabilitaci&oacute;n, dieta y/o nutrici&oacute;n.</span></li>
            <li><span style='font-family:"Times New Roman",serif;font-size:14px;'>Creaci&oacute;n, estudio, an&aacute;lisis, actualizaci&oacute;n y mantenimiento de archivo cl&iacute;nico.</span></li>
            <li><span style='font-family:"Times New Roman",serif;font-size:14px;'>Facturaci&oacute;n y cobranza por servicios.</span></li>
            <li><span style='font-family:"Times New Roman",serif;font-size:14px;'>Mantenimiento de registros e historial cl&iacute;nico para seguimiento a servicios.</span></li>
        </ul>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
        <div style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;'>
            <ol start="2" style="margin-bottom:0cm;list-style-type: lower-alpha;">
                <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;font-size:14px;'>Finalidades distintas:</span></li>
            </ol>
        </div>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
        <ul style="list-style-type: square;">
            <li><span style='font-family:"Times New Roman",serif;font-size:14px;'>Estudios, registros, estad&iacute;sticas y an&aacute;lisis de informaci&oacute;n de salud.</span></li>
            <li><span style='font-family:"Times New Roman",serif;font-size:14px;'>Mantenimiento de registros para prestaci&oacute;n de servicios en el futuro.</span></li>
            <li><span style='font-family:"Times New Roman",serif;font-size:14px;'>An&aacute;lisis estad&iacute;sticos y de mercado.</span></li>
            <li><span style='font-family:"Times New Roman",serif;font-size:14px;'>Promoci&oacute;n y mercadeo de productos y servicios de INTERAMED u otras empresas pertenecientes al mismo grupo corporativo.</span></li>
        </ul>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>En caso de que los titulares no deseen que INTERAMED d&eacute; tratamiento a sus datos personales para las finalidades previstas anteriormente, tendr&aacute;n un plazo de 5 (cinco) d&iacute;as para manifestar su negativa dirigiendo un correo electr&oacute;nico a la siguiente direcci&oacute;n: jose.lara@larcam.com.mx</span></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;'><strong><span style='font-size:14px;font-family:"Times New Roman",serif;'>TRANSFERENCIA</span></strong></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>Para la prestaci&oacute;n de servicios m&eacute;dicos de primer contacto, atenci&oacute;n de enfermer&iacute;a, servicios farmac&eacute;uticos, an&aacute;lisis de laboratorio radiolog&iacute;a e imagen, estudios y an&aacute;lisis patol&oacute;gicos, terapia, rehabilitaci&oacute;n, dieta y nutrici&oacute;n, creaci&oacute;n, estudio, an&aacute;lisis, actualizaci&oacute;n y mantenimiento de archivos cl&iacute;nicos, INTERAMED puede transferir dentro y fuera del pa&iacute;s, los datos personales en su posesi&oacute;n a terceros subcontratados para los fines se&ntilde;alados en este Aviso de Privacidad. Los terceros a los que se transferir&aacute;n dichos datos podr&aacute;n ser laboratorios, cl&iacute;nicas, hospitales, centros de investigaci&oacute;n, aseguradoras, as&iacute; como cualquier empresa o subsidiaria del mismo grupo corporativo de INTERAMED, o en su caso autoridades competentes.</span></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>No ser&aacute; necesario el consentimiento de los titulares cuando las transferencias se realicen a sociedades del mismo grupo de INTERAMED o cuando dicha transferencia sea necesaria para prestarle un servicio o mantener actualizados los archivos cl&iacute;nicos.</span></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>En caso de que los titulares no deseen que INTERAMED transfiera sus datos personales, tendr&aacute;n un plazo de 5 (cinco) d&iacute;as para manifestar su negativa dirigiendo un correo electr&oacute;nico a la siguiente direcci&oacute;n: jose.lara@larcam.com.mx</span></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;'><strong><span style='font-size:14px;font-family:"Times New Roman",serif;'>LIMITACI&Oacute;N DE USO Y DIVULGACI&Oacute;N DE DATOS PERSONALES</span></strong></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;'><strong><span style='font-size:14px;font-family:"Times New Roman",serif;'>&nbsp;</span></strong></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>Los titulares podr&aacute;n limitar el uso de sus datos personales, a trav&eacute;s de un correo electr&oacute;nico dirigido a jose.lara@larcam.com.mx o notificaci&oacute;n por escrito a Mayorazgo N&uacute;mero Exterior 130, Interior 926, Colonia Xoco, Alcald&iacute;a Benito Ju&aacute;rez, C.P. 03330, Ciudad de M&eacute;xico, de Lunes a Viernes de 9:00 a 15:00 horas, y de 17:00 a 19:00 horas, en la que se se&ntilde;ale la limitaci&oacute;n al uso de los datos deseada.</span></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:14px;font-family:"Times New Roman",serif;'>SOLICITUD DE ACCESO, RECTIFICACI&Oacute;N, CANCELACI&Oacute;N U OPOSICI&Oacute;N DE DATOS PERSONALES Y REVOCACI&Oacute;N DEL CONSENTIMIENTO (DERECHOS ARCO)</span></strong></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:14px;font-family:"Times New Roman",serif;'>&nbsp;</span></strong></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>Todos los datos personales son tratados de conformidad con la legislaci&oacute;n aplicable y vigente, por ello los titulares de datos personales tienen en todo momento el derecho a acceder a los datos personales que posee INTERAMED, y del tratamiento de los mismos, as&iacute; como a rectificarlos en caso de ser inexactos o incompletos; cancelarlos cuando resulten ser excesivos o innecesarios para las finalidades que justificaron su obtenci&oacute;n; y oponerse a su tratamiento.</span></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;'><strong><span style='font-size:14px;font-family:"Times New Roman",serif;'>MEDIOS PARA EJERCER DERECHOS ARCO (ACCESO, RECTIFICACI&Oacute;N, CANCELACI&Oacute;N Y OPOSICI&Oacute;N)</span></strong></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>Para dar inicio a una solicitud ARCO, el titular de los datos personales deber&aacute; presentar una solicitud a trav&eacute;s de un correo electr&oacute;nico dirigido a jose.lara@larcam.com.mx, o bien, notificaci&oacute;n por escrito dirigida a Mayorazgo N&uacute;mero Exterior 130, Interior 926, Colonia Xoco, Alcald&iacute;a Benito Ju&aacute;rez, C.P. 03330, Ciudad de M&eacute;xico, Departamento de Datos Personales, de Lunes a Viernes de 9:00 a 15:00 horas y de 17:00 a 19:00 horas, y deber&aacute; contener la siguiente informaci&oacute;n:</span></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
        <ul style="list-style-type: square;">
            <li><span style='font-family:"Times New Roman",serif;font-size:14px;'>Nombre del titular.</span></li>
            <li><span style='font-family:"Times New Roman",serif;font-size:14px;'>Domicilio del titular o direcci&oacute;n de correo electr&oacute;nico para comunicar respuesta a su solicitud.</span></li>
            <li><span style='font-family:"Times New Roman",serif;font-size:14px;'>Documentos que acrediten identidad o autorizaci&oacute;n para representarlo en la solicitud.</span></li>
            <li><span style='font-family:"Times New Roman",serif;font-size:14px;'>Descripci&oacute;n de datos personales sobre los que se pretende ejercer alg&uacute;n derecho ARCO.</span></li>
            <li><span style='font-family:"Times New Roman",serif;font-size:14px;'>Fecha de atenci&oacute;n y nombre de la sucursal de INTERAMED en la cual recibi&oacute; los servicios.</span></li>
            <li><span style='font-family:"Times New Roman",serif;font-size:14px;'>Cualquier otro elemento que permita la localizaci&oacute;n de los datos personales y atenci&oacute;n a la solicitud.</span></li>
        </ul>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;'><strong><span style='font-size:14px;font-family:"Times New Roman",serif;'>MEDIOS PARA REVOCAR CONSENTIMIENTO</span></strong></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>En cualquier momento los titulares pueden solicitar la revocaci&oacute;n del consentimiento otorgado a INTERAMED para tratar sus datos personales enviando una solicitud a trav&eacute;s de un correo electr&oacute;nico dirigido a jose.lara@larcam.com.mx, o enviando una notificaci&oacute;n por escrito al domicilio ubicado en Mayorazgo N&uacute;mero Exterior 130, Interior 926, Colonia Xoco, Alcald&iacute;a Benito Ju&aacute;rez, C.P. 03330, Ciudad de M&eacute;xico, en un horario de Lunes a Viernes de 9:00 a 15:00 horas y de 17:00 a 19:00 horas, en la que se detalle claramente los datos respecto de los cuales revoca su consentimiento.</span></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;'><strong><span style='font-size:14px;font-family:"Times New Roman",serif;'>TRANSFERENCIA DE DATOS PERSONALES.</span></strong></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;'><strong><span style='font-size:14px;font-family:"Times New Roman",serif;'>&nbsp;</span></strong></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>INTERAMED informama que sus datos personales podr&aacute;n ser compartidos dentro y fuera del pa&iacute;s con las siguientes personas, empresas, organizaciones o autoridades distintas a INTERAMED, sin el consentimiento del Titular, para los siguientes fines de conformidad con el art&iacute;culo 37 de la Ley Federal de Protecci&oacute;n de Datos Personales en Posesi&oacute;n de los Particulares.</span></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
        <ul style="list-style-type: square;">
            <li><span style='font-family:"Times New Roman",serif;font-size:14px;'>Cuando la transferencia este prevista en una ley o tratado en los que M&eacute;xico sea parte;</span></li>
            <li><span style='font-family:"Times New Roman",serif;font-size:14px;'>Cuando la transferencia sea necesaria para la prevenci&oacute;n o el diagn&oacute;stico m&eacute;dico, la prestaci&oacute;n de asistencia sanitaria, tratamiento m&eacute;dico o la gesti&oacute;n de servicios sanitarios;</span></li>
            <li><span style='font-family:"Times New Roman",serif;font-size:14px;'>Cuando la transferencia sea efectuada a sociedades controladoras, subsidiarias o afiliadas bajo el control com&uacute;n del responsable, o a una sociedad matriz o a cualquier sociedad del mismo grupo del responsable que opere bajo los mismos procesos y pol&iacute;ticas internas;</span></li>
            <li><span style='font-family:"Times New Roman",serif;font-size:14px;'>Cuando la transferencia sea necesaria por virtud de un contrato celebrado o por celebrar en intereses de los titulares;</span></li>
            <li><span style='font-family:"Times New Roman",serif;font-size:14px;'>Cuando la transferencia sea necesaria o legalmente exigida para la salvaguarda de un inter&eacute;s p&uacute;blico, o para la procuraci&oacute;n o administraci&oacute;n de justicia;</span></li>
            <li><span style='font-family:"Times New Roman",serif;font-size:14px;'>Cuando la transferencia sea precisa para el reconocimiento, ejercicio o defensa de un derecho en un proceso judicial, y</span></li>
            <li><span style='font-family:"Times New Roman",serif;font-size:14px;'>Cuando la transferencia sea precisa para el mantenimiento o cumplimiento de una relaci&oacute;n jur&iacute;dica entre INTERAMED y el titular de los datos.</span></li>
        </ul>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>No obstante lo anterior y, en caso de que se presenten vulneraciones de seguridad ocurridas en cualquier fase del tratamiento, que afecten de forma significativa los derechos patrimoniales o morales de los titulares, &eacute;stos ser&aacute;n informados por correo electr&oacute;nico, de forma inmediata, a fin de que estos &uacute;ltimos puedan tomar las medidas correspondientes a la defensa de sus derechos.</span></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:14px;font-family:"Times New Roman",serif;'>USO DE &ldquo;COOKIES&rdquo; Y &ldquo;WEB BEACONS&rdquo;</span></strong></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>Es posible INTERAMED haga uso de las cookies que puedan servir para facilitar el uso de un sitio web y/o aplicaci&oacute;n m&oacute;vil al guardar contrase&ntilde;as y preferencias mientras los titulares navegan en internet o dentro de la aplicaci&oacute;n. INTERAMED no usar&aacute; las cookies para obtener datos de identificaci&oacute;n personal de la computadora o m&oacute;viles de los titulares que no se hayan enviado originalmente como parte de las cookies.</span></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>Por su parte, las &ldquo;web beacons&rdquo; son im&aacute;genes insertadas en una p&aacute;gina de internet, aplicaci&oacute;n m&oacute;vil o correo electr&oacute;nico, que puede ser utilizado para monitorear el comportamiento de un visitante, como almacenar informaci&oacute;n sobre la direcci&oacute;n IP del usuario, duraci&oacute;n del tiempo de interacci&oacute;n y el tipo de navegador utilizado, entre otros.</span></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>Aunque la mayor&iacute;a de los navegadores y aplicaciones m&oacute;viles aceptan &ldquo;cookies&rdquo; y &ldquo;web beacons&rdquo; autom&aacute;ticamente, los titulares pueden configurar su navegador y/o aplicaci&oacute;n m&oacute;vil para que no los acepte.</span></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;'><strong><span style='font-size:14px;font-family:"Times New Roman",serif;'>&nbsp;</span></strong></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;'><strong><span style='font-size:14px;font-family:"Times New Roman",serif;'>MODIFICACIONES</span></strong></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;'><strong><span style='font-size:14px;font-family:"Times New Roman",serif;'>&nbsp;</span></strong></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>Las partes acuerdan que el Aviso de Privacidad, puede ser modificado en el tiempo y forma en que INTERAMED, lo determine, atendiendo al estudio y las regulaciones en materia de protecci&oacute;n de datos personales surjan, por lo que se obliga a mantener actualizado el presente Aviso, lo anterior a efecto de que en su caso, los titulares se encuentren en posibilidades de ejercer sus derechos ARCO.</span></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;'><strong><span style='font-size:14px;font-family:"Times New Roman",serif;'>LEY APLICABLE Y JURISDICCI&Oacute;N</span></strong></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>Las partes expresan que el presente Aviso, se regir&aacute; por las disposiciones legales aplicables en la Rep&uacute;blica Mexicana, en especial, por lo dispuesto en la Ley Federal de Protecci&oacute;n de Datos Personales en Posesi&oacute;n de los Particulares y su reglamento.</span></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>Para el caso de que exista una disputa o controversia, derivada de la interpretaci&oacute;n, ejecuci&oacute;n o cumplimiento del presente Aviso o de cualquiera de los documentos que del mismo se deriven, o que guarden relaci&oacute;n con &eacute;ste, las partes amigablemente, buscar&aacute;n llegar a un acuerdo dentro de un plazo de 30 (treinta) d&iacute;as naturales, contados a partir de la fecha en que surja cualquier diferencia y se notifique por escrito sobre dicho evento a la contraparte, deduciendo el proceso de mediaci&oacute;n ante el Centro de Justicia Alternativa de la Ciudad de M&eacute;xico, llev&aacute;ndose al amparo de la Ley de Justicia Alternativa del Tribunal Superior de Justicia de la Ciudad de M&eacute;xico y su Reglamento Interno, vigente al momento de que se presente la controversia.</span></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:14px;font-family:"Times New Roman",serif;'>&nbsp;</span></strong></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>En caso de que las partes no lleguen a un acuerdo, ambas convienen someter todas las desavenencias que se deriven del presente Aviso o de cualquiera de los documentos que del mismo se deriven o que guarden relaci&oacute;n con &eacute;ste o con aqu&eacute;llos, de manera definitiva a la competencia y leyes de las Autoridades Administrativas Federales o Tribunales competentes de la Ciudad de M&eacute;xico, renunciando expresamente a cualquier fuero distinto que por razones de sus domicilios presentes o futuros pudieren corresponderles.</span></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:14px;font-family:"Times New Roman",serif;'>CONSENTIMIENTO&nbsp;</span></strong></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>Los titulares declaran bajo protesta de decir verdad que han le&iacute;do en su totalidad este Aviso de Privacidad y entienden plenamente su alcance y contenido. Por medio del presente otorgan su consentimiento para que INTERAMED trate sus datos personales de acuerdo con este Aviso de Privacidad.</span></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
        <p style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:14px;font-family:"Times New Roman",serif;'>De igual forma expresamente consienten el tratamiento de sus datos personales sensibles y la transferencia de sus datos personales. Asimismo, consienten expresamente el tratamiento de su informaci&oacute;n (datos personales) para las &ldquo;Finalidades Distintas&rdquo;, descritas en el inciso b) de la secci&oacute;n &ldquo;FINALIDAD DEL TRATAMIENTO DE LOS DATOS&rdquo;.</span></p>
        
        

    </div>

   
    <router-outlet></router-outlet>
    <app-footer></app-footer>
</div>