<app-buttons  (button_selected)="option_selector($event)"></app-buttons> 
<app-grid [rowSelection]="'multiple'" [rowData]="rowData" [columnDefs]="columnDefs" [show_filter]= true [server_filter] = true (on_row_text_changed)="onFilterTextBoxChanged($event)" ></app-grid>

<!-- Ventana modal para creación y actualización de camas -->
<ng-template #modal let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Medicamento</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body p-0">
        <div class="card m-b-0">
            <ul class="nav nav-tabs profile-tab" role="tablist">
                <li class="nav-item"> <a class="nav-link active" data-toggle="tab" href="#account" role="tab">Medicamento</a></li>
            </ul>
            <div class="tab-content">
                <div class="tab-pane active" id="account" role="tabpanel">
                   <app-medicamentos-dialog [catalog]= "_medicamento" [show_cancel_button]=show_cancel_button [show_close_button]=show_close_button [show_save_button]="show_save_button" (close_modal)="modal.close('Cancelar')" (submit_form)="save_medicamento($event)"></app-medicamentos-dialog>
                </div>
            </div>
        </div>
    </div>
</ng-template>
