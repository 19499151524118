import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { paciente } from 'src/app/models/paciente.model';
import { FormsService, PacienteService } from 'src/app/services/service.index';
import * as moment from "moment";

@Component({
  selector: 'app-area-mediciones',
  templateUrl: './area-mediciones.component.html',
  styles: [
  ]
})
export class AreaMedicionesComponent implements OnInit {
  
  @Input() paciente: paciente = new paciente({});

  multi: any[] = [];
  view: any[] = undefined;//[700, 300];

  data_glucosa: any[] = [];

  presion_form: FormGroup;
  glucosa_form: FormGroup;

  // options
  legend: boolean = true;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = false;
  showXAxisLabel: boolean = false;
  xAxisLabel: string = 'Fecha';
  yAxisLabel: string = 'Valor';
  timeline: boolean = false;
  
  colorScheme = "cool";


  posicion = [{ nombre: 'Sentado', id: '1' }, { nombre: 'Acostado', id: '2' }];
  brazo = [{ nombre: 'Izquierdo', id: '1' }, { nombre: 'Derecho', id: '2' }];


  constructor(private _fb: FormBuilder, public _frm: FormsService, private pacienteService:PacienteService) {
    //Object.assign(this, { multi });
   }

  ngOnInit(): void { 
    var currentDate = new Date();
    var firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    var lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

    this.presion_form = this._fb.group({
      desde: [{ year: firstDay.getFullYear(), month: firstDay.getMonth() + 1, day: firstDay.getDate() } , [Validators.required]],
      hasta: [{ year: lastDay.getFullYear(), month: lastDay.getMonth() + 1, day: lastDay.getDate() } , [Validators.required]],
      posicion_id: ["1", [Validators.required]],
      brazo_id: ["1", [Validators.required]],
    });


    this.glucosa_form = this._fb.group({
      desde: [{ year: firstDay.getFullYear(), month: firstDay.getMonth() + 1, day: firstDay.getDate() } , [Validators.required]],
      hasta: [{ year: lastDay.getFullYear(), month: lastDay.getMonth() + 1, day: lastDay.getDate() } , [Validators.required]],
    });


  }

  panelChange(panel:any) {
    if (panel == "presion_arterial") {
      this. refresh_presion();
    }
    else if (panel == "glucosa") {
      this.  refresh_glucosa();
    }
    
  }


  refresh_presion() {

    var f1 = this.presion_form.value.desde;
    var f2 = this.presion_form.value.hasta;
    var fi = new Date(f1.year, f1.month -1, f1.day, 0,0,0);
    var ff = new Date(f2.year, f2.month -1, f2.day,23,59,59);

    var brazo = this.presion_form.value.brazo_id == '1' ? 'Izquierdo' : 'Derecho';
    var posicion = this.presion_form.value.posicion_id == '1' ? 'Sentado' : 'Acostado';

    this.pacienteService.get_presion_arterial(this.paciente.user_id, fi, ff).subscribe((data: any[]) => {

      console.log("Data:" + data);
      console.log(brazo);
      console.log(posicion);
      
      
      const result = data.filter(item => item.brazo==brazo).filter(item => item.posicion==posicion);


      var sistolica = {
        name: "Sistolica",
        series: []
      }
      var diastolica = {
        name: "Diastolica",
        series: []
      }

      result.forEach(element => {
        sistolica.series.push({
          value:element.sistolica,
          name:element.fecha_registro
        });
        diastolica.series.push({
          value:element.diastolica,
          name:element.fecha_registro
        });
      });

      var final = [sistolica,diastolica];
      this.multi = final;

      console.log(this.multi);
      

    }, 
    error => console.error(error));
  }

  refresh_glucosa() {

    var f1 = this.glucosa_form.value.desde;
    var f2 = this.glucosa_form.value.hasta;
    var fi = new Date(f1.year, f1.month -1, f1.day, 0,0,0);
    var ff = new Date(f2.year, f2.month -1, f2.day,23,59,59);

    this.pacienteService.get_glucosa(this.paciente.user_id, fi, ff).subscribe((data: any[]) => {

      console.log("Data:" + data);

      var glucosa = {
        name: "Glucosa",
        series: []
      }

      data.forEach(element => {
        glucosa.series.push({
          value:element.glucosa,
          name:element.fecha_registro
        });
      });

      var final = [glucosa];
      this.data_glucosa = final;

      console.log(this.data_glucosa);
      

    }, 
    error => console.error(error));
  }

 formatDate(value) {
    try {
      return moment(value).format("DD-MMM-YY HH:mm");
    } catch (error) {
      return value;
    }
}



}


