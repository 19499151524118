<div class="card-body p-b-0">
    <form class="form-material" [formGroup]="componentForm" (ngSubmit)="on_submit()" #form="ngForm" novalidate>
        <div class="row">
            <div class="col-md-4">
                <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('procedimiento_cod', componentForm, form.submitted)}">
                    <label for="txt_name" [ngClass]="{ 'star_symbol': _frm.is_required('procedimiento_cod', componentForm) }">Clave</label>
                    <input type="text" placeholder="codigo" class="form-control" id="txt_name" formControlName="procedimiento_cod">
                    <div class="invalid-feedback">
                        <div *ngIf="componentForm.controls['procedimiento_cod'].errors?.required">La clave es obligatorio</div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('nombre', componentForm, form.submitted)}">
                    <label for="txt_name" [ngClass]="{ 'star_symbol': _frm.is_required('nombre', componentForm) }">Descripción oficial</label>
                    <input type="text" placeholder="Nombre" class="form-control" id="txt_name" formControlName="nombre">
                    <div class="invalid-feedback">
                        <div *ngIf="componentForm.controls['nombre'].errors?.required">El nombre es obligatorio</div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('nombre_compuesto', componentForm, form.submitted)}">
                    <label for="txt_name" [ngClass]="{ 'star_symbol': _frm.is_required('nombre_compuesto', componentForm) }">Descripción personalizada</label>
                    <input type="text" placeholder="Descripción personalizada" class="form-control" id="txt_name" formControlName="nombre_compuesto">
                    <div class="invalid-feedback">
                        <div *ngIf="componentForm.controls['nombre_compuesto'].errors?.required">El nombre es obligatorio</div>
                    </div>
                </div>
            </div>

        </div>
        <div class="row m-t-30">
            <div class="col-md-12 modal-footer">
                <button *ngIf="show_cancel_button" type="button" class="btn btn-danger btn-sm" (click)="on_close()"><i class="mdi mdi-close"></i> Cancelar</button>
                <button *ngIf="show_close_button" type="button" class="btn btn-danger btn-sm" (click)="on_close()"><i class="mdi mdi-close"></i> Cerrar</button>
                <button *ngIf="show_save_button" type="submit" class="btn btn-primary btn-sm "><i class="mdi mdi-content-save"></i> Guardar</button>
            </div>
        </div>
    </form>
</div>