<div class="card-body p-b-0">
  <form class="form-material" [formGroup]="module_form" (ngSubmit)="on_submit()" #form="ngForm" novalidate>


    <div class="row">
      <div class="col-md-6">
        <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('name', module_form, form.submitted)}">
          <label for="txt_name" [ngClass]="{ 'star_symbol': _frm.is_required('name', module_form) }">Nombre</label>
          <input type="text" placeholder="Nombre" class="form-control" id="txt_name" formControlName="name">
          <div class="invalid-feedback">
            <div *ngIf="module_form.controls['name'].errors?.required">El nombre es obligatorio</div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('title', module_form, form.submitted)}">
          <label for="txt_title" [ngClass]="{ 'star_symbol': _frm.is_required('title', module_form) }">Título</label>
          <input type="text" placeholder="Título" class="form-control" id="txt_title" formControlName="title">
          <div class="invalid-feedback">
            <div *ngIf="module_form.controls['title'].errors?.required">El título es obligatorio</div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('subtitle', module_form, form.submitted)}">
          <label for="txt_subtitle" [ngClass]="{ 'star_symbol': _frm.is_required('subtitle', module_form) }">Subtitulo</label>
          <input type="text" placeholder="Subtitulo" class="form-control" id="txt_subtitle" formControlName="subtitle">
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('route', module_form, form.submitted)}">
          <label for="txt_route" [ngClass]="{ 'star_symbol': _frm.is_required('route', module_form) }">Ruta</label>
          <input type="text" placeholder="Ruta" class="form-control" id="txt_route" formControlName="route">
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('parameters', module_form, form.submitted)}">
          <label for="txt_parameters" [ngClass]="{ 'star_symbol': _frm.is_required('parameters', module_form) }">Parámetros</label>
          <input type="text" placeholder="Parámetros" class="form-control" id="txt_parameters" formControlName="parameters">
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('css_class', module_form, form.submitted)}">
          <label for="txt_class" [ngClass]="{ 'star_symbol': _frm.is_required('css_class', module_form) }">Clase</label>
          <input type="text" placeholder="Clase" class="form-control" id="txt_class" formControlName="css_class">
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('is_visible', module_form, form.submitted)}">
          <label for="ddl_is_visible" [ngClass]="{ 'star_symbol': _frm.is_required('is_visible', module_form) }">Visible</label>
          <ng-select  placeholder="Visible" formControlName="is_visible" id="ddl_is_visible" class="form-control" [items]="options" bindLabel="label" bindValue="value" [searchable]="false"></ng-select>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('display_order', module_form, form.submitted)}">
          <label for="txt_display_order" [ngClass]="{ 'star_symbol': _frm.is_required('display_order', module_form) }">Orden</label>
          <input type="number" placeholder="Orden" class="form-control" id="txt_display_order" formControlName="display_order">
        </div>
      </div>


      <div class="col-md-6">
        <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('description', module_form, form.submitted)}">
          <label for="txt_description" [ngClass]="{ 'star_symbol': _frm.is_required('description', module_form) }">Descripción</label>
          <textarea placeholder="Descripción" class="form-control" id="txt_description" formControlName="description"></textarea>
        </div>
      </div>
    </div>

    <div class="row m-t-30">
      <div class="col-md-12 modal-footer">
        <button *ngIf="show_cancel_button" type="button" class="btn btn-danger btn-sm" (click)="on_close()"><i class="mdi mdi-close"></i>
          Cancelar</button>
        <button type="submit" class="btn btn-primary btn-sm "><i class="mdi mdi-content-save"></i> Guardar Módulo</button>
      </div>
    </div>

  </form>
</div>