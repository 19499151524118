import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { AuthorizationService } from './authorization.service';
import { environment } from '../../environments/environment';
import { person, user, role, moduleX, module_permission, module_authorize, permission_authorize, permission } from '../models/authorization.model';
import { address } from '../models/address.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(public http: HttpClient, public _auth: AuthorizationService) { }

  insert_user(new_user: user) {
    // console.log(new_user);
    
    let url = environment.apiEndpoint + "users/insertuser";
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type':  'application/json','Authorization': 'Bearer ' + this._auth.token}),
    };
    return this.http.post(url, new_user, httpOptions);
  }
  delete_user(user_deleted:user){
    let url = environment.apiEndpoint + "users/deleteuser";
    const httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this._auth.token })
    };
    return this.http.put(url,user_deleted, httpOptions)
  }
  get_users(filter: user) {
    let url = environment.apiEndpoint + "users/getusers";
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type':  'application/json','Authorization': 'Bearer ' + this._auth.token}),
    };
    return this.http.get(url, httpOptions);
  }
  get_personal_catalog(keyword: string) {
    let url = environment.apiEndpoint + "medico/getPersonal";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
      params: new HttpParams().set('keyword', keyword),
    };
    return this.http.get(url, httpOptions);
  }
  update_profile(person: person) {
    // console.log(`person:${JSON.stringify(person)}`);

    let person_clone = Object.assign({}, person);

    if(person_clone.birthdate != null){
      let fecha:any = person_clone.birthdate;
      person_clone.birthdate  = new Date(Date.UTC(fecha.year, fecha.month - 1, fecha.day));
    }
    
    let url = environment.apiEndpoint + "users/updateperson";
    const httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this._auth.token })
    };
   
    const formData = new FormData();
    formData.append('data', JSON.stringify(person_clone));
    if (person.file)  formData.append('file', person.file, person.file.name);
    return this.http.put(url, formData, httpOptions)
  }
  update_address(updated_address: address){
    let url = environment.apiEndpoint + "users/updateaddress";
    const httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this._auth.token })
    };
    return this.http.put(url, updated_address, httpOptions);
  }



  // Role Methods
  get_roles(filter:role){
    let url = environment.apiEndpoint + "users/getroles";
    return this.http.get(url, {
      headers: new HttpHeaders({'Content-Type':  'application/json','Authorization': 'Bearer ' + this._auth.token}),
      params: new HttpParams().set('is_active', filter.is_active.toString()),
      observe: 'response'
    });
  }
  insert_role(new_role: role) {
    console.log(new_role);
    
    let url = environment.apiEndpoint + "users/insertrole";
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type':  'application/json','Authorization': 'Bearer ' + this._auth.token}),
    };
    return this.http.post(url, new_role, httpOptions);
  }
  update_role(updated_role: role) {
    let url = environment.apiEndpoint + "users/updaterole";
    const httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this._auth.token })
    };
    return this.http.put(url, updated_role, httpOptions)
  }
  delete_role(deleted_role: role) {
    let url = environment.apiEndpoint + "users/deleterole";
    const httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this._auth.token })
    };
    return this.http.put(url, deleted_role, httpOptions)
  }


  // Module Methods
  get_modules(filter:moduleX){
    let url = environment.apiEndpoint + "users/getmodules";
    return this.http.get(
      url, {
        headers: new HttpHeaders({'Content-Type':  'application/json','Authorization': 'Bearer ' + this._auth.token}),
        params: new HttpParams().set('is_active', filter.is_active.toString()),
        observe: 'response'
      }
    );
  }
  insert_module(new_module: moduleX) {  
    new_module.is_active = true; 
    let url = environment.apiEndpoint + "users/insertmodule";
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type':  'application/json','Authorization': 'Bearer ' + this._auth.token}),
    };
    return this.http.post(url, new_module, httpOptions);
  }
  update_module(updated_module: moduleX) {
    let url = environment.apiEndpoint + "users/updatemodule";
    const httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this._auth.token })
    };
    return this.http.put(url, updated_module, httpOptions)
  }
  delete_module(deleted_module: moduleX) {
    let url = environment.apiEndpoint + "users/deletemodule";
    const httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this._auth.token })
    };
    return this.http.put(url, deleted_module, httpOptions)
  }

  get_module_permissions(module_id:number){
    let url = environment.apiEndpoint + "users/getmodulepermission";
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type':  'application/json','Authorization': 'Bearer ' + this._auth.token}),
      params: new HttpParams().set('module_id', module_id.toString())
    };
    return this.http.get(url, httpOptions);
  }
  insert_module_permission(new_module: module_permission) {  
    let url = environment.apiEndpoint + "users/insertmodulepermission";
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type':  'application/json','Authorization': 'Bearer ' + this._auth.token}),
    };
    return this.http.post(url, new_module, httpOptions);
  }
  delete_module_permission(deleted_module: module_permission) {  
    let url = environment.apiEndpoint + "users/deletemodulepermission";
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type':  'application/json','Authorization': 'Bearer ' + this._auth.token}),
    };
    return this.http.put(url, deleted_module, httpOptions);
  }

  get_module_authorize(role_id:number, user_id:number){
    let url = environment.apiEndpoint + "users/getmoduleauthorize";
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type':  'application/json','Authorization': 'Bearer ' + this._auth.token}),
      params: new HttpParams().set('role_id', role_id.toString()).set('user_id', user_id.toString())

    };
    return this.http.get(url, httpOptions);
  }
  insert_module_role_authorize(new_module: permission_authorize) {  
    let url = environment.apiEndpoint + "users/insertmoduleroleauthorize";
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type':  'application/json','Authorization': 'Bearer ' + this._auth.token}),
    };
    return this.http.post(url, new_module, httpOptions);
  }
  delete_module_role_authorize(new_module: permission_authorize) {  
    let url = environment.apiEndpoint + "users/deletemoduleroleauthorize";
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type':  'application/json','Authorization': 'Bearer ' + this._auth.token}),
    };
    return this.http.put(url, new_module, httpOptions);
  }
  insert_module_user_authorize(new_module: permission_authorize) {  
    let url = environment.apiEndpoint + "users/insertmoduleuserauthorize";
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type':  'application/json','Authorization': 'Bearer ' + this._auth.token}),
    };
    return this.http.post(url, new_module, httpOptions);
  }
  delete_module_user_authorize(new_module: permission_authorize) {  
    let url = environment.apiEndpoint + "users/deletemoduleuserauthorize";
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type':  'application/json','Authorization': 'Bearer ' + this._auth.token}),
    };
    return this.http.put(url, new_module, httpOptions);
  }

  // Permission methods
  get_permissions(filter:permission){
    let url = environment.apiEndpoint + "users/getpermissions";
    return this.http.get(
      url, {
        headers: new HttpHeaders({'Content-Type':  'application/json','Authorization': 'Bearer ' + this._auth.token}),
        params: new HttpParams().set('is_active', filter.is_active.toString()),
      }
    );
  }
  insert_permission(new_permission: permission) {  
    new_permission.is_active = true; 
    let url = environment.apiEndpoint + "users/insertpermission";
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type':  'application/json','Authorization': 'Bearer ' + this._auth.token}),
    };
    return this.http.post(url, new_permission, httpOptions);
  }
  update_permission(updated_permission: permission) {
    let url = environment.apiEndpoint + "users/updatepermission";
    const httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this._auth.token })
    };
    return this.http.put(url, updated_permission, httpOptions)
  }
  delete_permission(deleted_permission: permission) {
    let url = environment.apiEndpoint + "users/deletepermission";
    const httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this._auth.token })
    };
    return this.http.put(url, deleted_permission, httpOptions)
  } 
}
