<app-buttons (button_selected)="option_selector($event)"></app-buttons>
<app-grid [rowData]="rowData" [columnDefs]="columnDefs" [rowSelection]="'single'"></app-grid>



<!-- Ventana modal para creación y actualización de roles -->
<ng-template #modal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" *ngIf="!role.role_id">Nuevo Rol</h4>
        <h4 class="modal-title" *ngIf="role.role_id">Actualizar Rol</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body p-0">
        <div class="card m-b-0">
            <ul class="nav nav-tabs profile-tab" role="tablist">
                <li class="nav-item"> <a class="nav-link active" data-toggle="tab" href="#account" role="tab">Datos generales</a></li>
            </ul>
            <div class="tab-content">
                <div class="tab-pane active" id="account" role="tabpanel">
                    <app-roles-dialog [role]="role" [show_cancel_button]=true (close_modal)="modal.close('Cancelar')" (submit_form)="save_role($event)"></app-roles-dialog>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!-- Ventana modal para creación y actualización de roles -->
<ng-template #modal2 let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Asignación de módulos y permisos</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="modal-body p-0">
        <div class="card m-b-0">
            <ul class="nav nav-tabs profile-tab" role="tablist">
                <li class="nav-item"> <a class="nav-link active" data-toggle="tab" href="#account" role="tab">Rol [{{ role.display_text }}]</a></li>
            </ul>
            <div class="tab-content">
                <div class="tab-pane active" id="account" role="tabpanel">
                    <app-authorization-dialog [role_id]="role.role_id"></app-authorization-dialog>
                </div>
            </div>
        </div>
    </div>
</ng-template>
