
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-body">
        <h6 class="card-title">Pacientes</h6>
        <h6 class="card-subtitle text-muted">Busca los pacientes con citas agendadas</h6>
        <app-grid   [rowSelection]="'single'" [rowData]="rowData" [columnDefs]="columnDefs" 
                    [show_filter]= true [server_filter] = true (on_row_text_changed)="onFilterTextBoxChanged($event)" ></app-grid>
      </div>
    </div>
  </div>
</div> 