<div class="card-body p-b-0">
    <form class="form-material" #form="ngForm"  (ngSubmit)="save_doc()" [formGroup]="doc_form" >
      <div class="row">
        <div class="col-md-5">
          <div class="col-md-12" >
            <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('tipo_documento_id', doc_form, form.submitted)}">
                  <label for="tipo_documento_id" [ngClass]="{ 'star_symbol': _frm.is_required('tipo_documento_id', doc_form) }">Tipo de documento</label>
                  <ng-select  placeholder="Tipo de documento" formControlName="tipo_documento_id" class="form-control"  
                    [items]="tipos_documentos" bindLabel="nombre" bindValue="id"  (change)="change_document($event)" >
                  </ng-select>
                  <div class="invalid-feedback">
                    <div *ngIf="doc_form.controls['tipo_documento_id'].errors?.required">Ingrese el tipo de documento</div>
                  </div>
                </div>

          </div>
          <div class="col-md-12" >
            <div class="form-group" [hidden]="firmar_documento || doc_form.controls['tipo_documento_id'].value == 0"
            [ngClass]="{ 'has-danger': _frm.is_invalid('file', doc_form, form.submitted)}">
              <label [ngClass]="{ 'star_symbol': _frm.is_required('file', doc_form) }">Subir documento</label>
              <input type="file" class="dropify" accept=".png, .jpeg, .pdf, .jpg" data-allowed-file-extensions="png jpeg pdf jpg " data-max-file-size="10000K" (change)="change_file('file', $event.target.files)" >
            
              <div class="invalid-feedback">
                <div *ngIf="doc_form.controls['file'].errors?.required">Ingrese el archivo adjunto</div>
              </div>

              <div class="col-md-12 modal-footer " style="border: none; padding: 0; margin-top: 1rem;">
                <button type="submit" class="btn btn-primary btn-sm " [disabled]="uploading"><i class="mdi mdi-content-save" style="float: none; margin: 0;"></i> Adjuntar documento</button>
              </div>

            </div>  

            <div class="form-group" [hidden]="!firmar_documento  || doc_form.controls['tipo_documento_id'].value == 0">
              <label>Firmar documento</label>
              <div class="col-md-12 modal-footer" style="border: none; padding: 0;  margin-top: 1rem;">
                <button type="button" class="btn btn-primary btn-sm " (click)="create_qr()"  ><i class="mdi mdi-qrcode" style="float: none; margin: 0;"></i> Adjuntar documento para firma</button>
              </div>

              <div class="col-md-12">
                <qr-code *ngIf="qrInfo" [value]="qrInfo" size="280"></qr-code>
              </div>

            </div>
              <div class="col-md-12">
                <qr-code *ngIf="qrInfoHist" [value]="qrInfoHist" size="280"></qr-code>
              </div>
            <div>

            </div>

          </div>
        </div>
       
        <div class="col-md-7">
          <div class="row">
            <div class="col-md-12">
              <button class="btn btn-secondary float-right" (click)="selectDelete()"  ngbtooltip="Eliminar documento" type="button" ng-reflect-ngb-tooltip="Eliminar documento"><i class="mdi mdi-delete"></i></button>
              <button class="btn btn-secondary float-right" (click)="selectPrint()"  ngbtooltip="Ver documento" type="button" ng-reflect-ngb-tooltip="Ver documento"><i class="mdi mdi-eye"></i></button>
              <button class="btn btn-secondary float-right" (click)="selectScan()"  ngbtooltip="Mostrar código QR" type="button" ng-reflect-ngb-tooltip="Mostrar código QR"><i class="mdi mdi-qrcode-scan"></i></button>
            
            </div>

          </div>
          <div class="row">
            <div class="col-md-12">
              <app-grid [autosize]="false" [rowData]="rowData" [columnDefs]="columnDefs" gridHeight="400px" [rowSelection]="'single'"  #grid1></app-grid>
            </div>
          </div>
        </div>
      </div>
      <div class="row m-t-30">
        <div class="col-md-12 modal-footer">
        </div>
      </div>

    </form>
  </div>