 <div class="card-body p-b-0">
  <form class="form-material" [formGroup]="modal_prestamos_form" (ngSubmit)="on_submit()" #form="ngForm"  novalidate>
      <div class="row">
          <div class="col-md-4" [ngClass]="{ 'has-danger': _frm.is_invalid('fecha_devolucion_programada', modal_prestamos_form, form.submitted)}" >
            <div class="form-group" >
              <label for="fecha_prestamo " [ngClass]="{ 'star_symbol': _frm.is_required('fecha_devolucion_programada', modal_prestamos_form) }">Fecha de devolución programada</label>
              <input type="text" formControlName="fecha_devolucion_programada" placeholder="Fecha de devolución" class="form-control" id="fecha_devolucion_programada"  ngbDatepicker #d1="ngbDatepicker" (focus)="d1.toggle()" [(ngModel)] = "fecha_devolucion_programada"  [minDate]="fecha2"  />
              <i class="mdi mdi-calendar"></i>
              <div class="invalid-feedback">
                <div *ngIf="modal_prestamos_form.controls['fecha_devolucion_programada'].errors?.required">La fecha de devolución programada es obligatoria</div>
            </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
             <label for="ddl_persona_recibe">Personal recibe</label>
             <ng-select  id="ddl_persona_recibe" class="form-control"
                  [items]="personal$ | async"
                   bindLabel="person.fullname"
                   bindValue="person.person_id"
                   [loading]="personalLoading"
                   [typeahead]="personalInput$"
                   formControlName="personal_recibe_id"
                   placeholder="Personal recibe"
                   typeToSearchText ="Buscar personal"
                   notFoundText = "No se encontró personal"
                   loadingText = "Buscando">
            </ng-select>
           
            </div>
    </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
              <label for="ddlArea">Area</label>
           <ng-select [searchable]="true" placeholder="Area" class="form-control" id="ddlArea"
           [items]="areas" bindLabel="nombre" bindValue="id" formControlName="area_id" (change)="change_area($event)" >
           </ng-select>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group" *ngIf="bandVisble">
          <label for="ddlCama">Cama</label>
           <ng-select [searchable]="true" placeholder="Cama" class="form-control" id="ddlCama"
           [items]="camas" bindLabel="nombre" bindValue="id" formControlName="cama_id" >
           </ng-select>
          </div>
        </div>
    </div>
      <div class="row m-t-30">
          <div class="col-md-12 modal-footer">
              <button *ngIf="show_cancel_button" type="button" class="btn btn-danger btn-sm" (click) = "on_close()" ><i class="mdi mdi-close"></i> Cancelar</button>
              <button type="submit" class="btn btn-primary btn-sm "><i class="mdi mdi-content-save"></i> Guardar</button>
          </div>
      </div>
  </form>
</div> 

