<app-buttons (button_selected)="option_selector($event)"></app-buttons>

<div class="tab-content m-t-10 m-b-5">
    <div class="tab-pane active" id="paciente" role="tabpanel">
        <form class="form-material" #form="ngForm" novalidate>
            <div class="row">
                <div class="col-md-3">
                    <div class="form-group">
                        <input type="text" [(ngModel)]="fecha_inicio" placeholder="Fecha de inicio" 
                                class="form-control" id="fecha_inicio" ngbDatepicker #d1="ngbDatepicker" 
                                (click)="d1.toggle()" [ngModelOptions]="{standalone: true}" (dateSelect)="change_valores()"
                                 [maxDate]="maxDate" />
                        <i class="mdi mdi-calendar"></i>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <input type="text" [(ngModel)]="fecha_fin" placeholder="Fecha fin" class="form-control" id="fecha_fin" ngbDatepicker #d2="ngbDatepicker" (click)="d2.toggle()" [ngModelOptions]="{standalone: true}" (dateSelect)="change_valores()" [maxDate]="maxDate" />
                        <i class="mdi mdi-calendar"></i>
                    </div>
                </div>

            </div>
        </form>
        <app-grid [rowSelection]="'multiple'" [rowData]="rowData" [columnDefs]="columnDefs" gridHeight="400px" #grid1></app-grid>
    </div>
</div>