<app-buttons  (button_selected)="option_selector($event)"></app-buttons>

<div class="tab-content m-t-10 m-b-5">
  <div class="tab-pane active" id="paciente" role="tabpanel">
    <form class="form-material" #form="ngForm" novalidate>
       <div class="row">
        <div class="col-md-2">
          <div class="form-group">
            <input type="text" placeholder="Paciente / Num. Registro" class="form-control" id="txtPaciente" name ="paciente" [(ngModel)] = "paciente"  (keyup)="change_valores()">
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
              <input type="text" [(ngModel)]="fecha_inicio" placeholder="Fecha de inicio" class="form-control" id="fecha_inicio"  ngbDatepicker #d1="ngbDatepicker" (focus)="d1.toggle()" [ngModelOptions]="{standalone: true}" (dateSelect)="change_valores()"/>
              <i class="mdi mdi-calendar"></i>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
              <input type="text" [(ngModel)]="fecha_fin" placeholder="Fecha fin" class="form-control" id="fecha_fin"  ngbDatepicker #d2="ngbDatepicker" (focus)="d2.toggle()"  [ngModelOptions]="{standalone: true}" (dateSelect)="change_valores()" />
              <i class="mdi mdi-calendar"></i>
          </div>
        </div>
       <div class="col-md-3">
          <div class="form-group">
            <ng-select [searchable]="true" placeholder="Especialidad" class="form-control" id="ddlEspecialidad"
              [items]="especialidades"  name ="especialidad_id" [(ngModel)] = "especialidad_id" bindLabel="nombre" bindValue="id"  (change)="change_valores() ">
            </ng-select>
          </div>
        </div>
      </div> 
    </form>
    <app-grid [rowSelection]="'multiple'" [rowData]="rowData" [columnDefs]="columnDefs" gridHeight="400px" #grid1></app-grid>
  </div>


</div>


 <ng-template #modal let-modal>
  <div class="modal-header">
      <h4 class="modal-title">Devolución de expediente</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body p-0">
      <div class="card m-b-0">
          <ul class="nav nav-tabs profile-tab" role="tablist">
              <li class="nav-item"> <a class="nav-link active" data-toggle="tab" href="#account" role="tab">Prestamo</a></li>
          </ul>
          <div class="tab-content">
              <div class="tab-pane active" id="account" role="tabpanel">
                <app-devoluciones-dialog [prestamo]= "_prestamo" [show_cancel_button]=true (close_modal)="modal.close('Cancelar')" (submit_form)="save_prestamo($event)"></app-devoluciones-dialog >
              </div>
          </div>
      </div>
  </div>
</ng-template>

<!-- Ventana modal para visualizacion de expediente clinico -->
<ng-template #content let-modal>
  <div class="modal-header">
      <h4 class="modal-title" *ngIf="paciente2.paciente_id"><!-- {{paciente.nombre_completo}} ( {{paciente.numero_registro}} )  --></h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body p-0">
      <div class="card m-b-0">
          <app-expediente [paciente]="paciente2"></app-expediente>
      </div>
  </div>
</ng-template>
