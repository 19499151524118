import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AddressService } from 'src/app/services/service.index';
import { state, municipality, settlement, search_address, address } from '../../models/address.model';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styles: []
})
export class AddressComponent implements OnInit {
  address_form: FormGroup;

  @Output() submit_form = new EventEmitter<FormGroup>();
  @Output() close_modal = new EventEmitter();
  @Input()address: address;
  @Input() show_cancel_button: boolean = false;
  country_id: number = 146;
  states: state[];
  municipalities: municipality[];
  settlements: settlement[];
  search_address: search_address;

  constructor(public _adr: AddressService, private _fb: FormBuilder) {
  }

  ngOnInit() {   
    this.bind_catalogs(this.country_id, this.address.state_id, this.address.municipality_id, this.address.settlement);

    this.address_form = this._fb.group({
      address_id : [this.address.address_id],
      address_type_id : [this.address.address_type_id],
      country_id: [this.address.country_id],
      state_id: [this.address.state_id],
      municipality_id: [this.address.municipality_id],
      postal_code: [this.address.postal_code, Validators.required],
      state: [this.address.state],
      municipality: [this.address.municipality],
      settlement: [this.address.settlement],
      street: [this.address.street],
      exterior_number: [this.address.exterior_number],
      interior_number: [this.address.interior_number],
      reference: [this.address.reference],
      latitude: [this.address.latitude],
      longitude: [this.address.longitude]
    });
  }

  bind_catalogs(country_id: number, state_id: number, municipality_id: number, settlement: string) {
    if (country_id) {
      this._adr.get_states(country_id).subscribe((data: any) => { this.states = data });
      if (state_id) {
        this._adr.get_municipality(country_id, state_id).subscribe((data: any) => { this.municipalities = data });
        if (municipality_id) this._adr.get_settlements(country_id, state_id, municipality_id).subscribe((data: any) => { this.settlements = data; });
      }
    } 
  }



  change_postalcode(value: string) {
    this._adr.get_address_from_postalcode(value).subscribe((data: any) => {
      this.search_address = data;
      let state = this.search_address.state ? this.search_address.state.name : null;
      let municipality = this.search_address.municipality ? this.search_address.municipality.name : null;
      let state_id = this.search_address.state ? this.search_address.state.state_id : null;
      let municipality_id = this.search_address.municipality ? this.search_address.municipality.municipality_id : null;
      let settlement = this.search_address.settlement ? this.search_address.settlement : null;

      if (state_id) {
        this.address_form.patchValue({ "state_id": state_id, "state": state });

        this._adr.get_municipality(this.country_id, state_id).subscribe((data: any) => {
          this.municipalities = data;
          this.address_form.patchValue({ "municipality_id": municipality_id,"municipality": municipality });

          this.settlements = settlement;
          if (settlement.length > 1) {
            this.address_form.patchValue({ "settlement": null });
          }
          else {
            this.address_form.patchValue({ "settlement": settlement[0].name });
          }
        });
      }
      else {
        this.municipalities = [];
        this.settlements = [];
        this.address_form.patchValue({ "state_id": null, "municipality_id": null, "state": null, "municipality": null, "settlement": null });
      }

    });
  }
  change_state($event) {
    this.municipalities = [];
    this.settlements = [];
    this.address_form.patchValue({ "postal_code": null, "municipality_id": null, "settlement": null });
    this.address_form.patchValue({ "state": $event.state});
    this._adr.get_municipality(this.country_id, $event.state_id).subscribe((data: any) => { this.municipalities = data });
  }
  change_municipality($event) {
    this.settlements = [];
    this.address_form.patchValue({ "postal_code": null, "settlement": null });
    this.address_form.patchValue({ "municipality": $event.municipality});
    this._adr.get_settlements(this.country_id, $event.state_id, $event.municipality_id).subscribe((data: any) => { this.settlements = data; });
  }
  change_settlement($event) {
    this.address_form.patchValue({ "postal_code": $event.code });
  }


  onSubmit() {
    this.submit_form.emit(this.address_form);
  }
  
  on_close() {
    this.close_modal.emit();
  }



}
