<app-buttons  (button_selected)="option_selector($event)"></app-buttons>
<app-grid [rowData]="rowData" [columnDefs]="columnDefs" [rowSelection]="'single'"></app-grid>
 


<!-- Ventana modal para creación y actualización de especialidades -->
<ng-template #modal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" *ngIf="!_row_selected.servicio_id">Nuevo servicio</h4>
        <h4 class="modal-title" *ngIf="_row_selected.servicio_id">Actualizar servicio</h4> 
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body p-0">
        <div class="card m-b-0">
            <ul class="nav nav-tabs profile-tab" role="tablist">
                <li class="nav-item"> <a class="nav-link active" data-toggle="tab" href="#account" role="tab">Servicio</a></li>
            </ul>
            <div class="tab-content">
                <div class="tab-pane active" id="account" role="tabpanel">
                  <app-servicios-dialog [servicio]="_row_selected" (submit_form) = "save_servicio($event)"  ></app-servicios-dialog>
                </div>
            </div>
        </div>
    </div>
</ng-template>
