import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators  } from '@angular/forms';
import { FormsService, CatalogService, UserService } from 'src/app/services/service.index';
import { prestamo } from 'src/app/models/paciente.model';
import { Observable, Subject, concat, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap, catchError, map } from 'rxjs/operators';
import { medico } from 'src/app/models/medico.model';
import { user } from 'src/app/models/authorization.model';

@Component({
  selector: 'app-prestamos-dialog',
  templateUrl: './prestamos-dialog.component.html',
  styles: []
})
export class PrestamosDialogComponent implements OnInit {
  @ViewChild("form", { static: true }) form;
  @Output() submit_form = new EventEmitter<FormGroup>();
  @Output() close_modal = new EventEmitter();
  @Input()  show_cancel_button: boolean = false;
  @Input()  prestamo: prestamo= new prestamo({});
  @Input()  user: user;
  options: any = [{label:'Si', value:true}, {label:'No', value:false}];
  modal_prestamos_form: FormGroup;
  areas:any;
  camas:any;
  rowData: any = [];
  personal$: Observable<Object>;
  personalInput$ = new Subject<string>();
  personalLoading = false;
  bandVisble:boolean =  false;
  public fecha_devolucion_programada:Date;
  myDate:Date
  public fecha2:any;

  constructor(private _fb: FormBuilder, public _frm:FormsService,public _cat2: CatalogService,public usersCat: UserService) { }


  ngOnInit() {
    this.myDate=new Date()
    this.fecha2 ={year: this.myDate.getFullYear(), month:this.myDate.getMonth() +1, day: this.myDate.getDate()}
    console.log(this.fecha2)
    this.modal_prestamos_form = this._fb.group({
      paciente_id: [this.prestamo.paciente_id],
      fecha_prestamo: [this.prestamo.fecha_prestamo],
      fecha_devolucion_real: [this.prestamo.fecha_devolucion_real],
      fecha_devolucion_programada: [this.prestamo.fecha_devolucion_programada,[Validators.required]],
      observaciones: [this.prestamo.observaciones],
      personal_entrega_id: [this.prestamo.personal_entrega_id,[Validators.required]],
      personal_recibe_id: [this.prestamo.personal_recibe_id],
      personal_devuelve_id: [this.prestamo.personal_devuelve_id],
      area_id: [this.prestamo.area_id,[Validators.required]],
      cama_id: [this.prestamo.cama_id]
    });

      this._cat2.get_catalog("cama", true).subscribe(data => this.camas = data, error => console.log(error)); 
      this._cat2.get_catalog("area", true).subscribe(data => this.areas = data, error => console.log(error)); 
      this.loadPersonal();
  }
  on_submit() {
    this.submit_form.emit(this.modal_prestamos_form);
  }
  on_close() {
    this.close_modal.emit();
  }
  change_area($event) {
    if($event.id==2){
      this.bandVisble = true;
    }else{
      this.bandVisble = false;
    }
  }

  private loadPersonal() {
    this.personal$ = concat(
      of(
        this.prestamo.personal_recibe_id?
          [{ nombre_completo: this.prestamo.personal_recibe, person_id: this.prestamo.personal_recibe_id }] : []
        ),
      this.personalInput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => this.personalLoading = true),
        switchMap(term => this.usersCat.get_personal_catalog(term).pipe(
          catchError(() => of([])),
          tap(() => this.personalLoading = false),
        ))
      )
    )
  }


}
