
export class servicio {
    servicio_id?: number = null;
    precio?: number = null;
    medicion: boolean = false;
    nombre: string = "";
    imagen: string = "";
    descripcion: string = "";

    constructor(data: servicio | {} = {}) {
        Object.assign(this, data);
    }

}


export interface Iservicio {
    servicio_id: number;
    precio: number;
    medicion: boolean;
    nombre: string;
    imagen: string;
    descripcion: number;
  }

  