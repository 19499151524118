import { Component, OnInit } from "@angular/core";
import { Router, ActivationEnd } from "@angular/router";
import { filter, map } from "rxjs/operators";
import { Title } from "@angular/platform-browser";
import { AuthorizationService } from "src/app/services/service.index";

declare var sw: any;

@Component({
  selector: "app-breadcrumbs",
  templateUrl: "./breadcrumbs.component.html",
  styles: []
})
export class BreadcrumbsComponent implements OnInit {
  title: string;
  subtitle: string;

  first_node: string;
  first_node_route: string;
  second_node: string;
  second_node_route: string;
  third_node: string;
  third_node_route: string;

  constructor(
    private _router: Router,
    private _auth: AuthorizationService,
    private _title: Title
  ) {
    this._router.events
      .pipe(
        filter(event => event instanceof ActivationEnd),
        filter((event: ActivationEnd) => event.snapshot.firstChild === null)
      )
      .subscribe(event => {
        this.get_route_node("/" + event.snapshot.url[0].path);

        if  (this.first_node == undefined && this.first_node == undefined && this.first_node == undefined) {
          this.title = event.snapshot.data.title;
          this._title.setTitle(this.title);
        }

        sw.common.init_plugins();

      });
  }

  get_route_node(route: string) {
    this.first_node = undefined;
    this.first_node_route = undefined;
    this.second_node = undefined;
    this.second_node_route = undefined;
    this.third_node = undefined;
    this.third_node_route = undefined;
    this.title = undefined;
    this.subtitle = undefined;

    try {
      this._auth.modules.forEach((first, first_index) => {
        if (first.route === route) {
          this.first_node = first.title;
          this.first_node_route = undefined;
          this.title = first.title;
          this.subtitle = first.subtitle;
          this._title.setTitle(this.title);
          throw "Encontrado en primero";
        }

        first.children.forEach((second, second_index) => {
          if (second.route === route) {
            this.first_node = first.title;
            this.first_node_route = first.route;
            this.second_node = second.title;
            this.second_node_route = undefined;
            this.title = second.title;
            this.subtitle = second.subtitle;
            this._title.setTitle(this.title);
            throw "Encontrado en segundo";
          }
          second.children.forEach((third, third_index) => {
            if (third.route === route) {
              this.first_node = first.title;
              this.first_node_route = first.route;
              this.second_node = second.title;
              this.second_node_route = second.route;
              this.third_node = third.title;
              this.third_node_route = undefined;
              this.title = third.title;
              this.subtitle = third.subtitle;
              this._title.setTitle(this.title);
              throw "Encontrado en tercero";
            }
          });
        });
      });
    } catch (e) {
      //console.log(e);
    }







  }

  ngOnInit() {}
}
