import { address } from "./address.model";
import { medico } from "./medico.model";

export interface login_request {
    username: string,
    password: string,
    rememberme: boolean
}

export class user {
    user_id?: number = null;
    username: string = "";
    password_hash: string = "";
    password_salt: string = "";
    role_id?: number = null;
    role: string = "";
    email: string = "";
    email_confirmed: boolean;
    access_fail_count?: number = null;
    is_external_login_provider: boolean;
    is_system_user: boolean;
    user_preferences: string = "";
    valid_from?: Date = null;
    valid_to?: Date = null;
    is_active: boolean;
    last_login?: Date = null;
    saved_by?: number = null;
    token: string = "";
    person: person = new person({});
    medico: medico = new medico({});
    modules: moduleX[] = [new moduleX({})]
    medico_id?: number = null;
    constructor(data: user | {} = {}) {
        Object.assign(this, data);
    }

}

export class role {
    role_id?: number = null;
    name: string = "";
    description: string = "";
    display_text: string = "";
    is_active: boolean;

    constructor(data: role | {} = {}) {
        Object.assign(this, data);
    }

}
export class permission {
    permission_id?: number = null;
    name: string = "";
    display_text: string = "";
    description: string = "";
    css_class: string = "";
    module_id?: number = null;
    click_event: string = "";
    grouping?: boolean = null;
    show_button?: boolean = null;
    can_grant?: boolean = null;
    parent_id?: number = null;
    is_active: boolean;
    
    constructor(data: permission | {} = {}) {
        Object.assign(this, data);
    }

}


export class moduleX {
    module_id: number;
    name: string = "";
    title: string = "";
    subtitle: string = "";
    route: string = "";
    parameters: string = "";
    css_class: string = "";
    description: string = "";
    is_active: boolean;
    is_visible: boolean;
    display_order: number;
    parent_module_id: number;
    parent_module:string;
    path:string;
    permissions: any[];
    children: moduleX[]

    constructor(data: moduleX | {} = {}) {
        Object.assign(this, data);
    }
}

export class module_permission
{
    module_id: number;
    module_name: string = "";
    permission_id: number;
    permission_name: string = "";
    description: string = "";
    is_active: boolean;

    constructor(data: module_permission | {} = {}) {
        Object.assign(this, data);
    }

}


export class module_authorize
{
    role_id: number;
    user_id: number;
    module_id : number;
    name: string = "";
    title: string = "";
    path: string = "";
    is_active: boolean;
    saved_by: number;
    permissions: permission_authorize[];

    constructor(data: module_authorize | {} = {}) {
        Object.assign(this, data);
    }
}

export class permission_authorize
{
    role_id: number;
    user_id: number;
    module_id : number;
    permission_id : number;
    display_text : boolean;
    can_grant : boolean;
    is_active : boolean;
    saved_by: number;

    constructor(data: permission_authorize | {} = {}) {
        Object.assign(this, data);
    }

}





export class person {
    person_id: number = null;
    name: string = "";
    last_name: string = "";
    middle_name: string = "";
    fullname: string = "";
    address_id: number = null;
    birthdate: Date = undefined;
    title: string = "";
    email: string = "";
    home_phone: string = "";
    office_phone: string = "";
    mobile_phone: string = ""
    rfc: string = "";
    curp: string = "";
    gender_id: number = null;
    marital_status_id: number = null;
    photo: string = "http://cmdpdh.org/wp-content/uploads/2015/01/fondo-transparente.png";//"https://cobranzademo4.blob.core.windows.net/test/no-image.png";
    file: File;
    saved_by: number = null;
    address: address = new address({});

    constructor(data: person | {} = {}) {
        Object.assign(this, data);
    }
}




