import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { referencia,paciente } from '../../models/paciente.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormsService, PacienteService, CatalogService, AddressService } from 'src/app/services/service.index';
import { GridComponent } from 'src/app/shared/components/grid.component';
import { DatePipe } from '@angular/common'
import { formatDate } from '@angular/common';
import { Inject, LOCALE_ID } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import "ag-grid-enterprise";
import { HttpClient } from "@angular/common/http";
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-area-recetas',
  templateUrl: './area-recetas.component.html',
  styles: []
})
export class AreaRecetasComponent implements OnInit {

  public gridApi;
  public gridColumnApi;
  public columnDefs;
  public detailCellRendererParams;
  public rowData:any= [];

  @Input() paciente: paciente = new paciente({});
  @ViewChild("grid1") grid1: GridComponent;

 

  constructor(private http: HttpClient,@Inject(LOCALE_ID) private locale: string,public datepipe: DatePipe,private _toastr: ToastrService,private _fb: FormBuilder, public _frm: FormsService, public _paciente: PacienteService, public _cat: CatalogService, public _adr: AddressService) {
/*     this.columnDefs = [
      { headerName: "Fecha de consulta", field: "fecha_inicio_programada",cellRenderer: (data) => {
        return  formatDate(data.value, 'dd MMM yyyy', this.locale)
        }},
      { headerName: "Médico", field: "medico" },
      { headerName: "Especialidad", field: "especialidad" },
  
    ];
    
    this.detailCellRendererParams = {
      detailGridOptions: {
        columnDefs: [
          { field: "clave_id" },
          { field: "clave" },
          { field: "nombre_generico" }, 
          { field: "descripcion" },       
          { field: "cantidad" }, 
          { field: "presentacion" }, 
          { field: "via_administracion" }, 
          { field: "unidad_medica" }, 
          { field: "dosis" }, 
          { field: "frecuencia" }, 
          { field: "fecha_inicio" }, 
          { field: "fecha_fin" }, 
          { field: "indicaciones_adicionales" }, 
        ],
        onFirstDataRendered(params) {
          params.api.sizeColumnsToFit();
        }
      },
      getDetailRowData: function(params) {
        params.successCallback(params.data.medicamentos);
      }
    };  
   }
   onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    this.http
      .get(
        "https://localhost:44395/api/Paciente/recetas_get?paciente_id=5264"
      )
      .subscribe(data => {
        console.log(data)
        this.rowData = data;
      });

    setTimeout(function() {
      var rowCount = 0;

      params.api.forEachNode(function(node) {
        node.setExpanded(rowCount++ === 1);
      });
    }, 500);
  } */


    this.columnDefs = [
      {headerName: "Médico",
        field: "medico",
        cellRenderer: "agGroupCellRenderer"
      },
      { headerName: "Fecha de consulta", field: "fecha_inicio_programada",cellRenderer: (data) => {
        return  formatDate(data.value, 'dd MMM yyyy', this.locale)
        }},
        { headerName: "Especialidad", field: "especialidad" }    
    ];
    this.detailCellRendererParams = {
      detailGridOptions: {
        columnDefs: [
          { headerName: "Clave",field: "clave" },
          { headerName: "Nombre",field: "nombre_generico" }, 
          { headerName: "Descripción",field: "descripcion" },       
          { headerName: "Cantidad",field: "cantidad" }, 
          { headerName: "Presentacion",field: "presentacion" }, 
          { headerName: "Via de administración",field: "via_administracion" }, 
          { headerName: "Unidad médica",field: "unidad_medica" }, 
          { headerName: "Dosis",field: "dosis" }, 
          { headerName: "Frecuencia",field: "frecuencia" }, 
          { headerName: "Fecha de inciio",field: "fecha_inicio",cellRenderer: (data) => {
            return  formatDate(data.value, 'dd MMM yyyy', this.locale)
            } }, 
          { headerName: "Fecha fin",field: "fecha_fin",cellRenderer: (data) => {
            return  formatDate(data.value, 'dd MMM yyyy', this.locale)
            } }, 
          { headerName: "Otras indicaciones",field: "indicaciones_adicionales" }
        ],
        onFirstDataRendered(params) {
       
        }
      },
      getDetailRowData: function(params) {
        console.log(params.data)
        params.successCallback(params.data.medicamentos);
      }
    };
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    this.http
      .get(
        environment.apiEndpoint + "Paciente/recetas_get?paciente_id=" + this.paciente.paciente_id
      )
      .subscribe(data => {
        this.rowData = data;
      });

    setTimeout(function() {
      var rowCount = 0;
      params.api.forEachNode(function(node) {
        node.setExpanded(rowCount++ === 1);
      });
    }, 500);
  }



  ngOnInit() {
    
  }

}
