import { ConsultaService } from 'src/app/services/service.index';
import { consulta } from './../../models/consulta.model';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-busca-paciente',
  templateUrl: './busca-paciente.component.html',
  styles: []
})
export class BuscaPacienteComponent implements OnInit {

  consultas: consulta[];
  rowData: any = null;
  columnDefs = [
    { headerName: "Expediente", field: "numero_registro" },
    { headerName: "Paciente", field: "paciente" },
    {
      headerName: "Fecha", field: "fecha", cellRenderer: (data) => {
        return data.value ? moment(new Date(data.value)).format('MM/DD/YYYY h:mm') : '';
      }
    },
    { headerName: "Médico", field: "medico" },
    { headerName: "Especialidad", field: "especialidad" },
    { headerName: "Estatus", field: "estatus" }
  ];

  constructor(public _consulta:ConsultaService) {
    this.rowData = [];
   }

  ngOnInit() {
  }

  onFilterTextBoxChanged(search_word) {
    if (search_word.length > 2 || search_word.length == 0) {
      this._consulta.get_agenda_search(search_word || '').subscribe(
        data => { console.log(data); this.rowData = data; },
        err => console.log(err)
      );
    }
  }
}
