import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { GridComponent } from 'src/app/shared/components/grid.component';
import { UserService, FormsService } from 'src/app/services/service.index';
import { moduleX, module_authorize, permission_authorize, user } from '../../models/authorization.model';

@Component({
  selector: 'app-authorization-dialog',
  templateUrl: './authorization-dialog.component.html',
  styles: []
})
export class AuthorizationDialogComponent implements OnInit {
  @Input() role_id: number = 0;
  @Input() user_id: number = 0;
  @ViewChild("gridModules", { static: true }) gridModules: GridComponent;
  @ViewChild("gridPermissions", { static: true }) gridPermissions: GridComponent;

  rowData: any;
  columnDefs = [
    { headerName: "Título", field: "title" }
  ];
  autoGroupColumnDef = {
    headerName: "Módulo",
    cellRendererParams: {
      suppressCount: true,
      checkbox: true
    }
  };
  rowData2: any;
  columnDefs2 = [
    { headerName: "Permiso", field: "display_text", checkboxSelection: true }
  ];

  selected_module: module_authorize = new module_authorize({});
  selected_permission: permission_authorize = new permission_authorize({});
  pre_selected = true;

  constructor(public _usrs: UserService, public _frm: FormsService) { }

  ngOnInit() {
    this.get_data();
  }
  

  get_data() {
    this._usrs.get_module_authorize(this.role_id, this.user_id).subscribe(
      data => { this.rowData = data; this.rowData2 = null },
      err => console.log(err)
    );
  }
  pre_select_nodes(event) {
    this.gridModules.gridApi.forEachNode(function (node) {
      if (node.data.is_active === true) node.setSelected(true);
    });
  }
  change_selection(event) {
    if (event.node.selected) {
      this.selected_module = event.node.data;
      this.selected_module.permissions[0].is_active = true;
      this.load_permission(this.selected_module);
    }
    else {
      this.selected_module = event.node.data;
      this.selected_module.permissions.forEach(element => {
        element.is_active = false;
      });
      this.load_permission(this.selected_module);
    }
  }

  module_clicked(event) {
    this.pre_selected = false;
    this.load_permission(event.node.data);
  }

  load_permission(module: module_authorize) {
    this.selected_module = module;
    this.rowData2 = this.selected_module.permissions;
    setTimeout(() => {
      this.gridPermissions.gridApi.forEachNode(function (node) {
        if (node.data.is_active === true) node.setSelected(true);
        else node.setSelected(false);
      });
      setTimeout(() => { this.pre_selected = true; }, 100);
    }, 100);
  }

  permission_selected(event) {
    if (this.pre_selected) {
      this.selected_permission = event.node.data;
      this.selected_permission.role_id = this.role_id;
      this.selected_permission.user_id = this.user_id;
      if (event.node.selected) {

        if (this.role_id > 0) {
          this._usrs.insert_module_role_authorize(event.node.data).subscribe();
        }
        if (this.user_id > 0) {
          this._usrs.insert_module_user_authorize(event.node.data).subscribe();
        }

      }
      else {
        if (this.role_id > 0) {
          this._usrs.delete_module_role_authorize(event.node.data).subscribe();
        }
        if (this.user_id > 0) {
          this._usrs.delete_module_user_authorize(event.node.data).subscribe();
        }
      }
    }
  }


}
