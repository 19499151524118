<div class="card-body p-b-0">
    <form class="form-material" [formGroup]="componentForm" (ngSubmit)="on_submit()" #form="ngForm" novalidate>
        <div class="row">
            <div class="col-md-4">
                <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('estudio_cod', componentForm, form.submitted)}">
                    <label for="txt_name" [ngClass]="{ 'star_symbol': _frm.is_required('estudio_cod', componentForm) }">Código</label>
                    <input type="text" placeholder="Estudio" class="form-control" id="txt_name" formControlName="estudio_cod">
                    <div class="invalid-feedback">
                        <div *ngIf="componentForm.controls['estudio_cod'].errors?.required">El código es obligatorio</div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('estudio', componentForm, form.submitted)}">
                    <label for="txt_name" [ngClass]="{ 'star_symbol': _frm.is_required('estudio', componentForm) }">Descripción oficial</label>
                    <input type="text" placeholder="Estudio" class="form-control" id="txt_name" formControlName="estudio">
                    <div class="invalid-feedback">
                        <div *ngIf="componentForm.controls['estudio'].errors?.required">La descripción es obligatoria</div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('nombre_compuesto', componentForm, form.submitted)}">
                    <label for="txt_name" [ngClass]="{ 'star_symbol': _frm.is_required('nombre_compuesto', componentForm) }">Descripción personalizada</label>
                    <input type="text" placeholder="Descripción" class="form-control" id="txt_name" formControlName="nombre_compuesto">
                    <div class="invalid-feedback">
                        <div *ngIf="componentForm.controls['nombre_compuesto'].errors?.required">La descripción es obligatoria</div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('grupo_id', componentForm, form.submitted)}">
                    <label for="txt_grupo_id" [ngClass]="{ 'star_symbol': _frm.is_required('grupo_id', componentForm) }">Grupo</label>
                    <!-- <select formControlName="grupo_id" class="form-control" id="txt_grupo">
                    <option *ngFor="let option of catGruposEstudio" [ngValue]=option.id>
                        {{ option.nombre }}
                    </option>
                    </select> -->
                    <ng-select id="ddl-grupo" [searchable]="true" placeholder="Grupo" formControlName="grupo_id" class="form-control" [items]="catGruposEstudio" bindLabel="nombre" bindValue="id">
                    </ng-select>
                    <div class="invalid-feedback">
                        <div *ngIf="componentForm.controls['grupo_id'].errors?.required">El campo es obligatorio</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row m-t-30">
            <div class="col-md-12 modal-footer">
                <button *ngIf="show_cancel_button" type="button" class="btn btn-danger btn-sm" (click)="on_close()"><i class="mdi mdi-close"></i> Cancelar</button>
                <button *ngIf="show_close_button" type="button" class="btn btn-danger btn-sm" (click)="on_close()"><i class="mdi mdi-close"></i> Cerrar</button>
                <button *ngIf="show_save_button" type="submit" class="btn btn-primary btn-sm "><i class="mdi mdi-content-save"></i> Guardar</button>
            </div>
        </div>
    </form>
</div>