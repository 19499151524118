<div class="card-body p-b-0">
    <form class="form-material" [formGroup]="consulta_form" #form="ngForm" (ngSubmit)="on_submit()" novalidate>

        <div class="row">
            <div class="col-md-12">
                <div *ngIf="!exploracionFisicaRealizada" class="alert alert-warning" role="alert">No existe información de la exploración física del paciente.<br>Antes de iniciar la consulta es importante realizar la exploración física</div>
                <ngb-accordion [closeOthers]="true" activeIds="panel-general" (panelChange)="panel_change($event)">
                    <ngb-panel id="panel-general">
                        <ng-template ngbPanelTitle><i class="mdi mdi-medical-bag"></i> <span class="m-l-10">Motivo y nota médica</span></ng-template>
                        <ng-template ngbPanelContent>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="txt_fecha">Motivo</label>
                                        <textarea formControlName="motivo" placeholder="Motivo de la consulta" class="form-control m-t-20" id="txt_motivo"></textarea>
                                        <div class="invalid-feedback">
                                            <div>Ingresa el motivo de la consulta</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="txt_fecha">Nota Médica</label>
                                        <textarea formControlName="nota_medica" placeholder="Nota médica" class="form-control m-t-20" id="txt_nota"></textarea>
                                        <div class="invalid-feedback">
                                            <div>Ingresa la nota médica</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>
                    <!--
                    <ngb-panel id="panel-causes">
                        <ng-template ngbPanelTitle><i class="mdi mdi-plus-box"></i> <span class="m-l-10">CAUSES del Seguro Popular</span></ng-template>
                        <ng-template ngbPanelContent>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="ddl_causes">CAUSES</label>
                                        <ng-select id="ddl_causes" class="form-control " [items]="causes$ | async" bindLabel="nombre" bindValue="causes_id" [loading]="causes_loading" [typeahead]="causes_input$" formControlName="causes_id" placeholder="CAUSES" typeToSearchText="Buscar CAUSES"
                                            notFoundText="No se encontraron CAUSES" loadingText="Buscando..." (change)="change_causes($event)">
                                        </ng-select>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>
                -->
                    <ngb-panel id="panel-diagnostico">
                        <ng-template ngbPanelTitle><i class="mdi mdi-file-document"></i> <span class="m-l-10">Diagnósticos</span>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="ddl_diagnostico">Diagnóstico</label>
                                        <ng-select id="ddl_diagnostico" class="form-control" [items]="diagnostico$ | async" bindLabel="descripcion" bindValue="diagnostico_id" [loading]="diagnostico_loading" [typeahead]="diagnostico_input$" placeholder="Diagnósticos" typeToSearchText="Buscar diagnósticos"
                                            notFoundText="No se encontraron diagnósticos" loadingText="Buscando..." formControlName="diagnostico_id" (change)="change_diagnostico($event)">
                                            <ng-template ng-option-tmp let-item="item">
                                                <strong> {{item.clase}} </strong> {{item.descripcion}}
                                            </ng-template>
                                        </ng-select>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="txt_diagnostico_observacion">Observaciones</label>
                                        <textarea placeholder="Observaciones" formControlName="diagnostico_observaciones" class="form-control m-t-20" id="txt_diagnostico_observacion" style="height: 40px !important"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 text-right">
                                    <div class="btn-group btn-group-justified btn-group-sm" style="margin:0 0 10px 0; display: inline-block;">
                                        <button type="button" class="btn btn-secondary" ngbTooltip="Agregar diagnostico" (click)="add_diagnostico()"><i class="mdi mdi-plus-box" ></i></button>
                                        <button type="button" class="btn btn-secondary" ngbTooltip="Eliminar diagnostico" (click)="remove_diagnostico()"><i class="mdi mdi-delete"></i></button>
                                    </div>
                                </div>
                            </div>
                            <app-grid #grid_diagnostico [rowSelection]="'single'" [rowData]="consulta.diagnosticos" [columnDefs]="diagnostico_columndefs" [autosize]="false" [gridHeight]="'200px'"></app-grid>

                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="panel-medicamentos">
                        <ng-template ngbPanelTitle><i class="mdi mdi-pill"></i> <span class="m-l-10">Medicamentos</span>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="ddl_medicamentos">Medicamento</label>
                                        <ng-select id="ddl_medicamentos" class="form-control" [items]="medicamento$ | async" bindLabel="nombre_compuesto" bindValue="medicamento_id" [loading]="medicamento_loading" [typeahead]="medicamento_input$" placeholder="Medicamentos" typeToSearchText="Buscar medicamentos"
                                            notFoundText="No se encontraron medicamentos" loadingText="Buscando..." formControlName="medicamento_id" (change)="change_medicamento($event)">
                                            <ng-template ng-option-tmp let-item="item">
                                                <div class="row">
                                                    <div class="col-md-12"><strong>{{item.nombre_compuesto}}</strong></div>

                                                </div>
                                            </ng-template>
                                        </ng-select>
                                    </div>
                                </div>

                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label for="txt_medicamento_dosis">Dosis</label>
                                        <input type="text" formControlName="medicamento_dosis" placeholder="Dosis" class="form-control" id="txt_medicamento_dosis" />
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="txt_medicamento_frecuencia">Frecuencia</label>
                                        <input type="text" formControlName="medicamento_frecuencia" placeholder="Frecuencia" class="form-control" id="txt_medicamento_frecuencia" />
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label for="txt_medicamento_inicio">Desde el</label>
                                        <input type="text" formControlName="medicamento_inicio" placeholder="Desde" class="form-control" id="txt_medicamento_inicio" ngbDatepicker #d1="ngbDatepicker" (focus)="d1.toggle()" readonly="readonly" [minDate]="minDate" />
                                        <i class="mdi mdi-calendar"></i>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label for="txt_medicamento_fin">Hasta el</label>
                                        <input type="text" formControlName="medicamento_fin" placeholder="Hasta" class="form-control" id="txt_medicamento_fin" ngbDatepicker #d2="ngbDatepicker" (focus)="d2.toggle()" readonly="readonly" [minDate]="minDate2" />
                                        <i class="mdi mdi-calendar"></i>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="txt_medicamento_fin">Vía</label>
                                        <ng-select id="ddl_tipo" [searchable]="true" placeholder="Vía de administración" formControlName="medicamento_via" class="form-control" [items]="medicamento_via" bindLabel="nombre" bindValue="via_administracion_id" (change)="change_medicamento_via($event)">
                                        </ng-select>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="txt_medicamento_observaciones">Observaciones</label>
                                        <textarea placeholder="Observaciones" formControlName="medicamento_observaciones" class="form-control m-t-20" id="txt_medicamento_observaciones" style="height: 40px !important"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 text-right">
                                    <div class="btn-group btn-group-justified btn-group-sm" style="margin:0 0 10px 0; display: inline-block;">
                                        <button *ngIf="consulta.estatus_id == 1" type="button" class="btn btn-secondary" ngbTooltip="Agregar diagnostico" (click)="add_medicamento()"><i class="mdi mdi-plus-box"></i></button>
                                        <button *ngIf="consulta.estatus_id == 1" type="button" class="btn btn-secondary" ngbTooltip="Eliminar diagnostico" (click)="remove_medicamento()"><i class="mdi mdi-delete"></i></button>
                                    </div>
                                </div>
                            </div>
                            <app-grid #grid_medicamento [rowSelection]="'single'" [rowData]="consulta.medicamentos" [columnDefs]="medicamento_columndefs" [autosize]="false" [gridHeight]="'200px'"></app-grid>
                            <button type="button" class="btn btn-light btn-sm" (click)="printPrescription()" *ngIf="showPrintButton"><i class="mdi mdi-printer"></i> Imprimir</button>
                        </ng-template>
                    </ngb-panel>
                    <!-- <ngb-panel id="panel-procedimientos">
                        <ng-template ngbPanelTitle><i class="mdi mdi-stethoscope"></i> <span class="m-l-10">Procedimientos</span>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="ddl_procedimientos">Procedimientos</label>
                                        <ng-select id="ddl_procedimientos" class="form-control" [items]="procedimiento$ | async" bindLabel="nombre" bindValue="procedimiento_id" [loading]="procedimiento_loading" [typeahead]="procedimiento_input$" placeholder="Procedimientos" typeToSearchText="Buscar procedimientos"
                                            notFoundText="No se encontraron procedimientos" loadingText="Buscando..." formControlName="procedimiento_id" (change)="change_procedimiento($event)">
                                        </ng-select>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="txt_procedimiento_observacion">Observaciones</label>
                                        <textarea placeholder="Observaciones" formControlName="procedimiento_observaciones" class="form-control m-t-20" id="txt_procedimiento_observacion" style="height: 40px !important"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 text-right">
                                    <div class="btn-group btn-group-justified btn-group-sm" style="margin:0 0 10px 0; display: inline-block;">
                                        <button type="button" class="btn btn-secondary" ngbTooltip="Agregar procedimiento" (click)="add_procedimiento()"><i class="mdi mdi-plus-box"></i></button>
                                        <button type="button" class="btn btn-secondary" ngbTooltip="Eliminar procedimiento" (click)="remove_procedimiento()"><i class="mdi mdi-delete"></i></button>
                                    </div>
                                </div>
                            </div>
                            <app-grid #grid_procedimiento [rowSelection]="'single'" [rowData]="consulta.procedimientos" [columnDefs]="procedimiento_columndefs" [autosize]="false" [gridHeight]="'200px'"></app-grid>

                        </ng-template>
                    </ngb-panel> -->
                    <ngb-panel id="panel-estudios">
                        <ng-template ngbPanelTitle><i class="mdi mdi-flask"></i> <span class="m-l-10">Estudios</span></ng-template>
                        <ng-template ngbPanelContent>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="ddl_estudios">Estudio</label>
                                        <ng-select id="ddl_estudios" class="form-control" [items]="estudio$ | async" bindLabel="estudio" bindValue="estudio_id" [loading]="estudio_loading" [typeahead]="estudio_input$" placeholder="Estudios" typeToSearchText="Buscar estudios" notFoundText="No se encontraron estudios"
                                            loadingText="Buscando..." formControlName="estudio_id" (change)="change_estudio($event)">
                                        </ng-select>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="txt_estudio_observaciones">Observaciones</label>
                                        <textarea placeholder="Observaciones" formControlName="estudio_observaciones" class="form-control m-t-20" id="txt_estudio_observaciones" style="height: 40px !important"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 text-right">
                                    <div class="btn-group btn-group-justified btn-group-sm" style="margin:0 0 10px 0; display: inline-block;">
                                        <button type="button" class="btn btn-secondary" ngbTooltip="Agregar diagnostico" (click)="add_estudio()"><i class="mdi mdi-plus-box"></i></button>
                                        <button type="button" class="btn btn-secondary" ngbTooltip="Eliminar diagnostico" (click)="remove_estudio()"><i class="mdi mdi-delete"></i></button>
                                    </div>
                                </div>
                            </div>
                            <app-grid #grid_estudio [rowSelection]="'single'" [rowData]="consulta.estudios" [columnDefs]="estudio_columndefs" [autosize]="false" [gridHeight]="'200px'"></app-grid>
                            <button type="button" class="btn btn-light btn-sm" (click)="printEstudios()" *ngIf="showPrintButtonE"><i class="mdi mdi-printer"></i> Imprimir</button>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </div>
        </div>

        <div class="row m-t-30">
            <div class="col-md-12 modal-footer">
                <button type="button" class="btn btn-danger btn-sm" (click)="on_close()"><i class="mdi mdi-close"></i> Cancelar</button>
                <button type="submit" class="btn btn-primary btn-sm " *ngIf="consulta.estatus_id == 1"><i class="mdi mdi-content-save"></i> Guardar</button>
            </div>
        </div>

    </form>
</div>