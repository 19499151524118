<div class="row">
    <div class="col-md-6">
      <div class="card">
        <div class="card-body">
          <h6 class="card-title"><strong>Usuarios disponibles</strong></h6>
          <app-grid #gridAviable [rowData]="rowData1" [columnDefs]="columnDefs" [show_filter]="true" [rowSelection]="'single'" (on_row_clicked)="addUserToShareSchedule()"></app-grid>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card">
        <div class="card-body">
          <h6 class="card-title"><strong>Usuarios compartidos</strong></h6>
          <app-grid #gridShared [rowData]="rowData2" [columnDefs]="columnDefs2" [show_filter]="true" [rowSelection]="'single'" (on_row_clicked)="deleteUserToShareSchedule()"></app-grid>
        </div>
      </div>
    </div>
  </div>