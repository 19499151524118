export interface IMedicamento {
    medicamento_id: number;
    clave: string;
    nombre_generico: string;
    descripcion: string;
    cantidad: string;
    presentacion: string;
    grupo_id: number;
    accion?: any;
    controlado: boolean;
    nombre_compuesto: string;
    tabla:string;
    claveid:string;
    vias: Via[];
    txtVias: string;
    basico: boolean;
    
}

export class Medicamento implements IMedicamento{
    medicamento_id: number;
    clave: string;
    nombre_generico: string;
    descripcion: string;
    cantidad: string;
    presentacion: string;
    grupo_id: number = 1;
    accion?: any;
    controlado: boolean = true;
    nombre_compuesto: string;
    tabla:string;
    claveid:string;
    vias: Via[];
    txtVias: string;
    basico: boolean;

    constructor(data: Medicamento | {} = {}) {
        Object.assign(this, data);
    }
}

export interface Via {
    medicamento_id: number
    via_administracion_id: number
    nombre: string
  }