import { Component, OnInit, ViewChild } from "@angular/core";
import {
  CatalogService,
  ConsultaService,
  FormsService,
} from "src/app/services/service.index";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { GridComponent } from "src/app/shared/components/grid.component";
import { FormGroup } from "@angular/forms";
import { Diagnostico, IDiagnostico } from "src/app/models/diagnostico.model";
import { IFormGroup } from "@rxweb/types";
import { element } from "protractor";
import { Utils } from "src/app/shared/helpers/utils";

@Component({
  selector: "app-diagnosticos",
  templateUrl: "./diagnosticos.component.html",
  styles: [],
})
export class DiagnosticosComponent implements OnInit {
  @ViewChild("modal", { static: true }) modal;
  @ViewChild(GridComponent, { static: true }) mygrid: GridComponent;

  rowData: any;
  columnDefs = [
    { headerName: "Clase", field: "clase" },
    { headerName: "Clave", field: "clave" },
    { headerName: "Descripción Oficial", field: "descripcion" },
    { headerName: "Descripción Personalizada", field: "clase_descripcion" },
    { headerName: "Género", field: "genero_text" },
    { headerName: "Edad mínima", field: "edad_minima_text" },
    { headerName: "Edad máxima", field: "edad_maxima_text" },
  ];
  autoGroupColumnDef = [
    { headerName: "Nombre",
    cellRendererParams: {
      suppressCount: true,
      checkbox: true
    }
    }
  ];
  _diagnostico: IDiagnostico;
  utils = new Utils();

  show_cancel_button = false;
  show_close_button = true;
  show_save_button = false;

  constructor(
    public _cat: CatalogService,
    public _consulta: ConsultaService,
    private modalService: NgbModal,
    public _frm: FormsService
  ) {}

  ngOnInit() {
    this.rowData = [];
  }

  onFilterTextBoxChanged(search_word) {
    if (search_word.length > 2 || search_word.length == 0) {
      this._consulta.search_diagnostico(search_word || "").subscribe(
        (data) => {
          console.log(data);
          let newData = data as IDiagnostico[];
          newData.forEach( (element,i) => {
            newData[i].genero_text = element.genero_id == 1 ? 'Masculino' : element.genero_id == 2 ? 'Femenino' : '';

            let minType = this.utils.millisecondsToData(element.edad_minima).formatType;
            let maxType = this.utils.millisecondsToData(element.edad_maxima).formatType;

            let minNumber = this.utils.millisecondsToData(element.edad_minima).value;
            let maxNumber = this.utils.millisecondsToData(element.edad_maxima).value;

            let minTypeText = minType == 1 ? 'dia(s)' : minType == 2 ? 'mes(es)' : minType == 3 ? 'año(s)' : '';
            let maxTypeText = maxType == 1 ? 'dia(s)' : maxType == 2 ? 'mes(es)' : maxType == 3 ? 'año(s)' : '';

            newData[i].edad_minima_text = element.edad_minima != null ? `${minNumber} ${minTypeText}` : '';
            newData[i].edad_maxima_text = element.edad_maxima != null ? `${maxNumber} ${maxTypeText}` : '';
          });
          this.rowData = newData;
        },
        (err) => console.log(err)
      );
    }
  }

  option_selector(button: string) {
    console.log(button);

    switch (button) {
      case "create": {
        this.show_cancel_button = false;
        this.show_close_button = true;
        this.show_save_button = true;
        this._diagnostico = new Diagnostico({});
        this.modalService.open(this.modal, { size: "lg" });
        break;
      }
      case "update": {
        this.show_cancel_button = false;
        this.show_close_button = true;
        this.show_save_button = true;
        this._frm.get_rows_selected(this.mygrid, false, (selecteds) => {
          this._diagnostico = selecteds[0];
          this.modalService.open(this.modal, { size: "lg" });
        });
        break;
      }
      case "view": {
        this.show_cancel_button = false;
        this.show_close_button = true;
        this.show_save_button = false;
        this._frm.get_rows_selected(this.mygrid, false, (selecteds) => {
          this._diagnostico = selecteds[0];
          this.modalService.open(this.modal, { size: "lg" });
        });
        break;
      }
      case "delete": {
        this._frm.get_rows_selected(this.mygrid, true, (selecteds) => {
          this._diagnostico = selecteds[0];
          console.log(this._diagnostico.diagnostico_id);
          this._frm.show_warning(
            "Borrar diagnostico(s)",
            "¿Estas seguro de eliminar el/los diagnostico(s) seleccionados? Una vez eliminado(s) no podrán recuperarse.",
            () => {
              selecteds.forEach(element => {
                this._diagnostico = element;
                this.delete_diagnostico(this._diagnostico.diagnostico_id)
              });              
            }
          );
        });
        break;
      }
    }
  }

  save_diagnostico(form: IFormGroup<IDiagnostico>) {
    var newForm = form.getRawValue();
    var edadMinimaValue = newForm.edad_minima;
    var edadMinimaTipo = newForm.edad_minima_tipo;
    newForm.edad_minima = this.utils.dataToMilliseconds(edadMinimaValue, edadMinimaTipo);

    var edadMaximaValue = newForm.edad_maxima;
    var edadMaximaTipo = newForm.edad_maxima_tipo;
    newForm.edad_maxima = this.utils.dataToMilliseconds(edadMaximaValue, edadMaximaTipo);

    newForm.cie10 = false;

    this._frm.save_module(
      form,
      "id",
      () => {
        this._cat.insert_update_diagnostico(newForm).subscribe(
          (res: any) => {
            this._frm.toast_alert("Actualizado exitosamente", "Mensaje", "success");
            this.modalService.dismissAll(null);
            this._consulta.search_diagnostico("").subscribe(
              (data) => (this.rowData = data),
              (error) => console.log(error)
            );
          },
          (error) =>
            this._frm.toast_alert(
              "Error: " + error.error.message,
              "Ocurrio un error",
              "error"
            )
        );
      },
      () => {
        this._cat.insert_update_diagnostico(newForm).subscribe(
          (res: any) => {
            this._frm.toast_alert(
              "Creado exitosamente",
              "Mensaje",
              "success"
            );
            this.modalService.dismissAll(null);
            this._consulta.search_diagnostico("").subscribe(
              (data) => (this.rowData = data),
              (error) => console.log(error)
            );
          },
          (error) =>
            this._frm.toast_alert(
              "Error: " + error.error.message,
              "Ocurrio un error",
              "error"
            )
        );
      }
    );
  }

  delete_diagnostico(id: number) {
    this._cat.delete_diagnostico(id).subscribe(
      (res: any) => {this._frm.toast_alert("Eliminado exitosamente", "Mensaje", "success");
      this.modalService.dismissAll(null);
      this._consulta.search_diagnostico("").subscribe(data => this.rowData = data, error => console.log(error));
    },
    error => this._frm.toast_alert("Error: " + error.error.message, "Ocurrio un error", "error")
    );
  }

}
