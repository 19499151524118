import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { role } from 'src/app/models/authorization.model';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { ConsultaService } from 'src/app/services/consulta.service';
import { FormsService } from 'src/app/services/forms.service';
import { UserService } from 'src/app/services/user.service';
import { GridComponent } from 'src/app/shared/components/grid.component';

@Component({
  selector: 'app-consultorios',
  templateUrl: './consultorios.component.html',
  styles: [
  ]
})
export class ConsultoriosComponent implements OnInit {

  @ViewChild("modal", { static: true }) modal;
  @ViewChild("modal2", { static: true }) modal2;

  @ViewChild(GridComponent, { static: true }) mygrid: GridComponent;

  rowData: any;
  columnDefs = [
    { headerName: "Sucursal", field: "sucursal" },
    { headerName: "Consultorio", field: "consultorio" }
  ];
  consultorio: any = {};
  




  constructor(public _auth: AuthorizationService, public _frm: FormsService, public _consultaService: ConsultaService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.get_data();



  }

  get_data() {

    this._consultaService.get_consultorios().subscribe(
      data=> {this.rowData = data;},
      error => {console.log(error);}
      );

  }


  option_selector(button: string) {
    switch (button) {
      case "create": {
        this.consultorio = {};
        this.modalService.open(this.modal, { size: 'lg' });
        break;
      }
      case "update": {
        this._frm.get_rows_selected(this.mygrid, false, (selecteds) => {
          this.consultorio = selecteds[0];
          this.modalService.open(this.modal, { size: 'lg' })
        });
        break;
      }
      case "delete": {
        this._frm.get_rows_selected(this.mygrid, false, (selecteds) => {
          this.consultorio = selecteds[0];
          this._frm.show_warning('Borrar consultorio', 'Estas seguro de eliminar el consultorio [' + this.consultorio.consultorio + ']. Una vez eliminado no podra recuperarse.', () => this.delete_consultorio());
        });
        break;
      }
      case "horario": {        
        this._frm.get_rows_selected(this.mygrid, false, (selecteds) => {
          this.consultorio = selecteds[0];          
          this.modalService.open(this.modal2, { size: 'lg' });    
        });
        break;
      }

      }
    }




    save_consultorio(form: FormGroup) {

      this._frm.save_module(form, "consultorio_id", () => {
        // Crear nuevo rol
        this._consultaService.insert_consultorio(form.value).subscribe(
          (res: any) => this._frm.toast_alert("Consultorio creado exitosamente", "Nuevo Consultorio", "success"),
          error => this._frm.toast_alert("El consultorio no pudo ser creado correctamente. Error: " + error.error.message, "Nuevo Consultorio", "error")
        );
      }, () => {
        this._consultaService.update_consultorio(form.value).subscribe(
          // Actualizar rol seleccionado
          (res: any) =>
            this._frm.toast_alert("Consultorio actualizado exitosamente", "Actualizar Consultorio", "success"),
          error => {
            this._frm.toast_alert("El consultorio no pudo ser actualizado correctamente. Error: " + error.error.message, "Actualizar Consultorio", "error");
          }
        );
      });
      this.get_data();
      this.modalService.dismissAll(null);
    }

    delete_consultorio() {
      this._consultaService.delete_consultorio(this.consultorio.consultorio_id).subscribe(
        (res: any) => this._frm.toast_alert("Consultorio eliminado exitosamente", "Borrar Consultorio", "success"),
        error => { console.log(error); this._frm.toast_alert("El consultorio no pudo ser eliminado correctamente. Error: " + error.error.message, "Borrar Consultorio", "error") }
      );
      this.get_data();
    }


    save_consultorio_horario(horarios) {
      this._consultaService.update_horario_consultorio(horarios).subscribe(
        (res: any) => this._frm.toast_alert("Horarios actualizados exitosamente", "Actualizar horarios", "success"),
        error => { console.log(error); this._frm.toast_alert("Los horarios no pudieron ser actualizados correctamente. Error: " + error.error.message, "Actualizar horarios", "error") }
      );
    }

}
