import { Component, OnInit, Input,ViewChild, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { CatalogService, MedicoService, FormsService } from 'src/app/services/service.index';
import { medico } from 'src/app/models/medico.model';
import { FormGroup, FormBuilder,Validators } from '@angular/forms';
import { horario } from '../../models/medico.model';
import { formatDate } from '@angular/common';
import { Inject, LOCALE_ID } from '@angular/core';
import { GridComponent } from 'src/app/shared/components/grid.component';
import { ToastrService } from 'ngx-toastr';
import { HorarioEspecialidad } from 'src/app/models/horario_especialidad.model';
@Component({
  selector: 'app-horario-dialog',
  templateUrl: './horario-dialog.component.html',
  styles: [
    `
  .list-group-item {padding: .35rem 0.55rem;}
  [type="checkbox"].filled-in:checked + label::after {width: 15px;height: 15px;margin-top: 3px;}
  [type="checkbox"].filled-in:checked + label::before {width: 6px;height: 9px;margin-top: 5px;}
  [type="checkbox"].filled-in:not(:checked) + label::after {height: 15px;width: 15px;margin-top: 3px;}
`
  ]
})
export class HorarioDialogComponent implements OnInit {
  @Output() close_modal = new EventEmitter();
  @Output() submit_form = new EventEmitter<any>();
  @Input() medico: medico;
  especialidades: any;
  especialidades2: any;
  especialidad_form: FormGroup;
  excepciones_form:FormGroup;
  @ViewChild("grid1", { static: true }) grid1: GridComponent;
  horario: any = { lun: { dia: 1, from: 0, to: 0, checked: false }, mar: { dia: 2, from: 0, to: 0, checked: false }, mie: { dia: 3, from: 0, to: 0, checked: false }, jue: { dia: 4, from: 0, to: 0, checked: false }, vie: { dia: 5, from: 0, to: 0, checked: false }, sab: { dia: 6, from: 0, to: 0, checked: false }, dom: { dia: 7, from: 0, to: 0, checked: false } };
  horarios: horario[] = [];
  fechaHoy:any;
  fechaChange:any;
  result: any = {};
  bandValid:boolean=false;
  myDate:any;
   
  sino:any=[{nombre:"si",id:1},{nombre:"no",id:0}]
  columnDefs = [
    {width:100, headerName: "Fecha de inicio", field: "fecha_inicio",cellRenderer: (data) => {
      return  formatDate(data.value, 'dd MMM yyyy', this.locale)
      }},
    {width:100, headerName: "Fecha de fin", field: "fecha_fin",cellRenderer: (data) => {
        return  formatDate(data.value, 'dd MMM yyyy', this.locale)
        }},
    { headerName: "Laborable ", field: "laborable" ,cellRenderer: (data) => {
      if(data.value==1)
      return  "SI"
      else
      return  "NO"     
      }},
    { headerName: "Observaciones", field: "observacion" }
  ];
  rowData: any = [];

  //-->HORARIOS MÚLTIPLES
  horarioEspecialidades: HorarioEspecialidad[] = [];
  horarioEspecialidad: HorarioEspecialidad;
  lunesArray: Array<{from: number, to: number}> = [];
  martesArray: Array<{from: number, to: number}> = [];
  miercolesArray: Array<{from: number, to: number}> = [];
  juevesArray: Array<{from: number, to: number}> = [];
  viernesArray: Array<{from: number, to: number}> = [];
  sabadoArray: Array<{from: number, to: number}> = [];
  domingoArray: Array<{from: number, to: number}> = [];

  add_horario(dayArray: Array<{from: number, to: number}>){
    dayArray.push({from:0.0, to:0.0},);
  }
  remove_horario(dayArray:[], position: number){
    dayArray.splice(position, 1);
  }
  //<--HORARIOS MÚLTIPLES
  constructor(private _toastr: ToastrService,@Inject(LOCALE_ID) private locale: string,public _cat: CatalogService,public _frm :FormsService, private _fb: FormBuilder, public _med: MedicoService, private cdRef: ChangeDetectorRef, public _form: FormsService) { }


  ngOnInit() {    
    this._med.excepciones_get(this.medico.medico_id).subscribe(data => this.rowData = data, error => console.log(error));
    this.myDate=new Date()
    this.fechaHoy ={year: this.myDate.getFullYear(), month: this.myDate.getMonth() +1, day: this.myDate.getDate()}
    this.fechaChange=this.fechaHoy;
    this.especialidad_form = this._fb.group({
      especialidad_id: [null]
    });

    this.excepciones_form = this._fb.group({
      excepcion_id: [''],
      medico_id: [this.medico.medico_id],
      fecha_inicio: ['',[Validators.required]],
      fecha_fin: ['',[Validators.required]],
      laborable: ['',[Validators.required]],
      observacion: ['',[Validators.required]],
    });

    this._cat.get_catalog("especialidad", true).subscribe(data => {      
      this.especialidades = data
      let especialidad_id = this.medico.especialidades.split(',');
      this.especialidades2 = this.especialidades.filter(function (item) {        
        return especialidad_id.includes(item.id.toString());
      });
      console.log(`catalog_esp:${JSON.stringify(this.especialidades2 )}`);
      
      this.especialidades2.forEach((item) => {
        var h = new HorarioEspecialidad();
        h.especialidadId = item.id;
        this.horarioEspecialidades.push(h);
      })
      console.log(`this.horarioEspecialidades:${JSON.stringify(this.horarioEspecialidades)}`);
      this.especialidad_form.patchValue({ "especialidad_id": this.especialidades2[0].id });
      this.horario_get(this.medico.medico_id, this.especialidades2[0].id);
    },
      error => console.log(error));
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }


  toHourString(ts) {
    var decimalTimeString = ts.toString();
    var n = new Date(0, 0);
    n.setMinutes(+decimalTimeString * 60);
    var result = n.toTimeString().slice(0, 5);
    return result;
  }

  //change_slider(horario, event) {
  change_slider(dayArray:Array<{from: number, to: number}>, position: number, event) {
    dayArray[position] = {from:event.from, to:event.to};
    console.log(`event.from:${event.from}`);
    console.log(`event.to:${event.to}`);
    //horario.from = event.from;
    //horario.to = event.to;
  }
  change_day(h, event) {
    console.log(event);
    
    h.checked = event.srcElement.checked;
  }

  change_especialidad($event) {
    if (!$event) {
      for (let key in this.horario) {
        let value = this.horario[key];
        value.checked = false;
      }
    }

    else {
      this.horario = { lun: { dia: 1, from: 0, to: 0, checked: false }, mar: { dia: 2, from: 0, to: 0, checked: false }, mie: { dia: 3, from: 0, to: 0, checked: false }, jue: { dia: 4, from: 0, to: 0, checked: false }, vie: { dia: 5, from: 0, to: 0, checked: false }, sab: { dia: 6, from: 0, to: 0, checked: false }, dom: { dia: 7, from: 0, to: 0, checked: false } };
      this.horarios = [];
      this.horario_get(this.medico.medico_id, $event.id);
    }
  }


  /*horario_get(medico_id: number, especialidad_id: number) {
    this._med.horario_get(medico_id, especialidad_id).subscribe((data: any) => {
      var schedule: any = this.horario;
      data.forEach((item) => {
        schedule[item.dia] = { dia: item.dia_id, from: item.hora_inicio_decimal, to: item.hora_fin_decimal, checked: true }
      });
      this.horario = schedule
      console.log(this.horario);

    });
  }*/
  horario_get(medico_id: number, especialidad_id: number) {    
    this._med.horario_get(medico_id, especialidad_id).subscribe((data: any) => {
      var horarioEspecialidad = new HorarioEspecialidad();
      horarioEspecialidad.especialidadId = especialidad_id;      
      var schedule: any = this.horario;
      data.forEach((item) => {
        schedule[item.dia] = { dia: item.dia_id, from: item.hora_inicio_decimal, to: item.hora_fin_decimal, checked: true }
        switch (item.dia_id) {
          case 1: //LUNES
            horarioEspecialidad.days.lunesArray.push({from:item.hora_inicio_decimal, to:item.hora_fin_decimal});
            //this.lunesArray.push({from:item.hora_inicio_decimal, to:item.hora_fin_decimal});
            break;
          case 2: //MARTES
          horarioEspecialidad.days.martesArray.push({from:item.hora_inicio_decimal, to:item.hora_fin_decimal});
          //this.martesArray.push({from:item.hora_inicio_decimal, to:item.hora_fin_decimal});
            break;
          case 3: //MIERCOLES
          horarioEspecialidad.days.miercolesArray.push({from:item.hora_inicio_decimal, to:item.hora_fin_decimal});
          //this.miercolesArray.push({from:item.hora_inicio_decimal, to:item.hora_fin_decimal});
            break;
          case 4: //JUEVES
          horarioEspecialidad.days.juevesArray.push({from:item.hora_inicio_decimal, to:item.hora_fin_decimal});
          //this.juevesArray.push({from:item.hora_inicio_decimal, to:item.hora_fin_decimal});
            break;
          case 5: //VIERNES
          horarioEspecialidad.days.viernesArray.push({from:item.hora_inicio_decimal, to:item.hora_fin_decimal});
          //this.viernesArray.push({from:item.hora_inicio_decimal, to:item.hora_fin_decimal});
            break;
          case 6: //SÁBADO
          horarioEspecialidad.days.sabadoArray.push({from:item.hora_inicio_decimal, to:item.hora_fin_decimal});
          //this.sabadoArray.push({from:item.hora_inicio_decimal, to:item.hora_fin_decimal});
            break;
          case 7: //DOMINGO
          horarioEspecialidad.days.domingoArray.push({from:item.hora_inicio_decimal, to:item.hora_fin_decimal});
          //this.domingoArray.push({from:item.hora_inicio_decimal, to:item.hora_fin_decimal});
            break;

          default:
            break;
        }  
      });
      this.horarioEspecialidades.find(i => i.especialidadId === especialidad_id).days = horarioEspecialidad.days;
      console.log(`horarioEspecialidad:${JSON.stringify(horarioEspecialidad)}`);
      console.log(`this.horarioEspecialidades:${JSON.stringify(this.horarioEspecialidades)}`);
      this.horarioEspecialidad = horarioEspecialidad;
      this.horario = schedule
      //this.horario = horarioEspecialidad.days;
      console.log(this.horario);      
    });
  }


  /*on_submit() {

    if (this.especialidad_form.controls["especialidad_id"].value) {
      this.horarios = [];

      for (let key in this.horario) {
        let value = this.horario[key];
        if (value.checked) {
          var hora: horario = new horario;
          hora.medico_id = this.medico.medico_id;
          hora.especialidad_id = this.especialidad_form.controls["especialidad_id"].value;
          hora.dia_id = value.dia;
          hora.horario_cadena = "";
          hora.hora_inicio = this.toHourString(value.from);
          hora.hora_fin = this.toHourString(value.to);

          this.horarios.push(hora);
        }
      }

      this.result.horarios = this.horarios;
      this.result.medico_id = this.medico.medico_id;
      this.result.especialidad_id = this.especialidad_form.controls["especialidad_id"].value;;
      this.submit_form.emit(this.result);
    }
    else {
      this._form.toast_alert("Seleccione una especialidad", "Administración de Horarios", "warning")
    }
  }*/
  //MULTIHORARIO
  on_submit() {

    if (this.especialidad_form.controls["especialidad_id"].value) {
      this.horarios = [];
      //var horarioEspecialidad = this.horarioEspecialidades.find(i => i.especialidadId === this.especialidad_form.controls["especialidad_id"].value);

      for (let key in this.horario) {
        let value = this.horario[key];
        if (value.checked) {          
          switch (value.dia) {
            case 1: //LUNES
              this.fillHorariosMultiples(this.horarioEspecialidad.days.lunesArray, value.dia);
              break;
            case 2: //MARTES
              this.fillHorariosMultiples(this.horarioEspecialidad.days.martesArray, value.dia);
              break;
            case 3: //MIERCOLES
              this.fillHorariosMultiples(this.horarioEspecialidad.days.miercolesArray, value.dia);
              break;
            case 4: //JUEVES
              this.fillHorariosMultiples(this.horarioEspecialidad.days.juevesArray, value.dia);
              break;
            case 5: //VIERNES
              this.fillHorariosMultiples(this.horarioEspecialidad.days.viernesArray, value.dia);
              break;
            case 6: //SÁBADO
              this.fillHorariosMultiples(this.horarioEspecialidad.days.sabadoArray, value.dia);
              break;
            case 7: //DOMINGO
              this.fillHorariosMultiples(this.horarioEspecialidad.days.domingoArray, value.dia);
              break;

            default:
              break;
          }         
        }
      }

      this.result.horarios = this.horarios;
      this.result.medico_id = this.medico.medico_id;
      this.result.especialidad_id = this.especialidad_form.controls["especialidad_id"].value;;
      this.submit_form.emit(this.result);
    }
    else {
      this._form.toast_alert("Seleccione una especialidad", "Administración de Horarios", "warning")
    }
  }

  fillHorariosMultiples(dayArray: Array<{from: number, to: number}>, day: number){
      dayArray.forEach((item) =>{
      var hora: horario = new horario;
      hora.medico_id = this.medico.medico_id;
      hora.especialidad_id = this.especialidad_form.controls["especialidad_id"].value;
      hora.dia_id = day;
      hora.horario_cadena = "";
      hora.hora_inicio = this.decimalToHours(item.from.toString());
      hora.hora_fin = this.decimalToHours(item.to.toString());

      this.horarios.push(hora);
    });
  }

  decimalToHours(decimalData: string){
    var tmpData = decimalData.split('.');
    var tmpHour = tmpData[0];
    var tmpMin = tmpData[1] == '5' ? ':30:00.0000000' : ':00:00.0000000';
    return tmpHour + tmpMin;
  }

  on_close() {
    this.close_modal.emit();
  }

 onChangeDate(control: string){
  this.fechaChange=this.excepciones_form.controls[control].value;
  if(this.excepciones_form.controls["fecha_fin"].value != '')
  this.excepciones_form.controls["fecha_fin"].patchValue('')
 }
 resetForm(){
  this._med.excepciones_get(this.medico.medico_id).subscribe(data => this.rowData = data, error => console.log(error));
 
 }
  guardar(){
   
      this._med.excepciones_insert(this.excepciones_form.value).subscribe(
        (res: any) =>
        { this._frm.toast_alert("Excepción creada exitosamente", "Nueva excepción", "success");
        this.ngOnInit()
        this.bandValid=true;
      },
        error => this._frm.toast_alert("La referencia no pudo ser creado correctamente. Error: " + error.error.message, "Nueva Referencia", "error")
      );
      

  }
  selectDelete(){
    const selecteds = this.grid1.getSelectedRows();   
    if(selecteds.length == 0) {
      this._toastr.warning("Seleccione una excepción  del listado", "Expediente de paciente")
     }else{
      this._frm.show_warning('Borrar excepción', 'Esta seguro de eliminar la excepción. Una vez eliminada no podra recuperarse.', () => this.delete());
     }
    }

    delete(){
      const selecteds = this.grid1.getSelectedRows();
        this._med.
        excepciones_delete(selecteds[0].excepcion_id).subscribe(
          (res: any) =>
          { this._frm.toast_alert("Excepción eliminada exitosamente", "Eliminar excepción", "success");
          this.excepciones_form = this._fb.group({
            excepcion_id: [],
            medico_id: [this.medico.medico_id],
            fecha_inicio: ['',[Validators.required]],
            fecha_fin: ['',[Validators.required]],
            laborable: ['',[Validators.required]],
            observacion: ['',[Validators.required]],
          });
         
          this.resetForm();
        },
          error => this._frm.toast_alert("La excepción no pudo ser eliminada correctamente. Error: " + error.error.message, "Eliminar excepción", "error")
        );
       
    } 
}
