<app-buttons (button_selected)="option_selector($event)" ></app-buttons>
<app-grid [rowData]="rowData" [columnDefs]="columnDefs" [show_filter] = true [rowSelection]="'single'"></app-grid>
<!-- Ventana modal para compartir agenda -->
<ng-template #modal4 let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Compartir agenda</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"><span
                aria-hidden="true">&times;</span></button>
    </div>
    <div class="modal-body p-0">
        <br/>
        <h5 class="card-title  col-md-12">Compartir agenda médica del usuario <strong>{{medicoName}}</strong><br/>
            Al compartir la agenda médica los usuarios compartidos podran visualizar las citas agendadas asi como la posibilidad de realizar la consulta médica en caso de que se trate de un perfil médico.
             </h5>
        <div class="card m-b-0">
                <app-share-schedule-dialog [user_id]="userId"></app-share-schedule-dialog>
        </div>
    </div>
</ng-template>
