import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { referencia,paciente } from '../../models/paciente.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormsService, PacienteService, CatalogService, AddressService } from 'src/app/services/service.index';
import { GridComponent } from 'src/app/shared/components/grid.component';
import { DatePipe } from '@angular/common'
import { formatDate } from '@angular/common';
import { Inject, LOCALE_ID } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-area-referencias',
  templateUrl: './area-referencias.component.html',
  styles: []
})
export class AreaReferenciasComponent implements OnInit {
  @ViewChild("form", { static: true }) form;
  @Input() referencia: referencia = new referencia({});
  referencias_form: FormGroup;
  @Input() paciente: paciente = new paciente({});
  especialidades: any;
  idUpdate:any;
  @ViewChild("grid1", { static: true }) grid1: GridComponent;


  columnDefs = [
    {width:200, headerName: "Fecha", field: "fecha",cellRenderer: (data) => {
      return  formatDate(data.value, 'dd MMM yyyy', this.locale)
      }},
    { headerName: "Unidad refiere", field: "unidad_refiere" },
    { headerName: "Unidad referida", field: "unidad_referida" },
    { headerName: "Especialidad", field: "especialidad" },
    {headerName: "Motivo", field: "motivo" }
  ];
  rowData: any = [];


  constructor(@Inject(LOCALE_ID) private locale: string,public datepipe: DatePipe,private _toastr: ToastrService,private _fb: FormBuilder, public _frm: FormsService, public _paciente: PacienteService, public _cat: CatalogService, public _adr: AddressService) { }
  
  ngOnInit() {
    if(this.paciente.paciente_id != null){
      this._paciente.get_referencias(this.paciente.paciente_id).subscribe(data => this.rowData = data, error => console.log(error));
    }else{
      
    }
    
    
    this._cat.get_catalog("especialidad", true).subscribe(data => this.especialidades = data, error => console.log(error)); 
    this.referencias_form = this._fb.group({
      referencia_id: [this.referencia.referencia_id],
      fecha: [this.referencia.fecha, [Validators.required]],
      unidad_refiere:  [this.referencia.unidad_refiere, [Validators.required]],
      unidad_referida:  [this.referencia.unidad_referida, [Validators.required]],
      especialidad_id:[this.referencia.especialidad_id, [Validators.required]],
      motivo: [this.referencia.motivo],
      paciente_id:[this.paciente.paciente_id],
    });
  }
selectDelete(){
  const selecteds = this.grid1.getSelectedRows();   
  if(selecteds.length == 0) {
    this._toastr.warning("Seleccione un paciente del listado", "Expediente de paciente")
   }else{
    this._frm.show_warning('Borrar referencia', 'Esta seguro de eliminar la referencia. Una vez eliminado no podra recuperarse.', () => this.delete());
   }
  }
  delete(){
    const selecteds = this.grid1.getSelectedRows();
      this._paciente.delete_referencias(selecteds[0].referencia_id).subscribe(
        (res: any) =>
        { this._frm.toast_alert("Referencia eliminada exitosamente", "Eliminar referencia", "success");
          this._paciente.get_referencias(this.paciente.paciente_id).subscribe(data => this.rowData = data, error => console.log(error));
          this.referencias_form = this._fb.group({
          referencia_id: [this.referencia.referencia_id],
          fecha: [this.referencia.fecha, [Validators.required]],
          unidad_refiere:  [this.referencia.unidad_refiere, [Validators.required]],
          unidad_referida:  [this.referencia.unidad_referida, [Validators.required]],
          especialidad_id:[this.referencia.especialidad_id, [Validators.required]],
          motivo: [this.referencia.motivo, [Validators.required]],         
        });  
      },
        error => this._frm.toast_alert("La referencia no pudo ser eliminada correctamente. Error: " + error.error.message, "Nueva Referencia", "error")
      );
     
  } 

  selectRow(){
    const selecteds = this.grid1.getSelectedRows();    
    if(selecteds.length == 1) { 
    this.referencias_form.patchValue({ "unidad_refiere": selecteds[0].unidad_refiere });
    this.referencias_form.patchValue({ "especialidad_id":  selecteds[0].especialidad_id });
    let latest_date =this.datepipe.transform(selecteds[0].fecha, 'yyyy-MM-dd');
    let objFecha={year:parseInt(latest_date.substring(0,4)),month:parseInt(latest_date.substring(5,7)),day:parseInt(latest_date.substring(8,10))}
    this.referencias_form.patchValue({"fecha":objFecha  })
    this.referencias_form.patchValue({ "motivo":  selecteds[0].motivo });
    this.referencias_form.patchValue({ "unidad_referida":  selecteds[0].unidad_referida });
    this.referencias_form.patchValue({ "referencia_id":  selecteds[0].referencia_id });
  
    }else{
      this.referencias_form.patchValue({ "unidad_refiere": "" });
      this.referencias_form.patchValue({ "especialidad_id": null}); 
      this.referencias_form.patchValue({"fecha":""  })
      this.referencias_form.patchValue({ "motivo": "" });
      this.referencias_form.patchValue({ "unidad_referida":  ""});
      this.referencias_form.patchValue({ "referencia_id": null});
      
    }
  }


  update_paciente($event){
    this.paciente = $event;
  }
  guardar(){
    this._frm.save_module(this.referencias_form, "referencia_id", () => {
      this.referencias_form.value.paciente_id=this.paciente.paciente_id
      this._paciente.insert_referencias(this.referencias_form.value).subscribe(
        (res: any) =>
        { this._frm.toast_alert("Referencia creada exitosamente", "Nueva referencia", "success");
        this._paciente.get_referencias(this.paciente.paciente_id).subscribe(data => this.rowData = data, error => console.log(error));
        this.referencias_form = this._fb.group({
          referencia_id: [this.referencia.referencia_id],
          fecha: [this.referencia.fecha, [Validators.required]],
          unidad_refiere:  [this.referencia.unidad_refiere, [Validators.required]],
          unidad_referida:  [this.referencia.unidad_referida, [Validators.required]],
          especialidad_id:[this.referencia.especialidad_id, [Validators.required]],
          motivo: [this.referencia.motivo, [Validators.required]],
          
        });  
      },
        error => this._frm.toast_alert("La referencia no pudo ser creado correctamente. Error: " + error.error.message, "Nueva Referencia", "error")
      );
      
    }, () => {
      const selecteds = this.grid1.getSelectedRows();  
      if(selecteds.length == 1) { 
       this._paciente.update_referencias(this.referencias_form.value).subscribe(
        (res: any) =>
         {this._frm.toast_alert("Referencia actualizada exitosamente", "Actualizar referencia", "success");
         this._paciente.get_referencias(this.paciente.paciente_id).subscribe(data => this.rowData = data, error => console.log(error));  
         this.referencias_form = this._fb.group({
          referencia_id: [this.referencia.referencia_id],
          fecha: [this.referencia.fecha, [Validators.required]],
          unidad_refiere:  [this.referencia.unidad_refiere, [Validators.required]],
          unidad_referida:  [this.referencia.unidad_referida, [Validators.required]],
          especialidad_id:[this.referencia.especialidad_id, [Validators.required]],
          motivo: [this.referencia.motivo, [Validators.required]],
        }); 
   
        },
         error => {
           this._frm.toast_alert("La referencia no pudo ser actualizada correctamente. Error: " + error.error.message, "Actualizar Referencia", "error");
         }
       );
        }else{
          this._toastr.warning("Seleccione una referenacia del listado", "Refer5encias")
       }
    });
  


    

  }
}
