<div class="card-body p-b-0">
    <form class="form-material" [formGroup]="consultorio_form" (ngSubmit)="on_submit()" #form="ngForm"  novalidate>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group" [ngClass]="{ 'has-danger': is_invalid('consultorio')}">
                    <label for="txt_name" [ngClass]="{ 'star_symbol': is_required('consultorio') }">Nombre</label>
                    <input type="text" placeholder="Nombre" class="form-control" id="txt_name" formControlName="consultorio">
                    <div class="invalid-feedback">
                        <div *ngIf="consultorio_form.controls['consultorio'].errors?.required">El nombre es obligatorio</div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group" [ngClass]="{ 'has-danger': is_invalid('sucursal_id')}">
                    <label for="select_sucursal" [ngClass]="{ 'star_symbol': is_required('sucursal_id') }">Sucursal</label>

                    <ng-select id="select_sucursal" placeholder="Sucursal" formControlName="sucursal_id" class="form-control" 
                    [items]="sucursales"  bindLabel="text" bindValue="id"> </ng-select>

                    <div class="invalid-feedback">
                        <div *ngIf="consultorio_form.controls['sucursal_id'].errors?.required">La sucursal es obligatoria</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row m-t-30">
            <div class="col-md-12 modal-footer">
                <button type="button" class="btn btn-danger btn-sm" (click) = "on_close()" ><i class="mdi mdi-close"></i> Cancelar</button>
                <button type="submit" class="btn btn-primary btn-sm "><i class="mdi mdi-content-save"></i> Guardar Consultorio</button>
            </div>
        </div>
    </form>
</div>
