import { Component, Input, OnInit } from '@angular/core';
import { agenda } from 'src/app/models/consulta.model';
import { NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup } from '@angular/forms';
import { ConsultaService } from 'src/app/services/consulta.service';

@Component({
  selector: 'app-cuestionarios',
  templateUrl: './cuestionarios.component.html',
  styles: [
  ]
})
export class CuestionariosComponent implements OnInit {
  @Input() consultaId: number;
  @Input() agenda: agenda = new agenda({});
  consulta_form: FormGroup;

  public quest_1 = {};
  public quest_2 = {};
  public quest_3 = {};

  constructor(public _cs:ConsultaService) { }

  ngOnInit(): void {

    
  }

  on_submit() {

  }
  on_close() {
    //this.close_modal.emit();
  }
  
  panel_change($event: NgbPanelChangeEvent) {
    if ($event.nextState) {
      if ($event.panelId === 'panel-question-1') {
        console.log(this.consultaId);
        this._cs.get_cuestionario(1,this.consultaId).subscribe(
          (res: any) => {
            this.quest_1 = res;
            console.log(this.quest_1);
        },
          error => { 
            console.  error(error); 
          }
        );
      }
      else if ($event.panelId === 'panel-question-2') {
        console.log(this.consultaId);
        this._cs.get_cuestionario(2,this.consultaId).subscribe(
          (res: any) => {
            this.quest_2 = res;
            console.log(this.quest_2);
        },
          error => { 
            console.  error(error); 
          }
        );

      }
      else if ($event.panelId === 'panel-question-3') {
        console.log(this.consultaId);
        this._cs.get_cuestionario(3,this.consultaId).subscribe(
          (res: any) => {
            this.quest_3 = res;
            console.log(this.quest_3);
        },
          error => { 
            console.  error(error); 
          }
        );

      }
    }
  }



  calculate_quest_1(){
      console.log(this.quest_1);
      this._cs.result_cuestionario(1,this.consultaId,  JSON.stringify(this.quest_1)).subscribe(
        (res: any) => {this.quest_1 = res;   console.log(this.quest_1);},
        error => {console.  error(error);}
      );
  }
  save_quest_1() {
    console.log(this.quest_1);
    this._cs.save_cuestionario(1,this.consultaId,  JSON.stringify(this.quest_1)).subscribe(
      (res: any) => {

      },
      error => {console.  error(error);}
    );
  }



  calculate_quest_2(){
    console.log(this.quest_2);
    this._cs.result_cuestionario(2,this.consultaId,  JSON.stringify(this.quest_2)).subscribe(
      (res: any) => {this.quest_2 = res;},
      error => {console.  error(error);}
    );
  }
  save_quest_2() {
    console.log(this.quest_2);
    this._cs.save_cuestionario(2,this.consultaId,  JSON.stringify(this.quest_2)).subscribe(
      (res: any) => {
        
      },
      error => {console.  error(error);}
    );
  }


  calculate_quest_3(){
    console.log(this.quest_3);
    this._cs.result_cuestionario(3,this.consultaId,  JSON.stringify(this.quest_3)).subscribe(
      (res: any) => {this.quest_3 = res;},
      error => {console.  error(error);}
    );
  }
  save_quest_3() {
    console.log(this.quest_3);
    this._cs.save_cuestionario(3,this.consultaId,  JSON.stringify(this.quest_3)).subscribe(
      (res: any) => {
        
      },
      error => {console.  error(error);}
    );
  }


}
