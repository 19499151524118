import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

// Routes
import { APP_ROUTES } from './app.routes';

// Modules
import { PagesModule } from './pages/pages.module';

// Servicios
import { ServiceModule } from "./services/service.module";

// Components
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { PagesComponent } from './pages.component';
import { DatePipe } from '@angular/common';
import { AppointmentComponent } from './landing/appointment/appointment.component';

// Temporal
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


 // importar locales
 import localeEs from '@angular/common/locales/es-MX';
import { registerLocaleData } from '@angular/common';
import { LandingModule } from './landing/landing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from "ngx-spinner";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ChangePasswordComponent } from './change-password/change-password.component';

 registerLocaleData(localeEs);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PagesComponent,
    ChangePasswordComponent
  ],
  imports: [
    BrowserModule,
    APP_ROUTES,
    PagesModule,
    ServiceModule,
    FormsModule,
    LandingModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    FontAwesomeModule,
    ReactiveFormsModule
  ],
  providers: [ { provide: LOCALE_ID, useValue: 'es-MX' } ,DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
