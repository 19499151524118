import { user } from "./../models/authorization.model";
import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { AuthorizationService } from "../services/service.index";
import { login_request } from "../models/authorization.model";
import { Router, ActivatedRoute } from "@angular/router";
import Swal from 'sweetalert2/dist/sweetalert2.js';  

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styles: []
})
export class LoginComponent implements OnInit {
  returnUrl: string;
  errorMessage: string;
  constructor(public _auth: AuthorizationService, public _router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
    // console.log('Versión:1.9.1');
    this.returnUrl = this.route.snapshot.queryParams['return'];// || '/';
  }

  ingresar(forma: NgForm) {
    if (forma.invalid) {
      return;
    }

    let usuario: login_request = {
      username: forma.value.email,
      password: forma.value.password,
      rememberme: forma.value.rememeberme
    };


    this._auth
      .login(usuario)
      .subscribe(resp => {

        this.returnUrl = this.route.snapshot.queryParams['return'];// || '/';

        if (this.returnUrl) this._router.navigateByUrl(this.returnUrl);
        else   this._router.navigateByUrl("/dashboard"); 
       },
       err =>{ 
         if (err.status==401) this.errorMessage = "Usuario o contraseña incorrecta";
         else this.errorMessage = "No se ha podido conectar con el servidor. Comprueba tu conexion a internet y vuelve a intentarlo";
       }
     );
  }

  forgotPassword(){
    Swal.fire({
      title: 'Ingrese su email',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      showLoaderOnConfirm: true,
      preConfirm: async (email:String) => {
        //validate form before send.
        email = email.trim();
        if(email.length == 0){
          Swal.showValidationMessage(
            `Escriba su correo electrónico`
          )
          return;
        }else if(!this.validateEmail(email)){
          Swal.showValidationMessage(
            `Escriba un correo electrónico válido`
          )
          return;
        }else{
          await this._auth.forgotPassword(email)
          .then(response => {
            // console.log(`response:${JSON.stringify(response)}`);
            // console.log(`code:${response.status}`);
            
            if (!response.ok) {
              throw new Error(response.statusText)
            }
            
            return response;
            
          })
          .catch(error => {
            // console.log(`error:${JSON.stringify(error)}`);
            // console.log(`error:${error['error']['error']}`);
            Swal.showValidationMessage(
              `Error: ${error['error']['error']}`
            )
          })
        }                
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.dismiss == 'cancel') {
        // console.log('cancel');
      }else if(result.dismiss == 'overlay'){
        // console.log('cancel');
      }else{
        Swal.fire({
          title: `Las instrucciones fueron enviadas a su correo: ${result.value}`
        })
      }     
    })
  }

  validateEmail(email:String):boolean {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
}
