<app-buttons (button_selected)="option_selector($event)"></app-buttons>
 
<app-grid  [rowSelection]="'single'" [rowData]="rowData" [columnDefs]="columnDefs" [show_filter]= true [server_filter] = true (on_row_text_changed)="onFilterTextBoxChanged($event)" ></app-grid>



<!-- Ventana modal para creación y actualización de usuarios -->
<ng-template #content let-modal>
  <div class="modal-header">
      <h4 class="modal-title" *ngIf="!paciente.paciente_id">Nuevo Paciente</h4>
      <h4 class="modal-title" *ngIf="paciente.paciente_id">{{paciente.nombre_completo}} ( {{paciente.numero_registro}} ) </h4>
      <h4 class="modal-title" *ngIf="paciente.paciente_id">Actualizar Paciente</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body p-0">
      <div class="card m-b-0">
          <app-expediente [paciente]="paciente"></app-expediente>
      </div>
  </div>
</ng-template>



