import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { referencia,paciente } from '../../models/paciente.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormsService, PacienteService, CatalogService, AddressService } from 'src/app/services/service.index';
import { GridComponent } from 'src/app/shared/components/grid.component';
import { DatePipe } from '@angular/common'
import { formatDate } from '@angular/common';7
import { Inject, LOCALE_ID } from '@angular/core';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-area-estudios',
  templateUrl: './area-estudios.component.html',
  styles: []
})
export class AreaEstudiosComponent implements OnInit {
  columnDefs = [
    {  width:200,headerName: "Fecha de consulta", field: "fecha_inicio_programada",cellRenderer: (data) => {
      return  formatDate(data.value, 'dd MMM yyyy', this.locale)
      }},
    { headerName: "Médico", field: "medico" },
    { headerName: "Codigo", field: "estudio_cod" },
    { headerName: "Estudio", field: "estudio" },
    { headerName: "Grupo", field: "grupo" },
    {headerName: "Observaciones", field: "observaciones" }
  ];
  rowData: any = [];
  @Input() paciente: paciente = new paciente({});
  @ViewChild("grid1", { static: true }) grid1: GridComponent;

  constructor(@Inject(LOCALE_ID) private locale: string,public datepipe: DatePipe,private _toastr: ToastrService,private _fb: FormBuilder, public _frm: FormsService, public _paciente: PacienteService, public _cat: CatalogService, public _adr: AddressService) { }

  ngOnInit() {
    if(this.paciente.paciente_id != null){
      this._paciente.get_estudios(this.paciente.paciente_id).subscribe(data => this.rowData = data, error => console.log(error));
    }else{

    }
  }

}
