import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { user, role } from '../../models/authorization.model';
import { MustMatch } from '../helpers/must-match.validator';
import { UserService } from 'src/app/services/service.index';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styles: []
})
export class AccountComponent implements OnInit {
  @Output() submit_form = new EventEmitter<FormGroup>();
  @Output() close_modal = new EventEmitter();
  @Input() user: user;
  @Input() show_cancel_button: boolean = false;
  @ViewChild("form", { static: true }) form;

  user_form: FormGroup;
  roles: any;
  constructor(public _usrs: UserService,private _fb: FormBuilder) { }

  ngOnInit() {
    this.user_form = this._fb.group({
      user_id: [this.user.user_id],
      role_id: [this.user.role_id, [Validators.required]],
      username: [this.user.username, [Validators.required, Validators.email]],
      password: [this.user.password_hash, [Validators.required]],
      confirm_password: [this.user.password_hash, [Validators.required]]
    }, {
        validator: MustMatch('password', 'confirm_password')
      });
      this._usrs.get_roles(new role({ is_active: true })).subscribe(
        data => {
          this.roles = data.body;
        },
        err => console.log(err)
      );
  }
  is_required(control: string): boolean {
    var _validator: any = this.user_form.controls[control].validator && this.user_form.controls[control].validator(this.user_form.controls[control]);
    return _validator && _validator.required;
  }
  is_invalid(control: string): boolean {
    return (this.form.submitted && this.user_form.controls[control].invalid) || (this.user_form.controls[control].invalid && (this.user_form.controls[control].dirty || this.user_form.controls[control].touched))
  }
  change_role($event) {
  }

  on_close() {
    this.close_modal.emit();
  }
  on_submit() {
    console.log('AccountComponent');
    this.submit_form.emit(this.user_form);
  }
}
