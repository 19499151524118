<header class="topbar">
    <nav class="navbar top-navbar navbar-expand-md navbar-light">

        <div class="navbar-header">
            <a class="navbar-brand" href="index.html">
                <b>
                    <img src="assets/images/sw.png" alt="homepage" class="dark-logo m-r-10" style="width: 30px; height: 30px;" />
                    <img src="assets/images/sw.png" alt="homepage" class="light-logo m-r-10" style="width: 30px; height: 30px;" />
                </b>
                <span>
                 <img src="assets/images/swplus.png" alt="homepage" class="dark-logo" />  
                 <img src="assets/images/swplus.png" class="light-logo" alt="homepage" /></span>
            </a>
        </div>
        <div class="navbar-collapse">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item"> <a class="nav-link nav-toggler hidden-md-up waves-effect waves-dark" href="javascript:void(0)"><i class="mdi mdi-menu"></i></a> </li>
                <li class="nav-item"> <a class="nav-link sidebartoggler hidden-sm-down waves-effect waves-dark" href="javascript:void(0)"><i class="mdi mdi-menu"></i></a> </li>
                <li class="nav-item hidden-sm-down"></li>
            </ul>
            <ul class="navbar-nav my-lg-0">
                <!-- <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle waves-effect waves-dark" href="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i class="mdi mdi-message"></i>
                        <div class="notify"> <span class="heartbit"></span> <span class="point"></span> </div>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right mailbox animated fadeIn">
                        <ul>
                            <li>
                                <div class="drop-title">Notificaciones</div>
                            </li>
                            <li>
                                <div class="message-center">
                                    <a href="#">
                                        <div class="btn btn-danger btn-circle"><i class="mdi mdi-link"></i></div>
                                        <div class="mail-contnet m-l-10">
                                            <h6>Luanch Admin</h6> <span class="mail-desc">Just see the my new admin!</span> <span class="time">9:30 AM</span> </div>
                                     </a>
                                </div>
                            </li>
                            <li>
                                <a class="nav-link text-center" style="font-size: 14px;" href="javascript:void(0);"> <strong>Ver todas las notificaciones</strong> <i class="mdi mdi-chevron-right"></i> </a>
                            </li>
                        </ul>
                    </div>
                </li> -->
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle waves-effect waves-dark" href="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img [src]="_auth.user.person.photo" alt="user" class="profile-pic" /></a>
                    <div class="dropdown-menu dropdown-menu-right animated fadeIn">
                        <ul class="dropdown-user">
                            <li>
                                <div class="dw-user-box">
                                    <div class="u-img"><img [src]="_auth.user.person.photo" alt="user"></div>
                                    <div class="u-text">
                                        <h4>{{_auth.user.person.name + ' ' + _auth.user.person.last_name}}</h4>
                                        <p class="text-muted">{{_auth.user.person.email}}</p><a routerLinkActive="active" [routerLink]="['/profile']" class="btn btn-rounded btn-danger btn-sm">Ver perfil</a></div>
                                </div>
                            </li>
                            <li role="separator" class="divider"></li>
                            <li><a routerLinkActive="active" [routerLink]="['/profile']"><i class="ti-user"></i> Mi perfil</a></li>
                            <!-- <li><a routerLinkActive="active" [routerLink]="['/users']"><i class="ti-wallet"></i> Cambiar contraseña</a></li>
                            <li role="separator" class="divider"></li>
                            <li ><a routerLinkActive="active" [routerLink]="['/users']"><i class="ti-settings"></i> Configuración</a></li> -->
                            <li role="separator" class="divider"></li>
                            <li><a (click)="logout()" style="cursor: pointer"><i class="mdi mdi-power"></i> Logout</a></li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</header>
