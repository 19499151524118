import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { moduleX } from '../../models/authorization.model';
import { FormsService } from 'src/app/services/service.index';
import { tipo_cosulta } from 'src/app/models/consulta.model';


@Component({
  selector: 'app-tipo-consulta-dialog',
  templateUrl: './tipo-consulta-dialog.component.html',
  styles: []
})
export class TipoConsultaDialogComponent implements OnInit {
  @ViewChild("form", { static: true }) form;
  @Output() submit_form = new EventEmitter<FormGroup>();
  @Output() close_modal = new EventEmitter();
  @Input()  show_cancel_button: boolean = false;
  @Input()  tipo_consulta: tipo_cosulta;

  options: any = [{label:'Si', value:true}, {label:'No', value:false}];

  tipo_consulta_form: FormGroup;
  constructor(private _fb: FormBuilder, public _frm:FormsService) { }

  ngOnInit() {
    this.tipo_consulta_form = this._fb.group({
      tipo_id: [this.tipo_consulta.tipo_id],
      nombre: [this.tipo_consulta.nombre,[Validators.required]],
      duracion: [this.tipo_consulta.duracion],
      activo: [this.tipo_consulta.activo],
    });
  }

  
  on_submit() {
    this.submit_form.emit(this.tipo_consulta_form);
  }
  on_close() {
    this.close_modal.emit();
  }
  

}
