import { Component, OnInit,ViewChild } from '@angular/core';
import { CatalogService, MedicoService, PacienteService,FormsService } from 'src/app/services/service.index';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GridComponent } from 'src/app/shared/components/grid.component';
import { prestamo,paciente } from 'src/app/models/paciente.model';
import { ToastrService } from 'ngx-toastr';
import { FormGroup } from '@angular/forms';
import { Inject, LOCALE_ID } from '@angular/core';
import { formatDate } from '@angular/common';
import {NgbDate, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
declare var sw: any; 

@Component({
  selector: 'app-devoluciones',
  templateUrl: './devoluciones.component.html',
  styles: [`
  .custom-day {
    text-align: center;
    padding: 0.185rem 0.25rem;
    display: inline-block;
    height: 2rem;
    width: 2rem;
  }
  .custom-day.focused {
    background-color: #e6e6e6;
  }
  .custom-day.range, .custom-day:hover {
    background-color: rgb(2, 117, 216);
    color: white;
  }
  .custom-day.faded {
    background-color: rgba(2, 117, 216, 0.5);
  }
`]
  
})

export class DevolucionesComponent implements OnInit {
  @ViewChild("modal", { static: true }) modal;
  @ViewChild("grid1", { static: true }) grid1: GridComponent;
  @ViewChild("content", { static: true }) content;

  public paciente:string;
  public fecha_inicio:Date;
  public fecha_fin:Date;
  public especialidad_id:string; 
  public bandVentana:boolean =false;
  especialidades: any;
  arr:any=[];
  paciente2: paciente = new paciente({});
  _prestamo:prestamo = new prestamo({});
  rowData: any = [];
  columnDefs = [
    { headerName: "No Registro", field: "numero_registro", checkboxSelection: true, headerCheckboxSelection: true  },
    { headerName: "Paciente", field: "paciente" },
    { headerName: "Area", field: "area" },
    { headerName: "Fecha de prestamo", field: "fecha_prestamo",cellRenderer: (data) => {
      return  formatDate(data.value, 'dd MMM yyyy', this.locale)
      }},
    { headerName: "Personal que entregó", field: "personal_entrega" },
    { headerName: "Personal que recibió", field: "personal_recibe" },
    { headerName: "Fecha devolución programada", field: "fecha_devolucion_programada",cellRenderer: (data) => {
      return  formatDate(data.value, 'dd MMM yyyy', this.locale)
      }}
  ];
  hoveredDate: NgbDate;
  fromDate: NgbDate;
  toDate: NgbDate;
  constructor(calendar: NgbCalendar,@Inject(LOCALE_ID) private locale: string,public _cat: PacienteService,public _cat2: CatalogService, public _medico:MedicoService, private modalService: NgbModal, public _frm: FormsService, private _toastr: ToastrService,public _paciente: PacienteService) { 
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
  }
  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    console.log(date)
  }
  ngOnInit() {
    this._cat2.get_catalog("area", true).subscribe(data => this.especialidades = data, error => console.log(error)); 
  }
  option_selector(button: string) {
    console.log(button);
    
    switch (button) {
      case "expediente": {   
        const selecteds = this.grid1.getSelectedRows();

        if (selecteds.length == 0) this._toastr.warning("Seleccione un paciente del listado", "Expediente de paciente")
        else {
          if(selecteds.length > 1){
            this._toastr.warning("Seleccione solo un paciente del listado", "Expediente de paciente")
          }else{
          
          let selected = selecteds[0];
          this._paciente.get_paciente(selected.paciente_id).subscribe(
            (data: any) => {
              this.paciente2 = data;
              this.modalService.open(this.content, { backdrop: 'static', windowClass: "xlg" });
              sw.common.init_plugins();
            },
            err => console.log(err)
          );
          }
        }
        break;
      }
      case "devolver": {
        this.dispara_modal();
        break;
      }
    
    }
  }
  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }

  change_valores() {
    this._cat.prestamo_search(this.paciente,this.fecha_inicio,this.fecha_fin,this.especialidad_id,"0").subscribe(data => this.rowData = data, error => console.log(error));
  }

  dispara_modal(){
    const selected = this.grid1.getSelectedRows();
   
      if (selected.length == 0) this._toastr.warning("Seleccione por lo menos algun expediente", "Prestamo de expedientes")
      else {    
        this._prestamo = new prestamo({});
        this._prestamo = selected[0];
        this.modalService.open(this.modal, { size: 'lg' });
      }   
  }

  save_prestamo(form: FormGroup) {
    const selected= this.grid1.getSelectedRows();
    selected.forEach(element => {
      let clone = Object.assign({}, form.value);
      clone.prestamo_id = element.prestamo_id;
      this.arr.push(clone);
    });
    this._cat.update_prestamo(this.arr).subscribe(
      (res: any) =>
      { this._frm.toast_alert("Devoluciones creadas exitosamente", "Nueva prestamo", "success");
        this.modalService.dismissAll(null);
        this._cat.prestamo_search('',null,null,null,null).subscribe(data => this.rowData = data, error => console.log(error));
        this.arr=[];
      },
      error => this._frm.toast_alert("El prestamo no pudo ser creado correctamente. Error: " + error.error.message, "Nuevo Prestamo", "error")
    ); 
 }
}
