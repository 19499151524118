<div class="card-body p-b-0">
  <form class="form-material" [formGroup]="paciente_form" #form="ngForm" novalidate (ngSubmit)="guardar()">
    <div class="row">
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-12">
            <h5 class="modal-title text-right">Responsable</h5>
          </div>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('nombre', paciente_form, form.submitted)}">
                  <label for="txtNombre" [ngClass]="{ 'star_symbol': _frm.is_required('nombre', paciente_form) }">Nombre(s)</label>
                  <input type="text" placeholder="Nombre" class="form-control" id="txtNombre" formControlName="nombre">
                  <div class="invalid-feedback">
                    <div *ngIf="paciente_form.controls['nombre'].errors?.required">Ingrese el nombre del paciente</div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('paterno', paciente_form, form.submitted)}">
                  <label for="txtpaterno"  [ngClass]="{ 'star_symbol': _frm.is_required('paterno', paciente_form) }">Apellido Paterno</label>
                  <input type="text" placeholder="Apellido Paterno" class="form-control" id="txtpaterno"
                    formControlName="paterno">
                  <div class="invalid-feedback">
                    <div *ngIf="paciente_form.controls['paterno'].errors?.required">Ingrese el apellido paterno del
                      paciente</div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="txtmaterno">Apellido Materno</label>
                  <input type="text" placeholder="Apellido Materno" class="form-control" id="txtmaterno"
                    formControlName="materno">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="txtnacimiento">Fecha de nacimiento</label>
                  <input class="form-control" placeholder="Fecha de nacimiento" id="txtnacimiento"
                    formControlName="fecha_nacimiento" ngbDatepicker #d="ngbDatepicker" (focus)="d.toggle()"
                    readonly="readonly" [minDate]="{year: 1900, month: 1, day: 1}" [maxDate]="fechaHoy">
                  <i class="mdi mdi-calendar"></i>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('parentesco_id', paciente_form, form.submitted)}">
                  <label for="parentesco_id" [ngClass]="{ 'star_symbol': _frm.is_required('parentesco_id', paciente_form) }">Parentesco</label>
                  <ng-select  placeholder="Parentesco"  formControlName="parentesco_id" class="form-control"  
                    [items]="parentesco" bindLabel="nombre" bindValue="id">
                  </ng-select>
                  <div class="invalid-feedback">
                    <div *ngIf="paciente_form.controls['parentesco_id'].errors?.required">Ingrese el parentesco</div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>


        <div class="row">
          <div class="col-md-12  m-t-20">
            <h5 class="modal-title text-right">Contacto</h5>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label for="txtemail">Correo electrónico</label>
              <input type="email" placeholder="Correo electrónico" class="form-control" id="txtemail"
                formControlName="correo">
              <i class="mdi mdi-at"></i>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="txttelefonocasa">Teléfono fijo</label>
              <input type="text" placeholder="Teléfono" class="form-control" id="txttelefonocasa"
                formControlName="telefono_fijo">
              <i class="mdi mdi-phone"></i>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="txttelefonomovil">Teléfono móvil</label>
              <input type="text" placeholder="Teléfono" class="form-control" id="txttelefonomovil"
                formControlName="telefono_movil">
              <i class="mdi mdi-cellphone"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">

        <div class="row" formGroupName="domicilio">
          <div class="col-md-12">
            <h5 class="modal-title text-right">Domicilio</h5>
          </div>
          <div class="col-md-4">
            <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalidChild('postal_code','domicilio', paciente_form, form.submitted)}" >
              <label for="txtcp" [ngClass]="{ 'star_symbol': _frm.is_requiredChild('postal_code','domicilio', paciente_form) }">Código Postal</label>
              <input type="text" placeholder="Código Postal" class="form-control" id="txtcp"
                formControlName="postal_code" (blur)="change_postalcode($event.target.value)">
                <div class="invalid-feedback">
                  <div *ngIf="fg.controls['postal_code'].errors?.required">Ingrese el código postal</div>
                </div>
            </div>
          </div>
          <div class="col-md-8">
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="state_id">Estado</label>
              <ng-select [searchable]="true" placeholder="Estado" formControlName="state_id" class="form-control"  (change)="change_state($event)"
                [items]="states" bindLabel="name" bindValue="state_id">
              </ng-select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="municipality_id">Delegación/Municipio</label>
              <ng-select [searchable]="true" placeholder="Delegación/Municipio" formControlName="municipality_id" (change)="change_municipality($event)"
                class="form-control" [items]="municipalities" bindLabel="name" bindValue="municipality_id">
              </ng-select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="settlement">Colonia</label>
              <ng-select [searchable]="true" placeholder="Colonia" formControlName="settlement" class="form-control" (change)="change_settlement($event)"
                [items]="settlements" bindLabel="name" bindValue="name">
              </ng-select>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label for="txtstreet">Calle</label>
              <input type="email" placeholder="Calle" class="form-control" id="txtstreet" formControlName="street">
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="txtnumext">Número Exterior</label>
              <input type="text" placeholder="Número Exterior" class="form-control" id="txtnumext"
                formControlName="exterior_number">
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="txtnumint">Número Interior</label>
              <input type="text" placeholder="Número Interior" class="form-control" id="txtnumint"
                formControlName="interior_number">
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label for="txtref">Referencia</label>
              <input type="text" placeholder="Referencia" class="form-control" id="txtref" formControlName="reference">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row m-t-30">
      <div class="col-md-12 modal-footer">
        <button type="submit" class="btn btn-primary btn-sm "><i class="mdi mdi-content-save"></i> Guardar</button>
      </div>
    </div>
  </form>
</div>