
<div class="card-body p-b-0">
  <div class="row">
    <div class="col-md-12">
      <ag-grid-angular
      #agGgrid1rid
      style=" height: 700px;"
      id="myGrid"
      class="ag-theme-balham"
      [columnDefs]="columnDefs" 
      [masterDetail]="true"
      [detailCellRendererParams]="detailCellRendererParams"
      [rowData]="rowData" 
      (gridReady)="onGridReady($event)"
    ></ag-grid-angular>
    </div>
  </div>
</div>
