import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { moduleX } from '../../models/authorization.model';
import { FormsService } from 'src/app/services/service.index';

@Component({
  selector: 'app-module-dialog',
  templateUrl: './module-dialog.component.html',
  styles: []
})
export class ModuleDialogComponent implements OnInit {
  @ViewChild("form", { static: true }) form;
  @Output() submit_form = new EventEmitter<FormGroup>();
  @Output() close_modal = new EventEmitter();
  @Input()  show_cancel_button: boolean = false;
  @Input()  module: moduleX;

  options: any = [{label:'Si', value:true}, {label:'No', value:false}];

  module_form: FormGroup;

  constructor(private _fb: FormBuilder, public _frm:FormsService) { }

  ngOnInit() {
    this.module_form = this._fb.group({
      module_id: [this.module.module_id],
      name: [this.module.name,[Validators.required]],
      title: [this.module.title,[Validators.required]],
      subtitle: [this.module.subtitle],
      description: [this.module.description],
      route: [this.module.route],
      parameters: [this.module.parameters],
      css_class: [this.module.css_class],
      is_active: [this.module.is_active],
      is_visible: [this.module.is_visible],
      display_order: [this.module.display_order],
      parent_module_id: [this.module.parent_module_id]
    });
  }

  on_submit() {
    this.submit_form.emit(this.module_form);
  }
  on_close() {
    this.close_modal.emit();
  }
  

}
