<div class="landing">

    <header>
        <app-header></app-header>
        <!-- Background image -->
        <div class="text-center bg-image hero">
            <!-- <div class="mask" style="background-color: rgba(0, 0, 0, 0.6);">
                <div class="d-flex justify-content-center align-items-center h-100">
                    <div class="text-white">
                        <h1 class="mb-3">Heading</h1>
                        <h4 class="mb-3">Subheading</h4>
                        <a class="btn btn-outline-light btn-lg" href="#!" role="button">Call to action</a>
                    </div>
                </div>
            </div> -->
        </div>
        <!-- Background image -->
    </header>



    <div class="about_us container" id="about_us">
        <div class="row">
            <div class="col-md-4 text">
                <h2>Sobre Nosotros</h2>
                <p>Somos un equipo médico dedicado a ofrecer atención de primer nivel con consultas médicas accesibles; poniendo a disposición la opción de interactuar mediante tecnología móvil con tu salud. Realizar tus citas por vía remota, conocer tus registros médicos generados en cada visita, solicitar algún servicio general (peso, toma de presión arterial, etc.) o solicitar algún documento médico (certificados médicos).</p>
                <p>En Interamed Larcam buscamos honrar tu vida brindando atención médica eficiente, con calidez, cercanía y eficacia. Somos un espacio agradable, moderno y al alcance de tus posibilidades, porque la salud es un derecho de todos.</p>
            </div>
            <div class="col-md-8 picture">
                
            </div>
        </div>
    </div>

    <div class="services container" id="services">
        <h2>Servicios</h2>
        <div class="row">
            <div class="col-md-3">
                <img class="img-fluid" src="../../assets/images/Imagenes-03.png" alt="">
                <p>Consulta de medicina general y seguimiento. <br>
                    Diagnóstico y tratamiento primario para enfermedades como diabetes mellitus, hipertensión arterial, síndrome metabólico.
                    </p>
            </div>
            <div class="col-md-3">
                <img class="img-fluid" src="../../assets/images/Imagenes-04.png" alt="">
                <p>Detección de factores de riesgo para enfermedades crónico-degenerativas. <br>
                    Antropometría: toma de signos vitales, pesa y talla, índice de masa corporal.
                    </p>
            </div>
            <div class="col-md-3">
                <img class="img-fluid" src="../../assets/images/Imagenes-05.png" alt="">
                <p>Servicios: aplicación de medicamentos, inyecciones, certificados médicos, toma de glucosa en sangre. <br>
                    Referencia optimizada a segundo y tercer nivel de salud.
                    </p>
            </div>
            <div class="col-md-3">
                <img class="img-fluid" src="../../assets/images/Imagenes-06.png" alt="">
                <p>A través de nuestra aplicación desde tu celular podrás revisar tus registros médicos generados en cada cita médica.</p>
            </div>
        </div>
    </div>


    <router-outlet></router-outlet>
    <app-footer></app-footer>
</div>