import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { referencia,paciente } from '../../models/paciente.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormsService, PacienteService, CatalogService, AddressService } from 'src/app/services/service.index';
import { GridComponent } from 'src/app/shared/components/grid.component';
import { DatePipe } from '@angular/common'
import { formatDate } from '@angular/common';7
import { Inject, LOCALE_ID } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { DynamicFormsBack, Field } from 'src/app/models/dynamic_forms_back.model';
import { ConsultaExploracion } from 'src/app/models/consulta_exploracion.model';

export class NConsultas {
  consulta_id?:             number;
  fecha_inicio_programada?: null;
  fecha_fin_programada?:    Date;
  fecha?:                   Date;
  fecha_inicio_real?:       null;
  fecha_fin_real?:          null;
  medico_id?:               number;
  paciente_id?:             number;
  nombre_paciente?:         null;
  numero_registro?:         string;
  motivo?:                  null;
  nota_medica?:             null;
  externa?:                 null;
  especialidad_id?:         number;
  especialidad?:            string;
  estatus_id?:              number;
  estatus?:                 string;
  ubicacion?:               null;
  fecha_fin_exploracion?:   null;
  causes_id?:               null;
  cause?:                   null;
  tipo_id?:                 number;
  tipo?:                    string;
  saved_by?:                null;
  medico?:                  string;
  duracion?:                number;
  paciente?:                string;
  modalidad_id?:            null;
  link_medico?:             null;
  link_paciente?:           null;
  exploracion_fisica?:      null | string;
  procedimientos?:          null;
  medicamentos?:            null;
  medicamentos2?:           null;
  diagnosticos?:            null;
  estudios?:                null;
  exploracion?:             null;
  exp?:                     DynamicFormsBack;
}

@Component({
  selector: 'app-area-consultas',
  templateUrl: './area-consultas.component.html',
  styles: []
})

export class AreaConsultasComponent implements OnInit {

  public gridApi;
  public gridColumnApi;
  public detailCellRendererParams;
  public rowData: any = [];
  public columnDefs;
  /*public columnDefs = [
    { width:200,headerName: "Fecha de consulta", field: "fecha",cellRenderer: (data) => {
      return  formatDate(data.value, 'dd MMM yyyy', this.locale)
      }},
    { headerName: "Médico", field: "medico" },
    { headerName: "Especialidad", field: "especialidad" },
    { headerName: "Estado", field: "estatus" },
    {headerName: "Tipo", field: "tipo" }
  ];*/

  @Input() paciente: paciente = new paciente({});
  @ViewChild("grid1") grid1: GridComponent;


  constructor(private http: HttpClient, @Inject(LOCALE_ID) private locale: string,public datepipe: DatePipe,private _toastr: ToastrService,private _fb: FormBuilder, public _frm: FormsService, public _paciente: PacienteService, public _cat: CatalogService, public _adr: AddressService) { 
    this.columnDefs = [
      {
        headerName: "Médico",
        field: "medico",
        cellRenderer: "agGroupCellRenderer",
        with: "50px"
        
      },
      { 
        headerName: "Fecha de consulta", 
        field: "fecha",
        cellRenderer: (data) => {
          return  formatDate(data.value, 'dd MMM yyyy', this.locale)
        }
      },      
      {
        headerName: "Motivo", 
        field: "motivo" 
      },
      {
        headerName: "Nota Médica", 
        field: "nota_medica" 
      },
      { 
        headerName: "Especialidad", 
        field: "especialidad" 
      },
      { 
        headerName: "Estado", 
        field: "estatus" 
      },
      {
        headerName: "Tipo", 
        field: "tipo" 
      }

    ];
    
    this.detailCellRendererParams = {
      detailGridOptions: {
        columnDefs: [
          { headerName: "Exploracion Física",field: "name" },
          { headerName: "Valor",field: "value" },
          { headerName: "Medida",field: "placeholder" }
        ],
        onFirstDataRendered(params) {
       
        }
      },
      getDetailRowData: function(params) {
        // console.log(params.data)
        params.successCallback(params.data.exp.fields);
      }
    };
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this._paciente.get_consultas(this.paciente.paciente_id).subscribe(
      (data) => {
        // console.log(data);
        
        var dataNew:NConsultas[] = data as NConsultas[];

        dataNew.forEach((valueData) =>{
          if(valueData["exploracion_fisica"] == null){
            var exp = new DynamicFormsBack;
            exp.fields = [];
            //console.log(`exp:${JSON.stringify(exp)}`);
            valueData.exp = new DynamicFormsBack;
            valueData.exp = exp;
          }else{
            var exp = this.transformEF(valueData["exploracion_fisica"]);
            valueData.exp = new DynamicFormsBack;
            console.log(`exp:${JSON.stringify(exp)}`);
            valueData.exp = exp;
          }
        });
        
        // console.log(`dataNew${JSON.stringify(dataNew)}`)
        this.rowData = dataNew;
      },
      (error) => console.log(error)
    );

    setTimeout(function() {
      var rowCount = 0;
      params.api.forEachNode(function(node) {
        node.setExpanded(rowCount++ === 1);
      });
    }, 500);
    /*
    this.http
      .get(
        environment.apiEndpoint + "Paciente/recetas_get?paciente_id=" + this.paciente.paciente_id
      )
      .subscribe(data => {
        this.rowData = data;
      });
      
    setTimeout(function() {
      var rowCount = 0;
      params.api.forEachNode(function(node) {
        node.setExpanded(rowCount++ === 1);
      });
    }, 500);
    */

    this.autoSizeAll();

  }

  ngOnInit() {
    if(this.paciente.paciente_id != null){
      //this._paciente.get_consultas(this.paciente.paciente_id).subscribe(data => this.rowData = data, error => console.log(error));
    }else{
  
   }
  }

  transformEF(expFisica:string):DynamicFormsBack{
    var tmpExpFisica = expFisica.replace('\\','');
    var exp:DynamicFormsBack = JSON.parse(tmpExpFisica);
    return exp;
  }
  


  autoSizeAll() {
    var allColumnIds = [];
    this.gridColumnApi.getAllColumns().forEach(function(column) {
      allColumnIds.push(column.colId);
    });
    this.gridColumnApi.autoSizeColumns(allColumnIds);
  }



}
