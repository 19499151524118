import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { SharedModule } from "../shared/shared.module";
import { PagesComponent } from "./pages.component";

// Routes 

import { PAGES_ROUTES } from "./pages.routes";

// Components
import { AgGridModule } from "ag-grid-angular";
import "ag-grid-enterprise";
import { ToastrModule } from "ngx-toastr";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {NgbModule, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';

// Pages
import { DashboardComponent } from "./dashboard/dashboard.component";
import { UsersComponent } from "./settings/users.component";
import { ProfileComponent } from './settings/profile.component';
import { RolesComponent } from './settings/roles.component';
import { ExpedienteComponent } from "./paciente/expediente.component";
import { AreaGeneralComponent } from './paciente/area-general.component';
import { TipoConsultaComponent } from "./catalog/tipo-consulta.component";
import { AgendarComponent } from './consulta/agendar.component';
import { EspecialidadesComponent } from './catalog/especialidades.component';
import { AreasComponent } from './catalog/areas.component';
//Error Pages
import { Error404Component } from "./errors/error404.component";
import { Error403Component } from "./errors/error403.component";


import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RolesDialogComponent } from './settings/roles-dialog.component';
import { ModuleComponent } from './settings/module.component';
import { ModuleDialogComponent } from './settings/module-dialog.component';
import { PermissionsComponent } from './settings/permissions.component';
import { ModulePermissionsDialogComponent } from './settings/module-permissions-dialog.component';
import { AuthorizationDialogComponent } from './settings/authorization-dialog.component';
import { PacienteComponent } from './paciente/paciente.component';
import { CalendarioComponent } from './consulta/calendario.component';
import { AreaResponsableComponent } from './paciente/area-responsable.component';
import { AgendaDialogComponent } from './consulta/agenda-dialog.component';
import { EspecialidadDialogComponent } from './catalog/especialidad-dialog.component';
import { BuscaPacienteComponent } from './consulta/busca-paciente.component';
import { TipoConsultaDialogComponent } from './catalog/tipo-consulta-dialog.component';
import { ConsultaDialogComponent } from './consulta/consulta-dialog.component';
import { CamasComponent } from './catalog/camas.component';
import { CamasDialogComponent } from './catalog/camas-dialog.component';
import { AreasDialogComponent } from './catalog/areas-dialog.component';
import { TipoDocumentoComponent } from './catalog/tipo-documento.component';
import { TipoDocumentoDialogComponent } from './catalog/tipo-documento-dialog.component';
import { PrestamosComponent } from './prestamo/prestamos.component';
import { DevolucionesComponent } from './prestamo/devoluciones.component';
import { PrestamosDialogComponent } from './prestamo/prestamos-dialog.component';
import { DevolucionesDialogComponent } from './prestamo/devoluciones-dialog.component';
import { AreaDocumentosComponent } from './paciente/area-documentos.component';
import { AreaAntecedentesComponent } from './paciente/area-antecedentes.component';
import { AreaConsultasComponent } from './paciente/area-consultas.component';
import { AreaRecetasComponent } from './paciente/area-recetas.component';
import { AreaEstudiosComponent } from './paciente/area-estudios.component';
import { AreaDiagnosticosComponent } from './paciente/area-diagnosticos.component';
import { AreaOrdenesComponent } from './paciente/area-ordenes.component';
import { AreaReferenciasComponent } from './paciente/area-referencias.component';
import { HorarioDialogComponent } from './settings/horario-dialog.component';
import { IonRangeSliderModule } from "ng2-ion-range-slider";
import { AreaProcedimientosComponent } from './paciente/area-procedimientos.component';
import { MedicamentosComponent } from './catalog/medicamentos.component';
import { DiagnosticosComponent } from './catalog/diagnosticos.component';
import { ProcedimientosComponent } from './catalog/procedimientos.component';
import { EstudiosComponent } from './catalog/estudios.component';
import { ProcedimientosDialogComponent } from './catalog/procedimientos-dialog.component';
import { EstudiosDialogComponent } from './catalog/estudios-dialog.component';
import { MedicamentosDialogComponent } from './catalog/medicamentos-dialog.component';
import { DiagnosticosDialogComponent } from './catalog/diagnosticos-dialog.component';
import { ShareScheduleDialogComponent } from './settings/share-schedule-dialog.component';
import { ExploracionDialogComponent } from './consulta/exploracion-dialog.component';
import { MaterialDesignFrameworkModule } from '@ajsf/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FlexLayoutType } from './flex-layout.type';
import { RepPacientesComponent } from './reports/rep-pacientes.component';
import { ResetPasswordComponent } from './settings/reset-password/reset-password.component';


import { ArticulosComponent } from './articulos/articulos.component';
import { ArticulosDialogComponent } from './articulos/articulos-dialog.component';
import { AreaMedicionesComponent } from './paciente/area-mediciones.component';
// import { NgxChartsModule } from "@swimlane/ngx-charts";
import { CuestionariosComponent } from './consulta/cuestionarios.component';
import { QRCodeModule } from "angular2-qrcode";
import { ConsultoriosComponent } from './settings/consultorios.component';
import { ConsultoriosDialogComponent } from './settings/consultorios-dialog.component';
import { ConsultoriosHorarioComponent } from './settings/consultorios-horario.component';
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { ServiciosComponent } from './servicios/servicios.component';
import { ServiciosDialogComponent } from './servicios/servicios-dialog.component';
import { RepServiciosComponent } from './reports/rep-servicios.component';
import { RepConsultasComponent } from './reports/rep-consultas.component';
import { CertificadoComponent } from './consulta/certificado.component';
import { BtnCellRenderer } from "./consulta/btn-cell-renderer";



@NgModule({
  declarations: [    
    Error404Component,
    Error403Component,
    DashboardComponent, 
    PagesComponent, 
    UsersComponent, 
    ProfileComponent, 
    RolesComponent, 
    RolesDialogComponent, 
    ExpedienteComponent,
    ModuleComponent, 
    ModuleDialogComponent, 
    PermissionsComponent, 
    ModulePermissionsDialogComponent, 
    AuthorizationDialogComponent, 
    PacienteComponent,
    AreaGeneralComponent,
    AgendarComponent,
    CalendarioComponent,
    AreaResponsableComponent,
    AgendaDialogComponent,
    EspecialidadesComponent,
    EspecialidadDialogComponent,
    TipoConsultaComponent,
    BuscaPacienteComponent,
    TipoConsultaDialogComponent,
    ConsultaDialogComponent,
    CamasComponent,
    CamasDialogComponent,
    AreasComponent,
    AreasDialogComponent,
    TipoDocumentoComponent,
    TipoDocumentoDialogComponent,
    PrestamosComponent,
    DevolucionesComponent,
    PrestamosDialogComponent,
    DevolucionesDialogComponent,
    AreaDocumentosComponent,
    AreaAntecedentesComponent,
    AreaConsultasComponent,
    AreaRecetasComponent,
    AreaEstudiosComponent,
    AreaDiagnosticosComponent,
    AreaOrdenesComponent,
    AreaReferenciasComponent,
    HorarioDialogComponent,
    AreaProcedimientosComponent,
    MedicamentosComponent,
    DiagnosticosComponent,
    ProcedimientosComponent,
    EstudiosComponent,
    ProcedimientosDialogComponent,
    EstudiosDialogComponent,
    MedicamentosDialogComponent,
    DiagnosticosDialogComponent,
    ShareScheduleDialogComponent,
    ExploracionDialogComponent,
    FlexLayoutType,
    RepPacientesComponent,
    ResetPasswordComponent,
    ArticulosComponent,
    ArticulosDialogComponent,
    AreaMedicionesComponent,
    CuestionariosComponent,
    ConsultoriosComponent,
    ConsultoriosDialogComponent,
    ConsultoriosHorarioComponent,
    ServiciosComponent,
    ServiciosDialogComponent,
    RepServiciosComponent,
    RepConsultasComponent,
    CertificadoComponent,
    BtnCellRenderer
  
  ],
  exports: [
    DashboardComponent, 
    UsersComponent, 
    Error404Component,
    Error403Component
  ],
  imports: [
    CommonModule,
    SharedModule,
    PAGES_ROUTES,
    AgGridModule.withComponents([]),
    // NgxChartsModule,
    BrowserAnimationsModule,
    NgbModule,
    ToastrModule.forRoot(),
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    QRCodeModule,
    
    AgmCoreModule.forRoot({apiKey: 'AIzaSyDSLlSwi88fgl59Tm057i7BIc_UrdSANMk'}),
    IonRangeSliderModule,
    MaterialDesignFrameworkModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    FormlyModule.forRoot({
      types: [
        { name: 'flex-layout', component: FlexLayoutType }
      ],
    }),
    FormlyMaterialModule,
    NgxSliderModule
  ],
  providers: [
    GoogleMapsAPIWrapper
  ],
})
export class PagesModule {}


