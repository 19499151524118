import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FormsService } from '../../services/forms.service';
import { Estudios, IEstudios } from 'src/app/models/estudios.model';
import { IFormGroup, IFormBuilder } from '@rxweb/types';
import { CatalogService } from '../../services/catalog.service';

@Component({
  selector: 'app-estudios-dialog',
  templateUrl: './estudios-dialog.component.html',
  styleUrls: []
})
export class EstudiosDialogComponent implements OnInit {
  @ViewChild("form", { static: true }) form;
  @Output() submit_form = new EventEmitter<FormGroup>();
  @Output() close_modal = new EventEmitter();
  @Input()  show_cancel_button: boolean = false;
  @Input()  show_save_button: boolean = false;
  @Input()  show_close_button: boolean = false;
  @Input()  catalog: Estudios;

  options = [{label:'Si', value:true}, {label:'No', value:false}];
  componentForm: IFormGroup<IEstudios>;
  formBuilder: IFormBuilder;
  catGruposEstudio: any;

  constructor(formbuilder: FormBuilder, 
              public _frm:FormsService, 
              public catalogService: CatalogService ) { 
              this.formBuilder = formbuilder;
   }

  ngOnInit(){
    this.catalogService.get_catalog('grupos_estudios',true).subscribe(data => {
      this.catGruposEstudio = data;
    });
    var disableLonic = this.catalog.lonic ? true : false;
    this.componentForm = this.formBuilder.group<IEstudios>({
      estudio_id: [this.catalog.estudio_id],
      estudio_cod: [{ value: this.catalog.estudio_cod, disabled: disableLonic}],
      estudio: [{ value: this.catalog.estudio, disabled: disableLonic}, Validators.required],
      grupo_id: [{ value: this.catalog.grupo_id, disabled: disableLonic }, Validators.required],
      nombre_compuesto: [this.catalog.nombre_compuesto, Validators.required],
      lonic: [this.catalog.lonic]
    });
  }

  on_submit() {
    console.log(`this.componentForm: ${JSON.stringify(this.componentForm.value)}`);
    this.submit_form.emit(this.componentForm);
  }
  on_close() {
    this.close_modal.emit();
  }

}