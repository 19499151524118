import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';


import { HeaderComponent } from "./master/header.component";
import { SidebarComponent } from "./master/sidebar.component";
import { BreadcrumbsComponent } from "./master/breadcrumbs.component";
import { GridComponent } from './components/grid.component';
import { ButtonsComponent } from './components/buttons.component';
import { AddressComponent } from './forms/address.component';

import { AgGridModule } from "ag-grid-angular";
import "ag-grid-enterprise";
import { NgSelectModule } from '@ng-select/ng-select';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';

import { PermissionsDirective } from './../shared/helpers/permissions.directive';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PersonComponent } from './forms/person.component';
import { AccountComponent } from './forms/account.component';
import { FormComponent } from './components/form.component';
import { MedicoComponent } from './forms/medico.component';


@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    NgbModule,
    AgGridModule.withComponents([]),
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({apiKey: 'AIzaSyDSLlSwi88fgl59Tm057i7BIc_UrdSANMk'})
  ],
  declarations: [
    HeaderComponent,
    SidebarComponent,
    BreadcrumbsComponent,
    GridComponent,
    ButtonsComponent,
    PermissionsDirective,
    AddressComponent,
    PersonComponent,
    AccountComponent,
    FormComponent,
    MedicoComponent
  ],
  exports: [
    HeaderComponent,
    SidebarComponent,
    BreadcrumbsComponent,
    GridComponent,
    ButtonsComponent,
    PermissionsDirective,
    AddressComponent,
    PersonComponent,
    AccountComponent,
    FormComponent,
    MedicoComponent
  ],
  providers: [
    GoogleMapsAPIWrapper
  ],
})
export class SharedModule {}
