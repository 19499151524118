import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { UserService } from 'src/app/services/service.index';
import { GridComponent } from 'src/app/shared/components/grid.component';

@Component({
  selector: 'app-module-permissions-dialog',
  templateUrl: './module-permissions-dialog.component.html',
  styles: []
})
export class ModulePermissionsDialogComponent implements OnInit {
  @Input() module_id: number;
  @ViewChild(GridComponent, { static: true }) mygrid: GridComponent;
  rowData: any;
  columnDefs = [
    { headerName: "Permiso", field: "permission_name", headerCheckboxSelection: true, checkboxSelection: true },
    { headerName: "Descripcion", field: "description" }
  ];
  pre_selected = false;

  constructor(public _usrs: UserService) { }

  ngOnInit() {
    this.get_data();
  }

  get_data() {
    this._usrs.get_module_permissions(this.module_id).subscribe(
      data => this.rowData = data,
      err => console.log(err)
    );
  }
  pre_select_nodes(event) {
    this.mygrid.gridApi.forEachNode(function (node) {
      if (node.data.is_active === true) node.setSelected(true);
    });
    setTimeout(() => { this.pre_selected = true; }, 500);
  }
  change_selection(event) {
    //event.node.data.module_id = this.module_id;
    event.node.data.module_id = this.module_id.toString();

    if (this.pre_selected) {
      if (event.node.selected) {       
        this._usrs.insert_module_permission(event.node.data).subscribe(
          data => console.log(data),
          err => console.log(err)
        );
      }
      else {
        this._usrs.delete_module_permission(event.node.data).subscribe(
          data => console.log(data),
          err => console.log(err)
        );
      }
    }
  }

}
