import { Component, OnInit } from '@angular/core';
import { AuthorizationService, UserService } from 'src/app/services/service.index';
import { FormGroup } from '@angular/forms';
import { user } from '../../models/authorization.model';

import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styles: []
})
export class ProfileComponent implements OnInit {
  user: user;
  constructor(public _auth: AuthorizationService, public _usr: UserService, private _toastr: ToastrService) {
    this.user = _auth.user;
  }

  ngOnInit() { }




  submit_profile(form: FormGroup) {
    if (form.invalid) {
      this._toastr.warning("Existen problemas al validar los campos del formulario. Revise los mensajes para mayor información ", "Validación del formulario", { timeOut: 0, progressBar: true });
      return;
    }
    this._usr.update_profile(form.value).subscribe(
      (res: any) => {
        this._toastr.success("Perfil actualizado exitosamente", "Actualización de Perfil", { timeOut: 3000, progressBar: true })
        console.log(res);
        this._auth.user.person.photo = res.photo;
        this._auth.user.person.name = res.name;
        this._auth.user.person.last_name = res.last_name;
      }
      ,
      error => {
        this._toastr.error("El perfil no pudo ser actualizado correctamente. Error: " + error.error.message, "Actualización de Perfil", { timeOut: 0, progressBar: true });
      }
    );
  }

  submit_address(form: FormGroup) {
    console.log(form.value);
    if (form.invalid) {
      this._toastr.warning("Existen problemas al validar los campos del formulario. Revise los mensajes para mayor información ", "Validación del formulario", { timeOut: 0, progressBar: true });
      return;
    }
    this._usr.update_profile(form.value).subscribe(
      (res: any) => {
        this._toastr.success("Perfil actualizado exitosamente", "Actualización de Perfil", { timeOut: 3000, progressBar: true })
        console.log(res);
        this._auth.user.person.photo = res.photo;
        this._auth.user.person.name = res.name;
        this._auth.user.person.last_name = res.last_name;
      }
      ,
      error => {
        this._toastr.error("El perfil no pudo ser actualizado correctamente. Error: " + error.error.message, "Actualización de Perfil", { timeOut: 0, progressBar: true });
      }
    );
  }


  // submit_photo(files: FileList) {
  //   console.log("subiendo");

  //   const formData: FormData = new FormData();
  //   formData.append('filesData', files.item(0), files.item(0).name);
  //   console.log(files.item(0).name);
  //   this._usr.update_photo(formData)
  // }




}
