<div class="row page-titles">
    <div class="col-md-6 align-self-center">
        <h3 class="text-themecolor">{{title}}</h3>
        <h6 class="card-subtitle text-muted m-t-5">{{subtitle}}</h6>
    </div>
    <div class="col-md-6 align-self-center">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"> <a routerLink="/dashboard">Inicio</a> </li>
            <li *ngIf="!third_node && !second_node && !first_node" class="breadcrumb-item active">{{title}}</li>
            <li *ngIf="first_node" class="breadcrumb-item" [ngClass]="{'active': !second_node}">
                <a *ngIf="first_node_route" [routerLink]="first_node_route">{{first_node}}</a>
                <a *ngIf="!first_node_route">{{first_node}}</a>
            </li>
            <li *ngIf="second_node" class="breadcrumb-item" [ngClass]="{'active': !third_node}">
                <a *ngIf="second_node_route" [routerLink]="second_node_route">{{second_node}}</a>
                <a *ngIf="!second_node_route">{{second_node}}</a>
            </li>
            <li *ngIf="third_node" class="breadcrumb-item active">{{third_node}}</li>

        </ol>
    </div>
    <!-- <div>
        <button class="right-side-toggle waves-effect waves-light btn-inverse btn btn-circle btn-sm pull-right m-l-10"><i class="fa fa-cog text-white"></i></button>
    </div> -->
</div>