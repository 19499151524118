<div class="card-body p-b-0"> 
    <ul class="nav nav-tabs profile-tab" role="tablist">
      <li class="nav-item"> <a class="nav-link active" data-toggle="tab" href="#horarios" role="tab">Horarios</a></li>
    </ul>
    <div class="tab-content">
      <div class="tab-pane active" id="horarios" role="tabpanel">
        <form class="form-material" (ngSubmit)="on_submit()" novalidate>
          <div class="row p-t-10">
            <div class="col-sm-12">
  
              <ul class="list-group">
                <li class="list-group-item">
                  <div class="row">
                    <div class="col-sm-2">
                      <div class="checkbox checkbox-primary">
                        <input type="checkbox" id="lunes" class="filled-in chk-col-light-blue" #inputlunes 
                          [checked]="horario.lun.checked"  (change)="change_day(horario.lun, $event)">
                        <label for="lunes"> Lunes </label>
                      </div>
                    </div>
                    <div class="col-sm-10" *ngIf="inputlunes.checked" >
                        <div class="custom-slider">
                            <ngx-slider [(value)]="horario.lun.from" [(highValue)]="horario.lun.to" [options]="options"></ngx-slider>
                        </div>                               
                    </div>                  
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row">
                    <div class="col-sm-2">
                      <div class="checkbox checkbox-primary">
                        <input type="checkbox" id="martes" class="filled-in chk-col-light-blue" #inputmartes
                          [checked]="horario.mar.checked"  (change)="change_day(horario.mar, $event)">
                        <label for="martes"> Martes </label>
                      </div>
                    </div>
                    <div class="col-sm-10" *ngIf="inputmartes.checked">
                          <div class="custom-slider">
                            <ngx-slider [(value)]="horario.mar.from" [(highValue)]="horario.mar.to" [options]="options"></ngx-slider>
                          </div>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row">
                    <div class="col-sm-2">
                      <div class="checkbox checkbox-primary">
                        <input type="checkbox" id="miercoles" class="filled-in chk-col-light-blue" #inputmiercoles
                          [checked]="horario.mie.checked"  (change)="change_day(horario.mie, $event)">
                        <label for="miercoles"> Miercoles </label>
                      </div>
                    </div>
                    <div class="col-sm-10" *ngIf="inputmiercoles.checked">
                        <div class="custom-slider">
                            <ngx-slider [(value)]="horario.mie.from" [(highValue)]="horario.mie.to" [options]="options"></ngx-slider>
                        </div>                                                              
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row">
                    <div class="col-sm-2">
                      <div class="checkbox checkbox-primary">
                        <input type="checkbox" id="jueves" class="filled-in chk-col-light-blue" #inputjueves
                          [checked]="horario.jue.checked"  (change)="change_day(horario.jue, $event)">
                        <label for="jueves"> Jueves </label>
                      </div>
                    </div>
                    <div class="col-sm-10" *ngIf="inputjueves.checked">
                        <div class="custom-slider">  
                            <ngx-slider [(value)]="horario.jue.from" [(highValue)]="horario.jue.to" [options]="options"></ngx-slider>
                        </div>                                                             
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row">
                    <div class="col-sm-2">
                      <div class="checkbox checkbox-primary">
                        <input type="checkbox" id="viernes" class="filled-in chk-col-light-blue" #inputviernes
                          [checked]="horario.vie.checked"  (change)="change_day(horario.vie, $event)">
                        <label for="viernes"> Viernes </label>
                      </div>
                    </div>
                    <div class="col-sm-10" *ngIf="inputviernes.checked">
                        <div class="custom-slider">
                            <ngx-slider [(value)]="horario.vie.from" [(highValue)]="horario.vie.to" [options]="options"></ngx-slider>
                        </div>                                                             
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row">
                    <div class="col-sm-2">
                      <div class="checkbox checkbox-primary"> 
                        <input type="checkbox" id="sabado" class="filled-in chk-col-light-blue" #inputsabado
                          [checked]="horario.sab.checked" (change)="change_day(horario.sab, $event)">
                        <label for="sabado"> Sabado </label>
                      </div>
                    </div>
                    <div class="col-sm-10" *ngIf="inputsabado.checked">
                        <div class="custom-slider"> 
                            <ngx-slider [(value)]="horario.sab.from" [(highValue)]="horario.sab.to" [options]="options"></ngx-slider>
                        </div>                                                              
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="row">
                    <div class="col-sm-2">
                      <div class="checkbox checkbox-primary">
                        <input type="checkbox" id="domingo" class="filled-in chk-col-light-blue" #inputdomingo
                          [checked]="horario.dom.checked"  (change)="change_day(horario.dom, $event)">
                        <label for="domingo"> Domingo </label>
                      </div>
                    </div>
                    <div class="col-sm-10" *ngIf="inputdomingo.checked">
                        <div class="custom-slider">
                            <ngx-slider [(value)]="horario.dom.from" [(highValue)]="horario.dom.to" [options]="options"></ngx-slider>
                        </div>                                                              
                    </div>
                  </div>
                </li>
              </ul>
  
            </div>
          </div>
  
          <div class="row m-t-30">
            <div class="col-md-12 modal-footer">
              <button type="button" class="btn btn-danger btn-sm" (click)="on_close()"><i class="mdi mdi-close"></i> Cancelar</button>
              <button type="submit" class="btn btn-primary btn-sm "><i class="mdi mdi-content-save"></i> Guardar</button>
            </div>
          </div>
        </form>
      </div>

    </div>
  </div>