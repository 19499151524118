import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FormsService } from '../../services/forms.service';
import { Procedimientos, IProcedimientos } from 'src/app/models/procedimientos.model';
import { IFormGroup, IFormBuilder } from '@rxweb/types';

@Component({
  selector: 'app-procedimientos-dialog',
  templateUrl: './procedimientos-dialog.component.html',
  styleUrls: []
})
export class ProcedimientosDialogComponent implements OnInit {
  @ViewChild("form", { static: true }) form;
  @Output() submit_form = new EventEmitter<IFormGroup<Procedimientos>>();
  @Output() close_modal = new EventEmitter();
  @Input()  show_cancel_button: boolean = false;
  @Input()  show_save_button: boolean = false;
  @Input()  show_close_button: boolean = false;
  @Input()  catalog: Procedimientos;

  options: any = [{label:'Si', value:true}, {label:'No', value:false}];
  componentForm: IFormGroup<IProcedimientos>;
  formBuilder: IFormBuilder;

  constructor(formbuilder: FormBuilder, public _frm:FormsService) { 
    this.formBuilder = formbuilder;
  }

  ngOnInit(){
    var disableCie9 = this.catalog.cie9 ? true : false;
    this.componentForm = this.formBuilder.group<IProcedimientos>({
      padre_id: [this.catalog.padre_id],
      procedimiento_id: [this.catalog.procedimiento_id],
      procedimiento_cod: [{value: this.catalog.procedimiento_cod, disabled: disableCie9}, Validators.required],
      nombre: [{value: this.catalog.nombre, disabled: disableCie9}, Validators.required],
      nombre_compuesto: [ this.catalog.nombre_compuesto, Validators.required],
      cie9: [this.catalog.cie9]
    });
  }

  on_submit() {
    console.log(`this.componentForm: ${JSON.stringify(this.componentForm.value)}`);
    this.submit_form.emit(this.componentForm);
  }
  on_close() {
    this.close_modal.emit();
  }

}
