<div class="card-body p-b-0">
    <form *ngIf="antecedentes" class="form-material" (ngSubmit)="on_submit()" #form="ngForm"  novalidate>


        <ngb-accordion [closeOthers]="true" activeIds="a_heredofamiliares">
            <ngb-panel *ngFor="let element of antecedentes" [id]="element.name">
                <ng-template ngbPanelTitle><span class="m-l-10">{{element.description}}</span></ng-template>
                <ng-template ngbPanelContent>
                    <div *ngFor="let field of element.fields" class="row">
                        <div [class]="field.css_class">
                            <div class="form-group"  *ngIf="field.visible">
                                <label [for]="field.name">{{field.label}}</label>
                                <!-- Input tipo texto -->
                                <input *ngIf="field.type=='text'" type="text" [placeholder]="field.placeholder" class="form-control" [id]="field.name"  [readonly]="field.read_only" [(ngModel)]="field.value" [name]="field.name">
                                <i *ngIf="field.icon" [class]="field.icon"></i>
                                <!-- Input tipo textarea -->
                                <textarea *ngIf="field.type=='textarea'" type="text" [placeholder]="field.placeholder"  class="form-control" [id]="field.name" [(ngModel)]="field.value" [name]="field.name"></textarea>
                                <!-- Input tipo select -->
                                <ng-select *ngIf="field.type=='select'" [placeholder]="field.placeholder"
                                    [id]="field.name" class="form-control" [(ngModel)]="field.value" [name]="field.name"
                                    [items]="field.options" bindLabel="text" bindValue="value" [searchable]="false"
                                    [multiple]="field.multiselect" notFoundText="No hay resultados"></ng-select>
                                <!--   input tipo date -->
                                <input *ngIf="field.type=='date'" type="text" [placeholder]="field.placeholder" class="form-control" [id]="field.name"  [readonly]="field.read_only" [(ngModel)]="field.value" [name]="field.name" ngbDatepicker #d="ngbDatepicker" (focus)="d.toggle()" >

                                
                                     
                                <span *ngIf="field.helptext" class="help-block text-muted"><small>{{field.helptext}}  </small></span>
                                <div class="invalid-feedback">
                                    <div *ngFor="let val of field.validations">
                                        <div *ngIf="form_group.controls[field.name].errors?.required">   {{val.error_message}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngFor="let child of field.children" [class]="child.css_class">
                            <div class="form-group" *ngIf="field.value">
                                <label [for]="child.name">{{child.label}}</label>
                                <!-- Input tipo texto -->
                                <input *ngIf="child.type=='text'" type="text" [placeholder]="child.placeholder" class="form-control" [id]="child.name" [readonly]="child.read_only" [(ngModel)]="child.value" [name]="child.name">
                                <i *ngIf="child.icon" [class]="child.icon"></i>
                                <!-- Input tipo textarea -->
                                <textarea *ngIf="child.type=='textarea'" type="text" [placeholder]="child.placeholder"  class="form-control" [id]="child.name"  [(ngModel)]="child.value" [name]="child.name"></textarea>
                                <!-- Input tipo select -->
                                <ng-select *ngIf="child.type=='select'" [placeholder]="child.placeholder"
                                    [id]="child.name" class="form-control"  [(ngModel)]="child.value" [name]="child.name"
                                    [items]="child.options" bindLabel="text" bindValue="value" [searchable]="false"
                                    [multiple]="child.multiselect" notFoundText="No hay resultados"></ng-select>
                                 <!--   input tipo date -->
                                     <input *ngIf="child.type=='date'" type="text" [placeholder]="child.placeholder" class="form-control" [id]="child.name"  [readonly]="child.read_only" [(ngModel)]="child.value" [name]="child.name" ngbDatepicker #d="ngbDatepicker" (focus)="d.toggle()" >

                                <span *ngIf="child.helptext" class="help-block text-muted"><small>{{child.helptext}}  </small></span>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ngb-panel>
        </ngb-accordion>


        
        <div class="row m-t-30">
            <div class="col-md-12 modal-footer">
                <button *ngIf="show_cancel_button" type="button" class="btn btn-danger btn-sm" (click)="on_close()"><i class="mdi mdi-close"></i>Cancelar</button>
                <button type="submit" class="btn btn-primary btn-sm"><i class="mdi mdi-content-save"></i>Guardar</button>
            </div>
        </div>
    </form>
</div>