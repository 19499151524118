import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthorizationService } from './authorization.service';
import { environment } from '../../environments/environment';
import { servicio } from '../models/servicios.model';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ProductosService {

  constructor(public datepipe:DatePipe, public http: HttpClient, public _auth: AuthorizationService) { }


  get_services() {
    let url = environment.apiEndpoint + "Servicios/";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token })
    };
    return this.http.get(url, httpOptions);
  }



  delete_service(servicio_id: number) {
    let url = environment.apiEndpoint + "servicios/delete-service";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
      params: new HttpParams().set('servicio_id', servicio_id.toString())
    };
    return this.http.delete(url, httpOptions);
  }

  create_service(request:servicio){
    let url = environment.apiEndpoint + "servicios/create-service";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
    };
    return this.http.post(url, request, httpOptions);
  }

  update_service(request:servicio){
    let url = environment.apiEndpoint + "servicios/update-service";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
    };
    return this.http.put(url, request, httpOptions);
  }




  report_services(fecha_incio:Date,fecha_fin:Date) {
    
    if(fecha_incio!=null){
      var fecha1:any =fecha_incio
      var f1  = new Date(Date.UTC(fecha1.year, fecha1.month -1, fecha1.day + 1));
    }else{
      f1  = new Date(Date.UTC(1900, 1, 1));
    }
    if(fecha_fin!=null){
      var fecha2:any =fecha_fin
      var f2  = new Date(Date.UTC(fecha2.year, fecha2.month -1, fecha2.day + 1));
    }else{
      f2  = new Date(Date.UTC(2076, 6, 1));
    }
  
    let url = environment.apiEndpoint + "servicios/report";
    
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token, responseType: 'blob'} ),
      params: new HttpParams().set('start',this.datepipe.transform(f1, "yyyy-MM-dd")).set('end',this.datepipe.transform(f2, "yyyy-MM-dd")),
    };    
    return this.http.get(url, httpOptions );
  }
  



}
