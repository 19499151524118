import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FormsService } from 'src/app/services/service.index';
import { catalog } from 'src/app/models/catalog.model';

@Component({
  selector: 'app-tipo-documento-dialog',
  templateUrl: './tipo-documento-dialog.component.html',
  styles: []
})
export class TipoDocumentoDialogComponent implements OnInit {
  @ViewChild("form", { static: true }) form;
  @Output() submit_form = new EventEmitter<FormGroup>();
  @Output() close_modal = new EventEmitter();
  @Input()  show_cancel_button: boolean = false;
  @Input()  td: catalog;
  options: any = [{label:'Si', value:true}, {label:'No', value:false}];

  td_form: FormGroup;
  constructor(private _fb: FormBuilder, public _frm:FormsService) { }

  ngOnInit() {
    this.td_form = this._fb.group({
      id: [this.td.id],
      nombre: [this.td.nombre, [Validators.required]],
      codigo: [this.td.codigo],
      descripcion: [this.td.descripcion],
      tabla: [this.td.tabla="tipo_documento"],
      claveid: [this.td.claveid="tipo_documento"],
    });
  }
  on_submit() {
    this.submit_form.emit(this.td_form);
  }
  on_close() {
    this.close_modal.emit();
  }
}
