import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { AuthorizationService } from './authorization.service';
import { environment } from '../../environments/environment';
import { agenda, consulta } from '../models/consulta.model';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ConsultaService {

  public selected_consultorio_id: number = 0;
  public selected_consultorio: string = "";
  public selected_sucursal: string = "";

  constructor(public datepipe:DatePipe, public http: HttpClient, public _auth: AuthorizationService) { }
  
  /*  test_download(arr:any) {
    let url = environment.apiEndpoint + "consulta/download";
    return this.http.get(url, {responseType: 'blob' });
  } */
  test_download(medico_id:number, especialidad_id:number,fecha_icial:string ) {
    let url = environment.apiEndpoint + "consulta/printConsulta";
    let jsonPost ='{"medico_id":'+medico_id+',"especialidad_id":'+especialidad_id+',"fecha_icial":"'+fecha_icial+'"}'

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + this._auth.token
    });
    return this.http.post<Blob>(url,jsonPost ,{headers: headers, responseType: 'blob' as 'json' });
  } 
  
  insert_agenda(new_agenda: agenda) {
    let new_agenda_clone = Object.assign({}, new_agenda);
    let fecha:any = new_agenda_clone.fecha;
    let hora = new_agenda_clone.hora;
    new_agenda_clone.fecha  = new Date(Date.UTC(fecha.year, fecha.month - 1, fecha.day, hora.hour, hora.minute));

    let url = environment.apiEndpoint + "consulta/agenda_insert";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
    };
    return this.http.post(url, new_agenda_clone, httpOptions);
  }
  update_agenda(updated_agenda: agenda) {

    let updated_agenda_clone = Object.assign({}, updated_agenda);
    let fecha:any = updated_agenda_clone.fecha;
    let hora = updated_agenda_clone.hora;
    updated_agenda_clone.fecha  = new Date(Date.UTC(fecha.year, fecha.month - 1, fecha.day, hora.hour, hora.minute));

    let url = environment.apiEndpoint + "consulta/agenda_update";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
    };
    return this.http.post(url, updated_agenda_clone, httpOptions);
  }
  get_next_date(medico_id:number, especialidad_id:number,fecha_icial:string ) {
    let url = environment.apiEndpoint + "consulta/disponibilidad_search";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
      params: new HttpParams().set('medico_id', medico_id.toString()).set('especialidad_id', especialidad_id.toString()).set('fecha_icial', fecha_icial),
    };
    return this.http.get(url, httpOptions);
  }
  get_agenda_search(keyword:string ) {
    let url = environment.apiEndpoint + "consulta/agenda_search";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
      params: new HttpParams().set('keyword', keyword)
    };
    return this.http.get(url, httpOptions);
  }


  search_causes(keyword:string ) {
    let url = environment.apiEndpoint + "consulta/causes_search";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
      params: new HttpParams().set('keyword', keyword)
    };
    return this.http.get(url, httpOptions);
  }
  search_diagnostico(keyword:string, causes_id?:number) {
    let url = environment.apiEndpoint + "consulta/diagnostico_search";
    //let url = "https://api.mocki.io/v1/9415cb27";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
      //params: new HttpParams().set('keyword', keyword).set('activo', 'true').set('causes_id', causes_id? causes_id.toString() : null)
      params: new HttpParams().set('keyword', keyword)
    };
    return this.http.get(url, httpOptions);
  }
  search_medicamento(keyword:string, causes_id?:number) {
    let url = environment.apiEndpoint + "consulta/medicamentos_search";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
      //params: new HttpParams().set('keyword', keyword).set('causes_id', causes_id? causes_id.toString() : null)
      params: new HttpParams().set('keyword', keyword)
    };
    return this.http.get(url, httpOptions);
  }
  search_procedimiento(keyword:string, causes_id?:number) {
    let url = environment.apiEndpoint + "consulta/procedimientos_search";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
      //params: new HttpParams().set('keyword', keyword).set('causes_id', causes_id? causes_id.toString() : null)
      params: new HttpParams().set('keyword', keyword)
    };
    return this.http.get(url, httpOptions);
  }
  search_estudio(keyword:string, causes_id?:number) {
    let url = environment.apiEndpoint + "consulta/estudios_search";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
      params: new HttpParams().set('keyword', keyword).set('causes_id', causes_id? causes_id.toString() : null)
    };
    return this.http.get(url, httpOptions);
  }


  get_consulta(consulta_id:number){
    let url = environment.apiEndpoint + "consulta/consulta_get";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
      params: new HttpParams().set('consulta_id', consulta_id.toString())
    };
    return this.http.get(url, httpOptions);   
  }
  insert_consulta(consulta:consulta){
    let url = environment.apiEndpoint + "consulta/consulta_insert";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
    };
    return this.http.post(url, consulta, httpOptions);
  }


  insert_certificado(cetificado:any){
    let url = environment.apiEndpoint + "consulta/certificado_insert";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
    };
    return this.http.post(url, cetificado, httpOptions);
  }


  //#region  Agenda citas por consultorios
  get_consultorios(){
    let url = environment.apiEndpoint + "consulta/consultorios_get";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token })
    };
    return this.http.get(url, httpOptions);   
  }

  insert_consultorio(consultorio:any){
    //console.log(consultorio);
    
    let url = environment.apiEndpoint + "consulta/consultorio_insert";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token })
    };
    return this.http.post(url,consultorio, httpOptions);   
  }
  update_consultorio(consultorio:any){
    let url = environment.apiEndpoint + "consulta/consultorio_update";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token })
    };
    return this.http.put(url,consultorio, httpOptions);   
  }
  delete_consultorio(consultorio_id:number){
    let url = environment.apiEndpoint + "consulta/consultorio_delete?consultorio_id=" + consultorio_id.toString();
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token })
    };
    return this.http.delete(url,httpOptions);   
  }


  consultorio_calendario_get(consultorio_id: number, mes: number, anio: number) {
    let url = environment.apiEndpoint + "consulta/consultorio_calendario_get";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
      params: new HttpParams().set('consultorio_id', consultorio_id.toString()).set('mes', mes.toString()).set('anio', anio.toString()),
    };
    return this.http.get(url, httpOptions);
  }
  consultorio_agenda_get(consultorio_id: number, fecha: string) {
    let url = environment.apiEndpoint + "consulta/consultorio_agenda_get";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
      params: new HttpParams().set('consultorio_id', consultorio_id.toString()).set('fecha', fecha),
    };
    return this.http.get(url, httpOptions);
  }
  consultorios_disponibilidad_dia_get(sucursal_id:number, consultorio_id: number, mes: number, anio: number, dia: number) {
    let url = environment.apiEndpoint + "consulta/consultorios_disponibilidad_dia_get";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
      params: new HttpParams().set('sucursal_id',sucursal_id.toString()).set('consultorio_id', consultorio_id.toString()).set('mes', mes.toString()).set('anio', anio.toString()).set('dia', dia.toString()),
    };
    return this.http.get(url, httpOptions);
  }


  
  //#endregion

  //#region  Cuestionarios

  get_cuestionario(quest_id:number, consulta_id:number){
    let url = environment.apiEndpoint + "consulta/cuestionarios_get?quest_id=" + quest_id + "&consulta_id=" + consulta_id;
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token })
    };
    return this.http.get(url, httpOptions);   
  }

  result_cuestionario(quest_id:number, consulta_id:number, quest:string){
    var params = {
      quest_id : quest_id,
      consulta_id: consulta_id,
      quest: quest
    }
    let url = environment.apiEndpoint + "consulta/cuestionarios_result";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token })
    };
    return this.http.post(url, params, httpOptions);   
  }

  save_cuestionario(quest_id:number, consulta_id:number, quest:string){
    var params = {
      quest_id : quest_id,
      consulta_id: consulta_id,
      quest: quest
    }
    let url = environment.apiEndpoint + "consulta/cuestionario_consulta_insert";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token })
    };
    return this.http.post(url, params, httpOptions);   
  }


  //#endregion  



  get_horario_consultorio(consultorio_id:number){
    let url = environment.apiEndpoint + "consulta/horario_consultorio_get?consultorio_id=" + consultorio_id;
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token })
    };
    return this.http.get(url, httpOptions);   
  }


  update_horario_consultorio(horarios:any){
    let url = environment.apiEndpoint + "consulta/update_horario_consultorio";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token })
    };
    return this.http.put(url,horarios,httpOptions);   
  }






  report_consultas(fecha_incio:Date,fecha_fin:Date) {
    
    if(fecha_incio!=null){
      var fecha1:any =fecha_incio
      var f1  = new Date(Date.UTC(fecha1.year, fecha1.month -1, fecha1.day + 1));
    }else{
      f1  = new Date(Date.UTC(1900, 1, 1));
    }
    if(fecha_fin!=null){
      var fecha2:any =fecha_fin
      var f2  = new Date(Date.UTC(fecha2.year, fecha2.month -1, fecha2.day + 1));
    }else{
      f2  = new Date(Date.UTC(2076, 6, 1));
    }
  
    let url = environment.apiEndpoint + "consulta/report";
    //console.log(this._auth);
    
    
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token, responseType: 'blob'} ),
      params: new HttpParams().set('start',this.datepipe.transform(f1, "yyyy-MM-dd")).set('end',this.datepipe.transform(f2, "yyyy-MM-dd")),
    };    
    return this.http.get(url, httpOptions );
  }
  


}
