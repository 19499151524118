import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from "@angular/core";
//import { AgGridNg2 } from "ag-grid-angular";

@Component({
  selector: "app-grid",
  templateUrl: "./grid.component.html",
  styles: []
})
export class GridComponent implements OnInit {

  @Input() rowData: any;
  @Input() columnDefs: any;
  @Input() autoGroupColumnDef: any;
  @Input() treeData: boolean = false;
  @Input() groupDefaultExpanded: number = -1;
  @Input() rowSelection: string = "multiple";
  @Input() rowMultiSelectWithClick: boolean = true;
  @Input() suppressRowClickSelection: boolean = false;
  @Input() show_filter: boolean = false;
  @Input() server_filter: boolean = false;
  @Input() autosize: boolean = true;
  @Input() getRowHeight = null;
  @Input() rowClassRules = null; 
  @Input() isRowSelectable = null; 
  @Input() gridHeight = "500px";

  @Input() groupIncludeFooter = false;
  @Input() groupIncludeTotalFooter = false;

  @Output() on_first_data_rendered = new EventEmitter<any>();
  @Output() on_row_selected = new EventEmitter<any>();
  @Output() on_row_clicked = new EventEmitter<any>();
  @Output() on_row_dbl_clicked = new EventEmitter<any>();
  @Output() on_row_text_changed = new EventEmitter<string>();


  @ViewChild("agGrid", { static: true })
  
  localeText = {
    // for filter panel
    page: "página",
    more: "más",
    to: "a",
    of: "de",
    next: "siguiente",
    last: "ultimo",
    first: "primero",
    previous: "anterior",
    loadingOoo: "Cargando...",

    // for set filter
    selectAll: "Seleccionar todos",
    searchOoo: "Buscar...",
    blanks: "vacios",

    // for number filter and text filter
    filterOoo: "Filtrar...",
    applyFilter: "Aplicar filtro...",

    // for number filter
    equals: "Igual",
    lessThan: "Menor que",
    greaterThan: "Mayor que",

    // for text filter
    contains: "Contiene",
    startsWith: "Empieza con",
    endsWith: "Termina con",

    // the header of the default group column
    group: "Grupo",

    // tool panel
    columns: "Columnas",
    rowGroupColumns: "laPivot Cols",
    rowGroupColumnsEmptyMessage: "la drag cols to group",
    valueColumns: "laValue Cols",
    pivotMode: "laPivot-Mode",
    groups: "laGroups",
    values: "laValues",
    pivots: "laPivots",
    valueColumnsEmptyMessage: "la drag cols to aggregate",
    pivotColumnsEmptyMessage: "la drag here to pivot",
    toolPanelButton: "la tool panel",

    // other
    noRowsToShow: "No existen resultados",

    // enterprise menu
    pinColumn: "Anclar columna",
    valueAggregation: "laValue Agg",
    autosizeThiscolumn: "Ajustar columna",
    autosizeAllColumns: "Ajustar todas las columnas",
    groupBy: "laGroup by",
    ungroupBy: "laUnGroup by",
    resetColumns: "Reestablecer columnas",
    expandAll: "Expandir todo",
    collapseAll: "Colapasar todo",
    toolPanel: "Panel de herramientas",
    export: "Exportar",
    csvExport: "Exportar a CSV",
    excelExport: "Exportar a Excel",

    // enterprise menu pinning
    pinLeft: "Anclar a la izquierda <<",
    pinRight: "Anclar a la derecha >>",
    noPin: "No anclar <>",

    // enterprise menu aggregation and status bar
    sum: "Suma",
    min: "Min",
    max: "Max",
    none: "Ninguna",
    count: "Cuenta",
    average: "Promedio",

    // standard menu
    copy: "Copiar",
    copyWithHeaders: "Copiar con cabeceras",
    ctrlC: "Ctrl + C",
    paste: "Pegar",
    ctrlV: "Ctrl + V"
  };

  public gridApi;
  public gridColumnApi;

  public search_word;

  constructor() { }
  ngOnInit() { }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    if (this.autosize) this.autoSizeAll();
  }
  onfirstDataRendered(event) {
    this.on_first_data_rendered.emit(event);
    try  {
      if (this.autosize) this.autoSizeAll();
    }
    catch {}
  }
  onRowSelected(event) {
    this.on_row_selected.emit(event);
  }
  onRowClicked(event) {
    this.on_row_clicked.emit(event);
  }
  onRowDoubleClicked(event) {
    this.on_row_dbl_clicked.emit(event);
  }
  getDataPath(data) {
    return data.path.split('/');
  };

  getSelectedRows() {
    const selectedNodes = this.gridApi.getSelectedNodes();
    return selectedNodes.map(node => node.data);
  }

  exportGrid() {
    this.gridApi.exportDataAsExcel();
  }



  onFilterTextBoxChanged() {
    if (this.server_filter)  this.on_row_text_changed.emit(this.search_word);
    else  this.gridApi.setQuickFilter(this.search_word);
  }

  autoSizeAll() {
    var allColumnIds = [];
    this.gridColumnApi.getAllColumns().forEach(function(column) {
      allColumnIds.push(column.colId);
    });
    this.gridColumnApi.autoSizeColumns(allColumnIds);
  }

}
