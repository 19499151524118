export { SettingsService } from "./settings.service";
export { AddressService } from "./address.service";
export { AuthorizationService } from "./authorization.service";
export { UserService } from './user.service';
export { FormsService } from './forms.service';
export { DynamicService } from './dynamic.service';
export { NotifyService } from './notify.service';
export { PacienteService } from './paciente.service';
export { CatalogService } from './catalog.service';
export { MedicoService } from './medico.service';
export { ConsultaService } from './consulta.service';

export { AuthorizationGuard } from './authorization.guard';
export { AutenticationGuard } from './autentication.guard';
