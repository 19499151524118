import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-especialidad-dialog',
  templateUrl: './especialidad-dialog.component.html',
  styles: []
})
export class EspecialidadDialogComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
