<form class="form-horizontal form-material" *ngIf="show_filter" >
    <div class="row">
        <div class="col-md-6"></div>
        <div class="col-md-6 text-right">
            <div class="form-group" style="margin-bottom: 5px">
                <input type="text" class="form-control" id="filter-text-box" placeholder="Buscar..." (keyup)="onFilterTextBoxChanged()"
                    [(ngModel)]="search_word" name="search_word" style="border: 1px solid #ced4da; border-radius: 0.25rem; background: #fff; height: 30px; min-height: 30px; padding: 0 10px; font-size:0.8rem;" />
            </div>
        </div>
    </div>
</form>



<ag-grid-angular #agGrid style="width: 100%;" [style.height]="gridHeight" class="ag-theme-balham" [localeText]="localeText"
    [rowMultiSelectWithClick]="rowMultiSelectWithClick" [suppressRowClickSelection]="suppressRowClickSelection"
    [enableColResize]="true" [pagination]="true" [enableSorting]="true" [enableFilter]="true" [treeData]="treeData"
    [animateRows]="true" [groupDefaultExpanded]="groupDefaultExpanded" [getDataPath]="getDataPath" [rowData]="rowData"
    [columnDefs]="columnDefs" [autoGroupColumnDef]="autoGroupColumnDef" [rowSelection]="rowSelection" 
    [getRowHeight]="getRowHeight" [rowClassRules]="rowClassRules"   [isRowSelectable]="isRowSelectable" [suppressCellSelection] = "true"
    

    [groupIncludeFooter]="groupIncludeFooter"
    [groupIncludeTotalFooter]="groupIncludeTotalFooter"

    (firstDataRendered)="onfirstDataRendered($event)" (rowSelected)="onRowSelected($event)" (rowClicked)="onRowClicked($event)" 
    (rowDoubleClicked)="onRowDoubleClicked($event)" (gridReady)="onGridReady($event)">
</ag-grid-angular>  