import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { consulta } from 'src/app/models/consulta.model';
import { ConsultaService } from 'src/app/services/consulta.service';
import { PacienteService } from 'src/app/services/paciente.service';
import { ScheduleService } from 'src/app/services/schedule.service';

@Component({
  selector: 'app-certificado',
  templateUrl: './certificado.component.html',
  styles: [
  ]
})
export class CertificadoComponent implements OnInit {
  @Input() consultaId: number;
  @Input() consulta: consulta;
  @ViewChild("form", { static: true }) form;
  
  certificado_form: FormGroup;
  certificado_path:string = "";
  exploracion: any[];

  constructor(public _fb: FormBuilder, public _scheduleService: ScheduleService, public _paciente: PacienteService, public _consulta: ConsultaService) { }

  ngOnInit(): void {   
    this.getData();
    this.certificado_form = this._fb.group({
      consulta_id: [ this.consultaId, [Validators.required]],
      estado: [this.consulta.estado_actual, [Validators.required]],
      observaciones: [this.consulta.observaciones],
      antecedentes: [this.consulta.antecedentes],
      resultado_exploracion: [this.consulta.resultado_exploracion],
    });


  }

  isNew(){
    return (this.consulta?.estado_actual != null || this.consulta?.estado_actual != undefined || this.consulta?.estado_actual?.length > 0)
  }

  is_invalid(control: string): boolean {
    return (this.form.submitted && this.certificado_form.controls[control].invalid) || (this.certificado_form.controls[control].invalid && (this.certificado_form.controls[control].dirty || this.certificado_form.controls[control].touched))
  }


  on_submit() {
    if (this.certificado_form.valid){
      console.log('enviado');
      console.log (this.certificado_form.value);

      let preloader = document.getElementById('preloader');
      preloader.style.display = 'block';


      this._consulta.insert_certificado(this.certificado_form.value).subscribe(
        data => {
            console.log('Ok');
            
            this._paciente.print_certificado(this.consultaId).subscribe(
              data => {
                console.log(data);
                
                let blob = new Blob([data], { type: 'application/pdf' });
                this.certificado_path = URL.createObjectURL(blob);
                window.open (this.certificado_path, '_blank');
                preloader.style.display = 'none';
              },
              err =>{ console.log(err);   preloader.style.display = 'none';}
            );

        },
        err =>{ console.log(err);   preloader.style.display = 'none';}
      );






    }


    
   
  }


  on_print() {
    
    let preloader = document.getElementById('preloader');
    preloader.style.display = 'block';

    this._paciente.print_certificado(this.consultaId).subscribe(
      data => {
        console.log(data);
        
        let blob = new Blob([data], { type: 'application/pdf' });
        this.certificado_path = URL.createObjectURL(blob);
        window.open (this.certificado_path, '_blank');
        preloader.style.display = 'none';
      },
      err =>{ console.log(err);   preloader.style.display = 'none';}
    );


  }



  async getData(){
     this._scheduleService.getDataDynamicForm(this.consultaId).subscribe(
      (data:any) => {
        this.exploracion = data?.fields;
        console.log( this.exploracion);
        
      }, 
      error => console.log(error));

  }




}
