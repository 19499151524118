export class address {
    address_id?: number = null;
    address_type_id?: number = null;
    country_id?: number = null;
    state_id?: number = null;
    municipality_id?: number = null;
    state: string = "";
    municipality: string = "";
    city: string = "";
    settlement: string = "";
    street: string = "";
    interior_number: string = "";
    exterior_number: string = "";
    reference: string = "";
    postal_code: string = "";
    latitude?: number = null;
    longitude?: number = null;
    saved_by?: number = null;

    constructor(data: address | {} = {}) {
        Object.assign(this, data);
    }

}

export class country {
    country_id?: number = null;
    name: string = "";
    code: string = "";
    is_active?: boolean = null;

    constructor(data: country | {} = {}) {
        Object.assign(this, data);
    }
}

export class state {
    state_id?: number = null;
    country_id?: number = null;
    name: string = "";
    is_active: boolean = null;

    constructor(data: state | {} = {}) {
        Object.assign(this, data);
    }
}

export class municipality {
    municipality_id?: number = null;
    state_id?: number = null;
    name: string = "";

    constructor(data: municipality | {} = {}) {
        Object.assign(this, data);
    }
}

export class settlement {
    settlement_id?: number = null;
    name: string = "";
    code: string = "";

    constructor(data: settlement | {} = {}) {
        Object.assign(this, data);
    }
}

export class search_address {
    country: country = new country({});
    state: state = new state({});
    municipality: municipality = new municipality({});
    settlement: settlement[] = [new settlement({})] 
}


