<app-buttons (button_selected)="option_selector($event)"></app-buttons>
<app-grid [rowData]="rowData" [columnDefs]="columnDefs" [rowSelection]="'single'"></app-grid>

<!-- Ventana modal para creación y actualización de permisos -->
<ng-template #modal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" *ngIf="!permission.permission_id">Nuevo Permiso</h4>
    <h4 class="modal-title" *ngIf="permission.permission_id">Actualizar Permiso</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-0">
    <div class="card m-b-0">
      <ul class="nav nav-tabs profile-tab" role="tablist">
        <li class="nav-item"> <a class="nav-link active" data-toggle="tab" href="#account" role="tab">Datos generales</a></li>
      </ul>
      <div class="tab-content">
        <div class="tab-pane active" id="account" role="tabpanel">
          <app-form [form_id] = "1" [data]="permission" [show_cancel_button] ="true" (close_modal)="modal.close('Cancelar')" (submit_form)="save_permission($event)"></app-form>
        </div>
      </div>
    </div>
  </div>
</ng-template>