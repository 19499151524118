import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { IFormGroup, IFormBuilder } from '@rxweb/types';
import { ToastrService } from 'ngx-toastr';
import { IMedicamento, Medicamento } from 'src/app/models/medicamento.model';
import { CatalogService, FormsService } from 'src/app/services/service.index';
import { GridComponent } from 'src/app/shared/components/grid.component';

@Component({
  selector: 'app-medicamentos-dialog',
  templateUrl: './medicamentos-dialog.component.html',
  styles: [
  ]
})
export class MedicamentosDialogComponent implements OnInit {
  @ViewChild("form", { static: true }) form;
  @ViewChild("grid_vias") grid_vias: GridComponent;
  @Output() submit_form = new EventEmitter<IFormGroup<Medicamento>>();
  @Output() close_modal = new EventEmitter();
  @Input()  show_cancel_button: boolean = false;
  @Input()  show_save_button: boolean = false;
  @Input()  show_close_button: boolean = false;
  @Input()  catalog: Medicamento;
  
  options = [{label:'Si', value:true}, {label:'No', value:false}];
  componentForm: IFormGroup<IMedicamento>;
  formBuilder: IFormBuilder; 
  catGruposMedicamento: any;
  catVias: any;

  vias_columndefs = [
    { headerName: "Vía", field: "nombre", width: 300 }
  ];
  vias = [];


  constructor(formbuilder: FormBuilder, public _frm:FormsService, public catalogService:CatalogService, private _toastr: ToastrService) { 
    this.formBuilder = formbuilder;    
  }

  ngOnInit() {    
    this.catalogService.get_catalog('grupos_medicamento',true).subscribe(data => {
      this.catGruposMedicamento = data;
    });
    this.catalogService.get_catalog('vias_administracion',true).subscribe(data => {
      this.catVias = data;
    });
    this.componentForm = this.formBuilder.group<IMedicamento>({
      medicamento_id: [this.catalog.medicamento_id],
      clave: [this.catalog.clave, Validators.required],
      nombre_generico: [this.catalog.nombre_generico, Validators.required],
      descripcion: [this.catalog.descripcion, Validators.required],
      cantidad: [this.catalog.cantidad, Validators.required],
      presentacion: [this.catalog.presentacion, Validators.required],
      grupo_id: [this.catalog.grupo_id, Validators.required],
      accion: [this.catalog.accion],
      controlado: [this.catalog.controlado, Validators.required],
      nombre_compuesto: [this.catalog.nombre_compuesto],
      tabla:[this.catalog.tabla],
      claveid:[this.catalog.claveid],
      basico:[this.catalog.basico],
      vias:[this.catalog.vias],
      txtVias: [""]
      });   
  }
  on_submit() {
    this.componentForm["vias"] = this.vias;
    console.log(`this.componentForm: ${JSON.stringify(this.componentForm.value)}`);
    this.submit_form.emit(this.componentForm);
  }
  on_close() {
    this.close_modal.emit();
  }

  add_via(){
    var viaId = Number.parseInt(this.componentForm.controls['txtVias'].value);
    var viaRepetido = false;
    this.catalog.vias = this.catalog.vias ?? [];

    if(Number.isNaN(viaId) || viaId == null){
      this._toastr.warning("Seleccione una vía", "Aviso")
    }else{
    this.catalog.vias.forEach((element)=>{ 
      if(viaId == element.via_administracion_id){
        viaRepetido = true;
      }
    });

    if (viaRepetido) {
      this._toastr.warning("La vía seleccionada ya se agregó con anterioridad", "Aviso")
    }else{
      this.catalog.vias.push({
        medicamento_id: this.catalog.medicamento_id ?? null,
        via_administracion_id: viaId,
        nombre: this.catVias.find(x => x.id == viaId).nombre,
      });
      this.grid_vias.gridApi.setRowData(this.catalog.vias);
    }  
    }      
  }

  remove_via(){
    console.log('REMOVE VIA');
    const selecteds = this.grid_vias.getSelectedRows();
    if (selecteds.length == 0) this._toastr.warning("Seleccione la vía que desea eliminar", "Aviso")
    else {
      let _via = selecteds[0];
      console.log(`_via:${JSON.stringify(_via)}`);
      this.catalog.vias = this.catalog.vias.filter(function (item) {
        return item.via_administracion_id !== _via.via_administracion_id;
      })
      console.log(`this.catalog.vias :${JSON.stringify(this.catalog.vias )}`);
      this.grid_vias.gridApi.setRowData(this.catalog.vias);
    }
  }
}
