import { Component, OnInit, ViewChild } from '@angular/core';
import { GridComponent } from '../../shared/components/grid.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup } from '@angular/forms';
import { Estudios } from 'src/app/models/estudios.model';
import { CatalogService, ConsultaService, FormsService } from 'src/app/services/service.index';

@Component({
  selector: 'app-estudios',
  templateUrl: './estudios.component.html',
  styles: [
  ]
})
export class EstudiosComponent implements OnInit {
  @ViewChild("modal", { static: true }) modal;
  @ViewChild(GridComponent, { static: true }) mygrid: GridComponent;

  rowData: any;
  columnDefs = [
    {headerName: "Código", field: "estudio_cod"},
    {headerName: "Descripción oficial", field: "estudio"},
    {headerName: "Descripción personalizada", field: "nombre_compuesto"},
    {headerName: "Grupo", field: "grupo"},
  ];

  _estudios: Estudios = new Estudios({});
  
  show_cancel_button = false;
  show_close_button = true;
  show_save_button = false;


  constructor(public _cat: CatalogService,
              public _consulta: ConsultaService, 
              private modalService: NgbModal,
              public _frm: FormsService) { }

  ngOnInit() {
    this.rowData = [];
  }

  onFilterTextBoxChanged(search_word) {
    if (search_word.length > 2 || search_word.length == 0) {
      this._consulta.search_estudio(search_word || '').subscribe(
        (data) => { console.log(data); this.rowData = data; },
        (err) => console.log(err)
      );
    }
  }

  option_selector(button: string) {
    console.log(button);
    
    switch (button) {
      case "create": {
        this.show_cancel_button = false;
        this.show_close_button = true;
        this.show_save_button = true;
        this._estudios = new Estudios({});
        this.modalService.open(this.modal, { size: 'lg' });
        break;
      }
      case "update": {
        this.show_cancel_button = false;
        this.show_close_button = true;
        this.show_save_button = true;
          this._frm.get_rows_selected(this.mygrid, false, (selecteds) => {
          this._estudios = selecteds[0];
          this.modalService.open(this.modal, { size: 'lg' })
          console.log(this._estudios );      
        });

        break;
      }
      case "view": {
        this.show_cancel_button = false;
        this.show_close_button = true;
        this.show_save_button = false;
        this._frm.get_rows_selected(this.mygrid, false, (selecteds) => {
        this._estudios = selecteds[0];
        this.modalService.open(this.modal, { size: 'lg' })
      });
        break;
      }
      case "delete": {
        this._frm.get_rows_selected(this.mygrid, true, (selecteds) => {
          this._estudios = selecteds[0];
          console.log(this._estudios.estudio_id)
          this._frm.show_warning("Borrar estudio(s)",
          "¿Estas seguro de eliminar el/los estudio(s) seleccionados? Una vez eliminado(s) no podrán recuperarse.",
        () => {
          selecteds.forEach(element => {
            this._estudios = element;
            this.delete_estudios(this._estudios.estudio_id)
          });
          }
        );
          //this._frm.show_warning('Borrar estudio', 'Estas seguro de eliminar el estudio [' + this._estudios.estudio + ']. Una vez eliminado no podra recuperarse.', () => this.delete_estudios(this._estudios.estudio_id));
        });
        break;
      }
    }
  }


  save_estudios(form: FormGroup) {
    var newForm = form.getRawValue();

    newForm.lonic = false;

    this._frm.save_module(form, "id", () => {
      console.log(form.value);
      this._cat.estudios_add(newForm).subscribe(
        (res: any) =>
        { this._frm.toast_alert("Estudio creada exitosamente", "Nuevo estudio", "success");
          this.modalService.dismissAll(null);
          this._consulta.search_estudio('prueba', null).subscribe(data => this.rowData = data, error => console.log(error));
        },
        error => this._frm.toast_alert("El estudio no pudo ser creado correctamente. Error: " + error.error.message, "Nuevo Estudio", "error")
      );
      
    }, () => {
       this._cat.estudios_add(newForm).subscribe(
        (res: any) =>
         {this._frm.toast_alert("Estudio actualizado exitosamente", "Actualizar Estudio", "success");
         this.modalService.dismissAll(null); 
         this._consulta.search_estudio('',null).subscribe(data => this.rowData = data, error => console.log(error));     
          },
         error => {
           this._frm.toast_alert("El estudio no pudo ser actualizado correctamente. Error: " + error.error.message, "Actualizar estudio", "error");
         }
       );
    });

  }

  delete_estudios(id:number) {
    this._cat.delete_estudios(id).subscribe(
      (res: any) => {this._frm.toast_alert("Estudio eliminado exitosamente", "Borrar estudios ", "success");
      this.modalService.dismissAll(null);
      this._consulta.search_estudio('prueba',null).subscribe(data => this.rowData = data, error => console.log(error));
    },
      error => { console.log(error); this._frm.toast_alert("El estudio no pudo ser actualizado correctamente. Error: " + error.error.message, "Borrar Tipo consulta", "error") }
    );
  }

}
