import { Component, OnInit, ViewChild } from '@angular/core';
import { CatalogService, FormsService } from 'src/app/services/service.index';
import { especialidad } from './../../models/medico.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GridComponent } from 'src/app/shared/components/grid.component';
import { catalog } from 'src/app/models/catalog.model';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-tipo-documento',
  templateUrl: './tipo-documento.component.html',
  styles: []
})
export class TipoDocumentoComponent implements OnInit {
  @ViewChild("modal", { static: true }) modal;
  @ViewChild(GridComponent, { static: true }) mygrid: GridComponent;

  rowData: any;
  columnDefs = [
    { headerName: "Nombre", field: "nombre"},
    { headerName: "Código", field: "codigo" },
    { headerName: "Descripción", field: "descripcion" }
  ];

  _td: catalog = new catalog({});
  constructor(public _cat: CatalogService, private modalService: NgbModal, public _frm: FormsService) { }

  ngOnInit() {
    this._cat.get_catalog("tipo_documento", true).subscribe(data => this.rowData = data, error => console.log(error));
  }
  option_selector(button: string) {
    console.log(button);

    switch (button) {
      case "create": {
        this._td = new catalog({});
        this.modalService.open(this.modal, { size: 'lg' });
        break;
      }
      case "update": {
        this._frm.get_rows_selected(this.mygrid, false, (selecteds) => {
          this._td = selecteds[0];
          this.modalService.open(this.modal, { size: 'lg' })
        });
        break;
      }
      case "delete": {
        this._frm.get_rows_selected(this.mygrid, false, (selecteds) => {
          this._td = selecteds[0];
          console.log(this._td.id)
          this._frm.show_warning('Borrar tipo docuemento', 'Estas seguro de eliminar el tipo de docuemento [' + this._td.nombre + ']. Una vez eliminado no podra recuperarse.', () => this.delete_td(this._td.id));
        });
        break;
      }
    }
  }

  delete_td(id: number) {
    this._cat.delete_catalog("tipo_documento", "tipo_documento", id).subscribe(
      (res: any) => {
        this._frm.toast_alert("Tipo documento eliminado exitosamente", "Borrar Tipo de documento ", "success");
        this.modalService.dismissAll(null);
        this._cat.get_catalog("tipo_documento", true).subscribe(data => this.rowData = data, error => console.log(error));
      },
      error => { console.log(error); this._frm.toast_alert("El Tipo de documento no pudo ser actualizado correctamente. Error: " + error.error.message, "Borrar Tipo consulta", "error") }
    );
  }
  save_td(form: FormGroup) {
    this._frm.save_module(form, "id", () => {
      this._cat.insert_catalog(form.value).subscribe(
        (res: any) => {
          this._frm.toast_alert("Tipo documento cread exitosamente", "Nueva Area", "success");
          this.modalService.dismissAll(null);
          this._cat.get_catalog("tipo_documento", true).subscribe(data => this.rowData = data, error => console.log(error));
        },
        error => this._frm.toast_alert("El Tipo documento no pudo ser creado correctamente. Error: " + error.error.message, "Nueva Tipo documento", "error")
      );

    }, () => {
      this._cat.update_catalog(form.value).subscribe(
        (res: any) => {
          this._frm.toast_alert("Tipo documento actualizado exitosamente", "Actualizar Tipo documento", "success");
          this.modalService.dismissAll(null);
          this._cat.get_catalog("tipo_documento", true).subscribe(data => this.rowData = data, error => console.log(error));
        },
        error => {
          this._frm.toast_alert("El Tipo documento no pudo ser actualizado correctamente. Error: " + error.error.message, "Actualizar Tipo documento", "error");
        }
      );
    });

  }

}
