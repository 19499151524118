<!--
<div class="card-body ">
  <div class="row">
    <div class="col-md-12">
      <app-grid  [autosize]="false" [rowData]="rowData" [columnDefs]="columnDefs" gridHeight="400px" [rowSelection]="'single'"  #grid1>
      </app-grid>
    </div>
  </div>
</div>
-->

<div class="card-body p-b-0">
  <div class="row">
    <div class="col-md-12">
      <ag-grid-angular 
      #agGgrid1rid
      style="width: 100%; height: 700px;"
      id="myGrid"
      class="ag-theme-balham"
      [columnDefs]="columnDefs" 
      [masterDetail]="true"
      [detailCellRendererParams]="detailCellRendererParams"
      [rowData]="rowData" 
      (gridReady)="onGridReady($event)"
      [enableColResize]="true"
    ></ag-grid-angular>
    </div>
  </div>
</div>
