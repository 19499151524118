import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot} from "@angular/router";
import { AuthorizationService } from "./authorization.service";

@Injectable({
  providedIn: "root"
})
export class AuthorizationGuard implements CanActivate {

  constructor(public _auth: AuthorizationService, public _router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //console.log(this._auth.get_module_list());
    //console.log(state.url);
    if (this._auth.get_module_list().includes(state.url)) {
      return true;
    } else {
      console.error("Acceso prohibido");
      this._router.navigate(["/error403"]);
      return false;
    }
  }
}
