<div class="card-body p-b-0">
  <form class="form-material" [formGroup]="td_form" (ngSubmit)="on_submit()" #form="ngForm"  novalidate>
      <div class="row">
          <div class="col-md-4">
              <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('nombre', td_form, form.submitted)}">
                  <label for="txt_name" [ngClass]="{ 'star_symbol': _frm.is_required('nombre', td_form) }">Nombre</label>
                  <input type="text" placeholder="Nombre" class="form-control" id="txt_name" formControlName="nombre">
                  <div class="invalid-feedback">
                      <div *ngIf="td_form.controls['nombre'].errors?.required">El nombre es obligatorio</div>
                  </div>
              </div>
          </div>
          <div class="col-md-4">
            <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('codigo', td_form, form.submitted)}">
                <label for="txt_codigo" [ngClass]="{ 'star_symbol': _frm.is_required('codigo', td_form) }">Duración</label>
                <input type="text" placeholder="Codigo" class="form-control" id="txt_codigo" formControlName="codigo">
            </div>
        </div>
        <div class="col-md-4">
          <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('descripcion', td_form, form.submitted)}">
              <label for="txt_descripcion" [ngClass]="{ 'star_symbol': _frm.is_required('descripcion', td_form) }">Descripción</label>
              <input type="text" placeholder="Descripcion" class="form-control" id="txt_descripcion" formControlName="descripcion">          
          </div>
      </div>
      </div>
      <div class="row m-t-30">
          <div class="col-md-12 modal-footer">
              <button *ngIf="show_cancel_button" type="button" class="btn btn-danger btn-sm" (click) = "on_close()" ><i class="mdi mdi-close"></i> Cancelar</button>
              <button type="submit" class="btn btn-primary btn-sm "><i class="mdi mdi-content-save"></i> Guardar</button>
          </div>
      </div>
  </form>
</div>
