import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { role } from '../../models/authorization.model';

@Component({
  selector: 'app-roles-dialog',
  templateUrl: './roles-dialog.component.html',
  styles: []
})
export class RolesDialogComponent implements OnInit {
  @ViewChild("form", { static: true }) form;

  @Output() submit_form = new EventEmitter<FormGroup>();
  @Output() close_modal = new EventEmitter();
  @Input()  role: role;
  @Input() show_cancel_button: boolean = false;

  role_form: FormGroup;

  constructor(private _fb: FormBuilder) { }

  ngOnInit() {
    this.role_form = this._fb.group({
      role_id: [this.role.role_id],
      name: [this.role.name, [Validators.required]],
      display_text: [this.role.display_text, [Validators.required]],
      description: [this.role.description]
    });
  }

  
  is_required(control: string): boolean {
    var _validator: any = this.role_form.controls[control].validator && this.role_form.controls[control].validator(this.role_form.controls[control]);
    return _validator && _validator.required;
  }
  is_invalid(control: string): boolean {
    return (this.form.submitted && this.role_form.controls[control].invalid) || (this.role_form.controls[control].invalid && (this.role_form.controls[control].dirty || this.role_form.controls[control].touched))
  }

  on_submit() {
    this.submit_form.emit(this.role_form);
  }
  on_close() {
    this.close_modal.emit();
  }
  
}
