<section id="wrapper" class="login-register login-sidebar"
    style="background-image:url(assets/images/background/back2.jpg);">
    <div class="login-box card">
        <div class="card-body">
            <form ngNativeValidate #f="ngForm" class="form-horizontal form-material" id="loginform"
                (ngSubmit)="ingresar(f)">
                <a href="javascript:void(0)" class="text-center db"><img src="assets/images/sw.png"
                        alt="Home" /><br /><img src="assets/images/swplus_.png" alt="Home" class="m-t-10" /></a>
                <div class="form-group m-t-40">
                    <div class="col-xs-12">
                        <label for="email">Nombre de usuario:</label>
                        <input ngModel name="email" class="form-control" type="email" placeholder="Usuario" required>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-xs-12">
                        <label for="password">Contraseña:</label>
                        <input ngModel name="password" class="form-control" type="password" placeholder="Contraseña"
                            required>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-6" style="display: none;">
                        <div class="checkbox checkbox-primary">
                            <input ngModel name="rememberme" id="checkbox-signup" type="checkbox"
                                class="filled-in chk-col-light-blue">
                            <label for="checkbox-signup"> Recordarme </label>
                        </div>

                    </div>
                    <div class="col-6"  style="display: none;">
                        <a [routerLink]="" (click)="forgotPassword()" id="to-recover" style="font-size: 12px; color: #263238; font-weight: 500;">
                            <i class="mdi mdi-lock" style="float:none"></i>
                            Olvide mi contraseña</a>
                    </div>
                </div> 
                <div *ngIf="errorMessage" class="form-group row">
                    <div class="col-12">
                        <h6 class="text-danger" style="font-size: 12px; font-weight: 500;">{{errorMessage}}</h6>
                    </div>
                </div>
                <div class="form-group text-center m-t-20">
                    <div class="col-xs-12">
                        <button class="btn btn-info btn-block text-uppercase" type="submit">Ingresar</button>
                    </div>
                </div>
            </form>
            <form class="form-horizontal" id="recoverform" action="index.html">
                <div class="form-group ">
                    <div class="col-xs-12">
                        <h3>Recover Password</h3>
                        <p class="text-muted">Enter your Email and instructions will be sent to you! </p>
                    </div>
                </div>
                <div class="form-group ">
                    <div class="col-xs-12">
                        <input class="form-control" type="text" required="" placeholder="Email">
                    </div>
                </div>
                <div class="form-group text-center m-t-20">
                    <div class="col-xs-12">
                        <button class="btn btn-primary btn-lg btn-block text-uppercase waves-effect waves-light"
                            type="submit">Reset</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>