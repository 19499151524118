import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {LicenseManager} from "ag-grid-enterprise";

declare var sw: any;

@Component({
  selector: "app-pages",
  templateUrl: "./pages.component.html",
  styles: []
})
export class PagesComponent implements OnInit {
  constructor(public _router: Router) {}

  ngOnInit() {
    sw.common.config_menu();
    LicenseManager.setLicenseKey("IRDEVELOPERS_COM_NDEwMjM0NTgwMDAwMA==f08aae16269f416fe8d65bbf9396be5f");
  }
}

