import { Component, OnInit } from '@angular/core';
import { FormsService } from 'src/app/services/forms.service';
import { MedicoService } from 'src/app/services/medico.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styles: [`
  
  .soluciones__cards {
    display: flex;
    gap: 5rem;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding-block-end: 2rem;
    padding-block-start: 2rem;
}

.soluciones__card {
    display: flex;
    flex: 10rem;
    flex-direction: row;
    max-inline-size: 45rem;
    min-height: 150px;
    overflow: hidden;
    border: 1px solid rgb(212, 208, 208);
    border-radius: 10px 10px 10px 10px;
    padding: 1em;
    box-shadow: 0 0 32px -16px rgb(0, 0, 0, .5);

}

.soluciones__card  img{ 
  width: 100%;
}

  `]
})
export class DashboardComponent implements OnInit {

  public dataSource:any = [];

  constructor(private ms:MedicoService, private _frm:FormsService) { }

  ngOnInit() {
    this.ms.get_articles().subscribe(
      (res: any) => {
        console.log(res);
        this.dataSource = res;
    },
      error => { 
        console.log(error); 
        this._frm.toast_alert("El paciente no pudo ser eliminado correctamente. Error: " + error.error.message, "Borrar paciente", "error") 
      }
    );
  }




}
