<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Cambio de contraseña</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body p-0">
        <div class="card m-b-0">
            <form class="form-material" [formGroup]="_form" (ngSubmit)="onSubmit(form)" #form="ngForm"  novalidate>
                <div class="col-md-12">
                    <div class="form-group" [ngClass]="{ 'has-danger': is_invalid('pass1')}">
                        <label for="txt_pass1" [ngClass]="{ 'star_symbol': is_required('pass1') }">Nueva contraseña</label>
                        <div class="input-group">
                            <input placeholder="" class="form-control" id="txt_pass1" formControlName="pass1" [type]="fieldTextType1 ? 'text' : 'password'">
                        <div class="input-group-append">
                            <span>
                              <i class="fa"
                              [ngClass]="{
                                'fa-eye-slash': !fieldTextType1,
                                'fa-eye': fieldTextType1
                              }"
                              (click)="toggleFieldTextType1()"></i>
                            </span>
                         </div>
                        </div>                        
                        <div class="invalid-feedback">
                            <div *ngIf="_form.controls['pass1'].errors?.required">Este campon es obligatorio</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group" [ngClass]="{ 'has-danger': is_invalid('pass2')}">
                        <label for="txt_pass2" [ngClass]="{ 'star_symbol': is_required('pass2') }">Ingrese nuevamente su contraseña</label>
                        <div class="input-group">
                            <input placeholder="" class="form-control" id="txt_pass2" formControlName="pass2" [type]="fieldTextType2 ? 'text' : 'password'">
                        <div class="input-group-append">
                            <span>
                              <i class="fa"
                              [ngClass]="{
                                'fa-eye-slash': !fieldTextType2,
                                'fa-eye': fieldTextType2
                              }"
                              (click)="toggleFieldTextType2()"></i>
                            </span>
                         </div>
                        </div>                        
                        <div class="invalid-feedback">
                            <div *ngIf="_form.controls['pass2'].errors?.required">Este campon es obligatorio</div>
                        </div>
                    </div>
                </div>
                <div class="form-group text-center m-t-20">
                    <div class="col-md-12">
                        <button type="submit" class="btn btn-primary btn-sm "><i class="mdi mdi-content-save"></i> Cambiar</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>