import { Component, OnInit, ViewChild } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { AuthorizationService, PacienteService, FormsService } from "src/app/services/service.index";
import { GridComponent } from '../../shared/components/grid.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { paciente } from '../../models/paciente.model';
import { AccountComponent } from '../../shared/forms/account.component';
import { FormGroup } from "@angular/forms";

declare var sw: any;
import * as moment from 'moment';


@Component({
  selector: 'app-paciente',
  templateUrl: './paciente.component.html',
  styles: []
})
export class PacienteComponent implements OnInit {
  @ViewChild("content", { static: true }) content;
  @ViewChild(GridComponent, { static: true }) mygrid: GridComponent;

  paciente: paciente = new paciente({});

  rowData: any;
  columnDefs = [
    { headerName: "No Registro", field: "numero_registro" },
    { headerName: "Paciente", field: "nombre_completo" },
    { headerName: "CURP", field: "curp" },
    {
      headerName: "Fecha de nacimiento", field: "fecha_nacimiento", cellRenderer: (data) => {
        return data.value ? moment(new Date(data.value)).format('MM/DD/YYYY') : '';
      }
    },
    { headerName: "Sexo", field: "sexo" }
  ];

  public currentPdf: string = null;
  
  constructor(public _auth: AuthorizationService, public _paciente: PacienteService, public _frm: FormsService, private _toastr: ToastrService, private modalService: NgbModal) { }

  ngOnInit() {
    this.rowData = [];
  }

  onFilterTextBoxChanged(search_word) {
    if (search_word.length > 2 || search_word.length == 0) {
      this._paciente.search_paciente(search_word || '').subscribe(
        data => { console.log(data); this.rowData = data; },
        err => console.log(err)
      );
    }
  }

  option_selector(button: string) {
    console.log('option_selector:' + button);
    switch (button) {
      case "create": {
        this.paciente = new paciente({}); 
        this.modalService.open(this.content, { backdrop:'static', windowClass: "xlg" });
        sw.common.init_plugins();
        console.log(this.paciente)

        break;
      }
      case "update": {

        const selecteds = this.mygrid.getSelectedRows();
        if (selecteds.length == 0) this._toastr.warning("Seleccione un paciente del listado", "Expediente de paciente")
        else {
          let selected = selecteds[0];
          this._paciente.get_paciente(selected.paciente_id).subscribe(
            (data: any) => { 
              this.paciente = data; 
              console.log(this.paciente); 
              this.paciente.flat=2;
              this.modalService.open(this.content, { backdrop:'static', windowClass: "xlg" });
              sw.common.init_plugins();
            },
            err => console.log(err)
          );

        }
        break;
      }
      case "view": {

        const selecteds = this.mygrid.getSelectedRows();
        if (selecteds.length == 0) this._toastr.warning("Seleccione un paciente del listado", "Expediente de paciente")
        else {
          let selected = selecteds[0];
          this._paciente.get_paciente(selected.paciente_id).subscribe(
            (data: any) => { 
              this.paciente = data; 
              console.log(this.paciente);
              
              // Validar si es médico
              var user = JSON.parse(localStorage.getItem("user"));
               console.log('médico:' + user["role_id"]);

               if((user["medico_id"] == null) && (user["role_id"] == 1)){
                  this.paciente.flat = 2;
               }else if(user["medico_id"] != null) {
                  this.paciente.flat = 2;
               }else{
                  this.paciente.flat = 1;
               }
             
               console.log( this.paciente.flat);
               
              // if(user["medico_id"] != null){
              //   console.log('médico:' + user["role_id"])
              //   this.paciente.flat = this.verifyApponitments(data,user["medico_id"]) ? 2 : 0;
              // }else{
              //   this.paciente.flat = 2;
              //   console.log('médico:' + user["medico_id"]);
              //   console.log('rol:' + user["role_id"]);
              // }

              this.modalService.open(this.content, { backdrop:'static', windowClass: "xlg" });
              sw.common.init_plugins();
            },
            err => console.log(err)
          );
        }
        break;
      }
      case "print": {
        let preloader = document.getElementById('preloader');
        preloader.style.display = 'block';

        this._paciente.print_pacientes(this.rowData).subscribe(
          data => {
            let blob = new Blob([data], { type: 'application/pdf' });
            //saveAs(blob, 'test.pdf');
            
            this.currentPdf = URL.createObjectURL(blob);
            window.open(this.currentPdf, '_blank');
            preloader.style.display = 'none';
          },
          err => console.log(err)
        );
        break;
      }
      case "delete": {
        const selecteds = this.mygrid.getSelectedRows();
        if (selecteds.length == 0) this._toastr.warning("Seleccione un paciente del listado", "Expediente de paciente")
        else {
          this._frm.get_rows_selected(this.mygrid, false, (selecteds) => {
            this.paciente = selecteds[0];
            this._frm.show_warning('Borrar paciente', 'Estas seguro de eliminar el paciente [' + this.paciente.nombre_completo + ']. Una vez eliminado no podra recuperarse.', () => this.delete_paciente(this.paciente.paciente_id));
          });
        }      
        break;
      }
    }
  }
  delete_paciente(id:number) {
    this._paciente.delete_paciente(id).subscribe(
      (res: any) => {this._frm.toast_alert("Paciente eliminado exitosamente", "Borrar paciente ", "success");
       this.rowData = [];
    },
      error => { console.log(error); this._frm.toast_alert("El paciente no pudo ser eliminado correctamente. Error: " + error.error.message, "Borrar paciente", "error") }
    );
  }

  //Verifica las consultas que tiene el paciente, si alguna de ellas pertenece al médico firmado regresará true.
  verifyApponitments(patient:paciente, medicoId: number): boolean{
    var returnValue = false;
    patient["consultas"].forEach(element => {
      if(element.medico_id === medicoId){
        returnValue = true;
      }
    });
    return returnValue;
  }


}
