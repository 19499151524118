import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, Validators } from '@angular/forms';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2/dist/sweetalert2.js';  
import { AuthorizationService } from '../services/authorization.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})

export class ChangePasswordComponent implements OnInit {
  @ViewChild("content", { static: true }) content;
  _form: FormGroup;
  submitted:boolean = false;
  row_id: String;
  fieldTextType1: boolean;
  fieldTextType2: boolean;
  
  constructor(private modalService: NgbModal,
    private _fb: FormBuilder, 
    private activatedRoute: ActivatedRoute,
    public _auth: AuthorizationService,
    private router: Router) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.row_id = params['row_id'] ?? '';
  });
   }

  ngOnInit(): void {
    this._form = this._fb.group({
      row_id: [this.row_id],
      pass1: ['', [Validators.required]],
      pass2: ['', [Validators.required]],
    });
    this.modalService.open(this.content, { size: 'md' });
  }

  is_required(control: string): boolean {
    var _validator: any = this._form.controls[control].validator && this._form.controls[control].validator(this._form.controls[control]);
    return _validator && _validator.required;
  }
  is_invalid(control: string): boolean {
    return (this.submitted && this._form.controls[control].invalid) || (this._form.controls[control].invalid && (this._form.controls[control].dirty || this._form.controls[control].touched));
  }

  async onSubmit(form: NgForm){
    this.submitted = true;
    let p1: String = form.value.pass1.trim();
    let p2: String = form.value.pass2.trim();

    if(p1.length == 0){
      return false;
    }
    else if(p1 === p2){
      await this._auth.changePassword(p1,form.value.row_id)
          .then(response => {
            //console.log(`response:${JSON.stringify(response)}`);
            //console.log(`code:${response.status}`);
            
            if(response.status == 200){
              Swal.fire({
                title: `Su contraseña ha sido cambiada`
              }).then(()=>{ 
                this.modalService.dismissAll(null);
                this.router.navigate(['/login']);
              })
            }else{
              Swal.fire({
                title: `Ocurrio un error..`
              }) 
            }
            
            
          })
          .catch(error => {
            //console.log(`error:${JSON.stringify(error)}`);
            let errorMessage = error['error']['error'] ?? error['error'] ?? error['message'] ?? error['statusText'] ?? 'Ocurrio un error..';
            //console.log(`errorMessage:${errorMessage}`);
            Swal.fire(
              {title: `Error: ${errorMessage}`}
            )
          })           
    }else{
      Swal.fire({
        title: `Las contraseñas no coinciden`
      })
    }
  }

  toggleFieldTextType1() {
    this.fieldTextType1 = !this.fieldTextType1;
  }
  toggleFieldTextType2() {
    this.fieldTextType2 = !this.fieldTextType2;
  }

}
