<app-buttons (button_selected)="option_selector($event)"></app-buttons>
<app-grid [rowData]="rowData" [columnDefs]="columnDefs" [rowSelection]="'single'"></app-grid>


<!-- Ventana modal para creación y actualización de consultorios -->
<ng-template #modal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" *ngIf="!consultorio.consultorio_id">Nuevo Consultorio</h4>
        <h4 class="modal-title" *ngIf="consultorio.consultorio_id">Actualizar Consultorio</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body p-0">
        <div class="card m-b-0">
            <ul class="nav nav-tabs profile-tab" role="tablist">
                <li class="nav-item"> <a class="nav-link active" data-toggle="tab" href="#account" role="tab">Datos generales</a></li>
            </ul>
            <div class="tab-content">
                <div class="tab-pane active" id="account" role="tabpanel">
                    <app-consultorios-dialog  [consultorio]="consultorio" (close_modal)="modal.close('Cancelar')" (submit_form)="save_consultorio($event)"></app-consultorios-dialog>
                </div>
            </div>
        </div>
    </div>
</ng-template>


<!-- Ventana modal para la administración de horarios de los consultorios -->
<ng-template #modal2 let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Administración de horarios</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body p-0">
        <div class="card m-b-0">
            <app-consultorios-horario [consultorio]= "consultorio" (submit_form)="save_consultorio_horario($event)"></app-consultorios-horario>
        </div>
    </div>
</ng-template>
