import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FormsService } from 'src/app/services/service.index';
import { catalog } from 'src/app/models/catalog.model';

@Component({
  selector: 'app-areas-dialog',
  templateUrl: './areas-dialog.component.html',
  styles: []
})
export class AreasDialogComponent implements OnInit {
  @ViewChild("form", { static: true }) form;
  @Output() submit_form = new EventEmitter<FormGroup>();
  @Output() close_modal = new EventEmitter();
  @Input()  show_cancel_button: boolean = false;
  @Input()  area: catalog;
  options: any = [{label:'Si', value:true}, {label:'No', value:false}];

  areas_form: FormGroup;
  constructor(private _fb: FormBuilder, public _frm:FormsService) { }

  ngOnInit() {
    this.areas_form = this._fb.group({
      id: [this.area.id],
      nombre: [this.area.nombre, [Validators.required]],
      codigo: [this.area.codigo],
      descripcion: [this.area.descripcion],
      tabla: [this.area.tabla="area"],
      claveid: [this.area.claveid="area"],
    });
  }
  on_submit() {
    this.submit_form.emit(this.areas_form);
  }
  on_close() {
    this.close_modal.emit();
  }
}
