export interface IEstudios{
    estudio_id: number;
    estudio_cod: number;
    estudio: string;
    grupo_id: number;
    nombre_compuesto: string;
    lonic: boolean;
}

export class Estudios implements IEstudios{
    estudio_id: number;
    estudio_cod: number;
    estudio: string;
    grupo_id: number;
    nombre_compuesto: string;
    lonic: boolean;

    constructor(data: Estudios | {} = {}) {
        Object.assign(this, data);
    }
}