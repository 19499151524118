<div class="card-body p-b-0">
    <form class="form-material" [formGroup]="componentForm" (ngSubmit)="on_submit()" #form="ngForm"  novalidate>
        <div class="row">
            <div class="col-md-4">
                <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('nombre_generico', componentForm, form.submitted)}">
                    <label for="txt_name" [ngClass]="{ 'star_symbol': _frm.is_required('nombre_generico', componentForm) }">Nombre genérico</label>
                    <input type="text" placeholder="nombre_generico" class="form-control" id="txt_name" formControlName="nombre_generico">
                    <div class="invalid-feedback">
                        <div *ngIf="componentForm.controls['nombre_generico'].errors?.required">El nombre es obligatorio</div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
              <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('cantidad', componentForm, form.submitted)}">
                  <label for="txt_cantidad" [ngClass]="{ 'star_symbol': _frm.is_required('cantidad', componentForm) }">Cantidad</label>
                  <input type="text" placeholder="cantidad" class="form-control" id="txt_cantidad" formControlName="cantidad">
                  <div class="invalid-feedback">
                    <div *ngIf="componentForm.controls['cantidad'].errors?.required">El campo es obligatorio</div>
                </div>
              </div>
          </div>
          <div class="col-md-4">
            <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('descripcion', componentForm, form.submitted)}">
                <label for="txt_descripcion" [ngClass]="{ 'star_symbol': _frm.is_required('descripcion', componentForm) }">Descripción</label>
                <textarea type="text" placeholder="Descripcion" class="form-control" id="txt_descripcion" formControlName="descripcion"></textarea>
                <div class="invalid-feedback">
                    <div *ngIf="componentForm.controls['descripcion'].errors?.required">El campo es obligatorio</div>
                </div> 
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('clave', componentForm, form.submitted)}">
                <label for="txt_clave" [ngClass]="{ 'star_symbol': _frm.is_required('clave', componentForm) }">Clave</label>
                <input type="text" placeholder="clave" class="form-control" id="txt_clave" formControlName="clave">
                <div class="invalid-feedback">
                    <div *ngIf="componentForm.controls['clave'].errors?.required">El campo es obligatorio</div>
                </div>     
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('controlado', componentForm, form.submitted)}">
                <label for="txt_controlado" [ngClass]="{ 'star_symbol': _frm.is_required('controlado', componentForm) }">Controlado</label>
                <!--<input type="text" placeholder="controlado" class="form-control" id="txt_controlado" formControlName="controlado">-->
                <!--
                    <select formControlName="controlado" class="form-control" id="txt_controlado">
                    <option *ngFor="let option of options" [ngValue]=option.value>
                      {{ option.label }}
                    </option>
                  </select>
                -->
                <ng-select id="ddl_controlado" [searchable]="true" placeholder="Controlado" formControlName="controlado" class="form-control"
                           [items]="options"  bindLabel="label" bindValue="value">
                </ng-select>
                
                  <div class="invalid-feedback">
                    <div *ngIf="componentForm.controls['controlado'].errors?.required">El campo es obligatorio</div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('grupo_id', componentForm, form.submitted)}">
                <label for="txt_grupo_id" [ngClass]="{ 'star_symbol': _frm.is_required('grupo_id', componentForm) }">Grupo</label>
                <!--<input type="text" placeholder="grupo_id" class="form-control" id="txt_grupo_id" formControlName="grupo_id">-->   
                <!--
                <select formControlName="grupo_id" class="form-control" id="txt_grupo_id">
                    <option *ngFor="let option of catGruposMedicamento" [ngValue]=option.id>
                        {{ option.nombre }}
                    </option>
                </select>
                -->
                <ng-select id="ddl_grupoo" [searchable]="true" placeholder="Grupo" formControlName="grupo_id" class="form-control"
                           [items]="catGruposMedicamento"  bindLabel="nombre" bindValue="id">
                </ng-select>
                <div class="invalid-feedback">
                    <div *ngIf="componentForm.controls['grupo_id'].errors?.required">El campo es obligatorio</div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('presentacion', componentForm, form.submitted)}">
                <label for="txt_presentacion" [ngClass]="{ 'star_symbol': _frm.is_required('presentacion', componentForm) }">Presentación</label>
                <input type="text" placeholder="presentacion" class="form-control" id="txt_presentacion" formControlName="presentacion">
                <div class="invalid-feedback">
                    <div *ngIf="componentForm.controls['presentacion'].errors?.required">El campo es obligatorio</div>
                </div>    
            </div>
        </div>
        <div class="col-md-8">
            <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('txtVias', componentForm, form.submitted)}">
                <label for="txt_vias" [ngClass]="{ 'star_symbol': _frm.is_required('txtVias', componentForm) }">Vías</label>
                <ng-select id="ddl_vias" [searchable]="true" placeholder="Vías" formControlName="txtVias" class="form-control"
                           [items]="catVias"  bindLabel="nombre" bindValue="id">
                </ng-select>       
            </div>
            <div class="btn-group btn-group-justified btn-group-sm" style="margin:0 0 10px 0;">
                <button  type="button" class="btn btn-secondary" ngbTooltip="Agregar vía" (click)="add_via()"><i class="mdi mdi-plus-box"></i></button>
                <button  type="button" class="btn btn-secondary" ngbTooltip="Eliminar vía" (click)="remove_via()"><i class="mdi mdi-delete"></i></button>
              </div>               
            <app-grid #grid_vias [rowSelection]="'single'" [rowData]="this.catalog.vias" [columnDefs]="vias_columndefs"  [autosize]="false" [gridHeight]="'200px'"></app-grid>
        </div>
        </div>
        <div class="row m-t-30">
            <div class="col-md-12 modal-footer">
                <button *ngIf="show_cancel_button" type="button" class="btn btn-danger btn-sm" (click) = "on_close()" ><i class="mdi mdi-close"></i> Cancelar</button>
                <button *ngIf="show_close_button" type="button" class="btn btn-danger btn-sm" (click) = "on_close()" ><i class="mdi mdi-close"></i> Cerrar</button>
                <button *ngIf="show_save_button" type="submit" class="btn btn-primary btn-sm "><i class="mdi mdi-content-save"></i> Guardar</button>
            </div>
        </div>
    </form>
  </div>
  