import { Input, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { module_authorize, permission_authorize, user } from 'src/app/models/authorization.model';
import { Especialidades } from 'src/app/models/especialidades.model';
import { UserResponse, Especialidad } from 'src/app/models/user_response.model';
import { CatalogService } from 'src/app/services/catalog.service';
import { FormsService } from 'src/app/services/forms.service';
import { ScheduleService } from 'src/app/services/schedule.service';
import { UserService } from 'src/app/services/user.service';
import { GridComponent } from 'src/app/shared/components/grid.component';

@Component({
  selector: 'app-share-schedule-dialog',
  templateUrl: './share-schedule-dialog.component.html',
  styleUrls: ['./share-schedule-dialog.component.css']
})
export class ShareScheduleDialogComponent implements OnInit {
  @Input() role_id: number = 0;
  @Input() user_id: number = 0;
  @ViewChild("gridAviable", { static: true }) gridAviable: GridComponent;
  @ViewChild("gridShared", { static: true }) gridShared: GridComponent;

  rowData1: any;
  columnDefs = [
    { headerName: "Nombre", field: "username" },
    { headerName: "Perfil", field: "role" },
    { headerName: "Especialidades", field: "user_preferences" }
  ];
  rowData2: any;
  columnDefs2 = [
    { headerName: "Nombre", field: "username" },
    { headerName: "Perfil", field: "role" },
    { headerName: "Especialidades", field: "user_preferences" }
  ];

  constructor(public _usrs: UserService, public _frm: FormsService, public _catalog: CatalogService, public _scheduleService: ScheduleService, private _toastr: ToastrService) { }

  ngOnInit(): void {    
    this.get_data1();
    this.get_data2();
  }

  get_data1(){
    this._scheduleService.getUsersForAssociate(this.user_id).subscribe(
      users => {
        this.rowData1 = users; 
    },
    err => console.log(err));
  }

  get_data2(){
    this._scheduleService.getUsersAssociates(this.user_id).subscribe(
      users => {
        this.rowData2 = users; 
    },
    err => console.log(err));
  }

  addUserToShareSchedule(){
    if (this.gridAviable.getSelectedRows().length == 0){
      this._toastr.warning("Seleccione un usuario", "Aviso")
    }else{
      const selected = this.gridAviable.getSelectedRows()[0];
      this._scheduleService.addUserToShareSchedule(this.user_id,selected["user_id"]).subscribe(
        _ => {
          this.get_data1();
          this.get_data2();
      },
      err => console.log(err));
      }    
  }

  deleteUserToShareSchedule(){
    if (this.gridShared.getSelectedRows().length == 0){
      this._toastr.warning("Seleccione un usuario", "Aviso")
    }else{
      const selected = this.gridShared.getSelectedRows()[0];
      this._scheduleService.deleteUserToShareSchedule(this.user_id,selected["user_id"]).subscribe(
        _ => {
          this.get_data1();
          this.get_data2();
      },
      err => console.log(err));
    }    
  }

}
