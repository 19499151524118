import { Component, OnInit, ViewChild } from '@angular/core';
import { GridComponent } from '../../shared/components/grid.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup } from '@angular/forms';
import { Procedimientos } from 'src/app/models/procedimientos.model';
import { CatalogService, ConsultaService, FormsService } from 'src/app/services/service.index';
import { IProcedimientos } from '../../models/procedimientos.model';

@Component({
  selector: 'app-procedimientos',
  templateUrl: './procedimientos.component.html',
  styles: [
  ]
})
export class ProcedimientosComponent implements OnInit {
  @ViewChild("modal", { static: true }) modal;
  @ViewChild(GridComponent, { static: true }) mygrid: GridComponent;

  rowData: any;
  columnDefs = [
    {headerName: "Clave", field: "procedimiento_cod"},
    {headerName: "Descripción oficial", field: "nombre"},
    {headerName: "Descripción personalizada", field: "nombre_compuesto"},
  ];
  autoGroupColumnDef = [
    {
      headerName: "Nombre",
      cellRendererParams: {
        suppressCount: true,
        checkbox: true
      }
    }
  ];

  _procedimientos: Procedimientos = new Procedimientos({});
  
  show_cancel_button = false;
  show_close_button = true;
  show_save_button = false;


  constructor(public _cat: CatalogService,
              public _consulta: ConsultaService, 
              private modalService: NgbModal,
              public _frm: FormsService) { }

  ngOnInit() {
    this.rowData = [];
  }

  onFilterTextBoxChanged(search_word) {
    if (search_word.length > 2 || search_word.length == 0) {
      this._consulta.search_procedimiento(search_word || '').subscribe(
        data => { 
          console.log(data);
          let newData = data as IProcedimientos[];
          this.rowData = newData; 
        },
        err => console.log(err)
      );
    }
  }

  option_selector(button: string) {
    console.log(button);
    
    switch (button) {
      case "create": {
        this.show_cancel_button = false;
        this.show_close_button = true;
        this.show_save_button = true;
        this._procedimientos = new Procedimientos({});
        this.modalService.open(this.modal, { size: 'lg' });
        break;
      }
      case "update": {
        this.show_cancel_button = false;
        this.show_close_button = true;
        this.show_save_button = true;
          this._frm.get_rows_selected(this.mygrid, false, (selecteds) => {
          this._procedimientos = selecteds[0];
          this.modalService.open(this.modal, { size: 'lg' })
          console.log(this._procedimientos );      
        });

        break;
      }
      case "view": {
        this.show_cancel_button = false;
        this.show_close_button = true;
        this.show_save_button = false;
        this._frm.get_rows_selected(this.mygrid, false, (selecteds) => {
        this._procedimientos = selecteds[0];
        this.modalService.open(this.modal, { size: 'lg' })
      });
        break;
      }
      case "delete": {
        this._frm.get_rows_selected(this.mygrid, true, (selecteds) => {
          this._procedimientos = selecteds[0];
          console.log(this._procedimientos.procedimiento_id)
          this._frm.show_warning("Borrar Procedimiento(s)",
          "¿Estas seguro de eliminar el los procedimiento(s) seleccionados? Una vez eliminado(s) no podrán recuperarse.",
          () => {
              selecteds.forEach(element => {
                this._procedimientos = element;
                this.delete_procedimientos(this._procedimientos.procedimiento_id);
              });
            }
          );
        });
        break;
      }
    }
  }


  save_procedimientos(form: FormGroup) {
    var newForm = form.getRawValue();

    newForm.cie9 = false;
  
    this._frm.save_module(form, "id", () => {
      console.log(form.value);
      this._cat.procedimientos_add(newForm).subscribe(
        (res: any) =>
        { this._frm.toast_alert("Procedimiento creado exitosamente", "Nuevo procedimiento", "success");
          this.modalService.dismissAll(null);
          this._consulta.search_procedimiento('', null).subscribe(data => this.rowData = data, error => console.log(error));
        },
        error => this._frm.toast_alert("El procedimiento no pudo ser creado correctamente. Error: " + error.error.message, "Nuevo Procedimiento", "error")
      );
      
    }, () => {
       this._cat.procedimientos_add(newForm).subscribe(
        (res: any) =>
         {this._frm.toast_alert("Procedimiento actualizado exitosamente", "Actualizar Procedimiento", "success");
         this.modalService.dismissAll(null); 
         this._consulta.search_procedimiento('',null).subscribe(data => this.rowData = data, error => console.log(error));     
          },
         error => {
           this._frm.toast_alert("El procedimiento no pudo ser actualizado correctamente. Error: " + error.error.message, "Actualizar Procedimiento", "error");
         }
       );
    });

  }

  delete_procedimientos(id:number) {
    this._cat.delete_procedimientos(id).subscribe(
      (res: any) => {this._frm.toast_alert("Procedimiento eliminado exitosamente", "Borrar Procedimientos ", "success");
      this.modalService.dismissAll(null);
      this._consulta.search_procedimiento('',null).subscribe(data => this.rowData = data, error => console.log(error));
    },
    error => this._frm.toast_alert("Error: " + error.error.message, "Ocurrio un error", "error")
    );
  }

}
