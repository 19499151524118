import { Component, OnInit, ViewChild } from '@angular/core';
import { CatalogService, FormsService } from 'src/app/services/service.index';
import { article, especialidad } from './../../models/medico.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GridComponent } from 'src/app/shared/components/grid.component';
import { MedicoService } from 'src/app/services/medico.service';
import * as moment from 'moment';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-articulos',
  templateUrl: './articulos.component.html',
  styles: [
  ]
})
export class ArticulosComponent implements OnInit {

  @ViewChild("modal", { static: true }) modal;
  @ViewChild(GridComponent, { static: true }) mygrid: GridComponent;
  
  rowData: any;
  columnDefs = [
    { headerName: "Título", field: "titulo"},
    { headerName: "Autor", field: "autor" },
    { headerName: "Descripción", field: "descripcion" },
    {
      headerName: "Publicación", field: "fecha_publicacion", cellRenderer: (data) => {
        return data.value ? moment(new Date(data.value)).format('MM/DD/YYYY') : '';
      }
    },
  ];

  _row_selected:article;

  constructor( public _cat: CatalogService, private _ms:MedicoService, private modalService: NgbModal, public _frm: FormsService) { }

  ngOnInit() {
    this.refesh_data();
  }



  option_selector(button: string) {
    console.log(button);
    
    switch (button) {
      case "create": {
        this._row_selected = new article({});
        this.modalService.open(this.modal, { size: 'lg' });
        break;
      }
      case "update": {
        this._frm.get_rows_selected(this.mygrid, false, (selecteds) => {
          this._row_selected = selecteds[0];
          this.modalService.open(this.modal, { size: 'lg' })
        });
        break;
      }
      case "delete": {
        this._frm.get_rows_selected(this.mygrid, false, (selecteds) => {
          this._row_selected = selecteds[0];
          this._frm.show_warning('Borrar artículo', 'Estas seguro de eliminar el artículo [' + this._row_selected.titulo + ']. Una vez eliminado no podra recuperarse.',
           () => this.delete_articulo(this._row_selected.articulo_id));
        });
        break;
      }
      case "view": {
        this._frm.get_rows_selected(this.mygrid, false, (selecteds) => {
          this._row_selected = selecteds[0];
          window.open(this._row_selected.ruta_archivo, "_blank");
        });
        break;
      }
    }
  }


  refesh_data() {
    this._ms.get_articles().subscribe(data => {this.rowData = data; console.log(this.rowData);}, error => console.log(error));
  }

  delete_articulo(id:number) {
    this._ms.delete_article(id).subscribe(
      (res: any) => {this._frm.toast_alert("Artículo eliminado exitosamente", "Borrar Area ", "success");
      this.modalService.dismissAll(null);
      this.refesh_data();
    },
      error => { console.log(error); this._frm.toast_alert("El Artículo no pudo ser eliminado correctamente. Error: " + error.error.message, "Eliminar Artículo", "error") }
    );
  }

  save_articulo(form: FormGroup) {
    this._frm.save_module(form, "articulo_id", 
    () => {
      this._ms.create_articulo(form.value).subscribe(
        (res: any) =>
        { this._frm.toast_alert("Artículo creado exitosamente", "Nuevo Artículo", "success");
          this.modalService.dismissAll(null);
          this.refesh_data();
        },
        error => this._frm.toast_alert("El artículo no pudo ser creado correctamente. Error: " + error.error.message, "Nuevo Artículo", "error")
      );
    }, 
    () => {
       this._ms.update_articulo(form.value).subscribe(
        (res: any) =>
         {this._frm.toast_alert("Artículo actualizado exitosamente", "Actualizar Artículo", "success");
         this.modalService.dismissAll(null); 
         this.refesh_data();   
          },
         error => {
           this._frm.toast_alert("El Artículo no pudo ser actualizado correctamente. Error: " + error.error.message, "Actualizar Artículo", "error");
         }
       );
    });

  }


}
