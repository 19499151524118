
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { AuthorizationService } from './authorization.service';
import { environment } from '../../environments/environment';
import { person } from '../models/authorization.model';
import { paciente, almacenamiento } from '../models/paciente.model';
import { catalog } from '../models/catalog.model';
import { tipo_cosulta } from '../models/consulta.model';
import { Medicamento } from '../models/medicamento.model';
import { Diagnostico } from '../models/diagnostico.model';
import { Procedimientos } from '../models/procedimientos.model';
import { Estudios } from '../models/estudios.model';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {

  constructor(public http: HttpClient, public _auth: AuthorizationService) { }

  get_catalog(tabla: string, activo?: boolean) {
    let url = environment.apiEndpoint + "catalog/get_catalogos";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
      params: new HttpParams().set('tabla', tabla).set('activo', activo ? "1" : "0"),
    };
    return this.http.get(url, httpOptions);
  }

  get_almacenamiento(alm: almacenamiento) {
    let url = environment.apiEndpoint + "catalog/almacenamiento_get";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
    };
    return this.http.post(url, alm, httpOptions);
  }

  get_tipo_consulta() {
    let url = environment.apiEndpoint + "catalog/tipo_consulta_get";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
    };
    return this.http.get(url, httpOptions);
  }
  insert_tipo_consulta(tp: tipo_cosulta) {
    let url = environment.apiEndpoint + "catalog/tipo_consulta_insert";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
    };
    return this.http.post(url,tp ,httpOptions);
  }
  update_tipo_consulta(tp: tipo_cosulta) {
    let url = environment.apiEndpoint + "catalog/tipo_consulta_update";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
    };
    return this.http.post(url,tp ,httpOptions);
  }
  delete_tipo_consulta(tipo_id:number) {
    let url = environment.apiEndpoint + "catalog/tipo_consulta_delete";
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type':  'application/json','Authorization': 'Bearer ' + this._auth.token}),
      params: new HttpParams().set('tp', tipo_id.toString())
    };
    return this.http.get(url,httpOptions);
  }

  insert_catalog(c: catalog) {
    let url = environment.apiEndpoint + "catalog/catalogo_insert";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
    };
    return this.http.post(url,c ,httpOptions);
  }
update_catalog(c: catalog) {
    let url = environment.apiEndpoint + "catalog/catalogo_update";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
    };
    return this.http.post(url,c ,httpOptions);
  }
  delete_catalog(tabla: string, claveid:string,id:number) {
    let url = environment.apiEndpoint + "catalog/catalogo_delete";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
      params: new HttpParams().set('tabla', tabla).set('claveid', claveid).set('id', id.toString()),
    };
    return this.http.get(url, httpOptions);
  }

  printPrueba(arra:any) {
    let url = environment.apiEndpoint + "consulta/printPrueba";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
    };
    return this.http.post(url, arra,{responseType: 'blob' });
  } 

  // CATALOGO DE PROCEDIMIENTOS
  procedimientos_add(p: Procedimientos) {
    let url = environment.apiEndpoint + "consulta/procedimientos_add";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
    };
    return this.http.post(url,p ,httpOptions);
  }

  delete_procedimientos(procedimiento_id: number) {
    let url = environment.apiEndpoint + "consulta/procedimientos_delete";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
      params: new HttpParams().set('procedimiento_id', procedimiento_id.toString())
    };
    return this.http.delete(url,httpOptions);
  }

  //CATALOGO DE ESTUDIOS
  estudios_add(e: Estudios) {
    let url = environment.apiEndpoint + "consulta/estudios_add";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
    };
    return this.http.post(url,e ,httpOptions);
  }

  delete_estudios(estudio_id: number) {
    let url = environment.apiEndpoint + "consulta/estudios_delete";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
      params: new HttpParams().set('estudio_id', estudio_id.toString())
    };
    return this.http.delete(url,httpOptions);
  }

  // CATÁLOGO DE MEDICAMENTOS
  insert_update_medicamento(c: Medicamento) {
    let url = environment.apiEndpoint + "consulta/medicamentos_insert";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
    };
    return this.http.post(url,c ,httpOptions);
  }
  delete_medicamento(medicamento_id: number) {
    let url = environment.apiEndpoint + "consulta/medicamentos_delete";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
      params: new HttpParams().set('medicamento_id', medicamento_id.toString())
    };
    return this.http.delete(url,httpOptions);
  }
  // CATÁLOGO DE DIAGNÓSTICOS
  insert_update_diagnostico(c: Diagnostico) {
    let url = environment.apiEndpoint + "consulta/diagnostico_insert";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
    };
    return this.http.post(url,c ,httpOptions);
  }
  delete_diagnostico(diagnostico_id: number) {
    let url = environment.apiEndpoint + "consulta/diagnostico_delete";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
      params: new HttpParams().set('diagnostico_id', diagnostico_id.toString())
    };
    return this.http.delete(url,httpOptions);
  }
}
