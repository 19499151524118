<nav id="landig_navbar" class="navbar navbar-expand-lg navbar-dark bg-dark header-navbar">
  <a class="navbar-brand my-2 my-lg-0" href="#">
    <img src="../../../assets/images/interamed_logo.svg" alt="" width="80">
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">

    <ul class="navbar-nav ml-auto">
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/landing']" fragment="about_us"  >Sobre Nosotros</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/landing']" fragment="services" >Servicios</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/landing']" fragment="footer" >Descargar App</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/dashboard">Iniciar sesión</a>
      </li>
    </ul>
  </div>
</nav>