import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { AuthorizationService } from './authorization.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DynamicService {

  constructor(public http: HttpClient, public _auth: AuthorizationService) { }

  get_form(form_id: number) {
    let url = environment.apiEndpoint + "dynamic/getform";
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type':  'application/json','Authorization': 'Bearer ' + this._auth.token}),
      params: new HttpParams().set('form_id', form_id.toString()),
    };
    return this.http.get(url, httpOptions);
  }
  save_form(json:string,paciente_id:number,medico_id:number) {
    let ant:any={json_template:json,medico_id:medico_id,paciente_id:paciente_id}
  
    //console.log(ant)
      let url = environment.apiEndpoint + "dynamic/save_form";
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
      };
      return this.http.post(url, ant, httpOptions);
  }

  get_antecedente(paciente_id:number) {
    //console.log(paciente_id)
    let url = environment.apiEndpoint + "dynamic/get_antecedente";
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type':  'application/json','Authorization': 'Bearer ' + this._auth.token}),
      params: new HttpParams().set('paciente_id', paciente_id.toString()),
    };
    return this.http.get(url, httpOptions);
    }


}
