<div class="card-body p-b-0">


    <ngb-accordion [closeOthers]="true" #acc="ngbAccordion" (shown)="panelChange($event)">
        <ngb-panel id="presion_arterial">
            <ng-template ngbPanelTitle><span class="m-l-10">Presión arterial</span></ng-template>
            <ng-template ngbPanelContent>
                <div class="row">
                    <div class="col col-md-2">
                        <form class="form-material" [formGroup]="presion_form" (ngSubmit)="refresh_presion()" #form="ngForm" novalidate>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group"  [ngClass]="{ 'has-danger': _frm.is_invalid('desde', presion_form, form.submitted)}">
                                        <label for="txtdesde"  [ngClass]="{ 'star_symbol': _frm.is_required('desde', presion_form) }">Desde</label>
                                        <input class="form-control" placeholder="Desde"  id="txtdesde" formControlName="desde" ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()" readonly="readonly" [minDate]="{year: 1900, month: 1, day: 1}">
                                        <i class="mdi mdi-calendar"></i>
                                        <div class="invalid-feedback">
                                            <div *ngIf="presion_form.controls['desde'].errors?.required">Ingrese la fecha de inicio</div>
                                          </div>
                                      </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="form-group"  [ngClass]="{ 'has-danger': _frm.is_invalid('hasta', presion_form, form.submitted)}">
                                        <label for="txthasta"  [ngClass]="{ 'star_symbol': _frm.is_required('hasta', presion_form) }">Hasta</label>
                                        <input class="form-control" placeholder="Hasta"  id="txthasta" formControlName="hasta" ngbDatepicker #d2="ngbDatepicker" (click)="d2.toggle()" readonly="readonly" [minDate]="{year: 1900, month: 1, day: 1}">
                                        <i class="mdi mdi-calendar"></i>
                                        <div class="invalid-feedback">
                                            <div *ngIf="presion_form.controls['hasta'].errors?.required">Ingrese la fecha de inicio</div>
                                          </div>
                                      </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('posicion_id', presion_form, form.submitted)}">
                                        <label for="posicion"  [ngClass]="{ 'star_symbol': _frm.is_required('posicion_id', presion_form) }">Posición</label>
                                        <ng-select [searchable]="true" placeholder="Posición" class="form-control" id="posicion" [items]="posicion" bindLabel="nombre" bindValue="id" formControlName="posicion_id">
                                        </ng-select>
                                        <div class="invalid-feedback">
                                            <div *ngIf="presion_form.controls['posicion_id'].errors?.required">Ingrese el sexo del paciente</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('brazo_id', presion_form, form.submitted)}">
                                        <label for="brazo"  [ngClass]="{ 'star_symbol': _frm.is_required('brazo_id', presion_form) }">Brazo</label>
                                        <ng-select [searchable]="true" placeholder="Brazo" class="form-control" id="brazo" [items]="brazo" bindLabel="nombre" bindValue="id" formControlName="brazo_id">
                                        </ng-select>
                                        <div class="invalid-feedback">
                                            <div *ngIf="presion_form.controls['brazo_id'].errors?.required">Ingrese el sexo del paciente</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <button type="submit" class="btn btn-primary btn-sm "><i class="mdi mdi-chart-line"></i> Consultar</button>
                                </div>

                            </div>
                        </form>
                    </div>
                    <div class="col col-md-10">
                        <!-- <div *ngIf="multi[0]?.series.length > 0 || multi[1]?.series.length > 0">
                            <ngx-charts-line-chart
                                [view]="view"
                                [scheme]="colorScheme"
                                [legend]="legend"
                                [showXAxisLabel]="showXAxisLabel"
                                [showYAxisLabel]="showYAxisLabel"
                                [xAxis]="xAxis"
                                [yAxis]="yAxis"
                                [xAxisLabel]="xAxisLabel"
                                [yAxisLabel]="yAxisLabel"
                                [timeline]="timeline"
                                [results]="multi"
                                [animations]="animations"
                                [xAxisTickFormatting]="formatDate"
                                legendPosition="below"
                                legendTitle="Presión arterial"
                            >

                                <ng-template #tooltipTemplate let-model="model">
                                    <div class="chart-tooltip">
                                        <div>{{model.series }} : {{ formatDate(model.name)}} </div>
                                        <div style="text-align: center; font-size: 20px;">{{model.value}}</div>
                                    </div>
                                </ng-template>

                            </ngx-charts-line-chart>                            
                        </div> -->
                        <div *ngIf="multi[0]?.series.length == 0 && multi[1]?.series.length == 0">
                            <p>No hay datos disponibles para esta consulta</p>
                        </div>

                    </div>
                </div>
            </ng-template>
        </ngb-panel>

        <ngb-panel id="glucosa">
            <ng-template ngbPanelTitle><span class="m-l-10">Glucosa</span></ng-template>
            <ng-template ngbPanelContent>
                <div class="row">
                    <div class="col col-md-2">
                        <form class="form-material" [formGroup]="glucosa_form" (ngSubmit)="refresh_presion()" #form2="ngForm" novalidate>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group"  [ngClass]="{ 'has-danger': _frm.is_invalid('desde', glucosa_form, form2.submitted)}">
                                        <label for="txtdesde"  [ngClass]="{ 'star_symbol': _frm.is_required('desde', glucosa_form) }">Desde</label>
                                        <input class="form-control" placeholder="Desde"  id="txtdesde" formControlName="desde" ngbDatepicker #d3="ngbDatepicker" (click)="d3.toggle()" readonly="readonly" [minDate]="{year: 1900, month: 1, day: 1}">
                                        <i class="mdi mdi-calendar"></i>
                                        <div class="invalid-feedback">
                                            <div *ngIf="glucosa_form.controls['desde'].errors?.required">Ingrese la fecha de inicio</div>
                                          </div>
                                      </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="form-group"  [ngClass]="{ 'has-danger': _frm.is_invalid('hasta', glucosa_form, form2.submitted)}">
                                        <label for="txthasta"  [ngClass]="{ 'star_symbol': _frm.is_required('hasta', glucosa_form) }">Hasta</label>
                                        <input class="form-control" placeholder="Hasta"  id="txthasta" formControlName="hasta" ngbDatepicker #d4="ngbDatepicker" (click)="d4.toggle()" readonly="readonly" [minDate]="{year: 1900, month: 1, day: 1}">
                                        <i class="mdi mdi-calendar"></i>
                                        <div class="invalid-feedback">
                                            <div *ngIf="glucosa_form.controls['hasta'].errors?.required">Ingrese la fecha de inicio</div>
                                          </div>
                                      </div>
                                </div>

                                <div class="col-md-12">
                                    <button type="submit" class="btn btn-primary btn-sm "><i class="mdi mdi-chart-line"></i> Consultar</button>
                                </div>

                            </div>
                        </form>
                    </div>
                    <div class="col col-md-10">
                        <!-- <div *ngIf="data_glucosa[0]?.series.length > 0 ">
                            <ngx-charts-line-chart
                                    [view]="view"
                                    [scheme]="colorScheme"
                                    [legend]="legend"
                                    [showXAxisLabel]="showXAxisLabel"
                                    [showYAxisLabel]="showYAxisLabel"
                                    [xAxis]="xAxis"
                                    [yAxis]="yAxis"
                                    [xAxisLabel]="xAxisLabel"
                                    [yAxisLabel]="yAxisLabel"
                                    [timeline]="timeline"
                                    [results]="data_glucosa"
                                    [animations]="animations"
                                    [xAxisTickFormatting]="formatDate"
                                    legendPosition="below"
                                    legendTitle="Glucosa"
                                >

                                <ng-template #tooltipTemplate let-model="model">
                                        <div class="chart-tooltip">
                                            <div>{{model.series }} : {{ formatDate(model.name)}} </div>
                                            <div style="text-align: center; font-size: 20px;">{{model.value}}</div>
                                        </div>
                                </ng-template>

                            </ngx-charts-line-chart>                           
                        </div> -->
                        <div *ngIf="data_glucosa[0]?.series.length == 0">
                            <p>No hay datos disponibles para esta consulta</p>
                        </div>

                    </div>
                </div>
            </ng-template>
        </ngb-panel>


    </ngb-accordion>

    <div class="row m-t-30">
        <div class="col-md-12 modal-footer"> </div>
    </div>
</div>