import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FormsService, PacienteService, CatalogService, ConsultaService } from 'src/app/services/service.index';
import { agenda } from 'src/app/models/consulta.model';
import { paciente } from 'src/app/models/paciente.model';
import { Observable, Subject, concat, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap, catchError, map } from 'rxjs/operators';

@Component({
  selector: 'app-agenda-dialog',
  templateUrl: './agenda-dialog.component.html',
  styles: []
})
export class AgendaDialogComponent implements OnInit {
  @ViewChild("form", { static: true }) form;
  @Output() submit_form = new EventEmitter<FormGroup>();
  @Output() close_modal = new EventEmitter();
  @Input() agenda: agenda = new agenda({});
  agenda_form: FormGroup;

  pacientes: any;// = [{nombre:'Prueba 1', id:1}, {nombre:'Prueba 2', id:2}];
  tipo_consulta = [{ nombre: 'Primera vez', id: 1, duracion: 20 }, { nombre: 'Subsecuente', id: 2, duracion: 15 }, { nombre: 'Somatometría', id: 9, duracion: 0 }];
  duracion = [{ nombre: '5 min', id: 5 }, { nombre: '10 min', id: 10 }, { nombre: '15 min', id: 15 },
  { nombre: '20 min', id: 20 }, { nombre: '25 min', id: 25 }, { nombre: '30 min', id: 30 },
  { nombre: '35 min', id: 35 }, { nombre: '40 min', id: 40 }, { nombre: '45 min', id: 45 },
  { nombre: '50 min', id: 50 }, { nombre: '55 min', id: 55 }, { nombre: '60 min', id: 60 }];;

  paciente$: Observable<Object>;
  pacienteinput$ = new Subject<string>();
  pacienteLoading = false;

  horarios = [
    {nombre:'', id: null},
    {nombre:'00:00', id: { hour: 0, minute:0 }},
    {nombre:'00:30', id: { hour: 0, minute:30 }},
    {nombre:'01:00', id: { hour: 1, minute:0 }},
    {nombre:'01:30', id: { hour: 1, minute:30 }},
    {nombre:'02:00', id: { hour: 2, minute:0 }},
    {nombre:'02:30', id: { hour: 2, minute:30 }},
    {nombre:'03:00', id: { hour: 3, minute:0 }},
    {nombre:'03:30', id: { hour: 3, minute:30 }},
    {nombre:'04:00', id: { hour: 4, minute:0 }},
    {nombre:'04:30', id: { hour: 4, minute:30 }},
    {nombre:'05:00', id: { hour: 5, minute:0 }},
    {nombre:'05:30', id: { hour: 5, minute:30 }},
    {nombre:'06:00', id: { hour: 6, minute:0 }},
    {nombre:'06:30', id: { hour: 6, minute:30 }},
    {nombre:'07:00', id: { hour: 7, minute:0 }},
    {nombre:'07:30', id: { hour: 7, minute:30 }},
    {nombre:'08:00', id: { hour: 8, minute:0 }},
    {nombre:'08:30', id: { hour: 8, minute:30 }},
    {nombre:'09:00', id: { hour: 9, minute:0 }},
    {nombre:'09:30', id: { hour: 9, minute:30 }},
    {nombre:'10:00', id: { hour: 10, minute:0 }},
    {nombre:'10:30', id: { hour: 10, minute:30 }},
    {nombre:'11:00', id: { hour: 11, minute:0 }},
    {nombre:'11:30', id: { hour: 11, minute:30 }},
    {nombre:'12:00', id: { hour: 12, minute:0 }},
    {nombre:'12:30', id: { hour: 12, minute:30 }},
    {nombre:'13:00', id: { hour: 13, minute:0 }},
    {nombre:'13:30', id: { hour: 13, minute:30 }},
    {nombre:'14:00', id: { hour: 14, minute:0 }},
    {nombre:'14:30', id: { hour: 14, minute:30 }},
    {nombre:'15:00', id: { hour: 15, minute:0 }},
    {nombre:'15:30', id: { hour: 15, minute:30 }},
    {nombre:'16:00', id: { hour: 16, minute:0 }},
    {nombre:'16:30', id: { hour: 16, minute:30 }},
    {nombre:'17:00', id: { hour: 17, minute:0 }},
    {nombre:'17:30', id: { hour: 17, minute:30 }},
    {nombre:'18:00', id: { hour: 18, minute:0 }},
    {nombre:'18:30', id: { hour: 18, minute:30 }},
    {nombre:'19:00', id: { hour: 19, minute:0 }},
    {nombre:'19:30', id: { hour: 19, minute:30 }},
    {nombre:'20:00', id: { hour: 20, minute:0 }},
    {nombre:'20:30', id: { hour: 20, minute:30 }},
    {nombre:'21:00', id: { hour: 21, minute:0 }},
    {nombre:'21:30', id: { hour: 21, minute:30 }},
    {nombre:'22:00', id: { hour: 22, minute:0 }},
    {nombre:'22:30', id: { hour: 22, minute:30 }},
    {nombre:'23:00', id: { hour: 23, minute:0 }},
    {nombre:'23:30', id: { hour: 23, minute:30 }}
  ]


  //horarios= [{nombre:'', id: null},];

  constructor(private _fb: FormBuilder, public _frm: FormsService,
     public _paciente: PacienteService, public _cat: CatalogService, public _consultaService:ConsultaService) { }

  ngOnInit() {
    this.agenda_form = this._fb.group({
      consulta_id: [this.agenda.consulta_id],
      fecha: [this.agenda.fecha ? {
        year: new Date(this.agenda.fecha).getFullYear(),
        month: new Date(this.agenda.fecha).getMonth() + 1,
        day: new Date(this.agenda.fecha).getDate()
      } : '', [Validators.required]],
      hora: [this.agenda.fecha ? {
        hour: new Date(this.agenda.fecha).getHours(),
        minute: new Date(this.agenda.fecha).getMinutes(),
      } : '', [Validators.required]],
      duracion: [30, [Validators.required]],
      paciente_id: [this.agenda.paciente_id,  [Validators.required]],
      especialidad_id: [this.agenda.especialidad_id],
      estatus_id: [this.agenda.estatus_id],
      ubicacion: [this.agenda.ubicacion],
      tipo_id: [this.agenda.tipo_id, [Validators.required]],
      medico_id: [this.agenda.medico_id],
      consultorio_id: [this.agenda.consultorio_id],
    });
    this.loadPaciente();


    this._consultaService.consultorios_disponibilidad_dia_get(1,this.agenda.consultorio_id,
      new Date(this.agenda.fecha).getMonth() + 1,new Date(this.agenda.fecha).getFullYear(),new Date(this.agenda.fecha).getDate()
       ).subscribe(
      (data:any)=> {
        data.forEach(element => {
          // this.horarios.push({
          //   nombre: element.fecha,
          //   id: { hour:  parseInt(element.fecha.toString().split(":")[0]) , 
          //         minute: parseInt(element.fecha.toString().split(":")[1]) 
          //       }
          // });
        });
        console.log( this.horarios);
        
      
      },
      error => {console.log(error);}
      );


    //this._cat.get_catalog("tipo_consulta", true).subscribe(data => this.tipo_consulta = data, error => console.log(error));
  }

  private loadPaciente() {
    this.paciente$ = concat(
      of(
        this.agenda.paciente_id?
          [{ nombre_completo: this.agenda.paciente, paciente_id: this.agenda.paciente_id, numero_registro: this.agenda.numero_registro }] : []
        ),
      this.pacienteinput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => this.pacienteLoading = true),
        switchMap(term => this._paciente.get_paciente_catalog(term).pipe(
          catchError(() => of([])),
          tap(() => this.pacienteLoading = false),
        ))
      )
    )
  }

  on_submit() {
    //this.agenda_form.patchValue({"paciente_id": this.agenda.paciente_id });
    this.submit_form.emit(this.agenda_form);
  }
  on_close() {
    this.close_modal.emit();
  }
  compareWith(item, selected) {
    //return item.id == selected

    return JSON.stringify(item.id) === JSON.stringify(selected); 
  }


}
