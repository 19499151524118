export class consulta {
    consulta_id?: number = null;
    fecha_inicio_programada?: Date = null;
    fecha_fin_programada?: Date = null;
    fecha?: Date = null;
    fecha_inicio_real?: Date = null;
    fecha_fin_real?: Date = null;
    medico_id?: number = null;
    paciente_id?: number = null;
    nombre_paciente: string = "";
    numero_registro: string = "";
    motivo: string = "";
    nota_medica: string = "";
    externa?: boolean = null;
    especialidad_id?: number = null;
    especilidad: string = "";
    estatus_id?: number = null;
    status: string = "";
    ubicacion: string = "";
    fecha_fin_exploracion?: Date = null;
    causes_id?: number = null;
    cause: string = "";
    tipo_id?: number = null;
    tipo: string = "";
    saved_by?: number = null;
    medico: string = "";
    duracion?: number = null;


    antecedentes: string = "";
    observaciones: string = "";
    estado_actual: string = "";
    resultado_exploracion: string = "";


    procedimientos: consulta_procedimiento[] = []
    medicamentos: consulta_medicamento[] = []
    diagnosticos: consulta_diagnostico[] = []
    estudios: consulta_estudio[] = []
    exploracion: consulta_exploracion = new consulta_exploracion({})

    constructor(data: consulta | {} = {}) {
        Object.assign(this, data);
    }

}


export class consulta_procedimiento {
    procedimiento_id?: number = null;
    consulta_id?: number = null;
    observacion?: string = "";
    procedimiento?: string = "";
    padre_id?: number = null;
    procedimiento_cod?: string = "";
    nombre?: string = "";


    constructor(data: consulta_procedimiento | {} = {}) {
        Object.assign(this, data);
    }

}
export class consulta_medicamento {
    consulta_id?: number = null;
    medicamento_id?: number = null;
    unidad_medica?: string = "";
    dosis?: string = "";
    frecuencia?: string = "";
    via_administracion_id?: number = null;
    fecha_inicio?: Date = null;
    fecha_fin?: Date = null;
    indicaciones_adicionales?: string = "";

    clave?: string = "";
    nombre_generico?: string = "";
    descripcion?: string = "";
    cantidad?: string = "";
    presentacion?: string = "";
    grupo_id?: number = null;
    controlado?: Date = null;

    via_administracion?: string = "";
    nombre_compuesto?: string = "";

    constructor(data: consulta_medicamento | {} = {}) {
        Object.assign(this, data);
    }
}
export class consulta_diagnostico {
    consulta_id?: number = null;
    diagnostico_id?: number = null;
    observaciones: string = "";

    clase?: string = "";
    descripcion?: string = "";
    padre_id?: number = null;
    is_leaf?: boolean = null;
    genero_id?: number = null;
    clave?: string = "";
    edad_minima?: number = null;
    edad_maxima?: number = null;

    constructor(data: consulta_diagnostico | {} = {}) {
        Object.assign(this, data);
    }
}
export class consulta_estudio {
    consulta_id?: number = null;
    estudio_id?: number = null;
    observaciones?: string = "";

    estudio_cod?: string = "";
    estudio?: string = "";
    grupo?: string = "";

    constructor(data: consulta_estudio | {} = {}) {
        Object.assign(this, data);
    }

}
export class consulta_exploracion {
    consulta_exploracion_id?: number = null;
    consulta_id?: number = null;
    pulso: string = "";
    temperatura: string = "";
    tension_arterial: string = "";
    frecuencia_cardiaca: string = "";
    frecuencia_respiratoria: string = "";
    peso: string = "";
    estatura: string = "";
    cabeza: string = "";
    cuello: string = "";
    torax: string = "";
    abdomen: string = "";
    genitales: string = "";
    miembros: string = "";
    saturacion_oxigeno: string = "";

    constructor(data: consulta_exploracion | {} = {}) {
        Object.assign(this, data);
    }

}




export class agenda {
    consulta_id?: number = null;
    fecha?: Date = null;
    hora?: any = null;
    duracion?: number = null;
    medico_id?: number = null;
    medico: string = "";
    paciente_id?: number = null;
    paciente: string;
    numero_registro: string;
    consulta_externa?: boolean = null;
    especialidad_id?: number = null;
    especialidad: string = "";
    estatus_id?: number = null;
    estatus: string = "";
    ubicacion: string = "";
    tipo_id?: number = null;
    tipo: string = "";
    saved_by?: number = null;
    link_medico?: string = "";
    consultorio_id?: number = null;
    consultorio: string = "";
    constructor(data: agenda | {} = {}) {
        Object.assign(this, data);
    }


}
export class tipo_cosulta {

    tipo_id?: number = null;
    nombre: string = "";
    duracion?: number = null;
    activo?: number = null;

    constructor(data: tipo_cosulta | {} = {}) {
        Object.assign(this, data);
    }
}

export class procedimientos_add {
    padre_id?: number = null;
    procedimiento_id?: number = null;
    procedimiento_cod?: number = null;
    nombre: string = "";

    constructor(data: procedimientos_add| {} = {}) {
        Object.assign(this, data);
    }
}

export class estudios_add {
    estudio_id?: number = null;
    estudio_cod?: number = null;
    estudio: string = "";
    grupo: string = "";

    constructor(data: estudios_add| {} = {}) {
        Object.assign(this, data);
    }
}