import { Component, OnInit, ViewChild } from '@angular/core';
import { GridComponent } from '../../shared/components/grid.component';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ConsultaService } from '../../services/consulta.service';


@Component({
  selector: 'app-rep-consultas',
  templateUrl: './rep-consultas.component.html',
  styles: [
  ]
})
export class RepConsultasComponent implements OnInit {


  @ViewChild("content", { static: true }) content;
  @ViewChild(GridComponent, { static: true }) mygrid: GridComponent;

  public paciente: string;
  public fecha_inicio: Date;
  public fecha_fin: Date;

  maxDate = { year: new Date().getUTCFullYear(), month: new Date().getUTCMonth() +1, day: new Date().getDate() };

  rowData: any = [];
  columnDefs = [
    {
      headerName: "Fecha Inicio", field: "fecha_inicio", cellRenderer: (data) => {
        return data.value ? moment(new Date(data.value)).format('MM/DD/YYYY HH:mm') : '';
      }
    },
    {
      headerName: "Fecha Fin", field: "fecha_fin", cellRenderer: (data) => {
        return data.value ? moment(new Date(data.value)).format('MM/DD/YYYY HH:mm') : '';
      }
    },
    { headerName: "Médico", field: "medico" },
    { headerName: "Paciente", field: "paciente" },
    { headerName: "Consultorio", field: "consultorio"}

  ];

  

  public currentPdf: string = null;


  
  
  constructor(private _toastr: ToastrService, private modalService: NgbModal, public consultas: ConsultaService) { }
  


  ngOnInit() {
    this.rowData = [];
  }
  
  change_valores() {
    var fecha1:any = this.fecha_inicio
    var fecha2:any = this.fecha_fin

    if(fecha1 != null || fecha2 != null){
      var f1  = new Date(Date.UTC(fecha1.year, fecha1.month -1, fecha1.day + 1));
      var f2  = new Date(Date.UTC(fecha2.year, fecha2.month -1, fecha2.day + 1));
      var inicio = moment(f1).format('YYYY/MM/DD');
      var fin = moment(f2).format('YYYY/MM/DD');
    }

    if(fin < inicio) {
        this._toastr.warning("La fecha fin debe ser igual o mayor a la fecha inicio");
    }
    else {
        this.consultas.report_consultas(this.fecha_inicio,this.fecha_fin).subscribe(
          data => {
            this.rowData = data;
          }, 
          error => console.log(error));
    }
  }

  option_selector(button: string){
    console.log(button);
    switch ( button ){
      case "excel": {
        this.mygrid.exportGrid();
        break;
      }
    }
  }


}
