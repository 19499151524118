<div class="container-fluid px-0 p-0 site-width bg-dark" id="footer">
  <div class="row info-c">
    <div class="col-md-6 info-navegacion">
      <div class="row" style="padding:50px 0" >
        <div class="col-md-6 mx-auto" style="justify-content: center; display: flex;">
          <img src="../../../assets/images/interamed_logo.svg" alt="" width="120">
        </div>
        <div class="col-md-6 mx-auto" >

          <ul class="list-group list-group-flush">
            <li class="list-group-item"><a href="https://www.facebook.com/profile.php?id=100084994921720" target="_blank" style="color: #FFF;"> Facebook</a></li>
            <li class="list-group-item"><a href="https://instagram.com/interamed.larcam?igshid=MmIxOGMzMTU=" target="_blank" style="color: #FFF;"> Instagram</a></li>
          </ul>
        </div>
      </div>
      
    </div>
    <div class="col-md-6 info-social" style="padding:50px 0; justify-content: space-evenly; display: flex; flex-direction: column; align-items: center;">
        <a href="https://apps.apple.com/app/interamed/id1621163208" target="_blank"><img src="../../../assets/images/android.png" alt="" width="150"></a>
      <a href="https://play.google.com/store/apps/details?id=com.swplus.interamed" target="_blank"><img src="../../../assets/images/apple.png" alt="" width="150"></a>
    </div>
  </div>
</div>