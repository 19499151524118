<div class="row m-b-20">
  <div class="col-md-6 ">
    <h6><i class="mdi mdi-hospital-building"> </i> {{this._agenda.selected_consultorio}} ( {{this._agenda.selected_sucursal}})</h6>
  </div>
  <div class="col-md-6 text-right">
      <h6> <i class="mdi mdi-calendar"></i> Agenda para el {{current_date}}</h6>
  </div>
</div>


<app-buttons (button_selected)="option_selector($event)" ></app-buttons>


<div class="row">
  <div class="col-md-3">
    <ngb-datepicker #dp [(ngModel)]="model" (navigate)="navigate($event)" [dayTemplate]="customDay" [markDisabled]="is_disabled" (select)="select()" [minDate]="calendar.getToday()" (dateSelect)="select()"></ngb-datepicker>  
  </div>
  <div class="col-md-9"> 
    <app-grid [rowSelection]="'single'" [rowData]="rowData" [columnDefs]="columnDefs" [getRowHeight]="getRowHeight" [rowClassRules]="rowClassRules" [autosize]="false"  [isRowSelectable]="isRowSelectable"></app-grid>  
  </div>
</div>


<ng-template #customDay let-date let-currentMonth="currentMonth" let-selected="selected" let-disabled="disabled"  let-focused="focused">
  <span [class]="set_day_class(date,currentMonth)" [class.focused]="focused" [class.selected]="selected"> {{ date.day  }}</span>
</ng-template>



<!-- Ventana modal para visualizacion de expediente clinico -->
<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" *ngIf="paciente.paciente_id">{{paciente.nombre_completo}} ( {{paciente.numero_registro}} ) </h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body p-0">
        <div class="card m-b-0">
            <app-expediente [paciente]="paciente"></app-expediente>
        </div>
    </div>
</ng-template>

<!-- Ventana modal para busqueda de pacientes con citas -->
<ng-template #pacientes let-modal>
    <div class="modal-header">
        <h4 class="modal-title"> Búsqueda de pacientes agendados</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body p-0">
        <div class="card m-b-0">
            <app-busca-paciente></app-busca-paciente>
        </div>
    </div>
</ng-template>


<!-- Ventana modal para crear/editar citas médicas-->
<ng-template #agenda let-modal>
    <div class="modal-header">
        <h4 class="modal-title" *ngIf="!cita.consulta_id">Nueva cita</h4> 
        <h4 class="modal-title" *ngIf="cita.consulta_id">{{ cita.paciente}} ( {{ cita.numero_registro}} ) </h4> 
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="modal-body p-0">
        <div class="card m-b-0">
            <ul class="nav nav-tabs profile-tab" role="tablist">
                <li class="nav-item"> <a class="nav-link active" data-toggle="tab" href="#agendar" role="tab"><strong>Consultorio:</strong> {{ cita.consultorio }} </a></li>
            </ul>
            <div class="tab-content">
                <div class="tab-pane active" id="agendar" role="tabpanel">
                    <app-agenda-dialog [agenda]="cita" (close_modal)="modal.close('Cancelar')" (submit_form)="guardar_agenda($event)"></app-agenda-dialog>
                </div>
            </div>
        </div>
    </div> 
</ng-template>


<!-- Ventana modal para atender consulta-->
<ng-template #consulta let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Consulta</h4> 
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="modal-body p-0">
        <div class="card m-b-0">
            <ul class="nav nav-tabs profile-tab" role="tablist">
                <li class="nav-item"> <a class="nav-link" data-toggle="tab" href="#exploracion" role="tab">Exploración Física</a></li>
                <li class="nav-item"> <a class="nav-link active"  data-toggle="tab" href="#agendar" role="tab">Consulta</a></li>
                <li class="nav-item"> <a class="nav-link"  data-toggle="tab" href="#cuestionarios" role="tab">Cuestionarios</a></li>
                <li class="nav-item"> <a class="nav-link"  data-toggle="tab" href="#certificado" role="tab">Certificado</a></li>

                <!--<li class="nav-item"> <a class="nav-link active" data-toggle="tab" href="#agendar" role="tab"><strong>Paciente: </strong> {{cita.paciente}} ({{ cita.numero_registro }}) </a></li>-->
            </ul>
            <div class="tab-content">
                <div class="tab-pane" id="exploracion" role="tabpanel">
                    <app-exploracion-dialog [medicoId]= "medicoId" [pacienteId]="pacienteId" [consultaId]="consultaId" [readOnly]="explorationReadOnly" (close_modal)="modal.close('Cancelar')" (submit_form)="guardarExploracion($event)" ></app-exploracion-dialog>
                </div>
                <div class="tab-pane active" id="agendar" role="tabpanel">
                    <app-consulta-dialog [agenda]= "cita" (close_modal)="modal.close('Cancelar')" (submit_form)="guardar_consulta($event)" ></app-consulta-dialog>
                </div>
                <div class="tab-pane" id="cuestionarios" role="tabpanel">
                    <app-cuestionarios [consultaId]="consultaId"></app-cuestionarios>
                </div>
                <div class="tab-pane" id="certificado" role="tabpanel">
                    <app-certificado [consultaId]="consultaId" [consulta]="cita"></app-certificado>
                </div>
            </div>
        </div>
    </div> 
</ng-template>

<!-- Ventana modal para exploración física-->
<ng-template #exploracion let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Exploración física</h4> 
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="modal-body p-0">
        <div class="card m-b-0">
            <app-exploracion-dialog [medicoId]= "medicoId" [pacienteId]="pacienteId" [consultaId]="consultaId" [readOnly]="explorationReadOnly" 
                                    (close_modal)="modal.close('Cancelar')" (submit_form)="guardarExploracion($event)" >
            </app-exploracion-dialog>
        </div>
    </div> 
</ng-template>

