export class UserResponse {
    user_id?:                    number;
    username?:                   string;
    password_hash?:              null;
    password_salt?:              null;
    role_id?:                    number;
    role?:                       Role;
    email?:                      null;
    email_confirmed?:            boolean;
    access_fail_count?:          null;
    is_external_login_provider?: boolean;
    is_system_user?:             boolean;
    user_preferences?:           null;
    valid_from?:                 null;
    valid_to?:                   null;
    is_active?:                  boolean;
    last_login?:                 null;
    saved_by?:                   null;
    token?:                      null;
    person?:                     Person;
    medico_id?:                  null;
    medico?:                     Medico | null;
    modules?:                    null;
}

export class Medico {
    medico_id?:         number;
    consultas_maximas?: number | null;
    cedula?:            null | string;
    consulta_externa?:  boolean | null;
    nombre_completo?:   null;
    person_id?:         null;
    name?:              null;
    last_name?:         null;
    middle_name?:       null;
    birthdate?:         null;
    email?:             null;
    home_phone?:        null;
    office_phone?:      null;
    mobile_phone?:      null;
    rfc?:               null;
    curp?:              null;
    gender_id?:         null;
    photo?:             null;
    especialidades?:    null | string;
    especialidades_name?:    null | string;
    especialidades_obj?:    Especialidad[] = [];
    saved_by?:          null;
}

export class Especialidad {
    id?:         number;
    nombre?:     null | string;
}

export class Person {
    person_id?:         number;
    name?:              null | string;
    last_name?:         null | string;
    middle_name?:       null | string;
    fullname?:          string;
    address_id?:        null;
    birthdate?:         Date | null;
    title?:             null;
    email?:             string;
    home_phone?:        null | string;
    office_phone?:      null;
    mobile_phone?:      null | string;
    rfc?:               null;
    curp?:              null | string;
    gender_id?:         number | null;
    marital_status_id?: null;
    photo?:             string;
    saved_by?:          null;
    address?:           Address;
}

export class Address {
    address_id?:      number;
    address_type_id?: number | null;
    country_id?:      number | null;
    state_id?:        number | null;
    municipality_id?: number | null;
    state?:           null | string;
    municipality?:    null | string;
    city?:            null;
    settlement?:      null | string;
    street?:          null | string;
    interior_number?: null | string;
    exterior_number?: null | string;
    reference?:       null | string;
    postal_code?:     null | string;
    latitude?:        number | null;
    longitude?:       number | null;
    saved_by?:        null;
}

export enum Role {
    Administrador = "Administrador",
    Admisión = "Admisión",
    Archivo = "Archivo",
    Laboratorista = "Laboratorista",
    Médico = "Médico",
    Propietario = "Propietario",
}
