<div class="card-body p-b-0">
  <form class="form-material" [formGroup]="tipo_consulta_form" (ngSubmit)="on_submit()" #form="ngForm"  novalidate>
      <div class="row">
          <div class="col-md-6">
              <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('nombre', tipo_consulta_form, form.submitted)}">
                  <label for="txt_name" [ngClass]="{ 'star_symbol': _frm.is_required('nombre', tipo_consulta_form) }">Nombre</label>
                  <input type="text" placeholder="Nombre" class="form-control" id="txt_name" formControlName="nombre">
                  <div class="invalid-feedback">
                      <div *ngIf="tipo_consulta_form.controls['nombre'].errors?.required">El nombre es obligatorio</div>
                  </div>
              </div>
          </div>
          <div class="col-md-6">
            <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('duracion', tipo_consulta_form, form.submitted)}">
                <label for="txt_duracion" [ngClass]="{ 'star_symbol': _frm.is_required('duracion', tipo_consulta_form) }">Duración</label>
                <input type="number" placeholder="Duración" class="form-control" id="txt_duracion" formControlName="duracion">
                <div class="invalid-feedback">
                    <div *ngIf="tipo_consulta_form.controls['duracion'].errors?.required">La duracion es obligatorio</div>
                </div>
            </div>
        </div>
      </div>
      <div class="row m-t-30">
          <div class="col-md-12 modal-footer">
              <button *ngIf="show_cancel_button" type="button" class="btn btn-danger btn-sm" (click) = "on_close()" ><i class="mdi mdi-close"></i> Cancelar</button>
              <button type="submit" class="btn btn-primary btn-sm "><i class="mdi mdi-content-save"></i> Guardar</button>
          </div>
      </div>
  </form>
</div>
