<div class="card-body p-b-0">

    <div class="row">
        <div class="col-md-12">
            <!-- <div *ngIf="!exploracionFisicaRealizada" class="alert alert-warning" role="alert">No existe información de la exploración física del paciente.<br>Antes de iniciar la consulta es importante realizar la exploración física</div> -->
            <ngb-accordion [closeOthers]="true" (panelChange)="panel_change($event)">
                <ngb-panel id="panel-question-1">
                    <ng-template ngbPanelTitle><i class="mdi mdi-file-question"></i> <span class="m-l-10">Diabetes
                            Mellitus tipo 2</span></ng-template>
                    <ng-template ngbPanelContent>
                        <form class="form-material" #form="ngForm" (ngSubmit)="on_submit()" novalidate>
                            <div *ngFor="let group of quest_1.groups">
                                <div class="row">
                                    <div class="col-md-12" m-t-20>
                                        <h6 class="modal-title text-left" style="font-weight: bold;">
                                            {{group.name }}
                                            <strong style="font-size: 12px; color: #0056b3;" *ngIf="group.result"> {{
                                                group.result.value}} {{ group.result.unit }} ({{
                                                group.result.description }})</strong>
                                        </h6>
                                    </div>
                                </div>
                                <div *ngFor="let field of group.fields" class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                             <label [for]="field.id">{{field.label}}
                                                <strong style="font-size: 12px; color: #0056b3;" *ngIf="field.result">
                                                    ({{ field.result}})</strong>
                                            </label>
                                            <input *ngIf="field.type=='text'" type="text" placeholder=""
                                                class="form-control" [id]="field.id" [(ngModel)]="field.value"
                                                [name]="field.id">
                                            <ng-select *ngIf="field.type=='combo'" placeholder="" [id]="field.id"
                                                class="form-control" [(ngModel)]="field.value" [name]="field.id"
                                                [items]="field.options" bindLabel="text" bindValue="value"
                                                [searchable]="false" notFoundText="No hay resultados"></ng-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row m-t-30">
                                <div class="col-md-12 modal-footer" style="border: none;">
                                    <button type="button" class="btn btn-primary btn-sm" (click)="calculate_quest_1()"><i class="mdi mdi-calculator"></i> Calcular</button>
                                    <button type="button" class="btn btn-primary btn-sm" (click)="save_quest_1()"><i class="mdi mdi-content-save"></i> Guardar</button>
                                </div>
                            </div>
                        </form>
                    </ng-template>
                </ngb-panel>
                <ngb-panel id="panel-question-2">
                    <ng-template ngbPanelTitle><i class="mdi mdi-file-question"></i> <span
                            class="m-l-10">Dislipidemias</span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <form class="form-material" #form="ngForm" (ngSubmit)="on_submit()" novalidate>
                            <div *ngFor="let group of quest_2.groups">
                                <div class="row">
                                    <div class="col-md-12" m-t-20>
                                        <h6 class="modal-title text-left" style="font-weight: bold;">
                                            {{group.name }}
                                            <strong style="font-size: 12px; color: #0056b3;" *ngIf="group.result"> {{
                                                group.result.value}} </strong>
                                        </h6>
                                    </div>
                                </div>
                                <div *ngFor="let field of group.fields" class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label [for]="field.id">{{field.label}}
                                                <strong style="font-size: 12px; color: #0056b3;" *ngIf="field.result">
                                                    ({{ field.result}})</strong>
                                            </label>
                                            <input *ngIf="field.type=='text'" type="text" placeholder=""
                                                class="form-control" [id]="field.id" [(ngModel)]="field.value"
                                                [name]="field.id">
                                            <ng-select *ngIf="field.type=='combo'" placeholder="" [id]="field.id"
                                                class="form-control" [(ngModel)]="field.value" [name]="field.id"
                                                [items]="field.options" bindLabel="text" bindValue="value"
                                                [searchable]="false" notFoundText="No hay resultados"></ng-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row m-t-30">
                                <div class="col-md-12 modal-footer" style="border: none;">
                                    <button type="button" class="btn btn-primary btn-sm" (click)="calculate_quest_2()"><i class="mdi mdi-calculator"></i>Calcular</button>
                                    <button type="button" class="btn btn-primary btn-sm" (click)="save_quest_2()"><i class="mdi mdi-content-save"></i> Guardar</button>
                                </div>
                            </div>
                        </form>
                    </ng-template>
                </ngb-panel>
                <ngb-panel id="panel-question-3">
                    <ng-template ngbPanelTitle><i class="mdi mdi-file-question"></i> <span class="m-l-10">Síndrome metabólico</span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <form class="form-material" #form="ngForm" (ngSubmit)="on_submit()" novalidate>
                            <div *ngFor="let group of quest_3.groups">
                                <div class="row">
                                    <div class="col-md-12" m-t-20>
                                        <h6 class="modal-title text-left" style="font-weight: bold;">
                                            {{group.name }}
                                            <strong style="font-size: 12px; color: #0056b3;" *ngIf="group.result"> {{
                                                group.result.value}} </strong>
                                        </h6>
                                    </div>
                                </div>
                                <div *ngFor="let field of group.fields" class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label [for]="field.id">{{field.label}}
                                                <strong style="font-size: 12px; color: #0056b3;" *ngIf="field.result">
                                                    ({{ field.result}})</strong>
                                            </label>
                                            <input *ngIf="field.type=='text'" type="text" placeholder=""
                                                class="form-control" [id]="field.id" [(ngModel)]="field.value"
                                                [name]="field.id">
                                            <ng-select *ngIf="field.type=='combo'" placeholder="" [id]="field.id"
                                                class="form-control" [(ngModel)]="field.value" [name]="field.id"
                                                [items]="field.options" bindLabel="text" bindValue="value"
                                                [searchable]="false" notFoundText="No hay resultados"></ng-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row m-t-30">
                                <div class="col-md-12 modal-footer" style="border: none;">
                                    <button type="button" class="btn btn-primary btn-sm" (click)="calculate_quest_3()"><i class="mdi mdi-calculator"></i>Calcular</button>
                                    <button type="button" class="btn btn-primary btn-sm" (click)="save_quest_3()"><i class="mdi mdi-content-save"></i> Guardar</button>
                                </div>
                            </div>
                        </form>

                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </div>
    </div>

    <div class="row m-t-30">
        <div class="col-md-12 modal-footer"></div>
    </div>


</div>