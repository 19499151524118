import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FormsService } from 'src/app/services/service.index';
import { servicio } from '../../models/servicios.model';

@Component({
  selector: 'app-servicios-dialog',
  templateUrl: './servicios-dialog.component.html',
  styles: [
  ]
})
export class ServiciosDialogComponent implements OnInit {

  @ViewChild("form", { static: true }) form;
  @Output() submit_form = new EventEmitter<FormGroup>();
  @Output() close_modal = new EventEmitter();
  @Input()  show_cancel_button: boolean = false;
  @Input()  servicio: servicio;

  myDate:any;
  fecha:any


  public servicio_form: FormGroup;

  constructor(private _fb: FormBuilder, public _frm:FormsService) { }

  ngOnInit() {
    this.myDate=new Date()
    this.fecha ={year: this.myDate.getFullYear(), month: 12, day: 1}

    const numberRegEx = /\-?\d*\.?\d{1,2}/;

    this.servicio_form = this._fb.group({
      servicio_id: [this.servicio.servicio_id],
      nombre: [this.servicio.nombre, [Validators.required]],
      descripcion: [this.servicio.descripcion],
      imagen: [this.servicio.imagen, [Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]],
      medicion: [this.servicio.medicion],
      precio: [this.servicio.precio, [ Validators.required, Validators.pattern(numberRegEx)]]
    });
  }

  on_submit() {
    this.submit_form.emit(this.servicio_form);
  }
  on_close() {
    this.close_modal.emit();
  }


}
