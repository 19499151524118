import { Component, OnInit, ViewChild } from '@angular/core';
import { CatalogService, FormsService } from 'src/app/services/service.index';
import { especialidad } from './../../models/medico.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GridComponent } from 'src/app/shared/components/grid.component';
import { catalog } from 'src/app/models/catalog.model';
import { FormGroup } from '@angular/forms';
@Component({
  selector: 'app-camas',
  templateUrl: './camas.component.html',
  styles: []
})
export class CamasComponent implements OnInit {
  @ViewChild("modal", { static: true }) modal;
  @ViewChild(GridComponent, { static: true }) mygrid: GridComponent;
  
  rowData: any;
  columnDefs = [
    { headerName: "Nombre", field: "nombre"},
    { headerName: "Código", field: "codigo" },
    { headerName: "Descripción", field: "descripcion" }
  ];
  _cama:catalog = new catalog({});
  constructor(public _cat: CatalogService, 
              private modalService: NgbModal, 
              public _frm: FormsService) { }

  ngOnInit() {
    this._cat.get_catalog("cama", true).subscribe(data => this.rowData = data, error => console.log(error));
  }

  
  option_selector(button: string) {
    console.log(button);
    
    switch (button) {
      case "create": {
        this._cama = new catalog({});
        this.modalService.open(this.modal, { size: 'lg' });
        break;
      }
      case "update": {
          this._frm.get_rows_selected(this.mygrid, false, (selecteds) => {
          this._cama = selecteds[0];
          this.modalService.open(this.modal, { size: 'lg' })
        });
        break;
      }
      case "delete": {
        this._frm.get_rows_selected(this.mygrid, false, (selecteds) => {
          this._cama = selecteds[0];
          console.log(this._cama.id)
          this._frm.show_warning('Borrar cama', 'Estas seguro de eliminar el área [' + this._cama.nombre + ']. Una vez eliminado no podra recuperarse.', () => this.delete_cama(this._cama.id));
        });
        break;
      }
    }
  }

  delete_cama(id:number) {
    this._cat.delete_catalog("cama","cama",id).subscribe(
      (res: any) => {this._frm.toast_alert("Area eliminada exitosamente", "Borrar Area ", "success");
      this.modalService.dismissAll(null);
      this._cat.get_catalog("cama",true).subscribe(data => this.rowData = data, error => console.log(error));
    },
      error => { console.log(error); this._frm.toast_alert("El Tipo consulta no pudo ser actualizado correctamente. Error: " + error.error.message, "Borrar Tipo consulta", "error") }
    );
  }
  save_cama(form: FormGroup) {
    this._frm.save_module(form, "id", () => {
      
     console.log(form.value);
      this._cat.insert_catalog(form.value).subscribe(
        (res: any) =>
        { this._frm.toast_alert("Cama creada exitosamente", "Nueva Cama", "success");
          this.modalService.dismissAll(null);
          this._cat.get_catalog("cama", true).subscribe(data => this.rowData = data, error => console.log(error));
        },
        error => this._frm.toast_alert("La cama no pudo ser creado correctamente. Error: " + error.error.message, "Nueva Cama", "error")
      );
      
    }, () => {
       this._cat.update_catalog(form.value).subscribe(
        (res: any) =>
         {this._frm.toast_alert("Cama actualizada exitosamente", "Actualizar Cama", "success");
         this.modalService.dismissAll(null); 
         this._cat.get_catalog("cama",true).subscribe(data => this.rowData = data, error => console.log(error));     
          },
         error => {
           this._frm.toast_alert("La cama no pudo ser actualizado correctamente. Error: " + error.error.message, "Actualizar Cama", "error");
         }
       );
    });

  }



}
