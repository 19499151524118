import { Component, OnInit, ViewChild } from '@angular/core';
import { GridComponent } from '../../shared/components/grid.component';
import { paciente } from '../../models/paciente.model';
import { AuthorizationService } from '../../services/authorization.service';
import { PacienteService } from '../../services/paciente.service';
import { FormsService } from '../../services/forms.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbDate } from '@ng-bootstrap/ng-bootstrap';

declare var sw: any;
import * as moment from 'moment';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-rep-pacientes',
  templateUrl: './rep-pacientes.component.html',
  styles: []
})

export class RepPacientesComponent implements OnInit {
  @ViewChild("content", { static: true }) content;
  @ViewChild(GridComponent, { static: true }) mygrid: GridComponent;

  public paciente: string;
  public fecha_inicio: Date;
  public fecha_fin: Date;

  maxDate = { year: new Date().getUTCFullYear(), month: new Date().getUTCMonth() +1, day: new Date().getDate() };

  _paciente: paciente = new paciente({});

  rowData: any = [];
  columnDefs = [
    { headerName: "No Registro", field: "numero_registro" },
    { headerName: "Paciente", field: "nombre_completo" },
    {
      headerName: "Fecha de nacimiento", field: "fecha_nacimiento", cellRenderer: (data) => {
        return data.value ? moment(new Date(data.value)).format('MM/DD/YYYY') : '';
      }
    },
    { headerName: "Sexo", field: "sexo" },
    { headerName: "Teléfono", field: "telefono_movil" },
    { headerName: "Email", field: "email"},
    { headerName: "Registro", field: "sys_start_time", cellRenderer: (data) => {
      return data.value ? moment(new Date(data.value)).format('MM/DD/YYYY') : '';}
    }

  ];
  hoveredDate: NgbDate;
  fromDate: NgbDate;
  toDate: NgbDate;

  
  
  public currentPdf: string = null;
  constructor(
    public _auth: AuthorizationService, 
    public _cat: PacienteService, 
    public _frm: FormsService, 
    private _toastr: ToastrService, 
    private modalService: NgbModal,
    public pacienteService: PacienteService
    ) { }
  
  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    console.log(date)
  }

  ngOnInit() {
    this.rowData = [];
  }
  
  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }

  change_valores() {
    var fecha1:any = this.fecha_inicio
    var fecha2:any = this.fecha_fin

    if(fecha1 != null || fecha2 != null){
      var f1  = new Date(Date.UTC(fecha1.year, fecha1.month -1, fecha1.day + 1));
      var f2  = new Date(Date.UTC(fecha2.year, fecha2.month -1, fecha2.day + 1));
      var inicio = moment(f1).format('YYYY/MM/DD');
      var fin = moment(f2).format('YYYY/MM/DD');
    }

    if(fin < inicio){
        this._toastr.warning("La fecha fin debe ser igual o mayor a la fecha inicio");
      }else{
      if(this.paciente == null){
        this._cat.search_paciente_reporte('',this.fecha_inicio,this.fecha_fin).subscribe(data => this.rowData = data, error => console.log(error));
      }else{
        this._cat.search_paciente_reporte(this.paciente,this.fecha_inicio,this.fecha_fin).subscribe(data => this.rowData = data, error => console.log(error));
      }
    }
  }

  option_selector(button: string){
    console.log(button);
    switch ( button ){
      case "print": {
        let preloader = document.getElementById('preloader');
        preloader.style.display = 'block';

        this.pacienteService.print_pacientes2(this.rowData).subscribe(
          data => {
            let blob = new Blob([data], { type: 'application/pdf' });          
            this.currentPdf = URL.createObjectURL(blob);
            window.open(this.currentPdf, '_blank');
            preloader.style.display = 'none';
          },
          err => console.log(err)
        );
        break;
      }
    }
  }
}
