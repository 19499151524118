import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { AuthorizationService } from "src/app/services/service.index";
import { Router } from "@angular/router";

@Component({
  selector: "app-buttons",
  templateUrl: "./buttons.component.html",
  styles: []
})
export class ButtonsComponent implements OnInit {
  permissions: any[] = [];
  parents: any[] = [];
  children: any[] = [];

  @Output() button_selected = new EventEmitter<string>();

  constructor(private _router: Router, private _auth: AuthorizationService) {}

  ngOnInit() {
    //console.log(`<BUTTONS>`);
    this.permissions = this._auth.get_permission_module(this._router.url);
    // console.log(`permissions:${JSON.stringify(this.permissions)}`);
    // console.log(`this._router.url:${this._router.url}`);
    this.parents = this.permissions
      .filter(p => p.show_button === true)
      .filter(p => p.parent_id === null);
  }

  subpermission(parentid: number) {
    return this.permissions.filter(p => p.parent_id === parentid);
  }

  clickButton(button: string) {
        this.button_selected.emit(button);
  }



}
