<div class="card-body p-b-0">
  <form class="form-material" [formGroup]="medic_form" (ngSubmit)="on_submit()" #form="ngForm" novalidate>

    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label for="txtCedula" >Cédula</label>
          <input type="text" placeholder="Cédula" class="form-control" id="txtCedula" formControlName="cedula">
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="input2">Consulta Externa</label>
          <ng-select placeholder="Consulta Externa" formControlName="consulta_externa" class="form-control" [items]="sino" bindLabel="name" bindValue="id">
        </ng-select>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="txtMaximas">Consultas Maximas</label>
          <input type="text" placeholder="Consultas Maximas" class="form-control" id="txtMaximas"  formControlName="consultas_maximas">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label for="input7">Especialidad(es)</label>
          <ng-select placeholder="Especialidades" formControlName="especialidades" class="form-control"  [multiple]="true" [items]="especialidades" bindLabel="nombre" bindValue="id"> </ng-select>
        </div>
      </div>
    </div>

    <div class="row m-t-30">
      <div class="col-md-12 modal-footer">
        <button *ngIf="show_cancel_button" type="button" class="btn btn-danger btn-sm" (click)="on_close()"><i class="mdi mdi-close"></i> Cancelar</button>
        <button type="submit" class="btn btn-primary btn-sm "><i class="mdi mdi-content-save"></i> Guardar</button>
      </div>
    </div>

  </form>
</div>