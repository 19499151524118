<div class="card-body p-b-0">
  <form class="form-material" [formGroup]="referencias_form" #form="ngForm" novalidate  (ngSubmit)="guardar()"> 
    <div class="row">
      <div class="col-md-4">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('fecha', referencias_form, form.submitted)}">
                  <label for="txtFecha" [ngClass]="{ 'star_symbol': _frm.is_required('fecha', referencias_form) }">Fecha</label>
                  <input class="form-control" placeholder="Fecha de referencia" id="txtFecha"
                    formControlName="fecha" ngbDatepicker #d="ngbDatepicker" (focus)="d.toggle()"
                    readonly="readonly" [minDate]="{year: 1900, month: 1, day: 1}" >
                  <i class="mdi mdi-calendar"></i>
                  <div class="invalid-feedback">
                    <div *ngIf="referencias_form.controls['fecha'].errors?.required">Ingrese la fecha de referencia</div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('unidad_refiere', referencias_form, form.submitted)}">
                  <label for="txtpaterno" [ngClass]="{ 'star_symbol': _frm.is_required('unidad_refiere', referencias_form) }">Unidad refiere</label>
                  <input type="text" placeholder="Unidad refiere" class="form-control" id="txtunidadRefiere"
                    formControlName="unidad_refiere" >
                  <div class="invalid-feedback">
                    <div *ngIf="referencias_form.controls['unidad_refiere'].errors?.required">Ingrese la unided refiere</div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('unidad_referida', referencias_form, form.submitted)}">
                  <label for="txtpaterno" [ngClass]="{ 'star_symbol': _frm.is_required('unidad_referida', referencias_form) }">Unidad referida</label>
                  <input type="text" placeholder="Unidad referida" class="form-control" id="txtunidadReferida"
                    formControlName="unidad_referida" >
                  <div class="invalid-feedback">
                    <div *ngIf="referencias_form.controls['unidad_referida'].errors?.required">Ingrese la unided referida</div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
              <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('especialidad_id', referencias_form, form.submitted)}">
                  <label for="ddlEspecialidad" [ngClass]="{ 'star_symbol': _frm.is_required('especialidad_id', referencias_form) }">Especialidad</label>
                  <ng-select [searchable]="true" placeholder="Especialidad" class="form-control" id="ddlEspecialidad"
                             [items]="especialidades"   
                             name ="especialidad_id"  bindLabel="nombre" 
                             bindValue="id" 
                             formControlName="especialidad_id">
                  </ng-select>
                  <div class="invalid-feedback">
                    <div *ngIf="referencias_form.controls['especialidad_id'].errors?.required">Ingrese la especialidad</div>
                  </div>
                </div> 
              </div>
              <div class="col-md-12">
                  <div class="form-group" >
                      <label for="txtmotivo">Motivo</label>
                      <input type="text" placeholder="Motivo" class="form-control" id="txtmotivo"
                        formControlName="motivo" >
                      
                    </div>
              </div>
           
            </div>
          </div>
        </div>

      </div>











      <div class="col-md-8">
          <button class="btn btn-secondary float-right" (click)="selectDelete()" ngbtooltip="Eliminar diagnostico" type="button" ng-reflect-ngb-tooltip="Eliminar referencia"><i class="mdi mdi-delete"></i></button>
          <app-grid [autosize]="false" (on_row_clicked)="selectRow()" [rowData]="rowData" [columnDefs]="columnDefs" gridHeight="400px" [rowSelection]="'single'"  #grid1></app-grid>

      </div>
    </div>

    <div class="row m-t-30">
      <div class="col-md-12 modal-footer">
        <button type="submit" class="btn btn-primary btn-sm "><i class="mdi mdi-content-save"></i> Guardar</button>
      </div>
    </div>

  </form>
</div>