import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { AuthorizationService } from './authorization.service';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AddressService {

  constructor(public http: HttpClient, public _auth:AuthorizationService) { }
  get_catalog(tabla: string, activo?: boolean) {
    let url = environment.apiEndpoint + "catalog/get_catalogos";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
      params: new HttpParams().set('tabla', tabla).set('activo', activo ? "1" : "0"),
    };
    return this.http.get(url, httpOptions);
  }
  
  get_states(country_id:number){
    let url = environment.apiEndpoint + "address/GetStates";
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type':  'application/json','Authorization': 'Bearer ' + this._auth.token}),
      params: new HttpParams().set('country_id', country_id.toString())
    };
    
    return this.http.get(url, httpOptions);
  }

  get_municipality(country_id:number, state_id:number){
    let url = environment.apiEndpoint + "address/GetMunicipality";
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type':  'application/json','Authorization': 'Bearer ' + this._auth.token}),
      params: new HttpParams().set('country_id', country_id.toString()).set('state_id', state_id.toString())
    };
    
    return this.http.get(url, httpOptions);
  }

  get_settlements(country_id:number, state_id:number, municipality_id:number){
    let url = environment.apiEndpoint + "address/GetSettlement";
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type':  'application/json','Authorization': 'Bearer ' + this._auth.token}),
      params: new HttpParams().set('country_id', country_id.toString()).set('state_id', state_id.toString()).set('municipality_id', municipality_id.toString())
    };
    
    return this.http.get(url, httpOptions);
  }

  get_address_from_postalcode(postalcode:string) {
    let url = environment.apiEndpoint + "address/SearchAddress";
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type':  'application/json','Authorization': 'Bearer ' + this._auth.token}),
      params: new HttpParams().set('code', postalcode)
    };      
    return this.http.get(url, httpOptions);
  }

}

