import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { agenda, consulta, consulta_diagnostico } from 'src/app/models/consulta.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable, Subject, concat, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap, catchError } from 'rxjs/operators';
import { ConsultaService } from 'src/app/services/service.index';
import { AuthorizationService, PacienteService } from "src/app/services/service.index";
import { NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { GridComponent } from 'src/app/shared/components/grid.component';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { element } from 'protractor';
import swal from'sweetalert2';
import Swal from 'sweetalert2/dist/sweetalert2.js';  
import { ScheduleService } from 'src/app/services/schedule.service';
import { ConsultaPrint } from 'src/app/models/consulta_print.model';

@Component({
  selector: 'app-consulta-dialog',
  templateUrl: './consulta-dialog.component.html',
  styles: []
})
export class ConsultaDialogComponent implements OnInit {
  @ViewChild("grid_diagnostico") grid_diagnostico: GridComponent;
  @ViewChild("grid_medicamento") grid_medicamento: GridComponent;
  @ViewChild("grid_procedimiento") grid_procedimiento: GridComponent;
  @ViewChild("grid_estudio") grid_estudio: GridComponent;
  
  @Input() agenda: agenda = new agenda({});
  //@Output() submit_form = new EventEmitter<consulta>(true);
  @Output() submit_form = new EventEmitter<ConsultaPrint>(true);
  @Output() close_modal = new EventEmitter();
  
  consulta: consulta = new consulta({});
  consulta_form: FormGroup;

  causes$: Observable<Object>;
  causes_input$ = new Subject<string>();
  causes_loading = false;

  diagnostico_model: any = { id: null, clase: null, descripcion: null };
  diagnostico$: Observable<Object>;
  diagnostico_input$ = new Subject<string>();
  diagnostico_loading = false;

  medicamento_model: any = { descripcion: null, via_administracion: '' };
  medicamento$: Observable<Object>;
  medicamento_input$ = new Subject<string>();
  medicamento_loading = false;
  medicamento_via: any[] = [];

  procedimiento_model: any = { descripcion: null, codigo:null };
  procedimiento$: Observable<Object>;
  procedimiento_input$ = new Subject<string>();
  procedimiento_loading = false;

  estudio_model: any = { descripcion: null, codigo:null };
  estudio$: Observable<Object>;
  estudio_input$ = new Subject<string>();
  estudio_loading = false;

  diagnostico_columndefs = [
    { headerName: "Clase", field: "clase", width: 100 },
    { headerName: "Descripción", field: "descripcion", width: 350 },
    { headerName: "Observaciones", field: "observaciones", width: 450 }
  ];
  medicamento_columndefs = [
    { headerName: "Medicamento", field: "nombre_compuesto", width: 300 },
    { headerName: "Dosis", field: "dosis", width: 120 },
    { headerName: "Frecuencia", field: "frecuencia", width: 120 },
    { headerName: "Desde", field: "fecha_inicio", width: 110, cellRenderer: (data) => { return moment(data.value).format('MMMM DD, YYYY') } },
    { headerName: "Hasta", field: "fecha_fin", width: 110, cellRenderer: (data) => { return moment(data.value).format('MMMM DD, YYYY') } },
    { headerName: "Via", field: "via_administracion", width: 120 },
    { headerName: "Observaciones", field: "indicaciones_adicionales", width: 350 }
  ];
  procedimiento_columndefs = [
    { headerName: "Código", field: "procedimiento_cod", width: 100 },
    { headerName: "Descripción", field: "procedimiento", width: 250 },
    { headerName: "Observaciones", field: "observacion", width: 250 }
  ];
  estudio_columndefs = [
    { headerName: "Código", field: "estudio_cod", width: 100 },
    { headerName: "Descripción", field: "estudio", width: 250 },
    { headerName: "Observaciones", field: "observaciones", width: 250 }
  ];

  minDate  = undefined;
  minDate2  = undefined;

  exploracionFisicaRealizada = false;
  public currentPdf: string = null;

  showPrintButton = false;
  showPrintButtonE = false;

  constructor(private _fb: FormBuilder, public _consulta: ConsultaService, private _toastr: ToastrService, public _scheduleService: ScheduleService,public pacienteService: PacienteService,public _auth: AuthorizationService,) { }

   ngOnInit() {   
     this.exploracionHasData(this.agenda.consulta_id).then(value=>this.exploracionFisicaRealizada = value);
    const current = new Date();
    this.minDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate()
    };

    this.minDate2 = {
      year: this.minDate.year,
      month: this.minDate.month,
      day: this.minDate.day
    };
    

    this.load_data_consulta();
    this.consulta_form = this._fb.group({
      motivo: [this.consulta.motivo, [Validators.required]],
      nota_medica: [this.consulta.nota_medica, [Validators.required]],
      causes_id: [this.consulta.causes_id],

      diagnostico_id: [null],
      diagnostico_observaciones: [null],

      medicamento_id: [null],
      medicamento_observaciones: [null],
      medicamento_dosis: [null],
      medicamento_frecuencia: [null],
      medicamento_inicio: [null],
      medicamento_fin: [null],
      medicamento_via: [null],

      procedimiento_id: [null],
      procedimiento_observaciones: [null],

      estudio_id: [null],
      estudio_observaciones: [null]

    });

    this.consulta_form.controls['medicamento_inicio'].valueChanges.subscribe(value => {
      this.minDate2 = {
        year: value.year,
        month: value.month,
        day: value.day
      };
    });
  }

  load_data_consulta(){
    this._consulta.get_consulta(this.agenda.consulta_id).subscribe(
      (data: any) => {
        console.log(data);
        
        this.consulta = data;
        this.consulta.fecha_inicio_real =  new Date();
        this.consulta_form.patchValue({"motivo": this.consulta.motivo });
        this.consulta_form.patchValue({"nota_medica": this.consulta.nota_medica });
        this.consulta_form.patchValue({"causes_id": this.consulta.causes_id });

        if(this.consulta.medicamentos != null && this.consulta.medicamentos.length > 0){
          this.showPrintButton = true;
        }
        if(this.consulta.estudios != null && this.consulta.estudios.length > 0){
          this.showPrintButtonE = true;
        }

      },
      err => console.log(err)
    );   
  }

  panel_change($event: NgbPanelChangeEvent) {
    if ($event.nextState) {
      if ($event.panelId === 'panel-causes') {
        this.load_causes();
      }
      if ($event.panelId === 'panel-diagnostico') {
        if (this.consulta_form.get("causes_id").value) {
          this._consulta.search_diagnostico('', this.consulta_form.get("causes_id").value).subscribe(data => {
            this.load_diagnostico(data);
          });
        }
        else {
          this.load_diagnostico([]);
        }
      }
      if ($event.panelId === 'panel-medicamentos') {
        if (this.consulta_form.get("causes_id").value) {
          this._consulta.search_medicamento('', this.consulta_form.get("causes_id").value).subscribe(data => {
            this.load_medicamento(data);
          });
        }
        else {
          this.load_medicamento([]);
        }
      }
      if ($event.panelId === 'panel-procedimientos') {
        if (this.consulta_form.get("causes_id").value) {
          this._consulta.search_procedimiento('', this.consulta_form.get("causes_id").value).subscribe(data => {
            this.load_procedimiento(data);
          });
        }
        else {
          this.load_procedimiento([]);
        }
      }
      if ($event.panelId === 'panel-estudios') {
        if (this.consulta_form.get("causes_id").value) {
          this._consulta.search_estudio('', this.consulta_form.get("causes_id").value).subscribe(data => {
            this.load_estudio(data);
          });
        }
        else {
          this.load_estudio([]);
        }
      }
    }
  }

  load_causes() {
    this.causes$ = concat(
      of(this.consulta.causes_id ?
        [{ nombre: this.consulta.cause, causes_id: this.consulta.causes_id }] : []
      ),
      this.causes_input$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => this.causes_loading = true),
        switchMap(term => this._consulta.search_causes(term).pipe(
          catchError(() => of([])),
          tap(() => this.causes_loading = false),
        ))
      )
    )
  }
  change_causes(event) {
    if (event) {
      this.consulta.causes_id = event.causes_id;
      this.consulta.cause = event.nombre;
    }
    else {
      this.consulta.causes_id = null;
      this.consulta.cause = '';
    }
  }


  load_diagnostico(init_data) {
    this.diagnostico$ = concat(
      of(init_data),
      this.diagnostico_input$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => this.diagnostico_loading = true),
        switchMap(term => this._consulta.search_diagnostico(term).pipe(
          catchError(() => of([])),
          tap(() => this.diagnostico_loading = false),
        ))
      )
    )
  }
  add_diagnostico() {
    
    const selecteds = this.grid_diagnostico.rowData;
    console.log(selecteds)

    var diagnosticoRepetido = false;
    this.consulta.diagnosticos.forEach((element)=>{ 
      if(this.consulta_form.get("diagnostico_id").value == element.diagnostico_id){
        diagnosticoRepetido = true;
      }
    });

    if(diagnosticoRepetido){
      this._toastr.warning("El diagnóstico seleccionado ya se agregó con anterioridad", "Aviso")
    }else{
      if (this.consulta_form.get("diagnostico_id").value) {
        this.consulta.diagnosticos.push({
        consulta_id: this.agenda.consulta_id,
        diagnostico_id: this.diagnostico_model.id,
        observaciones: this.consulta_form.get("diagnostico_observaciones").value,
        clase: this.diagnostico_model.clase,
        descripcion: this.diagnostico_model.descripcion
      });
      this.grid_diagnostico.gridApi.setRowData(this.consulta.diagnosticos);

      this.consulta_form.patchValue({ "diagnostico_id": null });
      this.consulta_form.patchValue({ "diagnostico_observaciones": null });
    }
    }    
  }
  remove_diagnostico() {
    const selecteds = this.grid_diagnostico.getSelectedRows();
    if (selecteds.length == 0) this._toastr.warning("Seleccione el diagnóstico que desea eliminar", "Consulta médica")
    else {
      let diag = selecteds[0];
      this.consulta.diagnosticos = this.consulta.diagnosticos.filter(function (item) {
        return item.diagnostico_id !== diag.diagnostico_id;
      })
      this.grid_diagnostico.gridApi.setRowData(this.consulta.diagnosticos);
    }
  }
  change_diagnostico(event) {
    if (event) {
      this.diagnostico_model.id = event.diagnostico_id;
      this.diagnostico_model.clase = event.clase;
      this.diagnostico_model.descripcion = event.descripcion;
    }
  }


  load_medicamento(init_data) {
    this.medicamento$ = concat(
      of(init_data),
      this.medicamento_input$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => this.medicamento_loading = true),
        switchMap(term => this._consulta.search_medicamento(term).pipe(
          catchError(() => of([])),
          tap(() => this.medicamento_loading = false),
        ))
      )
    )

  }
  add_medicamento() {

    //VALIDAR QUE EL MEDICAMENTO NO SE ENCUENTRE YA.
    //ID: INCIDENCIA I_EXP.MEDICO_11
    var medicamentoRepetido = false;
    this.consulta.medicamentos.forEach((element)=>{ 
      if(this.consulta_form.get("medicamento_id").value == element.medicamento_id){
        medicamentoRepetido = true;
        console.log(`medicamento repetido: ${element.medicamento_id}`);
        return;
      }
    });

    if (medicamentoRepetido) {
      this._toastr.warning("El medicamento seleccionado ya se agregó con anterioridad", "Aviso");
    }else{
      console.log('else');
      if (this.consulta_form.get("medicamento_id").value) {
          console.log('else-if');
        let obj_fecha_inicio = this.consulta_form.get("medicamento_inicio").value;
        let obj_fecha_fin = this.consulta_form.get("medicamento_fin").value;
  
        let fecha_inicio = new Date(obj_fecha_inicio.year, obj_fecha_inicio.month - 1, obj_fecha_inicio.day);
        let fecha_fin = new Date(obj_fecha_fin.year, obj_fecha_fin.month - 1, obj_fecha_fin.day);
  
        this.consulta.medicamentos.push({
          consulta_id: this.agenda.consulta_id,
          medicamento_id: this.consulta_form.get("medicamento_id").value,
          nombre_compuesto: this.medicamento_model.descripcion,
          dosis: this.consulta_form.get("medicamento_dosis").value,
          frecuencia: this.consulta_form.get("medicamento_frecuencia").value,
          fecha_inicio: fecha_inicio,
          fecha_fin: fecha_fin,
          via_administracion_id: this.consulta_form.get("medicamento_via").value,
          via_administracion: this.medicamento_model.via_administracion,
          indicaciones_adicionales: this.consulta_form.get("medicamento_observaciones").value,
        });
  
  
        this.grid_medicamento.gridApi.setRowData(this.consulta.medicamentos);
  
        this.consulta_form.patchValue({ "medicamento_id": null });
        this.consulta_form.patchValue({ "medicamento_observaciones": null });
        this.consulta_form.patchValue({ "medicamento_dosis": null });
        this.consulta_form.patchValue({ "medicamento_frecuencia": null });
        this.consulta_form.patchValue({ "medicamento_inicio": null });
        this.consulta_form.patchValue({ "medicamento_fin": null });
        this.consulta_form.patchValue({ "medicamento_via": null });
        this.consulta_form.patchValue({ "medicamento_observaciones": null });
      }
    }    
  }
  remove_medicamento() {
    const selecteds = this.grid_medicamento.getSelectedRows();
    if (selecteds.length == 0) this._toastr.warning("Seleccione el medicamento que desea eliminar", "Consulta médica")
    else {
      let meds = selecteds[0];
      this.consulta.medicamentos = this.consulta.medicamentos.filter(function (item) {
        return item.medicamento_id !== meds.medicamento_id;
      })
      this.grid_medicamento.gridApi.setRowData(this.consulta.medicamentos);
    }
  }
  change_medicamento(event) {
    if (event) {
      this.consulta_form.patchValue({ "medicamento_via": null });
      this.medicamento_model.descripcion = event.nombre_compuesto;
      this.medicamento_via = event.vias;
      if (this.medicamento_via.length == 1) {
        this.consulta_form.patchValue({ "medicamento_via": this.medicamento_via[0].via_administracion_id });
        this.medicamento_model.via_administracion = this.medicamento_via[0].nombre;
      }
    }
  }
  change_medicamento_via(event) {
    if (event) {
      this.medicamento_model.via_administracion = event.nombre;
    }

  }


  load_procedimiento(init_data) {
    this.procedimiento$ = concat(
      of(init_data),
      this.procedimiento_input$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => this.procedimiento_loading = true),
        switchMap(term => this._consulta.search_procedimiento(term).pipe(
          catchError(() => of([])),
          tap(() => this.procedimiento_loading = false),
        ))
      )
    )
  }
  add_procedimiento() {
    var procedimientoRepetido = false;
    this.consulta.procedimientos.forEach((element)=>{
      if(this.consulta_form.get("procedimiento_id").value == element.procedimiento_id){
        procedimientoRepetido = true;
      }
    });

    if(procedimientoRepetido){
      this._toastr.warning("El procedimeinto seleccionado ya se agrego con anterioridad", "Aviso")
    }else{
      if (this.consulta_form.get("procedimiento_id").value) {
          this.consulta.procedimientos.push({
          consulta_id: this.agenda.consulta_id,
          procedimiento_id: this.consulta_form.get("procedimiento_id").value,
          observacion: this.consulta_form.get("procedimiento_observaciones").value,
          procedimiento: this.procedimiento_model.descripcion,
          procedimiento_cod: this.procedimiento_model.codigo
      });
      this.grid_procedimiento.gridApi.setRowData(this.consulta.procedimientos);

      this.consulta_form.patchValue({ "procedimiento_id": null });
      this.consulta_form.patchValue({ "procedimiento_observaciones": null });
      }
    }
  }
  remove_procedimiento() {
    const selecteds = this.grid_procedimiento.getSelectedRows();
    if (selecteds.length == 0) this._toastr.warning("Seleccione el procedimiento que desea eliminar", "Consulta médica")
    else {
      let proc = selecteds[0];
      this.consulta.procedimientos = this.consulta.procedimientos.filter(function (item) {
        return item.procedimiento_id !== proc.procedimiento_id;
      })
      this.grid_procedimiento.gridApi.setRowData(this.consulta.procedimientos);
    }
  }
  change_procedimiento(event) {
    if (event) {
      this.procedimiento_model.descripcion = event.nombre;
      this.procedimiento_model.codigo = event.procedimiento_cod;
    }
  }


  load_estudio(init_data) {
    this.estudio$ = concat(
      of(init_data),
      this.estudio_input$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => this.estudio_loading = true),
        switchMap(term => this._consulta.search_estudio(term).pipe(
          catchError(() => of([])),
          tap(() => this.estudio_loading = false),
        ))
      )
    )
  }
  add_estudio() {
    var estudioRepetido = false;
    this.consulta.estudios.forEach((element)=>{
      if(this.consulta_form.get("estudio_id").value == element.estudio_id){
        estudioRepetido = true;
      }
    });

    if(estudioRepetido){
      this._toastr.warning("El estudio seleccionado ya se agrego con anterioridad", "Aviso")
    }else{
      if (this.consulta_form.get("estudio_id").value) {
          this.consulta.estudios.push({
          consulta_id: this.agenda.consulta_id,
          estudio_id: this.consulta_form.get("estudio_id").value,
          observaciones: this.consulta_form.get("estudio_observaciones").value,
          estudio: this.estudio_model.descripcion,
          estudio_cod: this.estudio_model.codigo
        });
        this.grid_estudio.gridApi.setRowData(this.consulta.estudios);

        this.consulta_form.patchValue({ "estudio_id": null });
        this.consulta_form.patchValue({ "estudio_observaciones": null });
      }
    }
  }
  remove_estudio() {
    const selecteds = this.grid_estudio.getSelectedRows();
    if (selecteds.length == 0) this._toastr.warning("Seleccione el estudio que desea eliminar", "Consulta médica")
    else {
      let estd = selecteds[0];
      this.consulta.estudios = this.consulta.estudios.filter(function (item) {
        return item.estudio_id !== estd.estudio_id;
      })
      this.grid_estudio.gridApi.setRowData(this.consulta.estudios);
    }
  }
  change_estudio(event) {
    if (event) {
      this.estudio_model.descripcion = event.estudio;
      this.estudio_model.codigo = event.estudio_cod;
    }
  }


  on_submit() {
    Swal.fire({
      title: 'Aviso',
      text: '¿Desea imprimir la receta?',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No, luego'
    }).then((result) => {
      if (result.value) {   
        this.callSubmit(true);
        //this.printPrescription();     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        this.callSubmit(false);
      }
    });
    /*
    this.consulta.motivo = this.consulta_form.get("motivo").value;
    this.consulta.nota_medica = this.consulta_form.get("nota_medica").value
    this.consulta.causes_id = this.consulta_form.get("causes_id").value
    this.consulta.fecha_fin_real =  new Date();
    console.log(`this.consulta: ${JSON.stringify(this.consulta)}`);
    this.submit_form.emit(this.consulta);
    */
  }

  callSubmit(printNow: boolean){
    this.consulta.motivo = this.consulta_form.get("motivo").value;
    this.consulta.nota_medica = this.consulta_form.get("nota_medica").value
    this.consulta.causes_id = this.consulta_form.get("causes_id").value
    this.consulta.fecha_fin_real =  new Date();
    console.log(`this.consulta: ${JSON.stringify(this.consulta)}`);
    //this.submit_form.emit(this.consulta);
    var consultaPrint = new ConsultaPrint();
    consultaPrint.consulta = this.consulta;
    consultaPrint.print = printNow;
    this.submit_form.emit(consultaPrint);
  }
  on_close() {
    this.close_modal.emit();
  }
  
  printPrescription() {
    let preloader = document.getElementById('preloader');
    preloader.style.display = 'block';

    this.pacienteService.print_prescription(this.consulta.consulta_id).subscribe(
      data => {
        let blob = new Blob([data], { type: 'application/pdf' });          
        this.currentPdf = URL.createObjectURL(blob);
        window.open(this.currentPdf, '_blank');
        preloader.style.display = 'none';
      },
      err => console.log(err)
    );
    this.close_modal.emit();
  }

  printEstudios() {
    let preloader = document.getElementById('preloader');
    preloader.style.display = 'block';

    this.pacienteService.print_estudios(this.consulta.consulta_id).subscribe(
      data => {
        console.log(data);
        
        let blob = new Blob([data], { type: 'application/pdf' });          
        this.currentPdf = URL.createObjectURL(blob);
        window.open(this.currentPdf, '_blank');
        preloader.style.display = 'none';
      },
      err => console.log(err)
    );
    this.close_modal.emit();
  }

  async exploracionHasData(consultaId:number): Promise<boolean> {
    var result = false;
    try {
      let response = await this._scheduleService.getDataDynamicForm(consultaId).toPromise();
      if(response == null){
        console.log('Exploración Física NO realizada');
        result = false;
      }else{
        console.log('Exploración Física SI realizada');
        result = true;
      }
    } catch(err) {
      console.log(err);
    } finally{
      return result;
    }
  }




}
