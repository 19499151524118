import { Options } from '@angular-slider/ngx-slider';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { horario } from 'src/app/models/medico.model';
import { ConsultaService } from 'src/app/services/consulta.service';

@Component({
  selector: 'app-consultorios-horario',
  templateUrl: './consultorios-horario.component.html',
  styles: []
})
export class ConsultoriosHorarioComponent implements OnInit {

  @Output() close_modal = new EventEmitter();
  @Output() submit_form = new EventEmitter<any>();
  @Input() consultorio: any;
  
  horario: any = { lun: { dia: 1, from: 0, to: 0, checked: false }, mar: { dia: 2, from: 0, to: 0, checked: false }, mie: { dia: 3, from: 0, to: 0, checked: false }, jue: { dia: 4, from: 0, to: 0, checked: false }, vie: { dia: 5, from: 0, to: 0, checked: false }, sab: { dia: 6, from: 0, to: 0, checked: false }, dom: { dia: 7, from: 0, to: 0, checked: false } };
  result: any = {};
  options: Options = {
    floor: 0,
    ceil: 23.5,
    step: 0.5,
    showTicks: true,
    translate: (value: number): string => {
      var decimalTimeString = value.toString();
      var n = new Date(0, 0);
      n.setMinutes(+decimalTimeString * 60);
      var result = n.toTimeString().slice(0, 5);
      return result;
    }

  };
  
  constructor(public cdRef: ChangeDetectorRef, public _consultaService:ConsultaService) { }

  ngOnInit(): void {

    this.get_data();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }


  get_data() { 
    this._consultaService.get_horario_consultorio(this.consultorio.consultorio_id).subscribe(
      (data:any) => {
        console.log(data);
        data.forEach(element => {
         if (element.dia_id == 1) {
          this.horario.lun.from = element.hora_inicio_decimal;
          this.horario.lun.to = element.hora_fin_decimal;
          this.horario.lun.checked = true;
         } 
         else if (element.dia_id == 2) {
          this.horario.mar.from = element.hora_inicio_decimal;
          this.horario.mar.to = element.hora_fin_decimal;
          this.horario.mar.checked = true;
         } 
         else if (element.dia_id == 3) {
          this.horario.mie.from = element.hora_inicio_decimal;
          this.horario.mie.to = element.hora_fin_decimal;
          this.horario.mie.checked = true;
         } 
         else if (element.dia_id == 4) {
          this.horario.jue.from = element.hora_inicio_decimal;
          this.horario.jue.to = element.hora_fin_decimal;
          this.horario.jue.checked = true;
         } 
         else if (element.dia_id == 5) {
          this.horario.vie.from = element.hora_inicio_decimal;
          this.horario.vie.to = element.hora_fin_decimal;
          this.horario.vie.checked = true;
         } 
         else if (element.dia_id == 6) {
          this.horario.sab.from = element.hora_inicio_decimal;
          this.horario.sab.to = element.hora_fin_decimal;
          this.horario.sab.checked = true;
         } 
         else if (element.dia_id == 7) {
          this.horario.dom.from = element.hora_inicio_decimal;
          this.horario.dom.to = element.hora_fin_decimal;
          this.horario.dom.checked = true;
         } 
          
        });
      },
      error => {console.log(error);}
    );
  }

  on_submit() {
    var resultado = []; 
    Object.entries(this.horario).map(item => {
      console.log(item)
      if (item.length > 0) {
        var dia:any = item[1];
        if (dia.checked) {
          resultado.push({
            consultorio_id: this.consultorio.consultorio_id,
            dia_id: dia.dia,
            hora_inicio:this.dateFormat(dia.from),
            hora_fin:this.dateFormat(dia.to)
          })
        }
      }
    })

    this.submit_form.emit(resultado);
    console.log(resultado);
    
  }
  on_close() {
    this.close_modal.emit();
  }


  change_day(h, event) {
    console.log(event);
    h.checked = event.srcElement.checked;
  }

  dateFormat(value) {
    var decimalTimeString = value.toString();
    var n = new Date(0, 0);
    n.setMinutes(+decimalTimeString * 60);
    var result = n.toTimeString().slice(0, 5);
    return result;
  }

}
