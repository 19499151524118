<div class="card-body p-b-0">


  <form class="form-material" [formGroup]="user_form" (ngSubmit)="on_submit()" #form="ngForm" novalidate autocomplete="off"s>

    <div class="row">
      <div class="col-md-12">
        <div class="form-group" [ngClass]="{ 'has-danger': is_invalid('role_id')}">
          <label for="ddlRole" [ngClass]="{ 'star_symbol': is_required('role_id') }">Rol</label>
          <ng-select id="ddlRole" placeholder="Roles" formControlName="role_id" class="form-control" (change)="change_role($event)"
              [items]="roles"  bindLabel="description" bindValue="role_id"> </ng-select>
          <div class="invalid-feedback">
            <div *ngIf="user_form.controls['role_id'].errors?.required">Ingrese el rol del usuario</div>
          </div>
        </div>
      </div>


    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="form-group" [ngClass]="{ 'has-danger': is_invalid('username')}">
          <label for="txtNombre" [ngClass]="{ 'star_symbol': is_required('username') }">Nombre de usuario</label>
          <input type="text" placeholder="Nombre de usuario" class="form-control" id="txtNombre"
            formControlName="username" autocomplete="off">

          <div class="invalid-feedback">
            <div *ngIf="user_form.controls['username'].errors?.required">Ingrese el nombre de usuario</div>
            <div *ngIf="user_form.controls['username'].errors?.email">Nombre de usuario no válido</div>
          </div>
        </div>
      </div>


    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="form-group" [ngClass]="{ 'has-danger': is_invalid('password')}">
          <label for="password" [ngClass]="{ 'star_symbol': is_required('password') }">Contraseña</label>
          <input type="password" placeholder="Contraseña" class="form-control" id="password" formControlName="password" autocomplete="new-password">
          <div class="invalid-feedback">
            <div *ngIf="user_form.controls['password'].errors?.required">Ingrese la contraseña</div>
          </div>
        </div>
      </div>

    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="form-group" [ngClass]="{ 'has-danger': is_invalid('confirm_password')}">
          <label for="confirm_password" [ngClass]="{ 'star_symbol': is_required('confirm_password') }">Confirmar
            contraseña</label>
          <input type="password" placeholder="Confirmar contraseña" class="form-control" id="confirm_password"
            formControlName="confirm_password">
          <div class="invalid-feedback">
            <div *ngIf="user_form.controls['confirm_password'].errors?.required">Ingrese la confirmación de la contraseña</div>
            <div *ngIf="user_form.controls['confirm_password'].errors?.mustMatch">Las contraseñas no coinciden</div>
          </div>
        </div>
      </div>
    </div>


    <div class="row m-t-30">
      <div class="col-md-12 modal-footer">
        <button *ngIf="show_cancel_button" type="button" class="btn btn-danger btn-sm" (click)="on_close()"><i
            class="mdi mdi-close"></i> Cancelar</button>
        <button type="submit" class="btn btn-primary btn-sm"><i class="mdi mdi-content-save"></i> Guardar
          Usuario</button>
      </div>
    </div>
  </form>




</div>