import { Component, OnInit, ViewChild } from '@angular/core';
import { MedicoService, AuthorizationService, FormsService, ConsultaService } from 'src/app/services/service.index';
import { Router } from '@angular/router';
import { GridComponent } from 'src/app/shared/components/grid.component';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ScheduleService } from 'src/app/services/schedule.service';

@Component({
  selector: 'app-agendar',
  templateUrl: './agendar.component.html',
  styles: []
})
export class AgendarComponent implements OnInit {

  @ViewChild(GridComponent, { static: true }) mygrid: GridComponent;
  @ViewChild("modal4", { static: true }) modal4;

  rowData: any;

  columnDefs = [
    { headerName: "Sucursal", field: "sucursal" },
    //{ headerName: "Cedula", field: "cedula" },
    { headerName: "Consultorio", field: "consultorio" }
  ];

  userId;
  medicoName;


  constructor( public _router: Router, private _toastr: ToastrService,
     public _frm: FormsService, private modalService: NgbModal, 
     public _auth: AuthorizationService, public _medico: MedicoService, 
     public _scheduleService: ScheduleService, public _consultaService: ConsultaService
    ) {}

  ngOnInit() {
    let medico = null;

    this._consultaService.get_consultorios().subscribe(
      data=> {
        this.rowData = data;
      });

    // if (this._auth.user.role_id == 1) {
    //   this._medico.search_medico(medico, null).subscribe(
    //     data => {
    //       this.rowData = data;
    //       console.log(this.rowData = data);
          
    //       if (this.rowData.length == 1) {
    //         this._medico.selected_medico.medico_id = this.rowData[0].medico_id;
    //         this._medico.selected_medico.especialidad_id = this.rowData[0].especialidad_id;
    //         this._medico.selected_medico.medico = this.rowData[0].medico;
    //         this._medico.selected_medico.especialidad = this.rowData[0].especialidad;
    //         this.userId = this.rowData[0].user_id;
    //         this.medicoName = this.rowData[0].medico;
    //         console.log(`this.rowData[0]:${JSON.stringify(this.rowData[0])}`);
    //         console.log(`this.userId:${this.userId}`);
    //         console.log(`this.medicoName:${this.medicoName}`);
    //       }
    //     },
    //     err => console.log(err)
    //   );
    // } else {

    //   if (this._auth.user.role == 'Médico') medico = this._auth.user.email

    //   this._scheduleService.getUsersShareWithMe(this._auth.user.user_id).subscribe(
    //     users => {
    //       this.rowData = this.transformData(users);
    //     },
    //     err => console.log(err)
    //   );
    // }



    // /*
    // this._medico.search_medico(medico,null).subscribe(
    //   data => { 
    //     this.rowData = data; 
    //     if(this.rowData.length == 1) {
    //       this._medico.selected_medico.medico_id =  this.rowData[0].medico_id;
    //       this._medico.selected_medico.especialidad_id =  this.rowData[0].especialidad_id;
    //       this._medico.selected_medico.medico =  this.rowData[0].medico;
    //       this._medico.selected_medico.especialidad =  this.rowData[0].especialidad;
    //       this.userId=  this.rowData[0].user_id;
    //       this.medicoName=  this.rowData[0].medico;
    //       console.log(`this.rowData[0]:${JSON.stringify(this.rowData[0])}`);
    //       console.log(`this.userId:${this.userId}`);
    //       console.log(`this.medicoName:${this.medicoName}`);
    //       //this._router.navigate(["/calendario"]);
    //     }
    //   },
    //   err => console.log(err)
    // );
    // */
  }


  option_selector(button: string) {
    switch (button) {
      case "Agendar": {
        const selected = this.mygrid.getSelectedRows();
        if (selected.length == 0) this._toastr.warning("Seleccione un consultorio", "Aviso")
        else {
          console.log(selected);
          this._consultaService.selected_consultorio_id = selected[0].consultorio_id;
          this._consultaService.selected_consultorio = selected[0].consultorio;
          this._consultaService.selected_sucursal = selected[0].sucursal;

          // this._medico.selected_medico.medico_id = selected[0].medico_id;
          // this._medico.selected_medico.especialidad_id = selected[0].especialidad_id;
          // this._medico.selected_medico.medico = selected[0].medico;
          // this._medico.selected_medico.especialidad = selected[0].especialidad;
          this._router.navigate(["/calendario"]);
        } 
        break;
      }
      // case "Compartir Agenda": {
      //   if (this._auth.user.role_id != 9) {
      //     if (this._auth.user.role_id == 1) {
      //       this._frm.toast_alert("El Administrador puedo hacerlo desde la opción de Configuración de Usuarios.", "Aviso", "warning");
      //     } else {
      //       this._frm.toast_alert("Esta opción es sólo para médicos", "Aviso", "warning");
      //     }
      //   } else {
      //     this.userId = this._auth.user.user_id;
      //     this.medicoName = this._auth.user.person.name;
      //     console.log(`this.userId:${this.userId}`);
      //     console.log(`this.medicoName:${this.medicoName}`);
      //     this.modalService.open(this.modal4, { size: 'xlg' });
      //   }
      //   break;
      // }
      /*
      case "Compartir Agenda": {
        if(this._auth.user.role_id != 1 && this._auth.user.role_id != 2 && this._auth.user.role_id != 9){
          this._frm.toast_alert("Esta opción es sólo para médicos", "Aviso", "error");
        }else{
          this._frm.get_rows_selected(this.mygrid, false, (selecteds) => {
            this.userId=  selecteds[0].user_id;
          this.medicoName=  selecteds[0].medico;
            console.log(`selecteds:${JSON.stringify(selecteds)}`);
            console.log(`this.userId:${this.userId}`);
            console.log(`this.medicoName:${this.medicoName}`);
            this.modalService.open(this.modal4, { size: 'xlg' });
          });
        }        
        break;
      }
      */
    }
  }

  // transformData(oldData: any): Object[] {
  //   let newData: any = [];
  //   //RECORRE EL OBJETO
  //   oldData.forEach(value => {
  //     //RECORRE LAS ESPECIALIDADES
  //     console.log(`VALUE:${JSON.stringify(value)}`);
  //     if (value["especialidades"] == null || value["user_preferences"] == null) return;
  //     let especialidadesId = value["especialidades"].split(",");
  //     let especialidadesName = value["user_preferences"].split(",");

  //     especialidadesId.forEach((id, index) => {
  //       let tmpData: any = {};
  //       tmpData["medico"] = value["username"];
  //       tmpData["medico_id"] = value["medico_id"];
  //       tmpData["especialidad"] = especialidadesName[index];
  //       tmpData["especialidad_id"] = id;

  //       newData.push(tmpData);
  //     });
  //   });

  //   return newData;
  // }

}
