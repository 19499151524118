import { Component, OnInit, Output, Input, EventEmitter, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { medico, especialidad } from '../../models/medico.model';
import { CatalogService } from 'src/app/services/service.index';

@Component({
  selector: 'app-medico',
  templateUrl: './medico.component.html',
  styles: []
})
export class MedicoComponent implements OnInit {

  @ViewChild("form", { static: true }) form;
  @Output() submit_form = new EventEmitter<FormGroup>();
  @Output() close_modal = new EventEmitter();
  @Input() medico: medico;
  @Input() show_cancel_button: boolean = false;

  medic_form: FormGroup;
  especialidades: any;

  sino: any = [{ name: 'Si', id: true }, { name: 'No', id: false }];
  constructor(public _cat: CatalogService, private _fb: FormBuilder) { }

  ngOnInit() {
    this.medic_form = this._fb.group({
      medico_id: [this.medico.medico_id],
      consultas_maximas: [this.medico.consultas_maximas],
      consulta_externa: [this.medico.consulta_externa],
      cedula: [this.medico.cedula],
      especialidades: [[]]
    });

    this._cat.get_catalog("especialidad", true).subscribe(data => {
        this.especialidades = data
        var especialidades_medico = [];

        if(this.medico.especialidades != null){
          let especialidad_id= this.medico.especialidades.split(',');
          especialidad_id.forEach(function (value) {
            especialidades_medico.push(parseInt(value))
          }); 
        }
        
        this.medic_form.patchValue({"especialidades":   especialidades_medico  });
      },
      error => console.log(error));
  }

  on_submit() {
    this.submit_form.emit(this.medic_form);
  }

  on_close() {
    this.close_modal.emit();
  }

}
