import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { DynamicService, FormsService } from 'src/app/services/service.index';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { referencia,paciente } from '../../models/paciente.model';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-area-antecedentes',
  templateUrl: './area-antecedentes.component.html',
  styles: []
})
export class AreaAntecedentesComponent implements OnInit {
  @ViewChild("form") form;

  @Input() form_id: number;
  @Input() show_cancel_button: boolean = false;
  @Input() data: any;
  @Output() submit_form = new EventEmitter<FormGroup>();
  @Output() close_modal = new EventEmitter();
  @Input() paciente: paciente = new paciente({});
  meta_data: any;
  form_group: FormGroup;
  antecedentes: any[] = [];

  constructor(private _toastr: ToastrService,public _dyn: DynamicService, public _frm: FormsService) { }

  ngOnInit() {
    if(this.paciente.paciente_id == null){

    }else{
      let existe:any
    this._dyn.get_antecedente(this.paciente.paciente_id).subscribe(data => {
     if(data != null){
       console.log(data)
       let formJson:any = data;
       this.antecedentes = formJson;

     }else{
      this._dyn.get_form(2).subscribe(data => { 
        this.antecedentes.push(data);
        this._dyn.get_form(3).subscribe(data => { 
          this.antecedentes.push(data);
           this._dyn.get_form(4).subscribe(data => {
            this.antecedentes.push(data);
             this._dyn.get_form(5).subscribe(data => {
              this.antecedentes.push(data);
                this._dyn.get_form(6).subscribe(data => {
                 this.antecedentes.push(data);
                  this._dyn.get_form(7).subscribe(data => {
                   this.antecedentes.push(data);
                    this._dyn.get_form(8).subscribe(data => {
                     this.antecedentes.push(data);
                    }, err => console.log(err));
                  }, err => console.log(err));
                }, err => console.log(err));
            }, err => console.log(err));
           }, err => console.log(err));
        }, err => console.log(err));
      }, err => console.log(err));

     }
     }, err => console.log(err));


    }
    
    

   
    
    

 
  }

  on_submit() {
  this.submit_form.emit(this.form_group)
  var jsonString=JSON.stringify(this.antecedentes)
  console.log(this.antecedentes)
  console.log(jsonString)
   this._dyn.save_form(jsonString,this.paciente.paciente_id,1).subscribe(data => {
    this._frm.toast_alert("Antecedente guardado con exito", "Antecedentes", "success");
    this.close_modal.emit();
     }, err => {      
      this._frm.toast_alert("El antecedente no pudo ser guradado", "Antecedentes", "error")
      this.close_modal.emit();
    }); 
  }
  on_close() {
    this.close_modal.emit();
   
  }


}
