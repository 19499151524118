<div class="card-body p-b-0">
    <form class="form-material" [formGroup]="servicio_form" (ngSubmit)="on_submit()" #form="ngForm" novalidate>
        <div class="row">
            <div class="col-md-4">
                <div class="form-group"  [ngClass]="{ 'has-danger': _frm.is_invalid('nombre', servicio_form, form.submitted)}">
                    <label for="txt_title"  [ngClass]="{ 'star_symbol': _frm.is_required('nombre', servicio_form) }">Nombre</label>
                    <input type="text" placeholder="Título" class="form-control" id="txt_title" formControlName="nombre">
                    <div class="invalid-feedback">
                        <div *ngIf="servicio_form.controls['nombre'].errors?.required">El nombre es obligatorio</div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('precio', servicio_form, form.submitted)}">
                    <label for="txt_precio"  [ngClass]="{ 'star_symbol': _frm.is_required('precio', servicio_form) }">Precio</label>
                    <input type="number" placeholder="Precio" class="form-control" id="txt_precio" formControlName="precio">

                    <div class="invalid-feedback">
                        <div *ngIf="servicio_form.controls['precio'].errors?.required">El precio es obligatorio</div>
                        <div *ngIf="servicio_form.controls['precio'].errors?.pattern">Ingrese un precio válido</div>
                    </div>

                </div>
            </div>
            <div class="col-md-4">

                <div class="form-group"  [ngClass]="{ 'has-danger': _frm.is_invalid('imagen', servicio_form, form.submitted)}">
                    <label for="txt_imagen"  [ngClass]="{ 'star_symbol': _frm.is_required('imagen', servicio_form) }">Imágen</label>
                    <input type="text" placeholder="http://" class="form-control" id="txt_imagen" formControlName="imagen">
                    <div class="invalid-feedback">
                        <div *ngIf="servicio_form.controls['imagen'].errors?.pattern">URL no válida</div>
                    </div>
                </div>



            </div>

        </div>
        <div class="row">
            <div class="col-md-8">
                <div class="form-group"  [ngClass]="{ 'has-danger': _frm.is_invalid('descripcion', servicio_form, form.submitted)}">
                    <label for="txt_descripcion" [ngClass]="{ 'star_symbol': _frm.is_required('descripcion', servicio_form) }">Descripcion</label>
                    <textarea placeholder="Descripcion" class="form-control" id="txt_descripcion" formControlName="descripcion"></textarea>
                </div>
            </div>
            <div class="col-md-4">

            </div>
        </div>

        <div class="row m-t-30">
            <div class="col-md-12 modal-footer">
                <button *ngIf="show_cancel_button" type="button" class="btn btn-danger btn-sm" (click)="on_close()"><i
                        class="mdi mdi-close"></i> Cancelar</button>
                <button type="submit" class="btn btn-primary btn-sm "><i class="mdi mdi-content-save"></i>Guardar</button>
            </div>
        </div>
    </form>
</div>