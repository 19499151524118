import { Component, OnInit } from "@angular/core";
import { AuthorizationService } from "../../services/service.index";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styles: []
})
export class SidebarComponent implements OnInit {
  constructor(public _auth: AuthorizationService) {}

  ngOnInit() {}

  logout() {
    this._auth.logout();
  }

}
