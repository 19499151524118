<div class="card-body p-b-0">
    <form class="form-material" [formGroup]="article_form" (ngSubmit)="on_submit()" #form="ngForm" novalidate>
        <div class="row">
            <div class="col-md-4">
                <div class="form-group"  [ngClass]="{ 'has-danger': _frm.is_invalid('titulo', article_form, form.submitted)}">
                    <label for="txt_title"  [ngClass]="{ 'star_symbol': _frm.is_required('titulo', article_form) }">Título</label>
                    <input type="text" placeholder="Título" class="form-control" id="txt_title" formControlName="titulo">
                    <div class="invalid-feedback">
                        <div *ngIf="article_form.controls['titulo'].errors?.required">El título es obligatorio</div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group"  [ngClass]="{ 'has-danger': _frm.is_invalid('autor', article_form, form.submitted)}">
                    <label for="txt_autor" [ngClass]="{ 'star_symbol': _frm.is_required('autor', article_form) }">Autor</label>
                    <input type="text" placeholder="Autor" class="form-control" id="txt_autor" formControlName="autor">
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('descripcion', article_form, form.submitted)}">
                    <label for="txt_descripcion"  [ngClass]="{ 'star_symbol': _frm.is_required('descripcion', article_form) }">Descripción</label>
                    <input type="text" placeholder="Descripcion" class="form-control" id="txt_descripcion" formControlName="descripcion">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="form-group"  [ngClass]="{ 'has-danger': _frm.is_invalid('url', article_form, form.submitted)}">
                    <label for="txt_url"  [ngClass]="{ 'star_symbol': _frm.is_required('url', article_form) }">Referencia (url)</label>
                    <input type="text" placeholder="https://site.com" class="form-control" id="txt_url" formControlName="url">
                    <div class="invalid-feedback">
                        <div *ngIf="article_form.controls['url'].errors?.pattern">URL no válida</div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group"  [ngClass]="{ 'has-danger': _frm.is_invalid('fecha_publicacion', article_form, form.submitted)}">
                    <label for="txt_nacimiento"  [ngClass]="{ 'star_symbol': _frm.is_required('fecha_publicacion', article_form) }">Fecha de publicación</label>
                    <input type="text" placeholder="Fecha de publicación" class="form-control" id="txt_nacimiento" formControlName="fecha_publicacion" ngbDatepicker #d="ngbDatepicker" (focus)="d.toggle()" readonly="readonly" [minDate]="{year: 1900, month: 1, day: 1}" [maxDate]="fecha">
                    <i class="mdi mdi-calendar"></i>
                </div>
            </div>
            <div class="col-md-4">

            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="form-group">
                    <label>Archivo adjunto</label>
                    <input type="file" class="file-input" (change)="on_file_changed($event)" #file_upload style="display: none;">

                    <div>
                        <button type="button"  class="btn btn-primary btn-sm" (click)="file_upload.click()">
                            <i class="mdi mdi-file" style="margin: 0;"></i>
                        </button>
                        <small  class="form-text text-muted">
                          {{file_name || "Seleccione el archivo adjunto"}}
                        </small>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label>Imágen adjunta</label>
                    <input type="file" class="file-input" (change)="on_image_changed($event)" #image_upload style="display: none;">

                    <div>
                        <button type="button"  class="btn btn-primary btn-sm" (click)="image_upload.click()">
                            <i class="mdi mdi-file-image" style="margin: 0;"></i>
                        </button>
                        <small  class="form-text text-muted">
                          {{image_name || "Seleccione la imágen adjunta"}}
                        </small>
                    </div>
                </div>
            </div>
        </div>
        <div class="row m-t-30">
            <div class="col-md-12 modal-footer">
                <button *ngIf="show_cancel_button" type="button" class="btn btn-danger btn-sm" (click)="on_close()"><i
                        class="mdi mdi-close"></i> Cancelar</button>
                <button type="submit" class="btn btn-primary btn-sm "><i class="mdi mdi-content-save"></i>
                    Guardar</button>
            </div>
        </div>
    </form>
</div>