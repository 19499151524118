import { Component } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";


// Errors
import { Error403Component } from '../pages/errors/error403.component';

// Pages
import { PagesComponent } from "./pages.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { UsersComponent } from './settings/users.component';
import { ProfileComponent } from './settings/profile.component';
import { RolesComponent } from "./settings/roles.component";
import { ModuleComponent } from './settings/module.component';
import { PermissionsComponent } from './settings/permissions.component';
import { PacienteComponent } from "./paciente/paciente.component";
import { AgendarComponent } from './consulta/agendar.component';
import { CalendarioComponent } from './consulta/calendario.component';
import { EspecialidadesComponent } from './catalog/especialidades.component';
import { TipoConsultaComponent } from "./catalog/tipo-consulta.component";
import { CamasComponent } from "./catalog/camas.component";
import { TipoDocumentoComponent } from "./catalog/tipo-documento.component";
import { PrestamosComponent } from './prestamo/prestamos.component';
import { DevolucionesComponent } from './prestamo/devoluciones.component';
import { RepPacientesComponent } from './reports/rep-pacientes.component';
import { ResetPasswordComponent } from './settings/reset-password/reset-password.component';


import { AreasComponent} from "./catalog/areas.component";
// Guards
import { AuthorizationGuard, AutenticationGuard } from "../services/service.index";
import { MedicamentosComponent } from "./catalog/medicamentos.component";
import { DiagnosticosComponent } from "./catalog/diagnosticos.component";
import { ProcedimientosComponent } from "./catalog/procedimientos.component";
import { EstudiosComponent } from "./catalog/estudios.component";
import { ArticulosComponent } from './articulos/articulos.component';
import { ConsultoriosComponent } from './settings/consultorios.component';
import { ServiciosComponent } from './servicios/servicios.component';
import { RepServiciosComponent } from './reports/rep-servicios.component';
import { RepConsultasComponent } from './reports/rep-consultas.component';




const pagesRoutes: Routes = [
  {
    path: "",
    component: PagesComponent,
    canActivate: [AutenticationGuard],
    canActivateChild: [ AutenticationGuard],
    children: [
      // Errores
      { path: "error403",  component: Error403Component, data: {title: "Error 403"} },
      // Common Pages
      { path: "dashboard",  component: DashboardComponent, data: {title: "Algoritmos Médicos"} },
      { path: "profile",  component: ProfileComponent , data: {title: "Mi Perfil"}  },      
      { path: "reset-password", component: ResetPasswordComponent },
      // Pages
      { path: "users", canActivate:[AuthorizationGuard], component: UsersComponent },
      { path: "articulos", canActivate:[AuthorizationGuard], component: ArticulosComponent },
      { path: "servicios", canActivate:[AuthorizationGuard], component: ServiciosComponent },
      { path: "roles", canActivate:[AuthorizationGuard], component: RolesComponent },
      { path: "modules", canActivate:[AuthorizationGuard], component: ModuleComponent },
      { path: "permissions", canActivate:[AuthorizationGuard], component: PermissionsComponent },
      { path: "paciente", canActivate:[AuthorizationGuard], component: PacienteComponent },
      { path: "prestamos", canActivate:[AuthorizationGuard], component: PrestamosComponent },
      { path: "devoluciones", canActivate:[AuthorizationGuard], component: DevolucionesComponent },
      { path: "agendar", canActivate:[AuthorizationGuard], component: AgendarComponent },
      { path: "calendario", canActivate:[AuthorizationGuard], component: CalendarioComponent },
      { path: "especialidades", canActivate:[AuthorizationGuard], component: EspecialidadesComponent },
      { path: "camas", canActivate:[AuthorizationGuard], component: CamasComponent },
      { path: "tipo-consulta", canActivate:[AuthorizationGuard], component: TipoConsultaComponent },
      { path: "areas", canActivate:[AuthorizationGuard], component: AreasComponent },
      { path: "tipo-documento", canActivate:[AuthorizationGuard], component: TipoDocumentoComponent },
      { path: "medicamentos", canActivate:[AuthorizationGuard], component: MedicamentosComponent },
      { path: "diagnosticos", canActivate:[AuthorizationGuard], component: DiagnosticosComponent },
      { path: "procedimientos", canActivate:[AuthorizationGuard], component: ProcedimientosComponent },
      { path: "estudios", canActivate:[AuthorizationGuard], component: EstudiosComponent },
      { path: "consultorios", canActivate:[AuthorizationGuard], component: ConsultoriosComponent },
      { path: "rep-pacientes", canActivate: [AuthorizationGuard], component: RepPacientesComponent },
      { path: "rep-servicios", canActivate: [AuthorizationGuard], component: RepServiciosComponent },
      { path: "rep-consultas", canActivate: [AuthorizationGuard], component: RepConsultasComponent },
      { path: "", redirectTo: "/landing", pathMatch: "full" }
    ]
  }
];

export const PAGES_ROUTES = RouterModule.forChild(pagesRoutes);
