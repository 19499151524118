import { Component, OnInit, ViewChild } from "@angular/core";
import { AuthorizationService, UserService, FormsService } from "src/app/services/service.index";
import { GridComponent } from '../../shared/components/grid.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { permission } from '../../models/authorization.model';
import { FormGroup } from "@angular/forms";

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styles: []
})
export class PermissionsComponent implements OnInit {
  @ViewChild("modal", { static: true }) modal;
  @ViewChild(GridComponent, { static: true }) mygrid: GridComponent;

  rowData: any;
  columnDefs = [
    { headerName: "Nombre", field: "name", checkboxSelection: true, headerCheckboxSelection: true },
    { headerName: "Etiqueta", field: "display_text" },
    { headerName: "Descripción", field: "description" },
    { headerName: "Clase", field: "css_class" },
    { headerName: "Evento", field: "click_event" },
    {
      headerName: "Mostrar Botón", field: "show_button", cellRenderer: params => {
        return `<input type='checkbox' style='position:static; opacity:1; ' ${params.value ? 'checked' : ''} disabled />`;
      }
    },
    {
      headerName: "Agrupado", field: "grouping", cellRenderer: params => {
        return `<input type='checkbox' style='position:static; opacity:1; ' ${params.value ? 'checked' : ''} disabled />`;
      }
    },
    { headerName: "Padre", field: "parent" },
  ];
  permission: permission;

  constructor(public _auth: AuthorizationService, public _frm: FormsService, public _usrs: UserService, private modalService: NgbModal) { }

  ngOnInit() {
    this.get_data();
  }

  get_data() {
    this._usrs.get_permissions(new permission({ is_active: true })).subscribe(
      data => { this.rowData = data; },
      err => console.log(err)
    );
  }
  option_selector(button: string) {
    switch (button) {
      case "create": {
        this.permission = new permission({});
        this.modalService.open(this.modal, { size: 'lg' });
        break;
      }
      case "update": {
        this._frm.get_rows_selected(this.mygrid, false, (selecteds) => {
          this.permission = selecteds[0];
          this.modalService.open(this.modal, { size: 'lg' })
        });
        break;
      }
      case "delete": {
        this._frm.get_rows_selected(this.mygrid, false, (selecteds) => {
          this.permission = selecteds[0];
          this._frm.show_warning('Borrar permiso', 'Estas seguro de eliminar el permiso [' + this.permission.display_text + ']. Una vez eliminado no podra recuperarse.', () => this.delete_permission());
        });
        break;
      }
    }
  }



  save_permission(form: FormGroup) {
console.log(form.value);

    this._frm.save_module(form, "permission_id",
      () => {
        // Crear nuevo permiso
        this._usrs.insert_permission(form.value).subscribe(
          (res: any) => {
            this._frm.toast_alert("Permiso creado exitosamente", "Nuevo permiso", "success")
            this.get_data();
            this.modalService.dismissAll(null);
          },
          error => this._frm.toast_alert("El permiso no pudo ser creado correctamente. Error: " + error.error.message, "Nuevo permiso", "error")
        );
      },
      () => {
        this._usrs.update_role(form.value).subscribe(
          // Actualizar permiso seleccionado
          (res: any) => {
            this._frm.toast_alert("Permiso actualizado exitosamente", "Actualizar Rol", "success")
            this.get_data();
            this.modalService.dismissAll(null);
          },
          error => {
            this._frm.toast_alert("El permiso no pudo ser actualizado correctamente. Error: " + error.error.message, "Actualizar permiso", "error");
          }
        );
      }
    );

  }
  delete_permission() {
    this._usrs.delete_permission(this.permission).subscribe(
      (res: any) => { this._frm.toast_alert("Permiso eliminado exitosamente", "Borrar Permiso", "success"); this.get_data(); },
      error => { console.log(error); this._frm.toast_alert("El permiso no pudo ser eliminado correctamente. Error: " + error.error.message, "Borrar Permiso", "error") }
    );

  }





}
