import { Component, OnInit, ViewChild } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { AuthorizationService, UserService, FormsService, MedicoService, CatalogService } from 'src/app/services/service.index';
import { GridComponent } from '../../shared/components/grid.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { user } from '../../models/authorization.model';
import { AccountComponent } from '../../shared/forms/account.component';
import { FormGroup } from "@angular/forms";
import { Especialidad, UserResponse } from "src/app/models/user_response.model";
import { Especialidades } from "src/app/models/especialidades.model";
import { toInteger } from "@ng-bootstrap/ng-bootstrap/util/util";
import { medico } from "src/app/models/medico.model";
import { HorarioEspecialidad } from "src/app/models/horario_especialidad.model";
import { address } from "src/app/models/address.model";


declare var sw: any;

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styles: []
})
export class UsersComponent implements OnInit {
  @ViewChild(GridComponent, { static: true }) mygrid: GridComponent;
  @ViewChild("content", { static: true }) content;
  @ViewChild("modal2", { static: true }) modal2;
  @ViewChild("modal3", { static: true }) modal3;
  @ViewChild("modal4", { static: true }) modal4;
  @ViewChild(AccountComponent) myaccount: AccountComponent;

  rowData: any;
  columnDefs = [
    { headerName: "Usuario", field: "username" },
    { headerName: "Nombre", field: "person.fullname" },
    { headerName: "Perfil", field: "role" },
    { headerName: "Especialidades", field: "medico.especialidades_name" }
  ];

  user: user = new user({});  
  noAddress: address = new address({});  

  constructor(public _auth: AuthorizationService, public _usrs: UserService, public _frm: FormsService,
    private _toastr: ToastrService, private modalService: NgbModal, public _medico: MedicoService, public _form: FormsService, public _catalog: CatalogService) { }

  ngOnInit() {
    this.get_data();
  }
  option_selector(button: string) {
    console.log(`button:${button}`);
    switch (button) {
      case "create": {
        this.user = new user({});
        this.modalService.open(this.content, { size: 'lg' });
        break;
      }
      case "update": {
        const selecteds = this.mygrid.getSelectedRows();
        if (selecteds.length == 0) this._toastr.warning("Seleccione al usuario que desea actualizar", "Actualización de usuario")
        else if (selecteds.length > 1) this._toastr.warning("Seleccione un solo usuario para actualizar", "Actualización de usuario")
        else {
          this.user = selecteds[0];
          console.log(`USER.PERSON.ADDRESS-->${JSON.stringify(this.user.person.address)}`)
          this.modalService.open(this.content, { size: 'lg' });
          sw.common.init_plugins();
        }
        break;
      }
      case "delete": {
        this._frm.get_rows_selected(this.mygrid, false, (selecteds) => {
          this.user = selecteds[0];
          this._frm.show_warning('Borrar usuario', 'Estas seguro de eliminar el usuario [' + this.user.person.fullname + ']. Una vez eliminado no podra recuperarse.', () => this.delete_user());
        });
        break;
      }
      case "authorize": {
        this._frm.get_rows_selected(this.mygrid, false, (selecteds) => {
          this.user = selecteds[0];
          this.modalService.open(this.modal2, { size: 'lg' });
        });
        break;
      }
      case "horario": {        
        this._frm.get_rows_selected(this.mygrid, false, (selecteds) => {
          this.user = selecteds[0];          
            if(this.user.role_id != 9){
              this._frm.toast_alert("Esta opción es sólo para médicos", "Aviso", "error");
            }else{
              this.modalService.open(this.modal3, { size: 'lg' });              
            }
        });
        break;
      }
      case "Compartir Agenda": {
        if(this._auth.user.role_id != 1 && this._auth.user.role_id != 2 && this._auth.user.role_id != 9){
          this._frm.toast_alert("Esta opción es sólo para médicos", "Aviso", "error");
        }else{
          this._frm.get_rows_selected(this.mygrid, false, async (selecteds) => {
            this.user = selecteds[0];            
            if(this.user.role_id != 9){
              this._frm.toast_alert("Esta opción es sólo para médicos", "Aviso", "error");
            }
            else{
              let hasHorarios = await this.validarQueTengaHorariosAsignados(this.user);
              if(hasHorarios){
                this.modalService.open(this.modal4, { size: 'xlg' });
              }else{
                this._frm.toast_alert("Primero debe definir el horario para poder compartirlo", "Aviso", "warning");
              }
            }
          });
        }        
        break;
      }
    }
  }

  //I_EXP.MEDICO_03
  async validarQueTengaHorariosAsignados(medicoToVerify:user): Promise<boolean> {
    let returnValue = false;
    let especialidadesDelMedico = medicoToVerify.medico.especialidades.split(',');

    for (const espId of especialidadesDelMedico) {
      const hasHorarios: any = await this._medico.horario_get(medicoToVerify.medico.medico_id, Number(espId)).toPromise();
      if(hasHorarios.length > 0){
        returnValue = true;
      }
    }
    return returnValue;
  }
  

  get_data(){
    this._usrs.get_users(new user({})).subscribe(
      async data => { 
        //USER DATA IN RESPONSE
        var userResponse = data as UserResponse[];
        //USER DATA TO FILL TABLE
        var userData:UserResponse[] = [];
        // CATALOGO ESPECIALIDADES
        const especialidades = await this._catalog.get_catalog("especialidad",true).toPromise() as Especialidades[];
        // BÚSQUEDA DE NOMBRE DE LA ESPECIALIDAD, YA QUE SOLO TRAE LOS ID'S EN UN STRING
        userResponse.forEach((user) =>{
          // VALIDAR QUE ES MÉDICO Y SI TIENE ESPECIALIDAD
          if(user.medico != null && user.medico.especialidades != null && user.medico.especialidades.length > 0){
            // PUEDE TENER MAS DE 1 ESPECIALIDAD
            const numEspecialidades = user.medico.especialidades.split(",");
            //console.log(`numEspecialidades:${numEspecialidades}`);
            user.medico.especialidades_name = '';
            numEspecialidades.forEach((item)=>{
              let especialidad = especialidades.find(esp => esp.id === Number(item));
              //console.log(`especialidad:${JSON.stringify(especialidad)}`);
              let espToPush = new Especialidad();
              espToPush.id = especialidad["id"];
              espToPush.nombre = especialidad["nombre"];
              user.medico.especialidades_obj = [];
              user.medico.especialidades_obj.push(espToPush);
              user.medico.especialidades_name = user.medico.especialidades_name == '' ? especialidad["nombre"] : user.medico.especialidades_name + ", " + especialidad["nombre"];
            });
          }
          
          userData.push(user);
          
        });
        //this.rowData = data; 
        this.rowData = userData; 
        console.log(data) 
      },
      err => console.log(err)
    );  
  }
  
  create_user(form: FormGroup) {
    if (form.invalid) {
      this._toastr.warning("Existen problemas al validar los campos del formulario. Revise los mensajes para mayor información ", "Validación del formulario");
      return;
    }    

    if (this.user.user_id == null) {
      this.user = form.value;
      this.user.password_hash = form.value.password;
      this.user.username = form.value.username;
      
      this._usrs.insert_user(this.user).subscribe(
        (data: any) => { 
          this._toastr.success("Usuario creado satisfactoriamente", "", { timeOut: 0, progressBar: true });
          this.user = data; 
          this.user.medico = new medico();
          this.user.medico.medico_id = this.user.medico_id;
          sw.common.init_plugins();
          //console.log('create_user:' + JSON.stringify(this.user));
        },
        err => {
          this._toastr.error(" Error: " + err.error.message, "Creación de usuarios");
        }
      );
    }
    else {
     //update
     this.update_account(form);
    }




  }


  async update_account (form: FormGroup) {    
    if (form.invalid) {
      this._toastr.warning("Existen problemas al validar los campos del formulario. Revise los mensajes para mayor información ", "Validación del formulario", { timeOut: 0, progressBar: true });
      return;
    }

    await  this._auth.changeUserPassword( form.value.password,this.user.user_id)
    this._toastr.success("Cuenta actualizada exitosamente", "Actualización de cuenta", { timeOut: 3000, progressBar: true })

  }


  update_profile(form: FormGroup) {    
    if (form.invalid) {
      this._toastr.warning("Existen problemas al validar los campos del formulario. Revise los mensajes para mayor información ", "Validación del formulario", { timeOut: 0, progressBar: true });
      return;
    }
    this.user.password_hash = form.value.password;
    this.user.username = form.value.username;
    this.user.person.name = form.value.name;
    this.user.person.last_name = form.value.last_name;
    this.user.person.middle_name = form.value.middle_name;
    this.user.person.birthdate = form.value.birthdate;
    this.user.person.gender_id = form.value.gender_id;
    this.user.person.home_phone = form.value.home_phone;
    this.user.person.mobile_phone = form.value.mobile_phone;
    this.user.person.file = form.value.file;
    
    console.log(`this.user:${JSON.stringify(this.user)}`);

    this._usrs.update_profile(this.user.person).subscribe(
      async (res: any) => {
        //await this._auth.changeUserPassword(this.user.password_hash,this.user.user_id);
        this._toastr.success("Perfil actualizado exitosamente", "Actualización de Perfil", { timeOut: 3000, progressBar: true })
        console.log(res);
        /* this._auth.user.person.photo = res.photo;
        this._auth.user.person.name = res.name;
        this._auth.user.person.last_name = res.last_name; */

        this.get_data();
      }
      ,
      error => {
        this._toastr.error("El perfil no pudo ser actualizado correctamente. Error: " + error.error.message, "Actualización de Perfil", { timeOut: 0, progressBar: true });
      }
    );
  }
  delete_user() {
    this._usrs.delete_user(this.user).subscribe(
      (res: any) => {this._frm.toast_alert("Usuario eliminado exitosamente", "Borrar Usuario", "success"); this.get_data(); },
      error => { console.log(error); this._frm.toast_alert("El usuario no pudo ser eliminado correctamente. Error: " + error.error.message, "Borrar Usuario", "error") }
    );
  }
  update_address(form: FormGroup) {
    if (form.invalid) {
      this._toastr.warning("Existen problemas al validar los campos del formulario. Revise los mensajes para mayor información ", "Validación del formulario", { timeOut: 0, progressBar: true });
      return;
    }
    this._usrs.update_address(form.value).subscribe(
      (res: any) => {this._frm.toast_alert("Domicilio actualizado exitosamente", "Actualización de domicilio", "success"); this.get_data(); },
      error => { console.log(error); this._frm.toast_alert("El domicilio no pudo ser actualizado correctamente. Error: " + error.error.message, "Actualización de domicilio", "error") }
    );
  }
  update_medico(form: FormGroup) {
    if (form.invalid) {
      this._toastr.warning("Existen problemas al validar los campos del formulario. Revise los mensajes para mayor información ", "Validación del formulario", { timeOut: 0, progressBar: true });
      return;
    }
    this._medico.update_medico(form.value).subscribe(
      (res: any) => {this._frm.toast_alert("Médico actualizado exitosamente", "Actualización de médico", "success"); this.get_data(); },
      error => { console.log(error); this._frm.toast_alert("El médico no pudo ser actualizado correctamente. Error: " + error.error.message, "Actualización de médico", "error") }
    );
  }
  update_horario(result: any) {
    console.log(result);

    this._medico.horario_delete(result.medico_id, result.especialidad_id).subscribe(
      (data: any) => {

        this._medico.horario_insert(result.horarios).subscribe(
          (data: any) => {
            this._form.toast_alert("Horario guardado exitosamente", "Administración de Horarios", "success")
            this.modalService.dismissAll(null)
          },
          err => { 
            console.log(`errpr:${err}`);
            console.log(`error:${JSON.stringify(err)}`);
            this._form.toast_alert(err.error.message, "Administración de Horarios", "error"); 
          }
        );
      },
      err => console.log(err)
    );
  }

}
