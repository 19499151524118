<div class="card-body p-b-0">
  <form class="form-material" [formGroup]="paciente_form" (ngSubmit)="save_paciente()" #form="ngForm" novalidate>
    <div class="row">
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-12">
            <h5 class="modal-title text-right">Paciente</h5>
          </div>
          <div class="col-md-6" *ngIf="paciente.paciente_id">
            <div class="form-group">
              <label>Fotografía</label>
              <input type="file" class="dropify" accept=".png, .jpg, .jpeg" data-allowed-file-extensions="jpg png jpeg"
                data-max-file-size="300K" [attr.data-default-file]="paciente.fotografia"
                (change)="change_file('file', $event.target.files)">
            </div>
          </div>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group"
                  [ngClass]="{ 'has-danger': _frm.is_invalid('nombre', paciente_form, form.submitted)}">
                  <label for="txtNombre"
                    [ngClass]="{ 'star_symbol': _frm.is_required('nombre', paciente_form) }">Nombre(s)</label>
                  <input type="text" placeholder="Nombre" class="form-control" id="txtNombre" formControlName="nombre">
                  <div class="invalid-feedback">
                    <div *ngIf="paciente_form.controls['nombre'].errors?.required">Ingrese el nombre del paciente</div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group"
                  [ngClass]="{ 'has-danger': _frm.is_invalid('paterno', paciente_form, form.submitted)}">
                  <label for="txtpaterno"
                    [ngClass]="{ 'star_symbol': _frm.is_required('paterno', paciente_form) }">Apellido Paterno</label>
                  <input type="text" placeholder="Apellido Paterno" class="form-control" id="txtpaterno"
                    formControlName="paterno">
                  <div class="invalid-feedback">
                    <div *ngIf="paciente_form.controls['paterno'].errors?.required">Ingrese el apellido paterno del
                      paciente</div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group"
                  [ngClass]="{ 'has-danger': _frm.is_invalid('materno', paciente_form, form.submitted)}">
                  <label for="txtmaterno"
                    [ngClass]="{ 'star_symbol': _frm.is_required('materno', paciente_form) }">Apellido Materno</label>
                  <input type="text" placeholder="Apellido Materno" class="form-control" id="txtmaterno"
                    formControlName="materno">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group"
                  [ngClass]="{ 'has-danger': _frm.is_invalid('sexo_id', paciente_form, form.submitted)}">
                  <label for="gender"
                    [ngClass]="{ 'star_symbol': _frm.is_required('sexo_id', paciente_form) }">Sexo</label>
                  <ng-select [searchable]="true" placeholder="Sexo" class="form-control" id="gender" [items]="genders"
                    bindLabel="nombre" bindValue="id" formControlName="sexo_id">
                  </ng-select>
                  <div class="invalid-feedback">
                    <div *ngIf="paciente_form.controls['sexo_id'].errors?.required">Ingrese el sexo del paciente</div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group"
                  [ngClass]="{ 'has-danger': _frm.is_invalid('fecha_nacimiento', paciente_form, form.submitted)}">
                  <label for="txtnacimiento"
                    [ngClass]="{ 'star_symbol': _frm.is_required('fecha_nacimiento', paciente_form) }">Fecha de
                    nacimiento</label>

                    <input class="form-control" 
                    placeholder="Fecha de nacimiento" 
                    id="txtnacimiento"
                    formControlName="fecha_nacimiento" 
                    
                    ngbDatepicker #d="ngbDatepicker"
                    (click)="d.toggle()"
                     readonly="readonly" [minDate]="{year: 1900, month: 1, day: 1}" [maxDate]="fecha"> 
                   
                  <!-- <input class="form-control" placeholder="Fecha de nacimiento" id="txtnacimiento"
                    formControlName="fecha_nacimiento" ngbDatepicker #d="ngbDatepicker" (focus)="d.toggle()"
                    readonly="readonly" [minDate]="{year: 1900, month: 1, day: 1}" [maxDate]="fecha"> -->


                  <i class="mdi mdi-calendar"></i>
                  <div class="invalid-feedback">
                    <div *ngIf="paciente_form.controls['fecha_nacimiento'].errors?.required">Ingrese la fecha de
                      nacimiento del paciente</div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group"
                  [ngClass]="{ 'has-danger': _frm.is_invalid('curp', paciente_form, form.submitted)}">
                  <label for="txtcurp"
                    [ngClass]="{ 'star_symbol': _frm.is_required('curp', paciente_form) }">CURP</label>
                  <input type="email" placeholder="CURP" class="form-control" id="txtcurp" formControlName="curp">
                  <i class="mdi mdi-account-card-details"></i>
                  <div class="invalid-feedback">
                    <div *ngIf="paciente_form.controls['curp'].errors?.required">Ingrese el CURP del paciente</div>
                  </div>
                </div>
              </div>

              <div class="col-md-6" *ngIf="paciente.paciente_id">
                <div class="form-group">
                  <label for="gender">Seguro</label>
                  <ng-select [searchable]="true" placeholder="Seguro" class="form-control" [items]="seguro"
                    bindLabel="nombre" bindValue="id" formControlName="tipo_seguro_id"> </ng-select>
                </div>
              </div>

              <div class="col-md-6" *ngIf="paciente.paciente_id">
                <div class="form-group">
                  <label for="txtpoliza">Num. Póliza</label>
                  <input type="text" placeholder="Num. Póliza" class="form-control" id="txtpoliza"
                    formControlName="numero_poliza">
                </div>
              </div>

              <div class="col-md-6" *ngIf="paciente.paciente_id">
                <div class="form-group">
                  <label for="gender">Nivel Socioeconomico</label>
                  <ng-select [searchable]="true" placeholder="Nivel Socioeconomico" class="form-control"
                    [items]="socioeconomico" bindLabel="nombre" bindValue="id" formControlName="nivel_socioeconomico">
                  </ng-select>
                </div>
              </div>

              <div class="col-md-6" *ngIf="paciente.paciente_id">
                <div class="form-group">
                  <label for="gender">Grupo Etnico</label>
                  <ng-select [searchable]="true" placeholder="Grupo Etnico" class="form-control" [items]="etnico"
                    bindLabel="nombre" bindValue="id" formControlName="grupo_etnico_id">
                  </ng-select>
                </div>
              </div>
              <div class="col-md-6" *ngIf="paciente.paciente_id">
                <div class="form-group">
                  <label for="gender">Religión</label>
                  <ng-select [searchable]="true" placeholder="Religión" class="form-control" [items]="religion"
                    bindLabel="nombre" bindValue="id" formControlName="religion_id">
                  </ng-select>
                </div>
              </div>

              <div class="col-md-6" *ngIf="paciente.paciente_id">
                <div class="form-group">
                  <label for="gender">Grupo Sanguineo</label>
                  <ng-select [searchable]="true" placeholder="Grupo Sanguineo" class="form-control" [items]="sangre"
                    bindLabel="nombre" bindValue="id" formControlName="tipo_sangre_id">
                  </ng-select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6" >
        <div class="row">
          <div class="col-md-12" m-t-20>
            <h5 class="modal-title text-right">Cuenta de usuario</h5>
          </div>
          <div class="col-md-12">
            <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('email', paciente_form, form.submitted)}">
              <label for="txtemail" [ngClass]="{ 'star_symbol': _frm.is_required('email', paciente_form) }">Correo electrónico</label>
              <input type="email" placeholder="Correo electrónico" class="form-control" id="txtemail"
                formControlName="email">
              <i class="mdi mdi-at"></i>

              <div class="invalid-feedback">
                <div *ngIf="paciente_form.controls['email'].errors?.required">Ingrese el correo electrónico del paciente</div>
                <!-- <div *ngIf="paciente_form.controls['email'].errors?.email">Dirección de correo electrónico no válida</div> -->
                <div *ngIf="paciente_form.controls['email'].errors?.pattern">Dirección de correo electrónico no válida</div>
              </div>

            </div>
          </div>
        </div>
        <div *ngIf="paciente.paciente_id">
          <div class="row">
            <div class="col-md-12" m-t-20>
              <h5 class="modal-title text-right">Contacto</h5>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label for="txttelefonocasa">Teléfono fijo</label>
                <input type="text" placeholder="Teléfono" class="form-control" id="txttelefonocasa"
                  formControlName="telefono_casa">
                <i class="mdi mdi-phone"></i>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="txttelefonomovil">Teléfono móvil</label>
                <input type="text" placeholder="Teléfono" class="form-control" id="txttelefonomovil"
                  formControlName="telefono_movil">
                <i class="mdi mdi-cellphone"></i>
              </div>
            </div>
          </div>
          <div class="row m-t-20" formGroupName="domicilio">
            <div class="col-md-12">
              <h5 class="modal-title text-right">Domicilio</h5>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="txtcp">Código Postal</label>
                <input type="text" placeholder="Código Postal" class="form-control" id="txtcp"
                  (blur)="change_postalcode($event.target.value)" formControlName="postal_code">
              </div>
            </div>
            <div class="col-md-8">
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="state_id">Estado</label>
                <ng-select [searchable]="true" placeholder="Estado" formControlName="state_id"
                  (change)="change_state($event)" class="form-control" [items]="states" bindLabel="name"
                  bindValue="state_id">
                </ng-select>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="municipality_id">Delegación/Municipio</label>
                <ng-select [searchable]="true" placeholder="Delegación/Municipio" formControlName="municipality_id"
                  class="form-control" (change)="change_municipality($event)" [items]="municipalities" bindLabel="name"
                  bindValue="municipality_id">
                </ng-select>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="settlement">Colonia</label>
                <ng-select [searchable]="true" placeholder="Colonia" formControlName="settlement" class="form-control"
                  (change)="change_settlement($event)" [items]="settlements" bindLabel="name" bindValue="name">
                </ng-select>
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <label for="txtstreet">Calle</label>
                <input type="email" placeholder="Calle" class="form-control" id="txtstreet" formControlName="street">
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="txtnumext">Número Exterior</label>
                <input type="text" placeholder="Número Exterior" class="form-control" id="txtnumext"
                  formControlName="exterior_number">
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="txtnumint">Número Interior</label>
                <input type="text" placeholder="Número Interior" class="form-control" id="txtnumint"
                  formControlName="interior_number">
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label for="txtref">Referencia</label>
                <input type="text" placeholder="Referencia" class="form-control" id="txtref" formControlName="reference">
              </div>
            </div>
          </div>
          <div class="row m-t-20" formGroupName="almacenamiento" style="display: none;">
            <div class="col-md-12 ">
              <h5 class="modal-title text-right">Almacenamiento</h5>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="gender">Anaquel</label>
                <ng-select [searchable]="true" placeholder="Anaquel" class="form-control" [items]="anaquel"
                  bindLabel="nombre" bindValue="id" formControlName="anaquel" (change)="change_anaquel($event)">
                </ng-select>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="gender">Fila</label>
                <ng-select [searchable]="true" placeholder="Fila" class="form-control" [items]="fila" bindLabel="nombre"
                  bindValue="id" formControlName="fila" (change)="change_fila($event)">
                </ng-select>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="gender">Columna</label>
                <ng-select [searchable]="true" placeholder="Columna" class="form-control" [items]="columna"
                  bindLabel="nombre" bindValue="id" formControlName="almacenamiento_id">
                </ng-select>
              </div>
            </div>
          </div>          
        </div>

      </div>
    </div>




    <div class="row m-t-30">
      <div class="col-md-12 modal-footer">
        <button type="submit" class="btn btn-primary btn-sm "><i class="mdi mdi-content-save"></i> Guardar
          Paciente</button>
      </div>
    </div>
  </form>
</div>