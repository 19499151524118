import { Component, OnInit } from '@angular/core';
import { AuthorizationService, NotifyService } from 'src/app/services/service.index';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: []
})
export class HeaderComponent implements OnInit {

  constructor(public _auth:AuthorizationService, public _ntf:NotifyService) { }

  ngOnInit() {
  }

  logout() {
    this._auth.logout();
  }
}
