import { Injectable } from '@angular/core';
import { GridComponent } from '../shared/components/grid.component';
import swal from "sweetalert2";
import { ToastrService } from "ngx-toastr";
import { FormGroup } from '@angular/forms';
import { Base64 } from 'js-base64';

@Injectable({
  providedIn: 'root'
})
export class FormsService {

  constructor(private _toastr: ToastrService, ) { }


  show_warning(_title: string, _text: string, on_acept: any) {
    swal({
      title: _title,
      text: _text,
      type: "warning",
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      buttonsStyling: false,
      confirmButtonClass: "btn btn-primary btn-sm",
      cancelButtonClass: "btn btn-danger btn-sm m-l-20"
    }).then((result) => {
      if (result.value) {
        on_acept();
      }
    })
  }
  toast_alert(message: string, title: string, type: string) {

    switch (type) {
      case "warning": {
        this._toastr.warning(message, title, { timeOut: 0, progressBar: true });
        break;
      }
      case "success": {
        this._toastr.success(message, title, { timeOut: 3000, progressBar: true });
        break;
      }
      case "error": {
        this._toastr.error(message, title, { timeOut: 0, progressBar: true });
        break;
      }
    }

  }
  get_rows_selected(mygrid: GridComponent, allow_multiple: boolean, on_select: any) {
    const selecteds = mygrid.getSelectedRows();
    if (selecteds.length == 0) this._toastr.warning("Seleccione por lo menos un registro del listado.", "Advertencia:")
    else if (selecteds.length > 1 && !allow_multiple) this._toastr.warning("Seleccione un solo registro del listado", "Advertencia:")
    else on_select(selecteds);
  }
  is_required(control: string, form_group: FormGroup): boolean {
    var _validator: any = form_group.controls[control].validator && form_group.controls[control].validator(form_group.controls[control]);
    return _validator && _validator.required;
  }
  is_requiredChild(control: string,controlPadre:string ,form_group: FormGroup): boolean {
    let fg:any =form_group.controls[controlPadre]
    var _validator: any = fg.controls[control].validator && fg.controls[control].validator(fg.controls[control]);
    return _validator && _validator.required;
  }
  is_invalid(control: string, form_group: FormGroup, submitted: boolean): boolean {
    
    return (submitted && form_group.controls[control].invalid) || (form_group.controls[control].invalid && (form_group.controls[control].dirty || form_group.controls[control].touched))
  }
  is_invalidChild(control: string, controlPadre:string ,form_group: FormGroup, submitted: boolean): boolean {
    let fg:any =form_group.controls[controlPadre]
    //console.log(fg.controls[control])
    return (submitted && fg.controls[control].invalid) || (fg.controls[control].invalid && (fg.controls[control].dirty || fg.controls[control].touched))
  }
  save_module(form: FormGroup, row_id: string, on_create: any, on_update: any) {
    if (form.invalid) {
      this.toast_alert("Existen problemas al validar los campos del formulario. Revise los mensajes para mayor información ", "Validación del formulario", "warning");
      return;
    }
    if (form.value[row_id] == null) {
      on_create();
    }
    else {
      on_update();
    }
  }

  get_columnDefs(metadata: string) {
    let meta = JSON.parse(Base64.decode(metadata));    
    let columnDefs = [];

    if (meta.columnDefs) {
      meta.columnDefs.forEach((element) => {
        var col: {[k: string]: any} = {};
        col.headerName = element.headerName;
        col.field = element.field;
        
        if (element.width) col.width = element.width;
        if (element.checkboxSelection) col.checkboxSelection = element.checkboxSelection;
        if (element.headerCheckboxSelection) col.headerCheckboxSelection = element.headerCheckboxSelection;

        
        if (element.cellRenderer) {
          if (element.cellRenderer =="checkbox") {
            col.cellRenderer = params => {
              return `<input type='checkbox' style='position:static; opacity:1; ' ${params.value ? 'checked' : ''} disabled />`;
            }
          }
        }

        columnDefs.push(col);
      });
      //console.log(`columnDefs: ${JSON.stringify(columnDefs)}`);
      return columnDefs;
    }
    else {
      return null;
    }
  }
  get_autoGroupColumnDef(metadata: string) {
    let meta = JSON.parse(Base64.decode(metadata));
    let autoGroupColumnDef = {};

    if (meta.autoGroupColumnDef) {
      autoGroupColumnDef = {
        headerName: meta.autoGroupColumnDef.headerName,
        cellRendererParams: {
          suppressCount: true,
          checkbox: true
        }
      }
      //console.log(`autoGroupColumnDef: ${JSON.stringify(autoGroupColumnDef)}`);
      return autoGroupColumnDef;
    }
    else {
      return null;
    }
  }
}
