import { Directive,  Input,  ViewContainerRef,  TemplateRef,  OnInit} from "@angular/core";
import { Router } from "@angular/router";
import { AuthorizationService } from 'src/app/services/service.index';



@Directive({
  selector: "[appPermissions]"
})
export class PermissionsDirective implements OnInit {
  @Input() appPermissions: string;

  isVisible = false;
  userpermission = [];
  itempermission = [];
  haspermission = false;

  constructor(private viewContainerRef: ViewContainerRef, private templateRef: TemplateRef<any>,private _router: Router,private _auth: AuthorizationService ) {

  }

  ngOnInit() {   

    this._auth.get_permission_module(this._router.url).forEach((element, index) => {
      this.userpermission.push(element.name)
    });
    
    this.itempermission = this.appPermissions.split(",");

    this.itempermission.forEach((element, index) => {
      if (this.userpermission.includes(element)) this.haspermission = true;
    });


    if (this.haspermission) {
      if (!this.isVisible) {
        this.isVisible = true;
        this.viewContainerRef.createEmbeddedView(this.templateRef);
      }
    } else {
      this.isVisible = false;
      this.viewContainerRef.clear();
    }


  }
}
