<div class="card-body p-b-0">
    <form class="form-material" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="bounds">
            <formly-form [form]="form" [fields]="formFields" [model]="model"></formly-form>
        </div>
        <div class="row m-t-30">
            <div *ngIf="!readOnly" class="col-md-12 modal-footer">
                    <button type="button" class="btn btn-danger btn-sm" (click)="on_close()"><i class="mdi mdi-close"></i> Cancelar</button>
                    <button type="submit" class="btn btn-primary btn-sm "><i class="mdi mdi-content-save"></i> Guardar</button>           
            </div>

            <div *ngIf="readOnly" class="col-md-12 modal-footer">
                <button type="button" class="btn btn-primary btn-sm" (click)="on_print()"><i class="mdi mdi-printer"></i> Imprimir</button>        
        </div>

        </div>
    </form>
</div>