<div class="card-body p-b-0">
  <form class="form-material" [formGroup]="agenda_form" (ngSubmit)="on_submit()" #form="ngForm" novalidate>

    <div class="row">
      <div class="col-md-6">
        <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('fecha', agenda_form, form.submitted)}">
          <label for="txt_fecha" [ngClass]="{ 'star_symbol': _frm.is_required('fecha', agenda_form) }">Fecha</label>
          <input type="text" placeholder="Fecha" class="form-control m-t-20" id="txt_fecha" formControlName="fecha" ngbDatepicker #d="ngbDatepicker" (focus)="d.toggle()" readonly="readonly">
          <div class="invalid-feedback">
            <div *ngIf="agenda_form.controls['fecha'].errors?.required">La fecha es obligatoria</div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('hora', agenda_form, form.submitted)}">
          <label for="txt_hora" [ngClass]="{ 'star_symbol': _frm.is_required('hora', agenda_form) }">Hora</label>
          
          
          <!-- <ngb-timepicker formControlName="hora"></ngb-timepicker> -->

            <ng-select id="ddl_hora" [searchable]="false" placeholder="Hora" formControlName="hora" class="form-control  m-t-20"
            [items]="horarios"  bindLabel="nombre" bindValue="id" [compareWith]="compareWith"></ng-select>




          <div class="invalid-feedback">
            <div *ngIf="agenda_form.controls['hora'].errors?.required">La hora es obligatoria</div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
          <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('paciente_id', agenda_form, form.submitted)}">
            <label for="ddl_paciente" [ngClass]="{ 'star_symbol': _frm.is_required('paciente_id', agenda_form) }">Paciente</label>
            <ng-select  id="ddl_paciente" class="form-control"
                  [items]="paciente$ | async"
                   bindLabel="nombre_completo"
                   bindValue="paciente_id"
                   [loading]="pacienteLoading"
                   [typeahead]="pacienteinput$"
                   formControlName="paciente_id"
                   placeholder="Paciente"
                   typeToSearchText ="Buscar paciente"
                   notFoundText = "No se encontraron pacientes"
                   loadingText = "Buscando">
                   <ng-template ng-option-tmp let-item="item">
                      <strong> {{item.numero_registro}} </strong> {{item.nombre_completo}}
                  </ng-template>
            </ng-select>

            
            <div class="invalid-feedback">
              <div *ngIf="agenda_form.controls['paciente_id'].errors?.required">Ingresa el paciente</div>
            </div>
          </div>
      </div>
      <div class="col-md-6">
          <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('tipo_id', agenda_form, form.submitted)}">
            <label for="ddl_tipo" [ngClass]="{ 'star_symbol': _frm.is_required('tipo_id', agenda_form) }">Tipo</label>
            <ng-select id="ddl_tipo" [searchable]="true" placeholder="Tipo" formControlName="tipo_id" class="form-control"
                           [items]="tipo_consulta"  bindLabel="nombre" bindValue="id">
            </ng-select>
            <div class="invalid-feedback">
              <div *ngIf="agenda_form.controls['tipo_id'].errors?.required">Ingresa el tipo de consulta</div>
            </div>
          </div>
      </div>
      <div class="col-md-6">
          <!-- <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('duracion', agenda_form, form.submitted)}">
            <label for="ddl_duracion" [ngClass]="{ 'star_symbol': _frm.is_required('duracion', agenda_form) }">Duración</label>
            <ng-select id="ddl_duracion" [searchable]="true" placeholder="Duración" formControlName="duracion" class="form-control"
                           [items]="duracion"  bindLabel="nombre" bindValue="id">
            </ng-select>
            <div class="invalid-feedback">
              <div *ngIf="agenda_form.controls['duracion'].errors?.required">Ingresa la duración de la consulta</div>
            </div>
          </div> -->
      </div>
      <!-- <div class="col-md-12" *ngIf="agenda.link_medico != ''">
        <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid('duracion', agenda_form, form.submitted)}">
          <br>
          <a href="{{agenda.link_medico}}">Link para su cita en linea</a>
        </div>
    </div> -->
 
    </div>
 
    <div class="row m-t-30">
      <div class="col-md-12 modal-footer">
        <button type="button" class="btn btn-danger btn-sm" (click)="on_close()"><i class="mdi mdi-close"></i> Cancelar</button>
        <button type="submit" class="btn btn-primary btn-sm "><i class="mdi mdi-content-save"></i> Guardar</button>
      </div>
    </div>

  </form>
</div>