<div *ngIf="dataSource">
  <div class="soluciones__cards">


  <div class="row mb-10 soluciones__card" *ngFor="let item of dataSource">
      <div class="example-card">
        <a [href]="item.ruta_archivo" target="_blank">
          <img [src]="item.ruta_image" alt="Cover">
        </a>
        
      </div>
  </div>