<div class="card-body p-b-0">
  <form *ngIf="meta_data" class="form-material" [formGroup]="form_group" (ngSubmit)="on_submit()" #form="ngForm"
    novalidate>

    <div class="row">
      <div *ngFor="let field of meta_data.fields" [class]="field.css_class">
        <div class="form-group" [ngClass]="{ 'has-danger': _frm.is_invalid(field.name, form_group, form.submitted)}"
          *ngIf="field.visible">
          <label for="txt_name" [ngClass]="{ 'star_symbol': _frm.is_required(field.name, form_group) }">{{field.label}}</label>
          
          <!-- Input tipo texto -->
          <input *ngIf="field.type=='text'" type="text" [placeholder]="field.placeholder" class="form-control" [id]="field.name"
            [formControlName]="field.name" [readonly]="field.read_only">
          <i *ngIf="field.icon" [class]="field.icon"></i>
          <!-- Input tipo textarea -->
          <textarea *ngIf="field.type=='textarea'" type="text" [placeholder]="field.placeholder" class="form-control"
            [id]="field.name" [formControlName]="field.name"></textarea>
          <!-- Input tipo select -->
          <ng-select *ngIf="field.type=='select'" [placeholder]="field.placeholder" [formControlName]="field.name" [id]="field.name"
            class="form-control" [items]="field.options" bindLabel="text" bindValue="value" [searchable]="false"
            [multiple]="field.multiselect" notFoundText="No hay resultados"></ng-select>

          <span *ngIf="field.helptext" class="help-block text-muted"><small>{{field.helptext}} </small></span>
          <div class="invalid-feedback">
            <div *ngFor="let val of field.validations">
              <div *ngIf="form_group.controls[field.name].errors?.required">{{val.error_message}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="row m-t-30">
      <div class="col-md-12 modal-footer">
        <button *ngIf="show_cancel_button" type="button" class="btn btn-danger btn-sm" (click)="on_close()"><i class="mdi mdi-close"></i>Cancelar</button>
        <button type="submit" class="btn btn-primary btn-sm"><i class="mdi mdi-content-save"></i>Guardar</button>
      </div>
    </div>

  </form>
</div>