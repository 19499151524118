import { Component, OnInit,ViewChild } from '@angular/core';
import { CatalogService, MedicoService, PacienteService,FormsService } from 'src/app/services/service.index';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GridComponent } from 'src/app/shared/components/grid.component';
import { prestamo,paciente } from 'src/app/models/paciente.model';
import { ToastrService } from 'ngx-toastr';
import { FormGroup } from '@angular/forms';
import { Inject, LOCALE_ID } from '@angular/core';
declare var sw: any; 
import { formatDate } from '@angular/common';
@Component({
  selector: 'app-prestamos',
  templateUrl: './prestamos.component.html',
  styles: []
})
export class PrestamosComponent implements OnInit {
  @ViewChild("modal", { static: true }) modal;
  @ViewChild("grid1", { static: true }) grid1: GridComponent;
  @ViewChild("grid2", { static: true }) grid2: GridComponent;
  @ViewChild("content", { static: true }) content;
  especialidades: any;
  medicos: any;
  rowData: any = [];
   ids:any = [];
  public arr:any=[];  
  public bandVentana:boolean =false;

  columnDefs = [
    { headerName: "No Registro", field: "numero_registro"},
    { headerName: "Paciente", field: "paciente" },
    { headerName: "Ubicacion", field: "ubicacion" },
    {headerName: "Estatus", field: "estatus" }
  ];
  rowData2: any = [];
  columnDefs2 = [
    { headerName: "No Registro", field: "numero_registro", checkboxSelection: true, headerCheckboxSelection: true },
    { headerName: "Paciente", field: "paciente" },
    { headerName: "Ubicacion", field: "ubicacion" },
    {headerName: "Estatus", field: "estatus" },
    { headerName: "Fecha de consulta", field: "fecha",cellRenderer: (data) => {
      return  formatDate(data.value, 'dd MMM yyyy', this.locale)
      }}];

 
  _prestamo:prestamo = new prestamo({});
  public paciente:string;
  public fecha:Date;
  public especialidad_id:string;
  public medico_id:string;
  paciente2: paciente = new paciente({});

  constructor(@Inject(LOCALE_ID) private locale: string,public _cat: PacienteService,public _cat2: CatalogService, public _medico:MedicoService, private modalService: NgbModal, public _frm: FormsService, private _toastr: ToastrService) { }
  
   
   
  ngOnInit() {
    this._cat2.get_catalog("especialidad", true).subscribe(data => this.especialidades = data, error => console.log(error)); 
    
  }
  option_selector(button: string) {

    switch (button) {
      case "expediente": {   
        const selecteds = this.grid1.getSelectedRows();
        const selecteds2 = this.grid2.getSelectedRows();

        if(!this.bandVentana){
          if(selecteds.length == 0) {
            this._toastr.warning("Seleccione un paciente del listado", "Expediente de paciente")
           }else {
            let selected = selecteds[0];
            this._cat.get_paciente(selected.paciente_id).subscribe(
              (data: any) => {
                this.paciente2 = data;
                this.modalService.open(this.content, { backdrop: 'static', windowClass: "xlg" });
                sw.common.init_plugins();
              },
              err => console.log(err)
            );
          }
        }else{
          if (selecteds2.length == 0) this._toastr.warning("Seleccione un paciente del listado", "Expediente de paciente")
          else {
            if(selecteds2.length > 1){
              this._toastr.warning("Seleccione solo un paciente del listado", "Expediente de paciente")
            }else{       
            let selected = selecteds2[0];
            this._cat.get_paciente(selected.paciente_id).subscribe(
              (data: any) => {
                this.paciente2 = data;
                this.modalService.open(this.content, { backdrop: 'static', windowClass: "xlg" });
                sw.common.init_plugins();
              },
              err => console.log(err)
            );
            }
          
          }
        }
        break;
      }
      case "prestar": {
        this.dispara_modal();
        break;
      }
    
    }
  }
  change_nombre() {
    console.log(this.paciente);
    this._cat.prestamo_estatus_get(this.paciente,'','','').subscribe(data => this.rowData = data, error => console.log(error));
  }
  change_valores(){
    console.log("pruebafecha")
    this._cat.prestamo_estatus_consulta(this.fecha,this.especialidad_id,this.medico_id).subscribe(data => this.rowData2 = data, error => console.log(error));
  }

  dispara_modal(){
    const selected = this.grid1.getSelectedRows();
    const selected2 = this.grid2.getSelectedRows();
   
    if(this.bandVentana==false){
      if (selected.length == 0) this._toastr.warning("Seleccione por lo menos algun expediente", "Prestamo de expedientes")
      else {    
        this._prestamo = new prestamo({});
        this._prestamo = selected[0];
        this.modalService.open(this.modal, { size: 'lg' });
      } 
    }else{
      console.log("mas")
      if (selected2.length == 0) this._toastr.warning("Seleccione uno o mas de un expedientes", "Prestamo de expedientes")
      else {  
        this._prestamo = new prestamo({});
        this._prestamo = selected2[0];
        console.log(selected2[0])
        this.modalService.open(this.modal, { size: 'lg' });
     
      }

    }

   
  }
  save_prestamo(form: FormGroup) {
     if(!this.bandVentana){
      this.arr.push(form.value)

       this._cat.insert_prestamo(this.arr).subscribe(
         (res: any) =>
         { this._frm.toast_alert("Prestamo creado exitosamente", "Nuevo prestamo", "success");
           this.modalService.dismissAll(null);
           this._cat.prestamo_estatus_get(this.paciente,'','','').subscribe(data => this.rowData = data, error => console.log(error));
           this.arr=[];
         },
         error => this._frm.toast_alert("El prestamo no pudo ser creado correctamente. Error: " + error.error.message, "Nuevo Prestamo", "error")
       ); 
     }else{
      const selected2 = this.grid2.getSelectedRows();
      selected2.forEach(element => {
        let clone = Object.assign({}, form.value);
        clone.paciente_id = element.paciente_id;
        this.arr.push(clone);
      });
      this._cat.insert_prestamo(this.arr).subscribe(
        (res: any) =>
        { this._frm.toast_alert("Prestamos creados exitosamente", "Nueva prestamo", "success");
          this.modalService.dismissAll(null);
          this._cat.prestamo_estatus_consulta(this.fecha,this.especialidad_id,this.medico_id).subscribe(data => this.rowData2 = data, error => console.log(error));
          this.arr=[];
        },
        
        error =>{
          this._frm.toast_alert(error.error.message, "Nuevo Prestamo", "error")
          this.arr=[];
          this._cat.prestamo_estatus_consulta(this.fecha,this.especialidad_id,this.medico_id).subscribe(data => this.rowData2 = data, error => console.log(error));
          this.modalService.dismissAll(null);
        } 
        
      ); 
     }
     

  }
  click_ventana(){
    if(this.bandVentana==false){
      this.bandVentana=true;
    }else{
      this.bandVentana=false;
    }
    

  }
  change_especialidad($event) {
        this.medicos = [];
        this._medico.search_medico('',$event.id).subscribe(data =>this.medicos = data, err => console.log(err));
  }
    
}
