<div class="row">
    <div class="col-md-12 text-right">
        <div class="btn-group btn-group-justified btn-group-sm" style="margin:0 0 10px 0; display: inline-block;">
            <ng-template ngFor let-permission [ngForOf]="parents">
                <button *ngIf="!permission.grouping" type="button" class="btn btn-secondary" (click)="clickButton(permission.name)"><i [class]="permission.css_class"></i> {{ permission.display_text}}</button>
                <div *ngIf="permission.grouping" class="btn-group btn-group-sm">
                    <button id="btnGroupDrop1" type="button" class="btn btn-sm btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i [class]="permission.css_class"></i>{{ permission.display_text}}
                    </button>
                    <div class="dropdown-menu" aria-labelledby="btnGroupDrop1" x-placement="bottom-start" style="position: absolute; transform: translate3d(0px, 35px, 0px); top: 0px; left: 0px; will-change: transform;">
                        <button *ngFor="let sub of subpermission(permission.permission_id)" class="btn-sm dropdown-item" (click)="clickButton(sub.name)" style="cursor: pointer"><i [class]="sub.css_class"></i>{{ sub.display_text}}</button >
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>