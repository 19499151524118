<app-buttons (button_selected)="option_selector($event)"></app-buttons>
<app-grid [rowData]="rowData" [columnDefs]="columnDefs" [autoGroupColumnDef]="autoGroupColumnDef" [treeData]="true" [rowSelection]="'single'"></app-grid>



<!-- Ventana modal para creación y actualización de roles -->
<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" *ngIf="!module.module_id">Nuevo Módulo</h4>
        <h4 class="modal-title" *ngIf="module.module_id">Actualizar Módulo</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body p-0">
        <div class="card m-b-0">
            <ul class="nav nav-tabs profile-tab" role="tablist">
                <li class="nav-item"> <a class="nav-link active" data-toggle="tab" href="#modules" role="tab">Datos generales</a></li>
            </ul>
            <div class="tab-content">
                <div class="tab-pane active" id="modules" role="tabpanel">
                    <app-module-dialog [module]="module" [show_cancel_button]=true (close_modal)="modal.close('Cancelar')" (submit_form)="save_module($event)"></app-module-dialog>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!-- Ventana modal para administracion de permisos sobre un módulo -->
<ng-template #content2 let-modal>
    <div class="modal-header">
        <h4 class="modal-title" >Asignación de permisos</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body p-0">
        <div class="card m-b-0">
            <ul class="nav nav-tabs profile-tab" role="tablist">
                <li class="nav-item"> <a class="nav-link active" data-toggle="tab" href="#permissions" role="tab">Módulo [ {{ module.name }} ]</a></li>
            </ul>
            <div class="tab-content">
                <div class="tab-pane active" id="permissions" role="tabpanel">
                    <app-module-permissions-dialog [module_id] = "module.module_id"></app-module-permissions-dialog>
                </div>
            </div>
        </div>
    </div>
</ng-template>


