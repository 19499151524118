<aside class="left-sidebar">
    <div class="scroll-sidebar">
        <nav class="sidebar-nav">
            <ul id="sidebarnav">
                <li class="user-profile">
                    <a class="has-arrow waves-effect waves-dark" href="#" aria-expanded="false"><img [src]="_auth.user.person.photo" alt="user" /><span class="hide-menu">{{_auth.user.person.name + ' ' + _auth.user.person.last_name}}</span></a>
                    <ul aria-expanded="false" class="collapse">
                        <li><a routerLinkActive="active" [routerLink]="['/profile']">Mi perfil</a></li>
                        <li><a routerLinkActive="active" [routerLink]="['/reset-password']">Cambiar contraseña</a></li>
                        <li><a style="cursor: pointer" (click)="logout()">Logout</a></li>
                    </ul>
                </li>
                <li class="nav-devider"></li>
                <li class="nav-small-cap">MENU</li>


                <li *ngFor="let module of _auth.modules">
                    <a class="has-arrow waves-effect waves-dark" href="#" aria-expanded="false">
                        <i [class]="module.css_class"></i>
                        <span class="hide-menu">{{ module.name}}</span>
                    </a>
                    <ul aria-expanded="false" class="collapse">
                        <li *ngFor="let child of module.children">
                            <a *ngIf="child.route && child.is_visible" routerLinkActive="active" [routerLink]="[child.route]">{{child.name}}</a>
                            <a *ngIf="!child.route && child.is_visible" class="has-arrow waves-effect waves-dark" href="#" aria-expanded="false">{{child.name}}</a>
                            <ul aria-expanded="false" class="collapse">
                                <li *ngFor="let gchild of child.children">
                                    <a routerLinkActive="active" [routerLink]="[gchild.route]">{{gchild.name}}</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
    </div>
</aside>