// VALORES QUE REGESA EL BACK PARA EL FORMULARIO DINÁMICO --->
export class DynamicFormsBack {
    form_id?:     number;
    name?:        string;
    description?: string;
    css_class?:   null;
    fields?:      Field[];
}

export class Field {
    field_id?:                     number;
    form_id?:                      null;
    name?:                         string;
    label?:                        string;
    type?:                         string;
    option_list_source?:           null;
    css_class?:                    null;
    mask?:                         null;
    enabled?:                      boolean;
    read_only?:                    boolean;
    helptext?:                     null;
    placeholder?:                  null;
    multiselect?:                  null | string;
    icon?:                         null;
    align?:                        null | string;
    display_order?:                number;
    visible?:                      boolean;
    value?:                        null;
    field_parent_id?:              null;
    field_parent_value_condition?: null;
    children?:                     null;
    options?:                      null;
    validations?:                  null;
}

export class FieldSchema {
    name?:                         string;
    value?:                        null;
}

// VALORES QUE SE ENVÍA AL BACK PARA EL FORMULARIO DINÁMICO --->
export class PostDynamicForm {
    consulta_id?:   number;
    medico_id?:     number;
    json_template?: string;
    json_template_obj?: DynamicFormsBack;
}

