import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import { map, catchError } from "rxjs/operators";
import { login_request, user, moduleX} from "./../models/authorization.model";
import { environment } from '../../environments/environment';
import * as jwt_decode from "jwt-decode";
import { Observable, throwError } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class AuthorizationService {
  user: user = null;
  modules: moduleX[] = null;
  permissions: any[] = null;
  token: string = null;
  module_list: string[] = null;
  constructor(public http: HttpClient, public _router: Router) {
    this.check_session_data();
  }

  check_session_data() {
    if (localStorage.getItem("user")) {
      this.user = JSON.parse(localStorage.getItem("user"));
      this.token = this.user.token;
      this.modules = this.user.modules;
    } else {
      this.user = null;
      this.token = null;
      this.modules = null;
      this.module_list = null;
    }
  }

  login(req: login_request) {
    let url = environment.apiEndpoint + "authorization/authenticate";

    return this.http.post(url, req).pipe(
      map((resp: any) => {
        //console.log(resp);
        
        localStorage.setItem("user", JSON.stringify(resp));
        this.token = resp.token;
        this.user = resp;
        this.modules = resp.modules;
        return true;
      })//,  catchError(e=>throwError(new Error("SOMETHING BAD HAPPENED")))
    );
  }

  logout() {
    this.user = null;
    this.token = null;
    this.modules = null;
    this.module_list = null;
    localStorage.clear();
    this._router.navigate(["/login"]);
  }

  get_token_expiration_date(token: string): Date {
    const decoded = jwt_decode(token);
    if (decoded.exp === undefined) return null;
    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  is_valid_login(): boolean {
    if (!this.token) return false;

    const date = this.get_token_expiration_date(this.token);
    if (date === undefined) return false;
    return (date.valueOf() > new Date().valueOf());
  }

  get_permission_module(route: string) {
    let search = (needle, haystack, found = []) => {
      haystack.forEach(key => {
        if (key.route === needle) {
          found.push(key.permissions);
          return found;
        }
        if (typeof key.children === "object") {
          search(needle, key.children, found);
        }
      });
      return found;
    };

    let permissions = search(route, this.modules);
    return permissions[0];
  }

  get_module_list(){
    let search = (haystack, found = []) => {
      haystack.forEach(key => {
        if (key.route != "") {
          found.push(key.route);
        }
        if (typeof key.children === "object") {
          search(key.children, found);
        }
      });
      return found;
    };

    let module_list = search(this.modules);
    return module_list;   
  }

  forgotPassword(email: String): Promise<HttpResponse<any>> {
    let url = environment.apiEndpoint + `users/forgot-password?email=${email}`;
    return this.http.post<any>(url, '', {observe: 'response'}).toPromise();
  }

  changePassword(password: String,rowId: String ): Promise<HttpResponse<any>> {
    let url = environment.apiEndpoint + `users/reset-password`;
    let body = {"password_hash":password, "row_id":rowId}
    return this.http.put<any>(url, body, {observe: 'response'}).toPromise();
  }

  changeUserPassword(password: String,userId: number ): Promise<HttpResponse<any>> {
    let url = environment.apiEndpoint + `users/reset-password-user`;
    let body = {"password_hash":password, "user_id":userId}
    return this.http.put<any>(url, body, {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.token }),observe: 'response'}).toPromise();
  }
}
