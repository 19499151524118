import { Component, OnInit, ViewChild, Output, Input, EventEmitter } from '@angular/core';
import { paciente, almacenamiento } from '../../models/paciente.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormsService, PacienteService, CatalogService, AddressService } from 'src/app/services/service.index';
import { formatDate } from '@angular/common';
import { Inject, LOCALE_ID } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GridComponent } from 'src/app/shared/components/grid.component';

declare  var clearFile;


@Component({
  selector: 'app-area-documentos',
  templateUrl: './area-documentos.component.html',
  styles: []
})

export class AreaDocumentosComponent implements OnInit {

  @Input() paciente: paciente = new paciente({});
  doc_form: FormGroup;
  @ViewChild("grid1", { static: true }) grid1: GridComponent;
  tipos_documentos: any[] = [];
  filesImg: any;

  firmar_documento: boolean = false;
  qrInfo: any = null;
  qrInfoHist: any = null;

  uploading: boolean = false;

  public removeEventListener: () => void;

  columnDefs = [
    {
      width: 140, headerName: "Fecha de creación", field: "fecha_creacion", cellRenderer: (data) => {
        return formatDate(data.value, 'dd MMM yyyy', this.locale)
      }
    },
    { width: 200, headerName: "Tipo", field: "tipo" },
    { width: 90,headerName: "Firmado", field: "firmado" }
  ];
  rowData: any = [];

 

  constructor(  private _toastr: ToastrService, @Inject(LOCALE_ID) private locale: string, private _fb: FormBuilder, 
                public _frm: FormsService, public _paciente: PacienteService, public _cat: CatalogService, 
                public _adr: AddressService) { }

  ngOnInit() {
    this._cat.get_catalog("tipo_documento", true).subscribe((data: any) => {
      this.tipos_documentos = data
      // console.log(data);


    });

    if (this.paciente.paciente_id != null) {
      this._paciente.get_documentos(this.paciente.paciente_id).subscribe(data => this.rowData = data, error => console.log(error));
    } else {

    }

    this.doc_form = this._fb.group({
      tipo_documento_id: ['', [Validators.required]],
      fotografia: [],
      file: [null, [Validators.required]],
      paciente_id: [this.paciente.paciente_id],
      plantilla: [null]
    });

   
  }


  change_file(control: string, files: FileList) {
    this.doc_form.controls[control].patchValue(files.item(0));
  }

  change_document($event) {
    if ($event) {
      this.qrInfoHist = null;
      // console.log($event);
      
      var firma: boolean = $event.requiere_firma;
      this.firmar_documento = firma;
      this.qrInfo = null;
      
      this.doc_form.controls['plantilla'].patchValue($event.plantilla);
      this.doc_form.controls['file'].patchValue(null);
      clearFile();  
    }

  }


  save_doc() {
    if (this.doc_form.valid){
      this.doc_form.value.paciente_id = this.paciente.paciente_id;
      this.uploading = true;
      this._paciente.insert_documento(this.doc_form.value).subscribe(
        (res: any) => {
          this._frm.toast_alert("Documento subido  exitosamente", "Nuevo Documento", "success")
          this._paciente.get_documentos(this.paciente.paciente_id).subscribe(data => this.rowData = data, error => console.log(error));
          this.doc_form = this._fb.group({
            tipo_documento_id: [],
            fotografia: [],
            file: [],
            paciente_id: [this.paciente.paciente_id],
            plantilla: [null]
          });
          this.firmar_documento = false;
          clearFile();  
          this.uploading = false;
        },
        error => {
          console.log(error)
          this._frm.toast_alert("El documento no pudo ser subido correctamente. Error: " + error.error.message, "Nuevo Documento", "error")
          this.uploading = false;
        }
      );      
    }
 
  }

  create_qr() {

    console.log(this.doc_form.value);
    
    this.doc_form.value.paciente_id = this.paciente.paciente_id;
    this._paciente.insert_documento_qr(this.doc_form.value).subscribe(
      (res: any) => {

        console.log(res);
        var index = this.tipos_documentos.findIndex((p:any) => p.id == this.doc_form.value.tipo_documento_id);
        res.tipo = this.tipos_documentos[index].nombre;

        this.qrInfo = JSON.stringify(res);
        this._frm.toast_alert("Utilice la aplicación de firmado para completar el proceso", "Nuevo Documento", "success")
        this._paciente.get_documentos(this.paciente.paciente_id).subscribe(data => this.rowData = data, error => console.log(error));
      },
      error => {
        console.log(error)
        this._frm.toast_alert("El documento no pudo ser subido correctamente. Error: " + error.error.message, "Nuevo Documento", "error")
      }
    );
  }

  update_paciente($event) {
    this.paciente = $event;
  }

  selectDelete() {
    const selecteds = this.grid1.getSelectedRows();
    if (selecteds.length == 0) {
      this._toastr.warning("Seleccione un documento del listado", "Documentos del paciente")
    } else {
      this._frm.show_warning('Borrar documento', 'Esta seguro de eliminar el documento. Una vez eliminado no podra recuperarse.', () => this.delete());
    }
  }

  selectPrint() {
    const selecteds = this.grid1.getSelectedRows();
    if (selecteds.length == 0) {
      this._toastr.warning("Seleccione un documento del listado", "Documentos del paciente")
    } else {
      //window.location.href= selecteds[0].ubicacion;
      window.open(selecteds[0].ubicacion)
    }
  }


  selectScan() {
    const selecteds = this.grid1.getSelectedRows();
    if (selecteds.length == 0) {
      this._toastr.warning("Seleccione un documento del listado", "Documentos del paciente")
    } else {

      if (selecteds[0].firmado== "NO" ) {
        console.log(selecteds[0]);
        var selected = selecteds[0];
        
        var res = {
          paciente: this.paciente.nombre_completo,
          fecha_nacimiento: this.paciente.fecha_nacimiento,
          numero_registro: this.paciente.numero_registro,
          edad: this.calcularEdad( this.paciente.fecha_nacimiento).toString(),
          identificador: selected.identificador,
          ubicacion: selected.ubicacion,
          tipo: selected.tipo
        }
        console.log(res);
        
        this.doc_form.controls["tipo_documento_id"].patchValue('');
        this.doc_form.get("tipo_documento_id").setErrors(null)
        this.qrInfoHist = JSON.stringify(res);

      }
      else {
        this._toastr.warning("El documento ya ha sido firmado correctamente", "Documentos del paciente")
      }

    }
  }

  calcularEdad(fecha) {
    var hoy = new Date();
    var cumpleanos = new Date(fecha);
    var edad = hoy.getFullYear() - cumpleanos.getFullYear();
    var m = hoy.getMonth() - cumpleanos.getMonth();

    if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
        edad--;
    }

    return edad;
}


  delete() {
    const selecteds = this.grid1.getSelectedRows();
    
    
    this._paciente.delete_documento(this.paciente.paciente_id, selecteds[0].tipo_documento_id, selecteds[0].ubicacion).subscribe(
      (res: any) => {
        this._frm.toast_alert("Documento eliminado exitosamente", "Eliminar documento", "success");
        this._paciente.get_documentos(this.paciente.paciente_id).subscribe(data => this.rowData = data, error => console.log(error));
        this.doc_form = this._fb.group({
          tipo_documento_id: [],
          fotografia: [],
          file: [""],
          paciente_id: [this.paciente.paciente_id],
          plantilla: [null]
        });
      },
      error => this._frm.toast_alert("El documento no pudo ser eliminado correctamente. Error: " + error.error.message, "Eliminar documento", "error")
    );

  }


}
