// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  urlbase: 'https://mdx-api.swplus.com.mx/',

  //desarrollo
  //apiEndpoint: 'https://localhost:5002/api/',

  //Produccion 
  apiEndpoint: 'https://mdx-api.swplus.com.mx/api/',

  BBB_URL: 'https://swplus.live/bigbluebutton/',
  BBB_SECRET: 'Umxg792WSlTPqPSG7io6PWR1MHc3xgW28kVQhpPfg'
};
