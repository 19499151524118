import { Component, OnInit, ViewChild } from '@angular/core';
import { GridComponent } from '../../shared/components/grid.component';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ProductosService } from '../../services/productos.service';
import { ColDef } from 'ag-grid-community';


@Component({
  selector: 'app-rep-servicios',
  templateUrl: './rep-servicios.component.html',
  styles: [
  ]
})
export class RepServiciosComponent implements OnInit {


  @ViewChild("content", { static: true }) content;
  @ViewChild(GridComponent, { static: true }) mygrid: GridComponent;

  public paciente: string;
  public fecha_inicio: Date;
  public fecha_fin: Date;

  maxDate = { year: new Date().getUTCFullYear(), month: new Date().getUTCMonth() +1, day: new Date().getDate() };

  rowData: any = [];
  columnDefs = [
    {
      headerName: "Fecha", field: "fecha", cellRenderer: (data) => {
        return data.value ? moment(new Date(data.value)).format('MM/DD/YYYY') : '';
      }
    },
    { headerName: "Servicio", field: "servicio" },
    { headerName: "Precio", field: "precio", aggFunc: 'sum', cellRenderer: (data) => {
        const options2 = { style: 'currency', currency: 'USD' };
        const numberFormat2 = new Intl.NumberFormat('en-US', options2);
        return numberFormat2.format(data.value);
      }
    },
    { headerName: "Paciente", field: "paciente" },
    {
      headerName: "Nacimiento", field: "nacimiento", cellRenderer: (data) => {
        return data.value ? moment(new Date(data.value)).format('MM/DD/YYYY') : '';
      }
    },
    { headerName: "Teléfono", field: "telefono" },
    { headerName: "Email", field: "correo"}

  ];

  public autoGroupColumnDef: ColDef = {
    minWidth: 300,
    cellRendererParams: {
      
      footerValueGetter: (params: any) => {
        console.log(params);
        
        return 'Grand Total';

        // const isRootLevel = params.node.level === -1;

        // if (isRootLevel) {
        //   return 'Grand Total';
        // }
        // return `Sub Total (${params.value})`;
      },
    },
  };
  

  public currentPdf: string = null;


  
  
  constructor(private _toastr: ToastrService, private modalService: NgbModal, public prod: ProductosService) { }
  


  ngOnInit() {
    this.rowData = [];
  }
  
  change_valores() {
    var fecha1:any = this.fecha_inicio
    var fecha2:any = this.fecha_fin

    if(fecha1 != null || fecha2 != null){
      var f1  = new Date(Date.UTC(fecha1.year, fecha1.month -1, fecha1.day + 1));
      var f2  = new Date(Date.UTC(fecha2.year, fecha2.month -1, fecha2.day + 1));
      var inicio = moment(f1).format('YYYY/MM/DD');
      var fin = moment(f2).format('YYYY/MM/DD');
    }

    if(fin < inicio) {
        this._toastr.warning("La fecha fin debe ser igual o mayor a la fecha inicio");
    }
    else {
        this.prod.report_services(this.fecha_inicio,this.fecha_fin).subscribe(
          data => {
            this.rowData = data;
          }, 
          error => console.log(error));
    }
  }

  option_selector(button: string){
    console.log(button);
    switch ( button ){
      case "excel": {
        this.mygrid.exportGrid();
        break;
      }
    }
  }

}
