<div class="row">
    <div class="col-md-12">
        <div class="card">
            <ul class="nav nav-tabs profile-tab" role="tablist">
                <li class="nav-item"> <a class="nav-link active" data-toggle="tab" href="#general" role="tab">Generales</a></li>
                <li class="nav-item"> <a class="nav-link" data-toggle="tab" href="#address" role="tab">Domicilio</a></li>
            </ul>
            <div class="tab-content">
                <div class="tab-pane active" id="general" role="tabpanel">
                    <app-person [person]="user.person" (submit_form)="submit_profile($event)"></app-person>
                </div>

                <div class="tab-pane" id="address" role="tabpanel">
                    <app-address [address]= "user.person.address" (submit_form)="submit_address($event)"></app-address>
                </div>
            </div>
        </div>
    </div>
</div>