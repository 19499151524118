import { Component, OnInit, ViewChild } from '@angular/core';
import { ProductosService } from '../../services/productos.service';
import { servicio } from '../../models/servicios.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormsService } from 'src/app/services/forms.service';
import { GridComponent } from 'src/app/shared/components/grid.component';
import { FormGroup } from '@angular/forms';


@Component({
  selector: 'app-servicios',
  templateUrl: './servicios.component.html',
  styles: [
  ]
})
export class ServiciosComponent implements OnInit {


  rowData: any;
  columnDefs = [
    { headerName: "Servicio", field: "nombre"},
    { headerName: "Precio", field: "precio" },
    { headerName: "Descripción", field: "descripcion" }
  ];

  _row_selected:servicio;
  @ViewChild("modal", { static: true }) modal;
  @ViewChild(GridComponent, { static: true }) mygrid: GridComponent;
  
  constructor(private prod:ProductosService, private modalService: NgbModal, public _frm: FormsService) { }

  ngOnInit(): void {
    this.refesh_data();
  }

  refesh_data() {
    this.prod.get_services().subscribe(
      data => {
        this.rowData = data; console.log(this.rowData);
      }, 
      error => console.log(error));
  }


  option_selector(button: string) {
    console.log(button);
    
    switch (button) {
      case "create": {
        this._row_selected = new servicio({});
        this.modalService.open(this.modal, { size: 'lg' });
        break;
      }
      case "update": {
        this._frm.get_rows_selected(this.mygrid, false, (selecteds) => {
          this._row_selected = selecteds[0];
          this.modalService.open(this.modal, { size: 'lg' })
          console.log(this._row_selected);
          
        });
        break;
      }
      case "delete": {
        this._frm.get_rows_selected(this.mygrid, false, (selecteds) => {
          this._row_selected = selecteds[0];
          this._frm.show_warning('Borrar servicio', 'Estas seguro de eliminar el servicio [' + this._row_selected.nombre + ']. Una vez eliminado no podra recuperarse.',
           () => this.delete_servicio(this._row_selected.servicio_id));
        });
        break;
      }
    }
  }




  delete_servicio(id:number) {
    this.prod.delete_service(id).subscribe(
      (res: any) => {this._frm.toast_alert("Servicio eliminado exitosamente", "Borrar Servicio ", "success");
      this.modalService.dismissAll(null);
      this.refesh_data();
    },
      error => { console.log(error); this._frm.toast_alert("El Servicio no pudo ser eliminado correctamente. Error: " + error.error.message, "Eliminar Servicio", "error") }
    );
  }


  save_servicio(form: FormGroup) {

    this._frm.save_module(form, "servicio_id", 
    () => {
      this.prod.create_service(form.value).subscribe(
        (res: any) =>
        { this._frm.toast_alert("Servicio creado exitosamente", "Nuevo Servicio", "success");
          this.modalService.dismissAll(null);
          this.refesh_data();
        },
        error => this._frm.toast_alert("El servicio no pudo ser creado correctamente. Error: " + error.error.message, "Nuevo Servicio", "error")
      );
    }, 
    () => {
       this.prod.update_service(form.value).subscribe(
        (res: any) =>
         {this._frm.toast_alert("Servicio actualizado exitosamente", "Actualizar Servicio", "success");
         this.modalService.dismissAll(null); 
         this.refesh_data();   
          },
         error => {
           this._frm.toast_alert("El Servicio no pudo ser actualizado correctamente. Error: " + error.error.message, "Actualizar Servicio", "error");
         }
       );
    });

  }


}
