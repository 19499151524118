<app-buttons  (button_selected)="option_selector($event)"></app-buttons>

<ul class="nav nav-tabs profile-tab" role="tablist">
  <li class="nav-item"> <a class="nav-link active" data-toggle="tab" href="#paciente" role="tab"  (click)="click_ventana()">Búsqueda por
      paciente</a></li>
  <li class="nav-item"> <a class="nav-link" data-toggle="tab" href="#consulta" role="tab" (click)="click_ventana()">Búsqueda por consulta
      externa</a></li>
</ul>
<div class="tab-content m-t-10 m-b-5">
  <div class="tab-pane active" id="paciente" role="tabpanel">
    <form class="form-material" #form="ngForm" novalidate>
      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <input type="text" placeholder="Paciente / Num. Registro" class="form-control" id="txtPaciente" name ="paciente" [(ngModel)] = "paciente" (keyup)="change_nombre()" >
          </div>
        </div>
      </div>
    </form>
    <app-grid [rowSelection]="'single'" [rowData]="rowData" [columnDefs]="columnDefs" gridHeight="400px" #grid1></app-grid>
  </div>
  <div class="tab-pane" id="consulta" role="tabpanel">
    <form class="form-material" #form="ngForm" novalidate>
      <div class="row">
        <div class="col-md-2">
          <div class="form-group">
            <input type="text" placeholder="Fecha de consulta" class="form-control" id="txtFecha" ngbDatepicker
                #d="ngbDatepicker" (focus)="d.toggle()" name ="fecha" [(ngModel)] = "fecha" (dateSelect)="change_valores()">
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <ng-select [searchable]="true" placeholder="Especialidad" class="form-control" id="ddlEspecialidad"
              [items]="especialidades" (change)="change_especialidad($event)"  name ="especialidad_id" [(ngModel)] = "especialidad_id" bindLabel="nombre" bindValue="id" (change)="change_valores()">
            </ng-select>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <ng-select [searchable]="true" placeholder="Médico" class="form-control" id="ddlMedico"
              [items]="medicos" bindLabel="medico" bindValue="medico_id" name ="medico_id" [(ngModel)] = "medico_id" (change)="change_valores()"> </ng-select>
          </div>
        </div>
      </div>
    </form>
    <app-grid [rowData]="rowData2" [columnDefs]="columnDefs2" gridHeight="400px" [rowSelection]="'multiple'"  #grid2></app-grid>
  </div>
</div>







 <!-- Ventana modal para creación y actualización de camas -->
 <ng-template #modal let-modal>
  <div class="modal-header">
      <h4 class="modal-title">Prestamo de expediente</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body p-0">
      <div class="card m-b-0">
          <ul class="nav nav-tabs profile-tab" role="tablist">
              <li class="nav-item"> <a class="nav-link active" data-toggle="tab" href="#account" role="tab">Prestamo</a></li>
          </ul>
          <div class="tab-content">
              <div class="tab-pane active" id="account" role="tabpanel">
                <app-prestamos-dialog [prestamo]= "_prestamo" [show_cancel_button]=true (close_modal)="modal.close('Cancelar')"(submit_form)="save_prestamo($event)"></app-prestamos-dialog >
              </div>
          </div>
      </div>
  </div>
</ng-template>

<!-- Ventana modal para visualizacion de expediente clinico -->
<ng-template #content let-modal>
  <div class="modal-header">
      <h4 class="modal-title" *ngIf="paciente2.paciente_id"><!-- {{paciente.nombre_completo}} ( {{paciente.numero_registro}} )  --></h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body p-0">
      <div class="card m-b-0">
          <app-expediente [paciente]="paciente2"></app-expediente>
      </div>
  </div>
</ng-template>
