import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FormsService } from 'src/app/services/service.index';
import { article } from 'src/app/models/medico.model';

@Component({
  selector: 'app-articulos-dialog',
  templateUrl: './articulos-dialog.component.html',
  styles: [
  ]
})
export class ArticulosDialogComponent implements OnInit {
  @ViewChild("form", { static: true }) form;
  @Output() submit_form = new EventEmitter<FormGroup>();
  @Output() close_modal = new EventEmitter();
  @Input()  show_cancel_button: boolean = false;
  @Input()  article: article;

  myDate:any;
  fecha:any

  file_name = '';
  image_name = '';

  public article_form: FormGroup;

  constructor(private _fb: FormBuilder, public _frm:FormsService) { }

  ngOnInit() {
    this.myDate=new Date()
    this.fecha ={year: this.myDate.getFullYear(), month: 12, day: 1}


    this.file_name = this.article.ruta_archivo;
    this.image_name = this.article.ruta_image;

    
    this.article_form = this._fb.group({
      id: [this.article.articulo_id],
      titulo: [this.article.titulo, [Validators.required]],
      autor: [this.article.autor],
      descripcion: [this.article.descripcion],
      ruta_archivo: [this.article.ruta_archivo],
      ruta_image: [this.article.ruta_image],
      url: [this.article.url, [Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]],
      b64_archivo: [this.article.b64_archivo],
      b64_image: [this.article.b64_image],
      fecha_publicacion: [this.article.fecha_publicacion ? { 
        year: new Date(this.article.fecha_publicacion).getFullYear(), 
        month: new Date(this.article.fecha_publicacion).getMonth() + 1, 
        day: new Date(this.article.fecha_publicacion).getDate() 
      } : ''],
      
    });
  }
  on_submit() {
    this.submit_form.emit(this.article_form);
  }
  on_close() {
    this.close_modal.emit();
  }


  on_file_changed(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      this.file_name = file.name;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
          this.article_form.patchValue({ "b64_archivo": reader.result });
      };
    }
  }

  on_image_changed(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      this.image_name = file.name;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
          this.article_form.patchValue({ "b64_image": reader.result });
      };
    }
  }



}
