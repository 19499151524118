import { Component, OnInit, ViewChild } from "@angular/core";
import { UserService, FormsService } from "src/app/services/service.index";
import { GridComponent } from '../../shared/components/grid.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { moduleX } from '../../models/authorization.model';
import { FormGroup } from "@angular/forms";

@Component({
  selector: 'app-module',
  templateUrl: './module.component.html',
  styles: []
})
export class ModuleComponent implements OnInit {
  @ViewChild("content", { static: true }) content;
  @ViewChild("content2", { static: true }) content2;
  @ViewChild(GridComponent, { static: true }) mygrid: GridComponent;

  module: moduleX;
  rowData: any;
  columnDefs: any;
  autoGroupColumnDef: any;

  constructor(public _usrs: UserService, public _frm: FormsService, private modalService: NgbModal) { }

  ngOnInit() {
    this.get_data();
  }
  get_data() {
    this._usrs.get_modules(new moduleX({ is_active: true })).subscribe(
      data => {
        this.columnDefs = this._frm.get_columnDefs(data.headers.get('X-Metadata'));
        this.autoGroupColumnDef = this._frm.get_autoGroupColumnDef(data.headers.get('X-Metadata'));
        setTimeout(() => { this.rowData = data.body; }, 500);
      },
      err => console.log(err)
    );
  }
  option_selector(button: string) {
    switch (button) {
      case "create": {
        const selecteds = this.mygrid.getSelectedRows();
        if (selecteds.length == 0) this.module = new moduleX({});
        else this.module = new moduleX({ parent_module_id: selecteds[0].parent_module_id });
        this.modalService.open(this.content, { size: 'lg' });
        break;
      }
      case "update": {
        this._frm.get_rows_selected(this.mygrid, false, (selecteds) => {
          this.module = selecteds[0];
          console.log(this.module);
          this.modalService.open(this.content, { size: 'lg' })
        });
        break;
      }
      case "delete": {
        this._frm.get_rows_selected(this.mygrid, false, (selecteds) => {
          this.module = selecteds[0];
          this._frm.show_warning('Borrar módulo', 'Estas seguro de eliminar el módulo [' + this.module.title + ']. Una vez eliminado no podra recuperarse.', () => this.delete_module());
        });
        break;
      }
      case "permission": {
        this._frm.get_rows_selected(this.mygrid, false, (selecteds) => {
          this.module = selecteds[0];
          this.modalService.open(this.content2);
        });
        break;
      }
    }
  }
  save_module(form: FormGroup) {
    this._frm.save_module(form, "module_id", () => {
      // Crear nuevo modulo
      this._usrs.insert_module(form.value).subscribe(
        (res: any) => this._frm.toast_alert("Módulo creado exitosamente", "Nuevo Módulo", "success"),
        error => this._frm.toast_alert("El módulo no pudo ser creado correctamente. Error: " + error.error.message, "Nuevo Módulo", "error")
      );
    }, () => {
      this._usrs.update_module(form.value).subscribe(
        // Actualizar modulo seleccionado
        (res: any) =>
          this._frm.toast_alert("Módulo actualizado exitosamente", "Actualizar Módulo", "success"),
        error => {
          this._frm.toast_alert("El módulo no pudo ser actualizado correctamente. Error: " + error.error.message, "Actualizar Módulo", "error");
        }
      );
    });
    this.get_data();
    this.modalService.dismissAll(null);
  }
  delete_module() {
    this._usrs.delete_module(this.module).subscribe(
      (res: any) => this._frm.toast_alert("Módulo eliminado exitosamente", "Borrar Módulo", "success"),
      error => { console.log(error); this._frm.toast_alert("El módulo no pudo ser actualizado correctamente. Error: " + error.error.message, "Borrar Módulo", "error") }
    );
    this.get_data();
  }

}