import { Component, OnInit, ViewChild, Output, Input, EventEmitter } from '@angular/core';
import { paciente, almacenamiento } from '../../models/paciente.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormsService, PacienteService, CatalogService, AddressService } from 'src/app/services/service.index';
import { state, municipality, settlement, search_address } from 'src/app/models/address.model';
import * as moment from 'moment';
import {formatDate} from '@angular/common';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { log } from 'console';
declare var sw: any;
@Component({
  selector: 'app-area-general',
  templateUrl: './area-general.component.html',
  styles: [],
})

export class AreaGeneralComponent implements OnInit {


  @ViewChild("form", { static: true }) form;
  @Input() paciente: paciente = new paciente({});
  @Output() submit_form = new EventEmitter<paciente>();;
  paciente_form: FormGroup;
  genders: any;
  seguro: any;
  etnico: any;
  religion: any;
  sangre: any;
  socioeconomico = [{ nombre: '1', id: '1' }, { nombre: '2', id: '2' }, { nombre: '3', id: '3' }, { nombre: '4', id: '4' }, { nombre: '5', id: '5' }, { nombre: '6', id: '6' }, { nombre: 'Excento', id: 'Excento' }];;
  anaquel: any;
  fila: any;
  columna: any;
  country_id: number = 146;
  states: state[];
  municipalities: municipality[];
  settlements: settlement[];
  search_address: search_address;
  myDate:any;
  fecha:any;
  model: NgbDateStruct;

  constructor(private _fb: FormBuilder, public _frm: FormsService, public _paciente: PacienteService, public _cat: CatalogService, public _adr: AddressService,) {
   
   }
 
  ngOnInit() {


    this.myDate=new Date()
    this.fecha ={year: this.myDate.getFullYear(), month: this.myDate.getMonth() + 1, day: this.myDate.getDate()}
    this.paciente_form = this._fb.group({
      paciente_id: [this.paciente.paciente_id],
      nombre: [this.paciente.nombre, [Validators.required]],
      paterno: [this.paciente.paterno, Validators.required],
      materno: [this.paciente.materno],
      fecha_nacimiento: [this.paciente.fecha_nacimiento ? { 
                                                            year: new Date(this.paciente.fecha_nacimiento).getFullYear(), 
                                                            month: new Date(this.paciente.fecha_nacimiento).getMonth() + 1, 
                                                            day: new Date(this.paciente.fecha_nacimiento).getDate() 
                                                          } : '', [Validators.required]],
      sexo_id: [this.paciente.sexo_id, [Validators.required]],
      curp: [this.paciente.curp],
      email: [{value:this.paciente.email, disabled:this.paciente.paciente_id }, [Validators.required, ,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      telefono_casa: [this.paciente.telefono_casa],
      telefono_movil: [this.paciente.telefono_movil],
      rfc: [this.paciente.rfc],
      estado_civil_id: [this.paciente.estado_civil_id],
      title: [this.paciente.title],
      tipo_sangre_id: [this.paciente.tipo_sangre_id],
      grupo_etnico_id: [this.paciente.grupo_etnico_id],
      religion_id: [this.paciente.religion_id],
      tipo_seguro_id: [this.paciente.tipo_seguro_id],
      nivel_socioeconomico: [this.paciente.nivel_socioeconomico],
      numero_registro: [this.paciente.numero_registro],
      numero_poliza: [this.paciente.numero_poliza],
      activo: [this.paciente.activo],
      fotografia: [this.paciente.fotografia],
      file: [null],
      // address
      domicilio: this._fb.group({ 
        postal_code: [this.paciente.domicilio?.postal_code],
        state_id: [this.paciente.domicilio?.state_id],
        municipality_id: [this.paciente.domicilio?.municipality_id],
        settlement: [this.paciente.domicilio?.settlement],
        street: [this.paciente.domicilio?.street],
        exterior_number: [this.paciente.domicilio?.exterior_number],
        interior_number: [this.paciente.domicilio?.interior_number],
        reference: [this.paciente.domicilio?.reference]
      }),
      almacenamiento: this._fb.group({ 
        anaquel: [this.paciente.almacenamiento.anaquel],
        fila: [this.paciente.almacenamiento.fila],
        columna: [this.paciente.almacenamiento.columna],
        almacenamiento_id: [this.paciente.almacenamiento.almacenamiento_id],

      })
      

    });

    this._cat.get_catalog("sexo", true).subscribe(data => this.genders = data, error => console.log(error));
    this._cat.get_catalog("tipo_seguro", true).subscribe(data => this.seguro = data, error => console.log(error));
    this._cat.get_catalog("grupo_etnico", true).subscribe(data => this.etnico = data, error => console.log(error));
    this._cat.get_catalog("religion", true).subscribe(data => this.religion = data, error => console.log(error));
    this._cat.get_catalog("tipo_sangre", true).subscribe(data => this.sangre = data, error => console.log(error));
    this.bind_catalogs(this.country_id, this.paciente.domicilio.state_id, this.paciente.domicilio.municipality_id, this.paciente.domicilio.settlement);
    this._cat.get_almacenamiento(new almacenamiento({})).subscribe(anaquel => {
      this.anaquel = anaquel;
      if (this.paciente.almacenamiento.anaquel) {
        this._cat.get_almacenamiento(new almacenamiento({anaquel:this.paciente.almacenamiento.anaquel})).subscribe(fila => {
          this.fila = fila;
          if (this.paciente.almacenamiento.fila) {
            this._cat.get_almacenamiento(new almacenamiento({anaquel:this.paciente.almacenamiento.anaquel, fila: this.paciente.almacenamiento.fila})).subscribe(columna => {
              this.columna = columna
            }, error => console.log(error));
          }
        }, error => console.log(error));
      }
     
    }, error => console.log(error));

  
  

  }

  bind_catalogs(country_id: number, state_id: number, municipality_id: number, settlement: string) {
    if (country_id) {
      this._adr.get_states(country_id).subscribe((data: any) => { this.states = data });
      if (state_id) {
        this._adr.get_municipality(country_id, state_id).subscribe((data: any) => { this.municipalities = data });
        if (municipality_id) this._adr.get_settlements(country_id, state_id, municipality_id).subscribe((data: any) => { this.settlements = data; });
      }
    } 
  }
  change_postalcode(value: string) {
    
    this._adr.get_address_from_postalcode(value).subscribe((data: any) => {

      this.search_address = data;
      let state_id = this.search_address.state ? this.search_address.state.state_id : null;
      let municipality_id = this.search_address.municipality ? this.search_address.municipality.municipality_id : null;
      let settlement = this.search_address.settlement ? this.search_address.settlement : null;

      if (state_id) {
        this.paciente_form.patchValue({domicilio:{ "state_id": state_id }});

        this._adr.get_municipality(this.country_id, state_id).subscribe((data: any) => {
          this.municipalities = data;
          this.paciente_form.patchValue({domicilio:{ "municipality_id": municipality_id }});

          this.settlements = settlement;
          if (settlement.length > 1) {
            this.paciente_form.patchValue({domicilio:{ "settlement": null }});
          }
          else {
            this.paciente_form.patchValue({domicilio:{ "settlement": settlement[0].name }});
          }
        });
      }
      else {
        this.municipalities = [];
        this.settlements = [];
        this.paciente_form.patchValue({domicilio:{ "state_id": null, "municipality_id": null, "settlement": null }});
      }

    }, error => console.log(error));
  }
  change_state($event) {
    this.municipalities = [];
    this.settlements = [];
    this.paciente_form.patchValue({domicilio:{ "postal_code": null, "municipality_id": null, "settlement": null }});
    this._adr.get_municipality(this.country_id, $event.state_id).subscribe((data: any) => { this.municipalities = data });
  }
  change_municipality($event) {
    this.settlements = [];
    this.paciente_form.patchValue({domicilio:{ "postal_code": null, "settlement": null }});
    this._adr.get_settlements(this.country_id, $event.state_id, $event.municipality_id).subscribe((data: any) => { this.settlements = data; });
  }
  change_settlement($event) {
    this.paciente_form.patchValue({domicilio:{ "postal_code": $event.code }});
  }
  change_anaquel($event){
    this._cat.get_almacenamiento(new almacenamiento({anaquel:$event.id})).subscribe(fila => {
      this.fila = fila
    }, error => console.log(error));
  }
  change_fila($event){
    console.log( this.paciente_form.controls["almacenamiento"].value.anaquel);
    
    this._cat.get_almacenamiento(new almacenamiento({anaquel:this.paciente_form.controls["almacenamiento"].value.anaquel , fila:$event.id })).subscribe(columna => {
      this.columna = columna
    }, error => console.log(error));

  }
  change_file(control: string, files: FileList) {
    this.paciente_form.controls[control].patchValue(files.item(0));
  }

  save_paciente() {
    console.log( ' paso 1 -->', this.paciente_form.value );
    if( this.paciente.flat=1){
      this.paciente_form.value.paciente_id=this.paciente.paciente_id;
    }
    this._frm.save_module(this.paciente_form, "paciente_id", () => {
      
      
      this._paciente.insert_paciente(this.paciente_form.value).subscribe(
        (res: any) => {
          this.paciente = res;        
          this._frm.toast_alert("Paciente creado exitosamente", "Nuevo Paciente", "success")
          this.paciente.flat=1;
          this.submit_form.emit(this.paciente);     
          sw.common.init_plugins();
          this.paciente_form.get("email").disable();
        },
        error => {

          if ( error.error.message == 'Ya existe un usuario registrado con el correo indicado' ) {
            this._frm.show_warning('Crear paciente', 'El correo electrónico proporcionado corresponde a un paciente registrado. ¿Desea asociar al paciente con la cuenta existente?', () => 
            {
            
              console.log( ' paso 2 -->', this.paciente_form.value );
              this._paciente.insert_paciente_attatch(this.paciente_form.value).subscribe(
                (res: any) => {
                  
                  
                  this.paciente = res;        
                  this._frm.toast_alert("Paciente creado exitosamente", "Nuevo Paciente", "success")
                  this.paciente.flat=1;
                  this.submit_form.emit(this.paciente);     
                  sw.common.init_plugins();
                  this.paciente_form.get("email").disable();
                },
                error => this._frm.toast_alert("El paciente no pudo ser creado correctamente. Error: " + error.error.message, "Nuevo Paciente", "error")
              );



              
            });
          }
          else {
            this._frm.toast_alert("El paciente no pudo ser creado correctamente. Error: " + error.error.message, "Nuevo Paciente", "error");
          }
          
          
         


        }
      );
    }, () => {
      this._paciente.update_paciente(this.paciente_form.value).subscribe(
        (res: any) =>
          this._frm.toast_alert("Paciente actualizado exitosamente", "Actualizar Paciente", "success"),
        error => {
        this._frm.toast_alert("El paciente no pudo ser actualizado correctamente. Error: " + error.error.message, "Actualizar Paciente", "error");
        }
      );
    });
  }



}
