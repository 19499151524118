import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { AuthorizationService } from './authorization.service';
import { environment } from '../../environments/environment';
import { medico_search, horario, medico, article } from '../models/medico.model';

@Injectable({
  providedIn: 'root'
})
export class MedicoService {

  public selected_medico: medico_search = new medico_search({});

  constructor(public http: HttpClient, public _auth: AuthorizationService) { }


  get_current_medico() {
     return this._auth.user?.medico_id;
  }

  get_articles() {
    let url = environment.apiEndpoint + "articles/get-articles";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token })
    };
    return this.http.get(url, httpOptions);
  }

  delete_article(article_id: number) {
    let url = environment.apiEndpoint + "articles/delete-article";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
      params: new HttpParams().set('article_id', article_id.toString())
    };
    return this.http.delete(url, httpOptions);
  }

  create_articulo(articulo_updated:article){
    let url = environment.apiEndpoint + "articles/add-article";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
    };
    return this.http.post(url, articulo_updated, httpOptions);
  }

  update_articulo(articulo_updated:article){
    let url = environment.apiEndpoint + "articles/update-article";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
    };
    return this.http.put(url, articulo_updated, httpOptions);
  }




  update_medico(medico_udapted:medico){
    let medico_clone = Object.assign({}, medico_udapted);
    let especialidades:any = medico_clone.especialidades;
    medico_clone.especialidades  = especialidades.join();

    let url = environment.apiEndpoint + "medico/medico_update";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
    };
    return this.http.put(url, medico_clone, httpOptions);
  }

  search_medico(keyword: string, especialidad_id: number) {
    let url = environment.apiEndpoint + "medico/searchmedico";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
      params: new HttpParams().set('keyword', keyword ? keyword : '').set('especialidad_id', especialidad_id ? especialidad_id.toString() : null),
    };
    return this.http.get(url, httpOptions);
  }

  calendario_get(medico_id: number, mes: number, anio: number, especialidad_id: number) {
    let url = environment.apiEndpoint + "medico/calendario_get";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
      params: new HttpParams().set('medico_id', medico_id.toString()).set('mes', mes.toString()).set('anio', anio.toString()).set('especialidad_id', especialidad_id.toString()),
    };
    return this.http.get(url, httpOptions);
  }

  agenda_get(medico_id: number, especialidad_id: number, fecha: string) {
    let url = environment.apiEndpoint + "consulta/agenda_get";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
      params: new HttpParams().set('medico_id', medico_id.toString()).set('especialidad_id', especialidad_id.toString()).set('fecha', fecha),
    };
    return this.http.get(url, httpOptions);
  }

  excepciones_get(medico_id: number) {
    let url = environment.apiEndpoint + "Medico/excepcion_read";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
      params: new HttpParams().set('medico_id', medico_id.toString())
    };
    return this.http.get(url, httpOptions);
  }
  excepciones_delete(excepcion_id: number) {
    let url = environment.apiEndpoint + "Medico/excepcion_delete";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
      params: new HttpParams().set('excepcion_id', excepcion_id.toString())
    };
    return this.http.delete(url, httpOptions);
  }
  horario_get(medico_id: number, especialidad_id: number) {
    let url = environment.apiEndpoint + "medico/horario_read";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
      params: new HttpParams().set('medico_id', medico_id.toString()).set('especialidad_id', especialidad_id.toString())
    };
    return this.http.get(url, httpOptions);
  }

  horario_insert(horarios: Array<horario>) {
    let url = environment.apiEndpoint + "medico/horario_insert";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
    };
    return this.http.post(url, horarios, httpOptions);
  }
  excepciones_insert(excepciones: any) {
    let fecha:any = excepciones.fecha_inicio 
    let fecha2:any = excepciones.fecha_fin
    excepciones.fecha_inicio   = new Date(Date.UTC(fecha.year, fecha.month - 1, fecha.day));
    excepciones.fecha_fin   = new Date(Date.UTC(fecha2.year, fecha2.month - 1, fecha2.day));
    let url = environment.apiEndpoint + "Medico/excepcion_insert";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
    };
    return this.http.post(url, excepciones, httpOptions);
  }

  horario_delete(medico_id: number, especialidad_id: number) {
    let url = environment.apiEndpoint + "medico/horario_delete";
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this._auth.token }),
      params: new HttpParams().set('medico_id', medico_id.toString()).set('especialidad_id', especialidad_id.toString())
    };   
    return this.http.delete(url, httpOptions);
  }

}

