
export class IMilliseconds{
    formatType: number;
    value: number;  
  }

export class Utils{
    ///type: 1 days, 2 months, 3 years
  dataToMilliseconds(data: number, type: number): number {
    switch (type) {
      case 1:
        return data * 86400000;
        break;
      case 2:
        return data * 2628000000;
        break;
      case 3:
        return data * 31540000000;
        break;

      default:
        return null;
        break;
    }
  }

  millisecondsToData(milliseconds: number): IMilliseconds{
    var retData = new IMilliseconds();

    if(milliseconds == null) return retData;

    var days = milliseconds / 86400000;
    var months = milliseconds / 2628000000;
    var years = milliseconds / 31540000000;

    var daysMod = milliseconds % 86400000;
    var monthsMod = milliseconds % 2628000000;
    var yearsMod = milliseconds % 31540000000;

    if(yearsMod == 0){
      retData.formatType = 3;
      retData.value = years;
    }else if(monthsMod == 0){
      retData.formatType = 2;
      retData.value = months;
    }else if(daysMod == 0){
      retData.formatType = 1;
      retData.value = days;
    }

    return retData;
  }
}