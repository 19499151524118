import { Component, OnInit, ViewChild } from '@angular/core';
import { CatalogService, FormsService } from 'src/app/services/service.index';
import { especialidad } from './../../models/medico.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GridComponent } from 'src/app/shared/components/grid.component';

@Component({
  selector: 'app-especialidades',
  templateUrl: './especialidades.component.html',
  styles: []
})
export class EspecialidadesComponent implements OnInit {
  @ViewChild("modal", { static: true }) modal;
  @ViewChild(GridComponent, { static: true }) mygrid: GridComponent;
  
  rowData: any;
  columnDefs = [
    { headerName: "Nombre", field: "nombre"},
    { headerName: "Código", field: "codigo" },
    { headerName: "Descripción", field: "descripcion" }
  ];

  _especialidad:especialidad = new especialidad({});
  public currentPdf: string = null;
  constructor( public _cat: CatalogService, private modalService: NgbModal, public _frm: FormsService) { }

  ngOnInit() {

    this._cat.get_catalog("especialidad", true).subscribe(data => this.rowData = data, error => console.log(error));

  }

  option_selector(button: string) {
    console.log(button);
    
    switch (button) {
      case "create": {
        this._especialidad = new especialidad({});
        this.modalService.open(this.modal, { size: 'lg' });
        break;
      }
      case "update": {
        this._frm.get_rows_selected(this.mygrid, false, (selecteds) => {
          this._especialidad = selecteds[0];
          this.modalService.open(this.modal, { size: 'lg' })
          console.log(this._especialidad );
          
        });
        break;
      }
      case "delete": {
        break;
      }
      case "print": {
        let preloader = document.getElementById('preloader');
        preloader.style.display = 'block';

        this._cat.printPrueba(this.rowData).subscribe(
          data => {
            let blob = new Blob([data], { type: 'application/pdf' });
            //saveAs(blob, 'test.pdf');
            
            this.currentPdf = URL.createObjectURL(blob);
            window.open(this.currentPdf, '_blank');
            preloader.style.display = 'none';
          },
          err => console.log(err)
        );
        break;
      }
    }
  }

}
