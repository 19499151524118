import { Component, OnInit, ViewChild } from "@angular/core";
import { AuthorizationService, UserService, FormsService } from "src/app/services/service.index";
import { GridComponent } from '../../shared/components/grid.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { role } from '../../models/authorization.model';
import { FormGroup } from "@angular/forms";

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styles: []
})
export class RolesComponent implements OnInit {
  @ViewChild("modal", { static: true }) modal;
  @ViewChild("modal2", { static: true }) modal2;
  @ViewChild(GridComponent, { static: true }) mygrid: GridComponent;

  rowData: any;
  columnDefs = [
    { headerName: "Nombre", field: "name", checkboxSelection: true, headerCheckboxSelection: true },
    { headerName: "Descripción", field: "description" },
    { headerName: "Etiqueta", field: "display_text" }
  ];
  role: role;

  constructor(public _auth: AuthorizationService, public _frm: FormsService, public _usrs: UserService, private modalService: NgbModal) { }

  ngOnInit() {
    this.get_data();
  }

  get_data() {
    this._usrs.get_roles(new role({ is_active: true })).subscribe(
      data => {
        this.columnDefs = this._frm.get_columnDefs(data.headers.get('X-Metadata'));
        setTimeout(() => { this.rowData = data.body; }, 500);
      },
      err => console.log(err)
    );
  }

  option_selector(button: string) {
    switch (button) {
      case "create": {
        this.role = new role({});
        this.modalService.open(this.modal, { size: 'lg' });
        break;
      }
      case "update": {
        this._frm.get_rows_selected(this.mygrid, false, (selecteds) => {
          this.role = selecteds[0];
          this.modalService.open(this.modal, { size: 'lg' })
        });
        break;
      }
      case "delete": {
        this._frm.get_rows_selected(this.mygrid, false, (selecteds) => {
          this.role = selecteds[0];
          this._frm.show_warning('Borrar rol', 'Estas seguro de eliminar el rol [' + this.role.name + ']. Una vez eliminado no podra recuperarse.', () => this.delete_role());
        });
        break;
      }
      case "authorize": {
        this._frm.get_rows_selected(this.mygrid, false, (selecteds) => {
          this.role = selecteds[0];
          this.modalService.open(this.modal2, { size: 'lg' });
        });
        break;
      }
    }
  }

  save_role(form: FormGroup) {

    this._frm.save_module(form, "role_id", () => {
      // Crear nuevo rol
      this._usrs.insert_role(form.value).subscribe(
        (res: any) => this._frm.toast_alert("Rol creado exitosamente", "Nuevo Rol", "success"),
        error => this._frm.toast_alert("El rol no pudo ser creado correctamente. Error: " + error.error.message, "Nuevo Rol", "error")
      );
    }, () => {
      this._usrs.update_role(form.value).subscribe(
        // Actualizar rol seleccionado
        (res: any) =>
          this._frm.toast_alert("Rol actualizado exitosamente", "Actualizar Rol", "success"),
        error => {
          this._frm.toast_alert("El rol no pudo ser actualizado correctamente. Error: " + error.error.message, "Actualizar Rol", "error");
        }
      );
    });
    this.get_data();
    this.modalService.dismissAll(null);
  }
  delete_role() {
    this._usrs.delete_role(this.role).subscribe(
      (res: any) => this._frm.toast_alert("Rol eliminado exitosamente", "Borrar Rol", "success"),
      error => { console.log(error); this._frm.toast_alert("El rol no pudo ser eliminado correctamente. Error: " + error.error.message, "Borrar Rol", "error") }
    );
    this.get_data();
  }




}
