export interface IDiagnostico {
    diagnostico_id:number;
    clase:string;
    descripcion:string;
    padre_id:number;
    is_leaf:boolean;
    genero_id:number;
    genero_text:string;
    clave:string;
    edad_minima:number;
    edad_maxima:number;
    edad_minima_tipo:number;
    edad_maxima_tipo:number;
    edad_minima_text: string;
    edad_maxima_text: string;
    clase_descripcion:string;
    accion:any;
    cie10:boolean;
}

export class Diagnostico implements IDiagnostico{
    diagnostico_id:number;
    clase:string;
    descripcion:string;
    padre_id:number;
    is_leaf:boolean;
    genero_id:number;
    genero_text:string;
    clave:string;
    edad_minima:number;
    edad_maxima:number;
    edad_minima_tipo:number;
    edad_maxima_tipo:number;
    edad_minima_text: string;
    edad_maxima_text: string;
    clase_descripcion:string;
    accion:any;
    cie10:boolean;

    constructor(data: Diagnostico | {} = {}) {
        Object.assign(this, data);
    }
}