import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FormsService } from 'src/app/services/service.index';
import { catalog } from 'src/app/models/catalog.model';


@Component({
  selector: 'app-camas-dialog',
  templateUrl: './camas-dialog.component.html',
  styles: []
})
export class CamasDialogComponent implements OnInit {
  @ViewChild("form", { static: true }) form;
  @Output() submit_form = new EventEmitter<FormGroup>();
  @Output() close_modal = new EventEmitter();
  @Input()  show_cancel_button: boolean = false;
  @Input()  cama: catalog;
  options: any = [{label:'Si', value:true}, {label:'No', value:false}];

  camas_form: FormGroup;
  constructor(private _fb: FormBuilder, public _frm:FormsService) { }

  ngOnInit() {
    this.camas_form = this._fb.group({
      id: [this.cama.id],
      nombre: [this.cama.nombre, [Validators.required]],
      codigo: [this.cama.codigo],
      descripcion: [this.cama.descripcion],
      tabla: [this.cama.tabla="cama"],
      claveid: [this.cama.claveid="cama"],
    });
  }
  on_submit() {
    this.submit_form.emit(this.camas_form);
  }
  on_close() {
    this.close_modal.emit();
  }
}
