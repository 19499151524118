import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import {
  SettingsService,
  AddressService,
  AuthorizationService,
  AuthorizationGuard,
  AutenticationGuard,
  FormsService,
  DynamicService,
  NotifyService,
  ConsultaService
} from "./service.index";

@NgModule({
  imports: [CommonModule, HttpClientModule],
  declarations: [],
  providers: [
    SettingsService,
    AddressService,
    AuthorizationService,
    AuthorizationGuard,
    AutenticationGuard,
    FormsService,
    DynamicService,
    NotifyService,
    ConsultaService
  ]
})
export class ServiceModule {}
