import { Component, OnInit, Output, Input, EventEmitter, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { person } from '../../models/authorization.model';

@Component({
  selector: 'app-person',
  templateUrl: './person.component.html',
  styles: []
})
export class PersonComponent implements OnInit {
  @ViewChild("form", { static: true }) form;
  @Output() submit_form = new EventEmitter<FormGroup>();
  @Output() close_modal = new EventEmitter();
  @Input() person: person;
  @Input() show_cancel_button: boolean = false;

  profile_form: FormGroup;

  genders: any = [{name:'Masculino', gender_id:1}, {name:'Femenino', gender_id:2}];
  noPhoto = '/assets/images/no_photo.png';
  constructor( private _fb: FormBuilder) { }

  ngOnInit() {
    this.profile_form = this._fb.group({
      person_id: [this.person.person_id],
      name: [this.person.name, [Validators.required]],
      last_name: [this.person.last_name, Validators.required],
      middle_name: [this.person.middle_name],
      birthdate: [this.person.birthdate ? {
        year: new Date(this.person.birthdate).getFullYear(),
        month: new Date(this.person.birthdate).getMonth() + 1,
        day: new Date(this.person.birthdate).getDate()
      } : ''],
      gender_id: [this.person.gender_id],
      home_phone: [this.person.home_phone],
      mobile_phone: [this.person.mobile_phone],
      email: [this.person.email, [Validators.required, Validators.email]],
      photo: [this.person.photo],
      file: [null],
    });


  }

  
  is_required(control: string): boolean {
    var _validator: any = this.profile_form.controls[control].validator && this.profile_form.controls[control].validator(this.profile_form.controls[control]);
    return _validator && _validator.required;
  }
  is_invalid(control: string): boolean {
    return (this.form.submitted && this.profile_form.controls[control].invalid) || (this.profile_form.controls[control].invalid && (this.profile_form.controls[control].dirty || this.profile_form.controls[control].touched))
  }
  change_file(control: string, files: FileList) {
    this.profile_form.controls[control].patchValue(files.item(0));
  }


  on_submit() {
    this.submit_form.emit(this.profile_form);
  }

  on_close() {
    this.close_modal.emit();
  }

}
